import React, { Component } from "react";
import "../../sass/Reports.scss";
import "../../sass/DropdownHeader.scss";
import axios from "axios";
import moment from "moment";
import DeleteIcon from "../../images/DeleteIconRed.svg";
import ReportIcon from "../../images/ReportsLogo.svg";
import { v4 as uuidv4 } from "uuid";
import CreatableSelect from "react-select/creatable";
import { Col, Row, setConfiguration } from "react-grid-system";
import { NotificationManager } from "react-notifications";
import Select from "react-select";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";
import DragHandlerIcon from "../../images/DragHandler.svg";
import { CurrencyList } from "../../constants/currencylist";

// Redux stuff
import { connect } from "react-redux";
import {
  setTab,
  setActiveTabIndex,
  companyOnboardingSet,
  authUserSet,
} from "../../redux";
import { laneColumns } from "./constants/columns/laneColumns";
import { laneFilters } from "./constants/filters/laneFilters";
import { SlidersHorizontal } from "lucide-react";
import { Plus } from "lucide-react";

setConfiguration({ gutterWidth: "0" });

const DragHandle = sortableHandle(() => (
  <span style={{ cursor: "grabbing" }}>
    <img
      src={DragHandlerIcon}
      alt="::"
      style={{ marginRight: "10px", cursor: "grab" }}
    />
  </span>
));

const SortableItem = sortableElement(({ column, sortButtonClickHandler }) => (
  <th>
    <Row>
      <Col lg={9} xl={9}>
        <DragHandle />
        {column.name}
      </Col>
      <Col lg={3} xl={3}>
        <button
          type="button"
          style={{
            background: "transparent",
            border: "none",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => sortButtonClickHandler(column)}
        >
          <i className="fa fa-long-arrow-up"></i>
          <i className="fa fa-long-arrow-down"></i>
        </button>
      </Col>
    </Row>
  </th>
));

const SortableContainer = sortableContainer(({ children }) => {
  return <thead>{children}</thead>;
});

class ReportsLaneSave extends Component {
  state = {
    filter: "",
    operator: "",
    filter_rules: [
      {
        id: uuidv4(),
        attribute: "owner",
        operator: "only_me",
        value: null,
        type: null,
      },
    ],
    filter_rules_isIn: [
      {
        id: uuidv4(),
        attribute: "owner",
        operator: "only_me",
        value: null,
        type: null,
      },
    ],
    reportsTable: [],
    showColumn: false,
    displayMenu: false,
    errorMessage: false,
    sortKey: null,
    sortDirection: false,
    customFields: [],
    isLoading: false,
    isApplying: false,
    access: "everyone",
    columnsArray: laneColumns,
  };
  wrapperRef = React.createRef();
  showDropdownMenu = (event) => {
    event.preventDefault();
    this.setState({ displayMenu: !this.state.displayMenu });
  };

  hideDropdownMenu = () => {
    this.setState({ displayMenu: false });
  };
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    axios({
      method: "POST",
      url: `/lanes/reports?_limit=30&_page=1`,
      data: {
        filter_rules: this.state.filter_rules_isIn.map((rule) => {
          const { attribute, operator, value } = rule;
          return {
            attribute,
            operator,
            value,
          };
        }),
        sort_key: this.state.sortKey,
        sort_dir: this.state.sortDirection === true ? "desc" : "asc",
      },
    }).then((res) => {
      this.setState({
        reportsTable: res.data.lanes,
      });
      console.log("report preview", res.data.lanes);
    });
    this.setState({
      customFields: this.props.allLaneCustomFields,
    });
    const customField = this.props.allLaneCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field) => {
            const { label, name, id } = field;
            return {
              name: label,
              key: name,
              selected: false,
              custom: true,
              id,
            };
          })
        );
      })
      .filter((m) => m !== false);
    const merged = customField.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArray = merged.filter((m) => m !== false);
    const columnsArray = this.state.columnsArray;
    this.setState({
      columnsArray: columnsArray.concat(customFieldArray),
    });
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside = (event) => {
    if (
      this.state.displayMenu &&
      this.wrapperRef &&
      !this.wrapperRef.current?.contains(event.target)
    ) {
      this.setState({ displayMenu: false });
    }
  };
  getReport = () => {
    if (this.state.filter_rules.length === 0) {
      NotificationManager.error("Atleast one filter should be selected");
      return false;
    }
    const checkError = this.state.filter_rules.every((rule) => {
      const { attribute, operator, value } = rule;
      if (
        attribute === "" ||
        attribute === null ||
        operator === "" ||
        operator === null
      ) {
        this.setState({ errorMessage: true, isApplying: false });
        return false;
      }
      if (
        (operator === "is_in" &&
          (value === null || value === "" || value.length === 0)) ||
        (operator === "is_not_in" &&
          (value === null || value === "" || value.length === 0)) ||
        (operator === "contains" && (value === null || value === "")) ||
        (operator === "not_contains" && (value === null || value === "")) ||
        (operator === "greater_than" && (value === null || value === "")) ||
        (operator === "less_than" && (value === null || value === ""))
      ) {
        this.setState({ errorMessage: true, isApplying: false });
        return false;
      }
      return rule;
    });
    this.setState({ isApplying: true });
    if (checkError === true) {
      axios({
        method: "POST",
        url: `/lanes/reports?_limit=30&_page=1`,
        data: {
          filter_rules: this.state.filter_rules_isIn.map((rule) => {
            const { attribute, operator, value } = rule;
            return {
              attribute,
              operator,
              value,
            };
          }),
          sort_key: this.state.sortKey,
          sort_dir: this.state.sortDirection === true ? "desc" : "asc",
        },
      })
        .then((res) => {
          this.setState({
            reportsTable: res.data.lanes,
            isApplying: false,
          });
        })
        .catch((err) => this.setState({ isApplying: false }));
    } else {
      this.setState({ isApplying: false });
    }
  };
  sortHandler = () => {
    axios({
      method: "POST",
      url: `/lanes/reports?_limit=30&_page=1`,
      data: {
        filter_rules: this.state.filter_rules_isIn.map((rule) => {
          const { attribute, operator, value } = rule;
          return {
            attribute,
            operator,
            value,
          };
        }),
        sort_key: this.state.sortKey,
        sort_dir: this.state.sortDirection === true ? "desc" : "asc",
      },
    }).then((res) => {
      this.setState({
        reportsTable: res.data.lanes,
      });
    });
  };
  sortButtonClickHandler = (column) => {
    this.setState(
      {
        sortKey: column.custom ? column.id : column.key,
        sortDirection: !this.state.sortDirection,
      },
      this.sortHandler
    );
  };
  changeType = (index, e) => {
    const { value } = e.target;
    const fieldType = this.state.customFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.find((field) => field.id === parseInt(value))
        );
      })
      .filter((data) => data !== undefined && data !== false);
    this.setState((prevState) => ({
      filter_rules: prevState.filter_rules.map((rule, i) => {
        if (index === i) {
          rule.type = fieldType[0]?.type;
          rule.choices = fieldType[0]?.choices;
        }
        return rule;
      }),
      filter_rules_isIn: prevState.filter_rules_isIn.map((rule, i) => {
        if (index === i) {
          rule.type = fieldType[0]?.type;
          rule.choices = fieldType[0]?.choices;
        }
        return rule;
      }),
    }));
  };
  changeHandler = (index, e) => {
    const { name, value } = e.target;
    name === "attribute" && this.changeType(index, e);
    this.setState((prevState) => ({
      filter_rules: prevState.filter_rules.map((rule, i) => {
        if (index === i) {
          rule[name] = value;
          if (
            (rule.operator === "contains" ||
              rule.operator === "not_contains") &&
            Array.isArray(rule.value)
          ) {
            rule.value = "";
          }
          if (
            rule.operator === "is_empty" ||
            rule.operator === "is_not_empty" ||
            rule.operator === "today" ||
            rule.operator === "yesterday" ||
            rule.operator === "this_week" ||
            rule.operator === "last_7_days" ||
            rule.operator === "last_30_days" ||
            rule.operator === "greater_than_14_days" ||
            rule.operator === "greater_than_30_days" ||
            rule.operator === "greater_than_60_days" ||
            rule.operator === "this_month" ||
            rule.operator === "this_quarter" ||
            rule.operator === "next_quarter" ||
            rule.operator === "last_quarter" ||
            rule.operator === "this_year" ||
            rule.operator === "only_me" ||
            rule.operator === "my_team" ||
            rule.operator === "everyone" ||
            rule.operator === "last_year"
          ) {
            rule.value = null;
          }
        }
        return rule;
      }),
      filter_rules_isIn: prevState.filter_rules_isIn.map((rule, i) => {
        if (index === i) {
          rule[name] = value;
          if (
            (rule.operator === "contains" ||
              rule.operator === "not_contains") &&
            Array.isArray(rule.value)
          ) {
            rule.value = "";
          }
          if (
            rule.operator === "is_empty" ||
            rule.operator === "is_not_empty" ||
            rule.operator === "today" ||
            rule.operator === "yesterday" ||
            rule.operator === "this_week" ||
            rule.operator === "last_7_days" ||
            rule.operator === "last_30_days" ||
            rule.operator === "greater_than_14_days" ||
            rule.operator === "greater_than_30_days" ||
            rule.operator === "greater_than_60_days" ||
            rule.operator === "this_month" ||
            rule.operator === "this_quarter" ||
            rule.operator === "next_quarter" ||
            rule.operator === "last_quarter" ||
            rule.operator === "this_year" ||
            rule.operator === "only_me" ||
            rule.operator === "my_team" ||
            rule.operator === "everyone" ||
            rule.operator === "last_year"
          ) {
            rule.value = null;
          }
        }
        return rule;
      }),
    }));
  };
  isInChangeHandler = (index, selected) => {
    console.log("selected:", selected);
    let selectedArray =
      selected
        ?.reduce((acc, val) => {
          return [acc, val.value];
        }, [])
        .flat(Infinity) ?? [];
    console.log("QWE:", selectedArray);

    this.setState((prevState) => ({
      filter_rules: prevState.filter_rules.map((rule, i) => {
        if (index === i) {
          rule["value"] = selected;
        }
        return rule;
      }),
      filter_rules_isIn: prevState.filter_rules_isIn.map((rule, i) => {
        if (index === i) {
          rule["value"] = selected?.map((option) => option.value);
        }
        return rule;
      }),
    }));
  };
  valueChangeHandler = (index, valueIndex, e) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      filter_rules: prevState.filter_rules.map((rule, i) => {
        console.log("report filter rule", rule);
        if (index === i) {
          if (rule["value"] === null) {
            rule["value"] = [];
          }

          rule["value"][valueIndex] = value;
        }
        return rule;
      }),
      filter_rules_isIn: prevState.filter_rules_isIn.map((rule, i) => {
        console.log("report filter rule", rule);
        if (index === i) {
          if (rule["value"] === null) {
            rule["value"] = [];
          }

          rule["value"][valueIndex] = value;
        }
        return rule;
      }),
    }));
  };
  addFilter = () => {
    this.setState((prevState) => ({
      filter_rules: [
        ...prevState.filter_rules,
        { id: uuidv4(), attribute: "", operator: "", value: null, type: null },
      ],
      filter_rules_isIn: [
        ...prevState.filter_rules_isIn,
        { id: uuidv4(), attribute: "", operator: "", value: null, type: null },
      ],
    }));
  };

  accessChange = () => {
    const checkboxView = document.getElementById("checkboxView");
    if (checkboxView.checked) {
      this.setState({ access: "everyone" });
    } else {
      this.setState({ access: "only_me" });
    }
  };

  deleteFilter = (index) => {
    this.setState(
      (prevState) => ({
        filter_rules: prevState.filter_rules.filter((rule, i) => i !== index),
        filter_rules_isIn: prevState.filter_rules_isIn.filter(
          (rule, i) => i !== index
        ),
      }),
      () => console.log("this.state.filter_rules", this.state.filter_rules)
    );
  };

  submitHandler = (e) => {
    e.preventDefault();
    if (this.state.filter_rules.length === 0) {
      NotificationManager.error("Atleast one filter should be selected");
      return false;
    }
    const checkError = this.state.filter_rules.every((rule) => {
      const { attribute, operator, value } = rule;
      if (
        attribute === "" ||
        attribute === null ||
        operator === "" ||
        operator === null
      ) {
        this.setState({ errorMessage: true, isLoading: false });
        return false;
      }
      if (
        (operator === "is_in" &&
          (value === null || value === "" || value.length === 0)) ||
        (operator === "is_not_in" &&
          (value === null || value === "" || value.length === 0)) ||
        (operator === "contains" && (value === null || value === "")) ||
        (operator === "not_contains" && (value === null || value === "")) ||
        (operator === "greater_than" && (value === null || value === "")) ||
        (operator === "less_than" && (value === null || value === ""))
      ) {
        this.setState({ errorMessage: true, isLoading: false });
        return false;
      }
      return rule;
    });
    this.setState({ isLoading: true });
    if (checkError === true) {
      axios({
        method: "POST",
        url: `/reports`,
        data: {
          name: this.props.location.state.reportName,
          type: this.props.location.state.reportType,
          description: this.props.location.state.reportDescription,
          access: this.state.access,
          filters: this.state.filter_rules_isIn.map((rule) => {
            return {
              attribute: rule.attribute,
              operator: rule.operator,
              value: rule.value,
            };
          }),
          columns: this.state.columnsArray
            .filter((columns) => columns.selected === true)
            .map((columns) => (columns.custom ? columns.id : columns.key)),
        },
      })
        .then((res) => {
          let newTabData = {
            type: "report",
            id: res.data.reports.id,
            name: res.data.reports.name,
          };

          this.props.setActiveTabIndex(this.props.totalTabs + 1);
          this.props.setTab(newTabData);
          this.props.history.push("/active-tabs");
        })
        .catch((error) => this.setState({ isLoading: false }));
    } else {
      this.setState({ isLoading: false });
    }
  };
  getLaneFieldsOptions = (id) => {
    const laneFields = [
      { label: "Equipment Types", value: "equipment_type_ids" },
      { label: "Special Requirements", value: "requirement_type_ids" },
      { label: "Modes", value: "mode_ids" },
      { label: "Lane Types", value: "type_ids" },
      { label: "Days of Week", value: "days_of_week" },
    ];
    const fieldName = laneFields.find(
      (arr) => arr?.value?.toString() === id?.toString()
    )?.value;
    console.log("fieldName", fieldName, id);
    let options = [];
    if (fieldName === "equipment_type_ids") {
      options = this.props.allEquipmentTypes?.map((type) => {
        return { value: type.id, label: type.name };
      });
    } else if (fieldName === "requirement_type_ids") {
      options = this.props.allSpecialRequirements?.map((type) => {
        return { value: type.id, label: type.name };
      });
    } else if (fieldName === "mode_ids") {
      options = this.props.allModes?.map((type) => {
        return { value: type.id, label: type.name };
      });
    } else if (fieldName === "type_ids") {
      options = this.props.allLaneTypes?.map((type) => {
        return { value: type.id, label: type.name };
      });
    } else if (fieldName === "days_of_week") {
      const days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
      options = days?.map((day) => {
        return { value: day, label: day };
      });
    }
    return options;
  };
  render() {
    console.log(
      "reports save props",
      this.props,
      "reports save state",
      this.state
    );
    const dateFormat =
      this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
        ? "DD/MM/YYYY"
        : "l";
    const currency = this.props.companyOnboarding?.currency;
    const currencySymbol = CurrencyList.find((cur) => cur.code === currency);
    const laneFields = [
      { label: "Equipment Types", value: "equipment_type_ids" },
      { label: "Special Requirements", value: "requirement_type_ids" },
      { label: "Modes", value: "mode_ids" },
      { label: "Lane Types", value: "type_ids" },
      { label: "Days of Week", value: "days_of_week" },
    ];
    const { columnsArray } = this.state;
    const colourStyles = {
      control: (styles) => ({
        ...styles,
        backgroundColor: "white",
        minWidth: "200px",
        outline: "#C0EAD8",
        minHeight: "30px",
        padding: "5px auto",
        transform: "translateY(-10px)",
        marginRight: "12px",
      }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isDisabled
            ? null
            : isSelected
            ? data.color
            : isFocused
            ? "#C0EAD8"
            : null,
          cursor: isDisabled ? "not-allowed" : "default",

          ":active": {
            ...styles[":active"],
            backgroundColor:
              !isDisabled && (isSelected ? data.color : "#C0EAD8"),
          },
        };
      },
      multiValue: (styles, { data }) => {
        return {
          ...styles,
          backgroundColor: "#C0EAD8",
        };
      },
      multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: "black",
        fontSize: "13px",
      }),
    };
    return (
      <div className="reports" style={{ padding: "0px", margin: "0px" }}>
        <div
          style={{
            margin: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="customerInfo-icon-report">
              <img
                src={ReportIcon}
                alt=""
                width="17px"
                style={{ transform: "translateY(9px)" }}
              />
            </div>
            <h1
              className="c-name"
              style={{ lineHeight: "19px", marginBottom: "0" }}
            >
              {this.props.location.state.reportName}
            </h1>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: "10px" }}>
              <span
                className="general-heading"
                style={
                  this.state.access === "only_me"
                    ? {
                        color: "#3AAB7B",
                        fontSize: "20px",
                      }
                    : {
                        color: "#A7ABAA",
                        fontSize: "20px",
                      }
                }
              >
                <i
                  className="fa fa-lock"
                  aria-hidden="true"
                  style={{ transform: "translateY(3px)" }}
                ></i>{" "}
                &nbsp;
              </span>
              <label className="switch">
                <input
                  type="checkbox"
                  value={this.state.access}
                  id="checkboxView"
                  checked={this.state.access === "everyone"}
                  onChange={this.accessChange}
                />
                <span className="slider round"></span>
              </label>
              <span
                className="general-heading"
                style={
                  this.state.access === "everyone"
                    ? {
                        color: "#3AAB7B",
                        fontSize: "20px",
                        transform: "translateY(2px)",
                      }
                    : {
                        color: "#A7ABAA",
                        fontSize: "20px",
                        transform: "translateY(2px)",
                      }
                }
              >
                {" "}
                &nbsp;<i className="fa fa-users" aria-hidden="true"></i>
              </span>
            </div>
            <button
              className="button-md"
              type="button"
              onClick={this.submitHandler}
              disabled={this.state.isLoading}
            >
              {this.state.isLoading ? "Saving ..." : "Save Report"}
            </button>
          </div>
        </div>
        <form>
          <div className="reports-filter">
            <div>
              <h4 style={{ marginTop: "0px" }}>Filters</h4>
            </div>
            {this.state.filter_rules.map((rule, index) => (
              <div style={{ display: "flex" }} key={rule.id}>
                <div>
                  <label>Filter</label>
                  <select
                    name="attribute"
                    onChange={(e) => this.changeHandler(index, e)}
                    value={rule.attribute}
                  >
                    <option hidden>-Select-</option>
                    <option selected disabled>
                      -Select-
                    </option>
                    <optgroup label="Default Fields">
                      {laneFilters.map((filter) => (
                        <option key={filter.value} value={filter.value}>
                          {filter.label}
                        </option>
                      ))}
                    </optgroup>
                    {this.state.customFields.map((group) => {
                      const { label, fields, meta } = group;
                      return (
                        (meta === null || meta.visible_in_form === true) && (
                          <optgroup label={label}>
                            {fields?.map((field) => {
                              const { id, label } = field;
                              return (
                                <option key={id} value={id}>
                                  {label}
                                </option>
                              );
                            })}
                          </optgroup>
                        )
                      );
                    })}
                  </select>
                  {this.state.errorMessage === true &&
                    rule.attribute === "" && (
                      <div
                        style={{
                          display: "block",
                          fontSize: "12px",
                          color: "red",
                        }}
                      >
                        Filters is required field
                      </div>
                    )}
                </div>
                {rule.attribute === "last_edit_date" || rule.type === "date" ? (
                  <div>
                    <label>Operator</label>
                    <select
                      name="operator"
                      onChange={(e) => this.changeHandler(index, e)}
                      value={rule.operator}
                    >
                      <option hidden>-Select-</option>
                      <option selected disabled>
                        -Select-
                      </option>
                      <option value="is">is</option>
                      <option value="is_not">is not</option>
                      <option value="greater_than">greater than</option>
                      <option value="less_than">less than</option>
                      <option value="is_empty">is empty</option>
                      <option value="is_not_empty">is not empty</option>
                      <option value="today">Today</option>
                      <option value="yesterday">Yesterday</option>
                      <option value="this_week">This Week</option>
                      <option value="last_7_days">Last 7 days</option>
                      <option value="last_30_days">Last 30 days</option>
                      <option value="this_month">This Month</option>
                      <option value="this_quarter">This Quarter</option>
                      <option value="next_quarter">Next Quarter</option>
                      <option value="last_quarter">Last Quarter</option>
                      <option value="this_year">This Year</option>
                      <option value="last_year">Last Year</option>
                      <option value="custom_period">Custom Period</option>
                    </select>
                    {this.state.errorMessage === true &&
                      rule.operator === "" && (
                        <div
                          style={{
                            display: "block",
                            fontSize: "12px",
                            color: "red",
                          }}
                        >
                          Operator is required field
                        </div>
                      )}
                  </div>
                ) : rule.attribute === "last_activity_date" ? (
                  <div>
                    <label>Operator</label>
                    <select
                      name="operator"
                      onChange={(e) => this.changeHandler(index, e)}
                      value={rule.operator}
                    >
                      <option hidden>-Select-</option>
                      <option selected disabled>
                        -Select-
                      </option>
                      <option value="is">is</option>
                      <option value="is_not">is not</option>
                      <option value="greater_than">greater than</option>
                      <option value="less_than">less than</option>
                      <option value="is_empty">is empty</option>
                      <option value="is_not_empty">is not empty</option>
                      <option value="today">Today</option>
                      <option value="yesterday">Yesterday</option>
                      <option value="this_week">This Week</option>
                      <option value="last_7_days">Last 7 days</option>
                      <option value="last_30_days">Last 30 days</option>
                      <option value="greater_than_14_days">
                        Greater than last 14 days
                      </option>
                      <option value="greater_than_30_days">
                        Greater than last 30 days
                      </option>
                      <option value="greater_than_60_days">
                        Greater than last 60 days
                      </option>
                      <option value="this_month">This Month</option>
                      <option value="this_quarter">This Quarter</option>
                      <option value="next_quarter">Next Quarter</option>
                      <option value="last_quarter">Last Quarter</option>
                      <option value="this_year">This Year</option>
                      <option value="last_year">Last Year</option>
                      <option value="custom_period">Custom Period</option>
                    </select>
                    {this.state.errorMessage === true &&
                      rule.operator === "" && (
                        <div
                          style={{
                            display: "block",
                            fontSize: "12px",
                            color: "red",
                          }}
                        >
                          Operator is required field
                        </div>
                      )}
                  </div>
                ) : rule.type === "checkbox" ? (
                  <div>
                    <label>Operator</label>
                    <select
                      name="operator"
                      value={rule.operator}
                      onChange={(e) => this.changeHandler(index, e)}
                    >
                      <option hidden>-Select-</option>
                      <option selected disabled>
                        -Select-
                      </option>
                      <option value="is_not_empty">is true</option>
                      <option value="is_empty">is false</option>
                    </select>
                    {this.state.errorMessage === true &&
                      rule.operator === "" && (
                        <div
                          style={{
                            display: "block",
                            fontSize: "12px",
                            color: "red",
                          }}
                        >
                          Operator is required field
                        </div>
                      )}
                  </div>
                ) : rule.type === "decimal" || rule.type === "number" ? (
                  <div>
                    <label>Operator</label>
                    <select
                      name="operator"
                      value={rule.operator}
                      onChange={(e) => this.changeHandler(index, e)}
                    >
                      <option hidden>-Select-</option>
                      <option selected disabled>
                        -Select-
                      </option>
                      <option value="is">is</option>
                      <option value="is_not">is not</option>
                      <option value="greater_than">greater than</option>
                      <option value="less_than">less than</option>
                      <option value="greater_than_equal">
                        equal or greater than
                      </option>
                      <option value="less_than_equal">
                        equal or less than
                      </option>
                      <option value="is_empty">is empty</option>
                      <option value="is_not_empty">is not empty</option>
                    </select>
                    {this.state.errorMessage === true &&
                      rule.operator === "" && (
                        <div
                          style={{
                            display: "block",
                            fontSize: "12px",
                            color: "red",
                          }}
                        >
                          Operator is required field
                        </div>
                      )}
                  </div>
                ) : rule.attribute === "created_date" ? (
                  <div>
                    <label>Operator</label>
                    <select
                      name="operator"
                      value={rule.operator}
                      onChange={(e) => this.changeHandler(index, e)}
                    >
                      <option hidden>-Select-</option>
                      <option selected disabled>
                        -Select-
                      </option>
                      <option value="is">is</option>
                      <option value="is_not">is not</option>
                      <option value="greater_than">greater than</option>
                      <option value="less_than">less than</option>
                      <option value="today">Today</option>
                      <option value="yesterday">Yesterday</option>
                      <option value="this_week">This Week</option>
                      <option value="last_7_days">Last 7 days</option>
                      <option value="last_30_days">Last 30 days</option>
                      <option value="this_month">This Month</option>
                      <option value="this_quarter">This Quarter</option>
                      <option value="next_quarter">Next Quarter</option>
                      <option value="last_quarter">Last Quarter</option>
                      <option value="this_year">This Year</option>
                      <option value="last_year">Last Year</option>
                      <option value="custom_period">Custom Period</option>
                    </select>
                    {this.state.errorMessage === true &&
                      rule.operator === "" && (
                        <div
                          style={{
                            display: "block",
                            fontSize: "12px",
                            color: "red",
                          }}
                        >
                          Operator is required field
                        </div>
                      )}
                  </div>
                ) : rule.attribute === "status_id" ? (
                  <div>
                    <label>Operator</label>
                    <select
                      name="operator"
                      onChange={(e) => this.changeHandler(index, e)}
                      value={rule.operator}
                    >
                      <option hidden>-Select-</option>
                      <option value="" selected disabled>
                        -Select-
                      </option>
                      <option value="is_in">is</option>
                      <option value="is_not_in">is not</option>
                    </select>
                    {this.state.errorMessage === true &&
                      rule.operator === "" && (
                        <div
                          style={{
                            display: "block",
                            fontSize: "12px",
                            color: "red",
                          }}
                        >
                          Operator is required field
                        </div>
                      )}
                  </div>
                ) : rule.attribute === "account_name" ||
                  rule.attribute === "carrier_name" ? (
                  <div>
                    <label>Operator</label>
                    <select
                      name="operator"
                      value={rule.operator}
                      onChange={(e) => this.changeHandler(index, e)}
                    >
                      <option hidden>-Select-</option>
                      <option value="" selected disabled>
                        -Select-
                      </option>
                      <option value="is_in">is</option>
                      <option value="is_not_in">is not</option>
                      <option value="contains">contains</option>
                      <option value="not_contains">does not contain</option>
                    </select>
                    {this.state.errorMessage === true &&
                      rule.operator === "" && (
                        <div
                          style={{
                            display: "block",
                            fontSize: "12px",
                            color: "red",
                          }}
                        >
                          Operator is required field
                        </div>
                      )}
                  </div>
                ) : rule.attribute === "age" ? (
                  <div>
                    <label>Operator</label>
                    <select
                      name="operator"
                      onChange={(e) => this.changeHandler(index, e)}
                      value={rule.operator}
                    >
                      <option hidden>-Select-</option>
                      <option value="" selected disabled>
                        -Select-
                      </option>
                      <option value="is_in">is</option>
                      <option value="is_not_in">is not</option>
                      <option value="greater_than">greater than</option>
                      <option value="less_than">less than</option>
                    </select>
                    {this.state.errorMessage === true &&
                      rule.operator === "" && (
                        <div
                          style={{
                            display: "block",
                            fontSize: "12px",
                            color: "red",
                          }}
                        >
                          Operator is required field
                        </div>
                      )}
                  </div>
                ) : rule.attribute === "owner" ? (
                  <div>
                    <label>Operator</label>
                    <select
                      name="operator"
                      onChange={(e) => this.changeHandler(index, e)}
                      value={rule.operator}
                    >
                      <option hidden>-Select-</option>
                      <option selected disabled>
                        -Select-
                      </option>
                      <option value="only_me">My Records</option>
                      <option value="my_team">My Team's Records</option>
                      <option value="everyone">Everyone’s Records</option>
                      <option value="is_in">is</option>
                      <option value="is_not_in">is not</option>
                      <option value="contains">contains</option>
                      <option value="not_contains">does not contain</option>
                      <option value="is_empty">is empty</option>
                      <option value="is_not_empty">is not empty</option>
                    </select>
                    {this.state.errorMessage === true &&
                      rule.operator === "" && (
                        <div
                          style={{
                            display: "block",
                            fontSize: "12px",
                            color: "red",
                          }}
                        >
                          Operator is required field
                        </div>
                      )}
                  </div>
                ) : (
                  <div>
                    <label>Operator</label>
                    <select
                      name="operator"
                      onChange={(e) => this.changeHandler(index, e)}
                      value={rule.operator}
                    >
                      <option hidden>-Select-</option>
                      <option selected disabled>
                        -Select-
                      </option>
                      <option value="is_in">is</option>
                      <option value="is_not_in">is not</option>
                      <option value="contains">contains</option>
                      <option value="not_contains">does not contain</option>
                      <option value="is_empty">is empty</option>
                      <option value="is_not_empty">is not empty</option>
                    </select>
                    {this.state.errorMessage === true &&
                      rule.operator === "" && (
                        <div
                          style={{
                            display: "block",
                            fontSize: "12px",
                            color: "red",
                          }}
                        >
                          Operator is required field
                        </div>
                      )}
                  </div>
                )}
                {rule.operator === "custom_period" ? (
                  <div style={{ display: "flex" }}>
                    <div>
                      <label>Start Date</label>
                      <input
                        type="date"
                        name="value"
                        onChange={(e) => this.valueChangeHandler(index, 0, e)}
                      />
                    </div>
                    <div>
                      <label>End Date</label>
                      <input
                        type="date"
                        name="value"
                        onChange={(e) => this.valueChangeHandler(index, 1, e)}
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    <label>&nbsp;</label>
                    {rule.operator === "is_empty" ||
                    rule.operator === "is_not_empty" ||
                    rule.operator === "today" ||
                    rule.operator === "yesterday" ||
                    rule.operator === "this_week" ||
                    rule.operator === "last_7_days" ||
                    rule.operator === "last_30_days" ||
                    rule.operator === "greater_than_14_days" ||
                    rule.operator === "greater_than_30_days" ||
                    rule.operator === "greater_than_60_days" ||
                    rule.operator === "this_month" ||
                    rule.operator === "this_quarter" ||
                    rule.operator === "next_quarter" ||
                    rule.operator === "last_quarter" ||
                    rule.operator === "this_year" ||
                    rule.operator === "only_me" ||
                    rule.operator === "my_team" ||
                    rule.operator === "everyone" ||
                    rule.operator === "last_year" ? (
                      <input
                        type="text"
                        name="value"
                        value={(rule.value = null)}
                        disabled={true}
                        onChange={(e) => this.changeHandler(index, e)}
                      />
                    ) : rule.attribute === "age" ? (
                      <input
                        type="number"
                        name="value"
                        value={rule.value}
                        onChange={(e) => this.changeHandler(index, e)}
                      />
                    ) : rule.attribute === "created_date" ||
                      rule.attribute === "last_edit_date" ||
                      rule.attribute === "last_activity_date" ||
                      rule.type === "date" ? (
                      <input
                        type="date"
                        name="value"
                        value={rule.value}
                        onChange={(e) => this.changeHandler(index, e)}
                      />
                    ) : laneFields
                        ?.map((arr) => arr?.value?.toString())
                        .includes(rule.attribute) ? (
                      <Select
                        isMulti
                        value={rule.value}
                        onChange={(selected) =>
                          this.isInChangeHandler(index, selected)
                        }
                        options={this.getLaneFieldsOptions(rule.attribute)}
                        styles={colourStyles}
                        placeholder="Add Value"
                      />
                    ) : rule.operator === "is_in" ||
                      rule.operator === "is_not_in" ? (
                      rule.type === "dropdown" ||
                      rule.type === "multi_select_dropdown" ||
                      rule.type === "radio" ? (
                        <Select
                          isMulti
                          value={rule.value}
                          onChange={(selected) =>
                            this.isInChangeHandler(index, selected)
                          }
                          options={rule?.choices?.map((choice) => {
                            const { value } = choice;
                            return { value, label: value };
                          })}
                          styles={colourStyles}
                          placeholder="Add Value"
                        />
                      ) : rule.attribute === "owner" ? (
                        <Select
                          isMulti
                          value={rule.value}
                          onChange={(selected) =>
                            this.isInChangeHandler(index, selected)
                          }
                          options={this.props.allUsers?.map((user) => {
                            const { name } = user;
                            return { value: name, label: name };
                          })}
                          styles={colourStyles}
                          placeholder="Add Value"
                        />
                      ) : rule.attribute === "status_id" ? (
                        <Select
                          isMulti
                          value={rule.value}
                          onChange={(selected) =>
                            this.isInChangeHandler(index, selected)
                          }
                          options={this.props.allLaneStatuses?.map((status) => {
                            return {
                              label: status.name,
                              value: status.id,
                            };
                          })}
                          styles={colourStyles}
                          placeholder="Add Value"
                        />
                      ) : (
                        <CreatableSelect
                          isMulti
                          value={rule.value}
                          onChange={(selected) =>
                            this.isInChangeHandler(index, selected)
                          }
                          options={rule.choices?.map((choice) => {
                            return { label: choice.value, value: choice.value };
                          })}
                          styles={colourStyles}
                          placeholder="Add Value"
                        />
                      )
                    ) : (
                      <input
                        type="text"
                        name="value"
                        value={rule.value}
                        onChange={(e) => this.changeHandler(index, e)}
                      />
                    )}
                    {this.state.errorMessage === true &&
                      ((rule.operator === "is_in" &&
                        (rule.value === null ||
                          rule.value === "" ||
                          rule.value.length === 0)) ||
                        (rule.operator === "is_not_in" &&
                          (rule.value === null ||
                            rule.value === "" ||
                            rule.value.length === 0)) ||
                        (rule.operator === "contains" &&
                          (rule.value === null || rule.value === "")) ||
                        (rule.operator === "not_contains" &&
                          (rule.value === null || rule.value === "")) ||
                        (rule.operator === "greater_than" &&
                          (rule.value === null || rule.value === "")) ||
                        (rule.operator === "less_than" &&
                          (rule.value === null || rule.value === ""))) && (
                        <div
                          style={{
                            display: "block",
                            fontSize: "12px",
                            color: "red",
                          }}
                        >
                          Value cannot be empty
                        </div>
                      )}
                  </div>
                )}

                <button
                  type="button"
                  onClick={() => this.deleteFilter(index)}
                  style={{
                    background: "transparent",
                    border: "none",
                    borderRadius: "28px",
                    outline: "none",
                    cursor: "pointer",
                    transform: "translateY(12px)",
                  }}
                >
                  <img src={DeleteIcon} alt="Delete" width="16px" />
                </button>
              </div>
            ))}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <button
                type="button"
                onClick={this.addFilter}
                className="button-md-secondary"
                style={{
                  border: "1px solid #EAEEF3",
                  background: "white",
                }}
              >
                Add Filter
                <Plus
                  size={14}
                  color="#62CA9D"
                  strokeWidth={3}
                  style={{ transform: "translateY(2px)", marginLeft: "5px" }}
                />
              </button>
              <button
                type="button"
                className="button-md-secondary"
                style={{
                  border: "1px solid #EAEEF3",
                  background: "white",
                }}
                onClick={() => this.getReport()}
              >
                {this.state.isApplying ? "Applying ..." : "Apply Filter"}
              </button>
            </div>
          </div>
        </form>
        <div style={{ margin: "20px" }}>
          <div style={{ display: "flex" }}>
            <div
              className="dropdownTask"
              ref={this.wrapperRef}
              style={{
                background: "transparent",
                width: "90px",
                height: "39px",
              }}
            >
              <div
                className="button"
                style={{
                  fontSize: "14px",
                  color: "black",
                  width: "90px",
                  right: "0",
                  top: "0",
                }}
                onClick={this.showDropdownMenu}
              >
                <div
                  style={{
                    color: "#212123",
                    borderRadius: "4px",
                    border: "1px solid #E7ECF2",
                    padding: "10px",
                    width: "90px",
                  }}
                  className="reports-column-button"
                >
                  Columns &nbsp;
                  <SlidersHorizontal size={14} />
                </div>
              </div>

              {this.state.displayMenu ? (
                <ul
                  style={{
                    width: "260px",
                    background: "white",
                    paddingLeft: "15px",
                    height: "350px",
                    overflowY: "auto",
                    left: "0px",
                    top: "52px",
                  }}
                >
                  <h4
                    style={{
                      color: "#586874",
                      fontSize: "14px",
                      marginLeft: "0px",
                      textAlign: "center",
                      marginBottom: "10px",
                      borderBottom: "1px solid #586874",
                      paddingBottom: "10px",
                      width: "220px",
                    }}
                  >
                    Displayed Columns
                  </h4>
                  {columnsArray.map((column, index) => {
                    let { name, selected } = column;
                    return (
                      <li
                        style={{
                          width: "200px",
                          fontSize: "12px",
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={selected}
                          id={column.key + index}
                          onChange={() =>
                            this.setState((prevState) => ({
                              columnsArray: prevState.columnsArray.map(
                                (clm) => {
                                  if (clm.key === column.key) {
                                    clm.selected = !clm.selected;
                                  }
                                  return clm;
                                }
                              ),
                            }))
                          }
                          style={{
                            height: "15px",
                            display: "inline-block",
                            width: "auto",
                            marginRight: "5px",
                          }}
                        />
                        <label
                          htmlFor={column.key + index}
                          style={{ display: "inline-block", cursor: "pointer" }}
                        >
                          {name}
                        </label>
                      </li>
                    );
                  })}
                </ul>
              ) : null}
            </div>
          </div>
          {this.state.reportsTable.length === 0 ? (
            <div
              style={{
                margin: "30px auto",
                marginLeft: "35vw",
                fontWeight: "500",
              }}
            >
              No records found.
            </div>
          ) : (
            <div style={{ width: "98vw", height: "auto", overflowX: "auto" }}>
              <table className="accountList__table">
                <SortableContainer
                  useDragHandle
                  axis={"x"}
                  onSortEnd={({ oldIndex, newIndex }) => {
                    this.setState(({ columnsArray }) => ({
                      columnsArray: arrayMove(columnsArray, oldIndex, newIndex),
                    }));
                  }}
                >
                  {columnsArray.map(
                    (column, index) =>
                      column.selected === true && (
                        <SortableItem
                          key={column.key}
                          column={column}
                          index={index}
                          sortButtonClickHandler={this.sortButtonClickHandler}
                        />
                      )
                  )}
                </SortableContainer>
                <tbody>
                  {this.state?.reportsTable.map((report) => {
                    const { id } = report;
                    return (
                      <tr key={id}>
                        {columnsArray.map(
                          (column) =>
                            column.selected === true &&
                            (column.key === "description" ? (
                              <td>
                                {report.description?.length > 140
                                  ? report.description?.substring(0, 140)
                                  : report.description}
                              </td>
                            ) : column.key === "price" ||
                              column.key === "rate_per_mile" ? (
                              <td>
                                {currencySymbol.symbol}
                                {report?.[column.key]?.toLocaleString("en-US")}
                              </td>
                            ) : column.key === "phone" ? (
                              <td>{report.formatted_phone}</td>
                            ) : moment(
                                report[column.key] ||
                                  report.custom_fields[column.key],
                                "YYYY-MM-DDTHH:mm:ss.SSSZ",
                                true
                              ).isValid() ? (
                              <td>
                                {(report[column.key] ||
                                  report.custom_fields[column.key]) &&
                                  moment(
                                    report[column.key] ||
                                      report.custom_fields[column.key]
                                  ).format(dateFormat)}
                              </td>
                            ) : column.key === "equipment_types" ||
                              column.key === "requirement_types" ||
                              column.key === "modes" ||
                              column.key === "types" ? (
                              <td>
                                {report?.[column.key]
                                  ?.map((col) => col.name)
                                  .join(", ")}
                              </td>
                            ) : column.key === "status" ? (
                              <td style={{ textTransform: "capitalize" }}>
                                {report.status}
                              </td>
                            ) : column.key === "account_owners" ||column.key === "carrier_owners"  ? (
                              <td>
                                {report?.[column.key]
                                  ?.map((owner) => owner.name)
                                  ?.join(", ")}
                              </td>
                            ) : (
                              <td>
                                {report.custom_fields[column.key] !==
                                  undefined &&
                                report.custom_fields[column.key] !== null &&
                                report.custom_fields[column.key]
                                  .toString()
                                  .startsWith(";")
                                  ? report.custom_fields[column.key]
                                      .replace(/;/g, ", ")
                                      .substr(1)
                                      .slice(0, -2)
                                  : report[column.key] ||
                                    report.custom_fields[column.key]}
                              </td>
                            ))
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    companyOnboarding: state.login.companyOnboarding,
    authUser: state.login.authUser,
    allEquipmentTypes: state.allEquipmentTypes.equipmentTypes,
    allSpecialRequirements: state.allSpecialRequirements.specialRequirements,
    allModes: state.allModes.modes,
    allUsers: state.allUsers.users,
    allLaneStatuses: state.allLaneStatuses.laneStatuses,
    allLaneCustomFields: state.allLaneCustomFields.fields,
    allLaneTypes: state.allLaneTypes.laneTypes,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};

export default connect(MSP, MDP)(ReportsLaneSave);
