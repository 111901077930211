import React, { Component } from "react";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
// import arrayMove from "array-move";
import DragHandlerIcon from "../../../images/DragHandler.svg";
import DeleteIcon from "../../../images/DeleteIcon.svg";
import EditIcon from "../../../images/Edit.svg";
import LockIcon from "../../../images/LockIcon.svg";
import ReactTooltip from "react-tooltip";

const DragHandle = sortableHandle(() => (
  <span style={{ cursor: "grabbing" }}>
    <img
      src={DragHandlerIcon}
      alt="::"
      style={{ marginRight: "10px", marginTop: "15px", cursor: "grab" }}
    />
  </span>
));
const SortableItem = sortableElement(
  ({ field, handleEditOpenModal, handleDeleteOpenModal }) => {
    const { label, type, id, parent_id } = field;
    let inputType;
    if (type === "text") {
      inputType = <input disabled={true} type="text" />;
    } else if (type === "hyperlink") {
      inputType = <input disabled={true} type="text" />;
    } else if (type === "revenue") {
      inputType = <input disabled={true} type="text" />;
    } else if (type === "paragraph") {
      inputType = <textarea disabled={true}></textarea>;
    } else if (type === "decimal") {
      inputType = <input disabled={true} type="number" />;
    } else if (type === "dropdown") {
      inputType = (
        <select disabled={true}>
          <option>- select -</option>
        </select>
      );
    } else if (type === "checkbox") {
      inputType = <input disabled={true} type="checkbox" />;
    } else if (type === "radio") {
      inputType = <input disabled={true} type="radio" />;
    } else if (type === "date") {
      inputType = <input disabled={true} type="date" />;
    } else if (type === "multi_select_dropdown") {
      inputType = (
        <select disabled={true}>
          <option>- select -</option>
        </select>
      );
    }
    return (
      <li className="lanes-panel-form-1-div" style={{ display: "block" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <DragHandle />
          <ReactTooltip />
          <div key={id} style={{ width: "100%" }}>
            <label>{label}</label>
            {inputType}
          </div>
          <button
            onClick={() => handleEditOpenModal(id, parent_id)}
            style={{ margin: "0 5px" }}
          >
            <img src={EditIcon} alt="Edit Field" width={15} />
          </button>
          <button onClick={() => handleDeleteOpenModal(id, parent_id)}>
            <img src={DeleteIcon} alt="Delete Field" />
          </button>
        </div>
      </li>
    );
  }
);

const SortableContainer = sortableContainer(({ children }) => {
  return <ul className="lanes-panel-form-1">{children}</ul>;
});

class LaneFieldsCustomSort extends Component {
  render() {
    return (
      <div>
        <SortableContainer
          onSortEnd={(params) =>
            this.props.onSortEnd(params, this.props.parent_id)
          }
          useDragHandle
          axis="xy"
        >
          {this.props.fields?.map((field, index) => (
            <SortableItem
              key={`item-${field.id}`}
              index={index}
              field={field}
              handleEditOpenModal={this.props.handleEditOpenModal}
              handleDeleteOpenModal={this.props.handleDeleteOpenModal}
              handleOpenModal={this.props.handleOpenModal}
            />
          ))}
        </SortableContainer>
      </div>
    );
  }
}

export default LaneFieldsCustomSort;
