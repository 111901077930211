import axios from "axios";
import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import Pagination from "react-js-pagination";
import CustomComponentModal from "./CustomComponentModal";
import moment from "moment";
import { debounce } from "lodash";

const AddComponentModal = ({
  showModal,
  handleCloseModal,
  submitHandler,
  modalHeight,
  modalTop,
  loading,
  widgetArray,
  handleAddDefaultComponent,
  isFreight,
  isTmsIntegrated,
}) => {
  // states
  const [reports, setReports] = useState([]);
  const [total, setTotal] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [reportType, setReportType] = useState("table");
  const [componentType, setComponentType] = useState("default");
  const [reportFilter, setReportFilter] = useState("all");
  const [reportQuery, setReportQuery] = useState("");
  const [reportData, setReportData] = useState({});
  const [showCustomComponentModal, setShowCustomComponentModal] =
    useState(false);
  // constants
  const defaultList = [
    { i: "tasks", w: 4, h: 4.7, type: "tasks", label: "Today's Tasks" },
    {
      i: "tasks_old",
      w: 4,
      h: 4.7,
      type: "tasks_old",
      label: "Today's Tasks (scheduled, open, overdue)",
    },
    { i: "lead", w: 4, h: 2.35, type: "lead", label: "Lead Nurturing" },
    { i: "funnel", w: 4, h: 2.35, type: "funnel", label: "Sales Funnel" },
    {
      i: "followup",
      w: 4,
      h: 2.35,
      type: "followup",
      label: "Suggested Follow-ups",
    },
    { i: "targets", w: 4, h: 2.35, type: "targets", label: "Targets" },
    { i: "reasons", w: 4, h: 2.35, type: "reasons", label: "Won/Lost Reasons" },
    {
      i: "activities-period-over-period",
      w: 4,
      h: 4.7,
      type: "activities-period-over-period",
      label: "Activities - Period over Period",
    },
    {
      i: "activities-metrics",
      w: 4,
      h: 2.35,
      type: "activities-metrics",
      label: "Activities Metrics",
    },
    {
      i: "pipeline-metrics",
      w: 4,
      h: 2.35,
      type: "pipeline-metrics",
      label: "Pipeline Metrics",
    },
    {
      i: "pipeline-stage-changes",
      w: 4,
      h: 2.35,
      type: "pipeline-stage-changes",
      label: "Pipeline Stage Changes",
    },
    {
      i: "account-status-changes",
      w: 4,
      h: 2.35,
      type: "account-status-changes",
      label: "Account Status Changes",
    },
    {
      i: "account-status-leaderboard",
      w: 4,
      h: 2.35,
      type: "account-status-leaderboard",
      label: "Account Status Leaderboard",
    },
    {
      i: "activities-leaderboard",
      w: 4,
      h: 2.35,
      type: "activities-leaderboard",
      label: "Activities Leaderboard",
    },
    {
      i: "stage-change-leaderboard",
      w: 4,
      h: 2.35,
      type: "stage-change-leaderboard",
      label: "Stage Change Leaderboard",
    },
    {
      i: "total-shipments-leaderboard",
      w: 4,
      h: 2.35,
      type: "total-shipments-leaderboard",
      label: "Total Shipments Leaderboard",
    },
    {
      i: "last-shipment-date-accounts",
      w: 4,
      h: 2.35,
      type: "last-shipment-date-accounts",
      label: "Accounts by Last Shipment Date",
    },
    {
      i: "shipment-by-sales-rep",
      w: 4,
      h: 2.35,
      type: "shipment-by-sales-rep",
      label: "Shipments by Sales Rep",
    },
    {
      i: "shipment-by-account",
      w: 4,
      h: 2.35,
      type: "shipment-by-account",
      label: "Shipments by Account",
    },
    {
      i: "unassignedNext7Days",
      w: 4,
      h: 2.35,
      type: "unassignedNext7Days",
      label: "Leads Needing Activity (Unassigned Risk)",
    },
    {
      filters: [
        {
          attribute: "owner",
          operator: "is_in",
          value: [{ label: "Hassan Tahawar", value: "Hassan Tahawar" }],
        },
      ],
      h: 2.35,
      height: 2.35,
      i: "default-lead-source-pie",
      name: "Lead Sources (Contacts)",
      reportType: "contact",
      segmentBy: "source",
      type: "pie",
      w: 4,
      width: 4,
      x: 4,
      y: 3,
      yAxis: "",
      label: "Lead Sources (Contacts)",
    },
  ];
  const widgetArrayTypes = widgetArray?.map((arr) => arr.type);
  const modalStyle = {
    content: {
      background: "transparent",
      border: "none",
      width: "0px",
    },
  };
  const getReports = debounce((page = 1, query) => {
    let url = `/reports?_limit=30&_page=${page}&type=${reportFilter}`;
    if (query) {
      url += `&name=${encodeURIComponent(query)}`;
    }
    axios({
      method: "GET",
      url,
    }).then((res) => {
      setReports(res.data.reports);
      setTotal(res.data.total);
    });
  }, 500);
  useEffect(() => {
    getReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportFilter]);
  return (
    <div>
      <ReactModal
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={handleCloseModal}
        style={modalStyle}
        ariaHideApp={false}
      >
        <div className="modal">
          <CustomComponentModal
            showModal={showCustomComponentModal}
            handleCloseModal={() => setShowCustomComponentModal(false)}
            submitHandler={submitHandler}
            modalHeight={90}
            modalTop={3}
            loading={false}
            reportType={reportType}
            setReportType={setReportType}
            reportData={reportData}
          />
          <div
            className="modal__content"
            style={{
              height: `${modalHeight}vh`,
              top: `${modalTop}%`,
              width: "60vw",
              left: "20%",
            }}
          >
            <div className="modal__header">
              <span>
                <div
                  className="customerInfo-icon-modal"
                  style={{
                    transform: "translateY(0px)",
                  }}
                >
                  <i className="fa fa-info" aria-hidden="true"></i>
                </div>
              </span>
              <div className="modal__head">
                <h2 className="modal__heading">Add a component</h2>
              </div>
              <button
                onClick={handleCloseModal}
                className="modal__close"
                style={{
                  background: "transparent",
                  border: "none",
                  outline: "none",
                  cursor: "pointer",
                }}
              >
                {" "}
                <i className="fa fa-times" aria-hidden="true"></i>
              </button>
            </div>
            <p>Please select a component to add to your dashboard</p>
            <div style={{ display: "grid", gridTemplateColumns: "20% 80%" }}>
              <div style={{ borderRight: "1px solid black" }}>
                <div
                  onClick={() => setComponentType("default")}
                  className="addComponent-report-default"
                  style={
                    componentType === "default"
                      ? { fontWeight: "500", marginBottom: "10px" }
                      : { marginBottom: "10px" }
                  }
                >
                  Default Components
                </div>
                <div
                  onClick={() => setComponentType("custom")}
                  className="addComponent-report-default"
                  style={
                    componentType === "custom" ? { fontWeight: "500" } : {}
                  }
                >
                  Custom Components
                </div>
              </div>
              <div>
                {componentType === "default" ? (
                  <div className="addComponent-report">
                    <div className="addComponent-report-list">
                      {defaultList
                        .filter((list) =>
                          !isTmsIntegrated
                            ? list.type !== "total-shipments-leaderboard" &&
                              list.type !== "last-shipment-date-accounts" &&
                              list.type !== "shipment-by-sales-rep" &&
                              list.type !== "shipment-by-account"
                            : list
                        )
                        ?.filter(
                          (list) => !widgetArrayTypes.includes(list.type)
                        )
                        .map((list) => (
                          <li
                            key={list.type}
                            onClick={() =>
                              handleAddDefaultComponent(
                                list.type,
                                list.w,
                                list.h
                              )
                            }
                          >
                            {list.label}
                          </li>
                        ))}
                    </div>
                  </div>
                ) : (
                  <div className="addComponent-report">
                    <div style={{ marginBottom: "10px" }}>
                      <button
                        type="button"
                        onClick={() => setReportFilter("all")}
                        className={
                          reportFilter === "all"
                            ? "addComponent-report-filter-selected"
                            : "addComponent-report-filter"
                        }
                      >
                        All Reports
                      </button>
                      <button
                        type="button"
                        onClick={() => setReportFilter("accounts")}
                        className={
                          reportFilter === "accounts"
                            ? "addComponent-report-filter-selected"
                            : "addComponent-report-filter"
                        }
                      >
                        Accounts
                      </button>
                      {isFreight && (
                        <button
                          type="button"
                          onClick={() => setReportFilter("carriers")}
                          className={
                            reportFilter === "carriers"
                              ? "addComponent-report-filter-selected"
                              : "addComponent-report-filter"
                          }
                        >
                          Carriers
                        </button>
                      )}
                      <button
                        type="button"
                        onClick={() => setReportFilter("opportunities")}
                        className={
                          reportFilter === "opportunities"
                            ? "addComponent-report-filter-selected"
                            : "addComponent-report-filter"
                        }
                      >
                        Deals
                      </button>
                      <button
                        type="button"
                        onClick={() => setReportFilter("contacts")}
                        className={
                          reportFilter === "contacts"
                            ? "addComponent-report-filter-selected"
                            : "addComponent-report-filter"
                        }
                      >
                        Contacts
                      </button>
                      <button
                        type="button"
                        onClick={() => setReportFilter("lanes")}
                        className={
                          reportFilter === "lanes"
                            ? "addComponent-report-filter-selected"
                            : "addComponent-report-filter"
                        }
                      >
                        Lanes
                      </button>
                      <button
                        type="button"
                        onClick={() => setReportFilter("activities")}
                        className={
                          reportFilter === "activities"
                            ? "addComponent-report-filter-selected"
                            : "addComponent-report-filter"
                        }
                      >
                        Activities
                      </button>
                      <input
                        placeholder="Search Report"
                        style={{ display: "inline-block", width: "auto" }}
                        value={reportQuery}
                        onChange={(e) => {
                          setReportQuery(e.target.value);
                          getReports(1, e.target.value);
                          setActivePage(1);
                        }}
                      />
                    </div>
                    <div className="addComponent-report-list">
                      {reports?.map((report) => (
                        <li
                          key={report.id}
                          onClick={() => {
                            setReportData(report);
                            setShowCustomComponentModal(true);
                          }}
                          className="addComponent-custom-component"
                        >
                          <h4>{report.name}</h4>
                          <span>{report.created_by}</span> .{" "}
                          <span>{moment(report.created_at).format("ll")}</span>
                        </li>
                      ))}
                    </div>
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={30}
                      totalItemsCount={total}
                      pageRangeDisplayed={5}
                      onChange={(page) => {
                        setActivePage(page);
                        getReports(page, reportQuery);
                      }}
                      itemClass="page-item"
                      linkClass="page-link"
                      hideDisabled
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="modal__buttons">
              <button
                type="button"
                className="modal__cancel-button"
                onClick={handleCloseModal}
              >
                Cancel
              </button>
              <button
                type="button"
                className="modal__confirm-button"
                disabled={loading}
                onClick={() => submitHandler()}
                style={{ right: "8%" }}
              >
                {loading ? "Confirming" : "Confirm"}
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default AddComponentModal;
