import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { nFormatter } from "../../../../../utils/Helper/reusableFunctions";
import DashboardLoader from "../../../../Loaders/DashboardLoader";

function LanesScoreCardDashboard({
  filter_rules,
  chartRedirectToReport,
  reportWidth = 470,
  reportName,
  reportId,
  userId,
  selectedUsers,
}) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [repName, setRepName] = useState(reportName);
  const getChart = (newValue) => {
    let url = `/charts/lane-chart`;
    axios({
      method: "POST",
      url,
      data: {
        chart: {
          type: "scorecard",
        },
        filter_rules: newValue,
      },
    })
      .then((res) => {
        setLoading(false);
        setData(res.data.lanes);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const getData = () => {
    setLoading(true);
    let newValue = [];
    if (reportId) {
      axios({
        method: "GET",
        url: `/reports/${reportId}`,
      }).then((res) => {
        setRepName(res.data.report.name);
        newValue = res.data.report.filters?.map((rule) => {
          let { attribute, operator, value } = rule;
          if (userId && selectedUsers.length !== 0 && operator === "only_me") {
            operator = "is_in";
            value = selectedUsers.map((user) => user.name);
          }
          return {
            attribute,
            operator,
            value,
          };
        });
        getChart(newValue);
      });
    } else {
      newValue = filter_rules.map((rule) => {
        let { attribute, operator, value } = rule;
        if (
          Array.isArray(value) &&
          (operator === "is_in" || operator === "is_not_in")
        )
          value = value.map((val) => val?.value);
        if (userId && selectedUsers.length !== 0 && operator === "only_me") {
          operator = "is_in";
          value = selectedUsers.map((user) => user.name);
        }
        return {
          attribute,
          operator,
          value,
        };
      });
      getChart(newValue);
    }
  };
  useEffect(() => {
    getData();
  }, [filter_rules, userId]);
  const getFormattedValue = (value) => {
    if (value > 9999) {
      return nFormatter(value, 1)?.toLocaleString("en-US");
    } else {
      return value;
    }
  };
  return (
    <div style={{ width: `${reportWidth * (97 / 100)}px` }}>
      {loading ? (
        <DashboardLoader />
      ) : (
        <div>
          <ReactTooltip />
          <h3
            className="funnel-heading-sub"
            style={{
              marginBottom: "0",
              textDecoration: "underline",
              zIndex: "1",
              cursor: "pointer",
            }}
            onClick={chartRedirectToReport}
          >
            {repName}
          </h3>
          <div
            style={{
              fontSize: "16px",
              fontWeight: "400",
              paddingTop: "70px",
            }}
          >
            <h4 style={{ fontSize: "16px", fontWeight: "400" }}>
              Total Lanes
            </h4>
            <div
              style={{
                fontWeight: "500",
                fontSize: `${reportWidth / 4.5}px`,
                textAlign: "center",
                color: "#4a9876",
              }}
              data-tip={data[0]?.count?.toLocaleString("en-US")}
            >
              {data.length > 0 ? getFormattedValue(data[0]?.count) : 0}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default LanesScoreCardDashboard;
