import React, { Component } from "react";
import ReactModal from "react-modal";
import "../../sass/CustomerModal.scss";
import { withRouter } from "react-router-dom";
import axios from "axios";
import AccountIcon from "../../images/AccountIcon.svg";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { colourStyles } from "../../constants/selectStyles";
import {
  isValidCurrency,
  getCurrencyValue,
} from "../../utils/Helper/reusableFunctions";
import GoogleAutocompleteComponent from "../GoogleComponents/GoogleAutocompleteComponent";
import { AsYouType } from "libphonenumber-js";

// Redux stuff
import { connect } from "react-redux";
import {
  setTab,
  setActiveTabIndex,
  companyOnboardingSet,
  authUserSet,
  fetchAllAllFormCarrierFields,
} from "../../redux";

import { MenuItem, Modal } from "@mui/material";
import InputField from "../Reusables/InputField/InputField";
import MuiButton from "../Reusables/Button/MuiButton";

import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";

import { muiModalStyle } from "../../constants/muiModalStyle";
import { customStylesErr } from "../../constants/selectStyles";

class CreateCarrierCustomModal extends Component {
  state = {
    fieldsData: [],
    name: null,
    mc_number: null,
    status_id: null,
    ownerIds: [],
    email: null,
    phone_ext: null,
    formatted_phone: null,
    equipment_type: [],
    modes: [],
    specialRequirements: [],
    dot_number: null,
    fleet_size: null,
    description: null,
    homeBase: {
      address: null,
      lat: null,
      long: null,
      city: null,
      state: null,
      zip: null,
      country: null,
    },
    customFields: {},
    customFieldsForAPI: {},
    errorMessage: false,
    windowWidth: window.innerWidth,
    isLoading: false,
    phoneValidationError: false,
    emailValidationError: false,
    customFieldRevenueTypeCheck: [],
    menuOpen: "bottom",
    carrierStatusArray: [],
    allFields: [],
    defaultFields: {
      name: null,
      mc_number: null,
      status_id: null,
      ownerIds: [],
      email: null,
      phone_ext: null,
      formatted_phone: null,
      equipment_type: [],
      modes: [],
      specialRequirements: [],
      dot_number: null,
      fleet_size: null,
      description: null,
      homeBase: {
        address: null,
        lat: null,
        long: null,
        city: null,
        state: null,
        zip: null,
        country: null,
      },
    },
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.handleCloseModal();
    this.setState({
      fieldsData: [],
      name: null,
      mc_number: null,
      status: null,
      status_id: null,
      ownerIds: [],
      email: null,
      phone_ext: null,
      formatted_phone: null,
      equipment_type: [],
      modes: [],
      specialRequirements: [],
      dot_number: null,
      fleet_size: null,
      description: null,
      homeBase: {
        address: null,
        lat: null,
        long: null,
        city: null,
        state: null,
        zip: null,
        country: null,
      },
      customFields: {},
      customFieldsForAPI: {},
      errorMessage: false,
      isLoading: false,
      phoneValidationError: false,
      emailValidationError: false,
      defaultFields: {
        name: null,
        mc_number: null,
        status_id: null,
        ownerIds: [],
        email: null,
        phone_ext: null,
        formatted_phone: null,
        equipment_type: [],
        modes: [],
        specialRequirements: [],
        dot_number: null,
        fleet_size: null,
        description: null,
        homeBase: {
          address: null,
          lat: null,
          long: null,
          city: null,
          state: null,
          zip: null,
          country: null,
        },
      },
    });
  };
  fetchCarrierData = () => {
    this.setState({
      fieldsData: this.props.allCarrierCustomFields,
    });
    const customField = this.props.allCarrierCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field) => {
            const { name } = field;
            return name;
          })
        );
      })
      .filter((m) => m !== false);
    const merged = customField.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArray = merged.filter((m) => m !== false);
    const obj = customFieldArray.reduce((accumulator, currentValue) => {
      accumulator[currentValue] = null;
      return accumulator;
    }, {});
    const customFieldTypeCheck = this.props.allCarrierCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field) => field)
        );
      })
      .filter((m) => m !== false);
    const mergedTypeCheck = customFieldTypeCheck.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArrayTypeCheck = mergedTypeCheck.filter(
      (m) => m !== false && m.type === "revenue"
    );
    const objTypeCheck = customFieldArrayTypeCheck.reduce(
      (obj, item) => Object.assign(obj, { [item.name]: item.type }),
      {}
    );
    this.setState((prevState) => ({
      customFields: obj,
      customFieldsForAPI: obj,
      customFieldRevenueTypeCheck: objTypeCheck,
    }));
  };
  fetchRequiredFields = () => {
    axios({
      method: "GET",
      url: `/admin/settings`,
    }).then((res) =>
      this.setState({
        requiredFields: res.data.settings.account,
      })
    );
  };
  getCarrierStatus = () => {
    this.setState((prevState) => ({
      carrierStatusArray: this.props.allCarrierStatuses,
      status_id: this.props.allCarrierStatuses[0]?.id,
      status: this.props.allCarrierStatuses[0]?.name,
      defaultFields: {
        ...prevState.defaultFields,
        status_id: this.props.allCarrierStatuses[0]?.id,
        status: this.props.allCarrierStatuses[0]?.name,
      },
    }));
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize, { passive: true });
    window.addEventListener("mousemove", this.mouseCoordinates, {
      passive: true,
    });
    this.fetchCarrierData();
    this.props.fetchAllAllFormCarrierFields();
    this.getCarrierStatus();
    this.setState({
      allFields: this.props.allCarrierFields,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.showOpportunityModal === true &&
      prevProps.showOpportunityModal !== this.props.showOpportunityModal
    ) {
      this.fetchCarrierData();
      this.setState({
        allFields: this.props.allCarrierFields,
      });
      if (this.state.carrierStatusArray.length) {
        this.setState((prevState) => ({
          defaultFields: {
            ...prevState.defaultFields,
            status_id: this.state.carrierStatusArray[0]?.id,
            status: this.state.carrierStatusArray[0]?.name,
          },
          status_id: this.state.carrierStatusArray[0]?.id,
          status: this.state.carrierStatusArray[0]?.name,
        }));
      }
      if (!this.state.carrierStatusArray.length) {
        this.getCarrierStatus();
      }
    }
    if (
      prevState?.carrierStatusArray.length !==
      this.state.carrierStatusArray.length
    ) {
      this.setState((prevState) => ({
        defaultFields: {
          ...prevState.defaultFields,
          status_id: this.state.carrierStatusArray[0]?.id,
          status: this.state.carrierStatusArray[0]?.name,
        },
        status_id: this.state.carrierStatusArray[0]?.id,
        status: this.state.carrierStatusArray[0]?.name,
      }));
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("mousemove", this.mouseCoordinates);
  }
  mouseCoordinates = (event) => {
    const position = window.innerHeight - event.clientY;
    this.setState({
      menuOpen: position < 450 ? "top" : "bottom",
    });
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };

  getFields = () => {
    this.state.fields.fields.map((item, i) => {
      return console.log(item.label);
    });
  };
  homeBaseChangeHandler = (homeBase) => {
    this.setState((prevState) => ({
      defaultFields: {
        ...prevState.defaultFields,
        homeBase,
      },
    }));
  };
  changeHandlerStatusSelect = (name, value) => {
    this.setState((prevState) => ({
      defaultFields: {
        ...prevState.defaultFields,
        [name]: value.label,
        status_id: value.value,
      },
    }));
  };
  changeHandler = (name, e) => {
    const { value } = e.target;
    if (name === "phone") {
      const formatter = new AsYouType("US"); // Initialize AsYouType instance
      const formattedValue = formatter.input(value); // Format the input value
      this.setState((prevState) => ({
        defaultFields: {
          ...prevState.defaultFields,
          formatted_phone: formattedValue ? formattedValue : null,
        },
      }));
    } else {
      this.setState((prevState) => ({
        defaultFields: {
          ...prevState.defaultFields,
          [name]: e.target.value === "" ? null : e.target.value,
        },
      }));
    }
  };
  changeHandlerSelect = (name, value) => {
    this.setState((prevState) => ({
      defaultFields: { ...prevState.defaultFields, [name]: value.value },
    }));
  };
  customFieldChangeHandler = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      customFields: { ...prevState.customFields, [name]: value },
      customFieldsForAPI: { ...prevState.customFieldsForAPI, [name]: value },
    }));
  };
  customFieldRevenueChangeHandler = (e) => {
    const { name, value } = e.target;
    const checkValidations = isValidCurrency(value);
    if (checkValidations) {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: value },
        customFieldsForAPI: { ...prevState.customFieldsForAPI, [name]: value },
      }));
    } else {
      return;
    }
  };
  customFieldSelectChangeHandler = (name, value) => {
    this.setState((prevState) => ({
      customFields: { ...prevState.customFields, [name]: value },
      customFieldsForAPI: { ...prevState.customFieldsForAPI, [name]: value },
    }));
  };
  customFieldCheckBoxChangeHandler = (e) => {
    const { name, value } = e.target;
    if (e.target.checked === true) {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: value },
        customFieldsForAPI: {
          ...prevState.customFieldsForAPI,
          [name]: value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: null },
        customFieldsForAPI: {
          ...prevState.customFieldsForAPI,
          [name]: null,
        },
      }));
    }
  };
  defaultFieldMultiDropdownChangeHandler = (fieldName, selectedOptions) => {
    let selectedArray =
      selectedOptions
        ?.reduce((acc, val) => {
          return [acc, val.value];
        }, [])
        .flat(Infinity) ?? [];
    this.setState((prevState) => ({
      defaultFields: {
        ...prevState.defaultFields,
        [fieldName]: selectedOptions,
      },
      multiSelectDropdownValue: selectedArray,
    }));
  };
  customFieldMultiDropdownChangeHandler = (fieldName, selectedOptions) => {
    let selectedArray =
      selectedOptions
        ?.reduce((acc, val) => {
          return [acc, val.value];
        }, [])
        .flat(Infinity) ?? [];
    this.setState((prevState) => ({
      customFields: {
        ...prevState.customFields,
        [fieldName]: selectedOptions,
      },
      customFieldsForAPI: {
        ...prevState.customFieldsForAPI,
        [fieldName]:
          ";" + selectedOptions?.map((option) => option.value)?.join(";") + ";",
      },
      multiSelectDropdownValue: selectedArray,
    }));
  };
  customFieldDateChangeHandler = (type, date) => {
    this.setState((prevState) => ({
      customFields: { ...prevState.customFields, [type]: date },
      customFieldsForAPI: { ...prevState.customFieldsForAPI, [type]: date },
    }));
  };
  submitHandler = (e) => {
    e.preventDefault();
    const emailValidator = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,16}$/i;
    if (
      !this.state.defaultFields.ownerIds ||
      this.state.defaultFields.ownerIds.length === 0
    ) {
      this.setState({
        isLoading: false,
        errorMessage: true,
      });
      return false;
    }
    if (
      this.state.defaultFields.email !== null &&
      this.state.defaultFields.email !== "" &&
      !emailValidator.test(this.state.defaultFields.email)
    ) {
      this.setState({
        isLoading: false,
        emailValidationError: true,
      });
      return false;
    }
    if (
      this.state.defaultFields.email !== null &&
      this.state.defaultFields.email !== "" &&
      emailValidator.test(this.state.defaultFields.email)
    ) {
      this.setState({
        emailValidationError: false,
      });
    }
    const data = this.state;
    if (data.defaultFields.name === null || data.defaultFields.name === "") {
      this.setState({ errorMessage: true });
      return;
    }
    const requiredFields = this.state.allFields
      ?.filter((field) => field.required)
      ?.map((field) => field.name);
    const isNotError = requiredFields?.every((field) =>
      Array.isArray(this.state.defaultFields[field])
        ? this.state.defaultFields[field].length !== 0
        : this.state.defaultFields[field] !== null &&
          this.state.defaultFields[field] !== ""
    );
    const isNotErrorCustom = requiredFields?.every((field) =>
      Array.isArray(this.state.customFields[field])
        ? this.state.customFields[field].length !== 0
        : this.state.customFields[field] !== null &&
          this.state.customFields[field] !== ""
    );
    console.log("isNotError", isNotError);
    if (!isNotError || !isNotErrorCustom) {
      this.setState({ errorMessage: true });
      return false;
    }
    let defaultFields = this.state.defaultFields;
    for (let i in defaultFields) {
      if (defaultFields[i] === "") {
        defaultFields[i] = null;
      }
    }
    let customField = this.state.customFieldsForAPI;
    for (let i in customField) {
      if (customField[i] === "") {
        customField[i] = null;
      }
    }
    for (let i in this.state.customFieldRevenueTypeCheck) {
      customField[i] = getCurrencyValue(customField[i]);
    }
    this.setState({ isLoading: true });
    axios({
      method: "POST",
      url: `/carriers/create`,
      data: {
        name: defaultFields.name,
        mc_number: defaultFields.mc_number,
        status_id: defaultFields.status_id,
        owner_ids: defaultFields.ownerIds?.map((id) => id.value),
        email: defaultFields.email,
        phone_ext: defaultFields.phone_ext,
        formatted_phone: defaultFields.formatted_phone,
        equipment_type_ids: defaultFields.equipment_type?.map(
          (type) => type.value
        ),
        mode_ids: defaultFields.modes?.map((type) => type.value),
        requirement_type_ids: defaultFields.specialRequirements?.map(
          (type) => type.value
        ),
        dot_number: defaultFields.dot_number,
        fleet_size: defaultFields.fleet_size,
        description: defaultFields.description,
        city: defaultFields.homeBase.city,
        state: defaultFields.homeBase.state,
        zip: defaultFields.homeBase.zip,
        country: defaultFields.homeBase.country,
        address: defaultFields.homeBase.address,
        lat: defaultFields.homeBase.lat,
        long: defaultFields.homeBase.long,
        custom_fields: customField,
      },
    })
      .then((response) => {
        let newTabData = {
          type: "carrier",
          id: response.data.carrier.id,
          name: response.data.carrier.name,
        };

        this.props.setActiveTabIndex(this.props.totalTabs + 1);
        this.props.setTab(newTabData);
        this.props.history.push("/active-tabs");
        this.handleCloseModal();
      })
      .then((response) => {
        NotificationManager.success("Carrier created successfully!");
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        if (error?.response?.status === 500) {
          NotificationManager.error("Error creating carrier.");
        } else {
          NotificationManager.error(error?.response?.data.message);
        }
      })
      .finally(() => {
        //will uncomment it after API
        this.fetchCarrierData();
      });
  };
  showInput = (
    fieldName,
    fieldLabel,
    fieldType,
    fieldChoices,
    fieldRequired,
    fieldParent
  ) => {
    let inputType;

    const err =
      this.state.errorMessage === true &&
      fieldRequired &&
      (this.state.defaultFields[fieldName] === null ||
        this.state.defaultFields[fieldName] === "" ||
        this.state.defaultFields[fieldName]?.length === 0 ||
        this.state.customFields[fieldName] === null ||
        this.state.customFields[fieldName] === "" ||
        this.state.customFields[fieldName]?.length === 0);

    const emailErr =
      fieldName === "email" &&
      this.state.emailValidationError === true &&
      this.state.defaultFields.email !== "" &&
      this.state.defaultFields.email !== null;

    const phoneErr =
      fieldName === "phone" &&
      this.state.phoneValidationError === true &&
      this.state.defaultFields.phone !== "" &&
      this.state.defaultFields.phone !== null;

    if (fieldName === "address") {
      inputType = (
        <div
          style={{
            width: "97%",
            marginBottom: "15px",
          }}
          className="controlsForms"
        >
          <div className="homeBase">
            <GoogleAutocompleteComponent
              placeholder=""
              onPlaceSelect={this.homeBaseChangeHandler}
            />
            <label className="activeLabel">Home Base </label>
          </div>
        </div>
      );
    } else if (fieldType === "text") {
      inputType = (
        <div style={{ marginBottom: "15px" }}>
          <InputField
            type="text"
            error={
              fieldName === "email"
                ? emailErr
                : fieldName === "phone"
                ? phoneErr
                : err
            }
            helperText={
              fieldName === "email"
                ? "Please enter a valid email address"
                : fieldName === "phone"
                ? "Please enter a valid phone number"
                : `${fieldLabel} is required`
            }
            name={fieldName}
            label={fieldLabel}
            value={
              fieldParent === "default_fields"
                ? this.state.defaultFields[fieldName]
                : this.state.customFields[fieldName]
            }
            onChange={(e) =>
              fieldParent === "default_fields"
                ? this.changeHandler(fieldName, e)
                : this.customFieldChangeHandler(e)
            }
            required={fieldRequired}
          />
        </div>
      );
    } else if (fieldType === "hyperlink") {
      inputType = (
        <div style={{ marginBottom: "15px" }}>
          <InputField
            type="text"
            error={err}
            helperText={`${fieldLabel} is required`}
            name={fieldName}
            label={fieldLabel}
            value={this.state.customFields[fieldName]}
            onChange={(e) =>
              fieldParent === "default_fields"
                ? this.changeHandler(fieldName, e)
                : this.customFieldChangeHandler(e)
            }
            required={fieldRequired}
          />
        </div>
      );
    } else if (fieldType === "revenue") {
      inputType = (
        <div style={{ marginBottom: "15px" }}>
          <InputField
            type="text"
            error={err}
            helperText={`${fieldLabel} is required`}
            name={fieldName}
            label={fieldLabel}
            value={this.state.customFields[fieldName]}
            onChange={(e) => this.customFieldRevenueChangeHandler(e)}
            required={fieldRequired}
          />
        </div>
      );
    } else if (fieldType === "paragraph") {
      inputType = (
        <div style={{ marginBottom: "15px" }}>
          <InputField
            name={fieldName}
            label={fieldLabel}
            error={err}
            helperText={`${fieldLabel} is required`}
            value={this.state.customFields[fieldName]}
            onChange={(e) =>
              fieldParent === "default_fields"
                ? this.changeHandler(fieldName, e)
                : this.customFieldChangeHandler(e)
            }
            required={fieldRequired}
          />
        </div>
      );
    } else if (fieldType === "decimal") {
      inputType = (
        <div style={{ marginBottom: "15px" }}>
          <InputField
            type="number"
            error={err}
            helperText={`${fieldLabel} is required`}
            name={fieldName}
            label={fieldLabel}
            value={this.state.customFields[fieldName]}
            onChange={(e) =>
              fieldParent === "default_fields"
                ? this.changeHandler(fieldName, e)
                : this.customFieldChangeHandler(e)
            }
            required={fieldRequired}
          />
        </div>
      );
    } else if (fieldType === "dropdown") {
      inputType =
        fieldName === "status" ? (
          <div
            className="controlsForms"
            style={{ width: "100%", marginTop: "5px", marginBottom: "10px" }}
          >
            <Select
              className="basic-single"
              classNamePrefix="select"
              styles={colourStyles}
              menuPlacement={this.state.menuOpen}
              value={{
                value: this.state.defaultFields.status_id,
                label: this.state.defaultFields.status,
              }}
              placeholder="-Select-"
              onChange={(selected) =>
                this.changeHandlerStatusSelect("status", selected)
              }
              isClearable={false}
              isSearchable={true}
              options={this.state.carrierStatusArray?.map((status) => ({
                value: status.id,
                label: status.name,
              }))}
            />
            <label className="activeLabel">
              <span style={{ fontSize: "12px" }}>{fieldLabel}</span>{" "}
              {fieldRequired && (
                <span
                  style={{
                    color: "#4A9876",
                    fontSize: "17px",
                    lineHeight: "16px",
                  }}
                >
                  *
                </span>
              )}
            </label>
          </div>
        ) : (
          <div
            className="controlsForms"
            style={{ width: "100%", marginTop: "5px", marginBottom: "10px" }}
          >
            <Select
              className="basic-single"
              classNamePrefix="select"
              styles={colourStyles}
              required={fieldRequired}
              value={{
                value: this.state.customFields[fieldName],
                label: this.state.customFields[fieldName],
              }}
              placeholder="-Select-"
              onChange={(selected) =>
                fieldParent === "default_fields"
                  ? this.changeHandlerSelect(fieldName, selected.value)
                  : this.customFieldSelectChangeHandler(
                      fieldName,
                      selected.value
                    )
              }
              isClearable={false}
              menuPlacement={this.state.menuOpen}
              isSearchable={true}
              name={fieldName}
              options={fieldChoices.map((choice) => {
                const { value } = choice;
                return { value, label: value };
              })}
            />
            <label className="activeLabel">
              <span style={{ fontSize: "12px" }}>{fieldLabel}</span>{" "}
              {fieldRequired && (
                <span
                  style={{
                    color: "#4A9876",
                    fontSize: "17px",
                    lineHeight: "16px",
                  }}
                >
                  *
                </span>
              )}
            </label>
          </div>
        );
    } else if (fieldType === "checkbox") {
      inputType = (
        <div style={{ width: "20px", marginBottom: "15px" }}>
          <label>
            {fieldLabel}{" "}
            {fieldRequired && (
              <span
                style={{
                  color: "#4A9876",
                  fontSize: "17px",
                  lineHeight: "16px",
                }}
              >
                *
              </span>
            )}
          </label>
          <input
            type="checkbox"
            style={{ display: "inline-block", textAlign: "left" }}
            name={fieldName}
            value={true}
            onChange={this.customFieldCheckBoxChangeHandler}
            checked={this.state.customFields[fieldName]}
          />
        </div>
      );
    } else if (fieldType === "radio") {
      inputType = (
        <div>
          <label>
            {fieldLabel}{" "}
            {fieldRequired && (
              <span
                style={{
                  color: "#4A9876",
                  fontSize: "17px",
                  lineHeight: "16px",
                }}
              >
                *
              </span>
            )}
          </label>
          {fieldChoices.map((choice) => {
            const { id, value } = choice;
            return (
              <div key={id} style={{ textAlign: "left", marginBottom: "15px" }}>
                <input
                  type="radio"
                  style={{
                    display: "inline-block",
                    textAlign: "left",
                    width: "20px",
                  }}
                  name={fieldName}
                  value={value}
                  onChange={(e) =>
                    fieldParent === "default_fields"
                      ? this.changeHandler(fieldName, e)
                      : this.customFieldChangeHandler(e)
                  }
                  checked={this.state.customFields[fieldName] === value}
                  id={fieldName + value}
                  required={fieldRequired}
                />
                <label
                  style={{ display: "inline-block", textAlign: "left" }}
                  htmlFor={fieldName + value}
                >
                  {value}
                </label>
              </div>
            );
          })}
        </div>
      );
    } else if (fieldType === "date") {
      inputType = (
        <div
          className="controlsForms"
          style={{ width: "100%", marginTop: "5px", marginBottom: "10px" }}
        >
          <DatePicker
            name={fieldName}
            className="datePicker"
            selected={Date.parse(this.state.customFields[fieldName])}
            value={Date.parse(this.state.customFields[fieldName])}
            onChange={(date) =>
              this.customFieldDateChangeHandler(fieldName, date)
            }
            dateFormat={
              this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
                ? "dd/MM/yyyy"
                : "MM/dd/yyyy"
            }
            placeholderText={
              this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
                ? "dd/mm/yyyy"
                : "mm/dd/yyyy"
            }
            autoComplete="off"
            required={fieldRequired}
            popperPlacement="top"
          />
          <label className="activeLabel">
            <span style={{ fontSize: "12px" }}>{fieldLabel}</span>{" "}
            {fieldRequired && (
              <span
                style={{
                  color: "#4A9876",
                  fontSize: "17px",
                  lineHeight: "16px",
                }}
              >
                *
              </span>
            )}
          </label>
        </div>
      );
    } else if (fieldType === "multi_select_dropdown") {
      let multiSelectDropdownValue = this.state.customFields[fieldName];
      inputType =
        fieldName === "owner_ids" ? (
          <div
            className="controlsForms"
            style={{ width: "100%", marginTop: "5px", marginBottom: "10px" }}
          >
            <Select
              value={this.state.defaultFields.ownerIds}
              closeMenuOnSelect={false}
              isMulti
              name={"ownerIds"}
              styles={colourStyles}
              options={this.props.allUsers?.map((user) => {
                const { name, id } = user;
                return { value: id, label: name };
              })}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
              }}
              placeholder="-Select-"
              className="basic-multi-select"
              classNamePrefix="select"
              required={true}
              onChange={(selectedOption) =>
                this.defaultFieldMultiDropdownChangeHandler(
                  "ownerIds",
                  selectedOption
                )
              }
            />
            <label className="activeLabel">
              <span style={{ fontSize: "12px" }}>{fieldLabel}</span>{" "}
              {fieldRequired && (
                <span
                  style={{
                    color: "#4A9876",
                    fontSize: "17px",
                    lineHeight: "16px",
                  }}
                >
                  *
                </span>
              )}
            </label>
          </div>
        ) : fieldName === "equipment_types" ? (
          <div
            className="controlsForms"
            style={{ width: "100%", marginTop: "5px", marginBottom: "10px" }}
          >
            <Select
              value={this.state.defaultFields.equipment_type}
              closeMenuOnSelect={false}
              isMulti
              name={"equipment_type"}
              styles={colourStyles}
              options={this.props.allEquipmentTypes?.map((type) => {
                return { value: type.id, label: type.name };
              })}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
              }}
              placeholder="-Select-"
              className="basic-multi-select"
              classNamePrefix="select"
              required={true}
              menuPlacement={this.state.menuOpen}
              onChange={(selectedOption) =>
                this.defaultFieldMultiDropdownChangeHandler(
                  "equipment_type",
                  selectedOption
                )
              }
            />
            <label className="activeLabel">
              <span style={{ fontSize: "12px" }}>{fieldLabel}</span>{" "}
              {fieldRequired && (
                <span
                  style={{
                    color: "#4A9876",
                    fontSize: "17px",
                    lineHeight: "16px",
                  }}
                >
                  *
                </span>
              )}
            </label>
          </div>
        ) : fieldName === "modes" ? (
          <div
            className="controlsForms"
            style={{ width: "100%", marginTop: "5px", marginBottom: "10px" }}
          >
            <Select
              value={this.state.defaultFields.modes}
              closeMenuOnSelect={false}
              isMulti
              name={"modes"}
              styles={colourStyles}
              options={this.props.allModes?.map((type) => {
                return { value: type.id, label: type.name };
              })}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
              }}
              placeholder="-Select-"
              className="basic-multi-select"
              classNamePrefix="select"
              required={true}
              menuPlacement={this.state.menuOpen}
              onChange={(selectedOption) =>
                this.defaultFieldMultiDropdownChangeHandler(
                  "modes",
                  selectedOption
                )
              }
            />
            <label className="activeLabel">
              <span style={{ fontSize: "12px" }}>{fieldLabel}</span>{" "}
              {fieldRequired && (
                <span
                  style={{
                    color: "#4A9876",
                    fontSize: "17px",
                    lineHeight: "16px",
                  }}
                >
                  *
                </span>
              )}
            </label>
          </div>
        ) : fieldName === "special_requirements" ? (
          <div
            className="controlsForms"
            style={{ width: "100%", marginTop: "5px", marginBottom: "10px" }}
          >
            <Select
              value={this.state.defaultFields.specialRequirements}
              closeMenuOnSelect={false}
              isMulti
              name={"specialRequirements"}
              styles={colourStyles}
              options={this.props.allSpecialRequirements?.map((type) => {
                return { value: type.id, label: type.name };
              })}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
              }}
              placeholder="-Select-"
              className="basic-multi-select"
              classNamePrefix="select"
              required={true}
              menuPlacement={this.state.menuOpen}
              onChange={(selectedOption) =>
                this.defaultFieldMultiDropdownChangeHandler(
                  "specialRequirements",
                  selectedOption
                )
              }
            />
            <label className="activeLabel">
              <span style={{ fontSize: "12px" }}>{fieldLabel}</span>{" "}
              {fieldRequired && (
                <span
                  style={{
                    color: "#4A9876",
                    fontSize: "17px",
                    lineHeight: "16px",
                  }}
                >
                  *
                </span>
              )}
            </label>
          </div>
        ) : (
          <div
            className="controlsForms"
            style={{ width: "100%", marginTop: "5px", marginBottom: "10px" }}
          >
            <Select
              value={multiSelectDropdownValue}
              closeMenuOnSelect={false}
              isMulti
              name={fieldName}
              styles={err ? customStylesErr : colourStyles}
              options={fieldChoices.map((choice) => {
                const { value } = choice;
                return { value: value, label: value };
              })}
              placeholder="-Select-"
              className="basic-multi-select"
              classNamePrefix="select"
              menuPlacement={this.state.menuOpen}
              required={fieldRequired}
              onChange={(selectedOption) =>
                this.customFieldMultiDropdownChangeHandler(
                  fieldName,
                  selectedOption
                )
              }
            />
            <label className="activeLabel">
              <span style={{ fontSize: "12px", color: err ? "#d32f2f" : "" }}>
                {fieldLabel}
              </span>{" "}
              {fieldRequired && (
                <span
                  style={{
                    color: err ? "#d32f2f" : "#4A9876",
                    fontSize: "17px",
                    lineHeight: "16px",
                  }}
                >
                  *
                </span>
              )}
            </label>
          </div>
        );
    }
    return (
      <>
        {fieldType === "heading" ? (
          <h3 className="modal__heading-sub">{fieldLabel}</h3>
        ) : (
          <div>
            {/* <label>
              {fieldLabel}{" "}
              {fieldRequired && (
                <span
                  style={{
                    color: "#4A9876",
                    fontSize: "17px",
                    lineHeight: "16px",
                  }}
                >
                  *
                </span>
              )}
            </label> */}
            {inputType}
            {/* <div
              style={{
                color: "#F36363",
                fontSize: "12px",
                marginBottom: "5px",
              }}
            >
              {this.state.errorMessage === true &&
              fieldRequired &&
              (this.state.defaultFields[fieldName] === null ||
                this.state.defaultFields[fieldName] === "" ||
                this.state.defaultFields[fieldName]?.length === 0 ||
                this.state.customFields[fieldName] === null ||
                this.state.customFields[fieldName] === "" ||
                this.state.customFields[fieldName]?.length === 0)
                ? `${fieldLabel} is required`
                : null}
            </div> */}
            {/* <div
              style={{
                color: "#F36363",
                fontSize: "12px",
                marginBottom: "5px",
              }}
            >
              {fieldName === "email" &&
              this.state.emailValidationError === true &&
              this.state.defaultFields.email !== "" &&
              this.state.defaultFields.email !== null
                ? `Please enter a valid email address`
                : null}
            </div> */}
            {/* <div
              style={{
                color: "#F36363",
                fontSize: "12px",
                marginBottom: "5px",
              }}
            >
              {fieldName === "phone" &&
              this.state.phoneValidationError === true &&
              this.state.defaultFields.phone !== "" &&
              this.state.defaultFields.phone !== null
                ? `Please enter a valid phone number`
                : null}
            </div> */}
          </div>
        )}
      </>
    );
  };
  render() {
    const flattenedFields =
      this.props.allCarrierCustomFields &&
      Array.isArray(this.props.allCarrierCustomFields) &&
      this.props.allCarrierCustomFields?.reduce((accumulator, section) => {
        if (section.fields && Array.isArray(section.fields)) {
          return [...accumulator, ...section.fields];
        }
        return accumulator;
      }, []);

    const filteredAllFields = this.state.allFields?.map((item) => {
      const matchingItem = flattenedFields?.find(
        (cfItem) => item.id === cfItem.id
      );

      if (matchingItem) {
        // Only take the choices key from matchingItem, keep everything else from item
        return {
          ...item,
          choices: matchingItem.choices,
        };
      }

      return item;
    });
    return (
      <Modal
        open={this.props.showOpportunityModal}
        contentLabel="onRequestClose Example"
        onClose={this.handleCloseModal}
        ariaHideApp={false}
        style={muiModalStyle}
      >
        <div className="muiModal">
          <div className="modal__content">
            <div className="modal__header">
              <div className="modal__head">
                <h2 className="modal__heading">Create Carrier</h2>
              </div>
              <button
                onClick={this.handleCloseModal}
                className="modal__close"
                style={{
                  border: "none",
                  background: "transparent",
                  height: "12px",
                  cursor: "pointer",
                  outline: "none",
                }}
              >
                {" "}
                <ClearIcon fontSize="small" />
              </button>
            </div>
            <form
              className="modal__form"
              style={{
                overflowY: "scroll",
                maxHeight: "60vh",
                height: "auto",
                WebkitOverflowScrolling: "touch",
              }}
            >
              {filteredAllFields?.map((field) => {
                const { id, label, type, choices, name, required, parent_id } =
                  field;
                return (
                  <div key={id}>
                    {this.showInput(
                      name,
                      label,
                      type,
                      choices,
                      required,
                      parent_id
                    )}
                  </div>
                );
              })}
            </form>
            <div className="modal__buttons">
              <button
                onClick={this.handleCloseModal}
                type="reset"
                className="modal__cancel-button"
                style={{ bottom: "10px", left: "5px" }}
              >
                Cancel
              </button>

              <MuiButton
                type="button"
                className="saveBtn"
                style={{
                  position: "absolute",
                  bottom: "10px",
                  width: "auto",
                  height: "35px",
                  color: "white",
                  backgroundColor: "#252a2d",
                }}
                disabled={this.state.isLoading}
                loading={this.state.isLoading}
                onClick={this.submitHandler}
                endIcon={
                  <AddIcon
                    style={{
                      color: this.state.isHovered ? "black" : "#62CA9D",
                    }}
                  />
                }
                onMouseEnter={() => {
                  this.setState({ isHovered: true });
                }}
                onMouseLeave={() => {
                  this.setState({ isHovered: false });
                }}
              >
                {this.state.isLoading ? "Creating..." : "Create"}{" "}
              </MuiButton>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    companyOnboarding: state.login.companyOnboarding,
    authUser: state.login.authUser,
    allCarrierFields: state.allCarrierFields.fields,
    allEquipmentTypes: state.allEquipmentTypes.equipmentTypes,
    allSpecialRequirements: state.allSpecialRequirements.specialRequirements,
    allModes: state.allModes.modes,
    allUsers: state.allUsers.users,
    allCarrierStatuses: state.allCarrierStatuses.carrierStatuses,
    allCarrierCustomFields: state.allCarrierCustomFields.fields,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    fetchAllAllFormCarrierFields: (allCarrierFields) =>
      dispatch(fetchAllAllFormCarrierFields(allCarrierFields)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};

export default connect(MSP, MDP)(withRouter(CreateCarrierCustomModal));
