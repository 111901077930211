import axios from "axios";
import { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import Select from "react-select";
import InputField from "../Reusables/InputField/InputField";

import { Box, Modal } from "@mui/material";

import ClearIcon from "@mui/icons-material/Clear";

const CreateWebhooksModal = ({
  showModal,
  handleCloseModal,
  modalHeight,
  modalTop,
  webhooksLoading: loading,
  setWebhooksLoading: setLoading,
  getAllWebhooks,
  colourStyles,
  allAccountStatus,
  customFields,
  webHooksEvents,
  allUsers,
  dealStages,
}) => {
  const [webhookUrl, setWebhookUrl] = useState("");
  const [description, setDescription] = useState("");
  const [events, setEvents] = useState([]);
  const [conditions, setConditions] = useState([]);

  const [isVerified, setIsVerified] = useState(false);
  const [isActive, setIsActive] = useState(true);

  const [condValues, setCondValues] = useState("");

  const filteredEvents = webHooksEvents?.filter((event) =>
    events?.some((item) => item.value === event.type)
  );

  const modalStyle = {
    content: {
      background: "transparent",
      border: "none",
      width: "0px",
    },
  };

  const eventsChangeHandler = (selectedOptions) => {
    setEvents(selectedOptions);
  };

  const conditionValuesHandler = (val, idFields) => {
    const selectedValueType = val.type.replace(/\.(.)/g, (match, char) =>
      char.toUpperCase()
    );
    if (val) {
      setCondValues((prevValues) => ({
        ...prevValues,
        [selectedValueType]: {
          type: val.type,
          condition: {
            id: val.value,
            value: idFields ? val.value : val.label,
            condId: val.conditionId,
          },
        },
      }));
    }
  };

  let selectedConditionsValue = Object.values(condValues);

  let filteredConditionsValues = selectedConditionsValue
    .filter((event) => filteredEvents?.some((item) => item.type === event.type))
    .map((item) => item);

  const conditionChangeHandler = (selectedCond, type) => {
    const typeExists = events.some((event) => event.value === type);

    if (typeExists) {
      const selectedType = type.replace(/\.(.)/g, (match, char) =>
        char.toUpperCase()
      );

      setConditions((prevState) => ({
        ...prevState,
        [selectedType]: selectedCond,
      }));
    }
  };

  let getdAllConditionsArr = Object.values(conditions);

  let selectedAllConditions = getdAllConditionsArr
    .filter((event) => filteredEvents?.some((item) => item.type === event.type))
    .map((item) => item);

  const checkVerification = () => {
    axios({
      method: "POST",
      url: webhookUrl,
      data: {
        event: "ping",
        timestamp: Date.now(),
        data: {},
      },
    })
      .then((res) => {
        if (res.status === 200) {
          setIsVerified(true);
          NotificationManager.success("Webhook url verified successfully");
        }
      })
      .catch((err) => {
        NotificationManager.error("Error verifying webhook url");
      });
  };

  const submitHandler = () => {
    if (!webhookUrl || webhookUrl === "") {
      setLoading(false);
      return false;
    }

    const allAccountCreated = events?.every((item) =>
      item.value.includes(".created")
    );

    const hasCreatedEvent = events?.some((item) =>
      item.value.includes(".created")
    );

    //managing multiple events

    const formattedEvents = !allAccountCreated
      ? [
          // First spread the existing conditions
          ...filteredConditionsValues?.map((item) => ({
            type: item?.type,
            conditions: [
              {
                id: item.condition.condId,
                value: item.condition.value,
              },
            ],
          })),
          // Then add created events if they exist
          ...(hasCreatedEvent
            ? events
                ?.filter((item) => item.value.includes(".created"))
                .map((eve) => ({ type: eve.value }))
            : []),
        ]
      : events?.map((eve) => ({ type: eve.value }));
    setLoading(true);
    axios({
      method: "POST",
      url: `/webhooks/subscriptions`,
      data: {
        status: isActive ? "active" : "inactive",
        events: formattedEvents,
        webhook_url: webhookUrl,
        description,
      },
    })
      .then((res) => {
        setLoading(false);
        handleCloseModal();
        getAllWebhooks();
        setEvents([]);
        setConditions([]);
        setCondValues();
      })
      .catch((err) => {
        setLoading(false);
        NotificationManager.error("Error saving webhooks.");
      });
  };

  useEffect(() => {
    if (events === null) {
      setConditions([]);
    }
  }, [events]);

  return (
    <div>
      <Modal open={showModal} onClose={handleCloseModal} style={modalStyle}>
        <div className="muiModal">
          <div
            className="modal__content"
            style={{ height: `${modalHeight}vh`, top: `${modalTop}%` }}
          >
            <div className="modal__header">
              <div className="modal__head">
                <h2 className="modal__heading">Create Webhooks</h2>
              </div>
              <button
                onClick={handleCloseModal}
                className="modal__close"
                style={{
                  background: "transparent",
                  border: "none",
                  outline: "none",
                  cursor: "pointer",
                }}
              >
                {" "}
                <ClearIcon fontSize="small" />
              </button>
            </div>
            <Box
              className="modal__form"
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
              style={{
                overflowY: "scroll",
                height: "55vh",
                maxHeight: "60vh",
                WebkitOverflowScrolling: "touch",
              }}
            >
              <div style={{ display: "flex" }}>
                <InputField
                  label="Enter Webhook url"
                  value={webhookUrl}
                  onChange={(e) => setWebhookUrl(e.target.value)}
                />

                <button
                  type="button"
                  className="button-sm"
                  disabled={!webhookUrl}
                  style={
                    !webhookUrl
                      ? {
                          background: "#a7abaa",
                          marginLeft: "10px",
                          marginTop: "5px",
                        }
                      : { marginLeft: "10px", marginTop: "5px" }
                  }
                  onClick={checkVerification}
                >
                  Verify
                </button>
              </div>
              <div>
                <InputField
                  label="Description"
                  name="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  multiline={true}
                />
              </div>
              <div className="controlsForms">
                <Select
                  value={events}
                  closeMenuOnSelect={false}
                  isMulti
                  name={"events"}
                  styles={colourStyles}
                  options={webHooksEvents?.map((choice) => {
                    const { type } = choice;
                    const formatedLabel = type
                      .replace(".", " ")
                      .replace(/\b\w/g, (c) => c.toUpperCase());

                    return { value: type, label: formatedLabel };
                  })}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(selectedOption) =>
                    eventsChangeHandler(selectedOption)
                  }
                />
                <label className="activeLabel" style={{ top: "-14px" }}>
                  <span style={{ fontSize: "12px" }}>Webhooks Events</span>
                </label>
              </div>
              {filteredEvents
                ?.filter((item) => item.type.includes(".updated"))
                ?.map((filItem) => {
                  const formatedLabel = filItem.type
                    .replace(".", " ")
                    .replace(/\b\w/g, (c) => c.toUpperCase());

                  return (
                    <>
                      <div className="controlsForms">
                        <Select
                          value={selectedAllConditions?.find(
                            (find) => find.type === filItem.type
                          )}
                          closeMenuOnSelect={true}
                          isMulti={false}
                          name={"condition"}
                          styles={colourStyles}
                          options={filItem?.conditions.map((cond) => {
                            const { id, description, field } = cond;
                            return {
                              value: id,
                              label: description,
                              field: field,
                              type: filItem.type,
                            };
                          })}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={(selected) =>
                            conditionChangeHandler(selected, filItem.type)
                          }
                        />
                        <label className="activeLabel" style={{ top: "-14px" }}>
                          <span style={{ fontSize: "12px" }}>
                            {formatedLabel + " Condition"}
                          </span>
                        </label>
                      </div>

                      {filItem.type.includes(".updated") &&
                        selectedAllConditions &&
                        selectedAllConditions
                          .filter((filterItem) =>
                            events?.some(
                              (item) => item.value === filterItem.type
                            )
                          )
                          .map((item) => {
                            const formatedLabel = item.type
                              .replace(".", " ")
                              .replace(/\b\w/g, (c) => c.toUpperCase());
                            return (
                              <>
                                {filItem.type.includes("account") &&
                                  item.type.includes("account") && (
                                    <>
                                      {item.field === "status" && (
                                        <div className="controlsForms">
                                          <Select
                                            value={condValues[item.type]}
                                            closeMenuOnSelect={true}
                                            name={"status"}
                                            styles={colourStyles}
                                            options={allAccountStatus.map(
                                              (status) => {
                                                const { id, name } = status;
                                                return {
                                                  value: id,
                                                  label: name,
                                                  type: item.type,
                                                  conditionId: item.value,
                                                };
                                              }
                                            )}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={(selectedStatus) =>
                                              conditionValuesHandler(
                                                selectedStatus
                                              )
                                            }
                                          />
                                          <label
                                            className="activeLabel"
                                            style={{ top: "-14px" }}
                                          >
                                            <span style={{ fontSize: "12px" }}>
                                              {formatedLabel + " Status"}
                                            </span>
                                          </label>
                                        </div>
                                      )}
                                      {item.field === null && (
                                        <div className="controlsForms">
                                          <Select
                                            value={condValues[item.type]}
                                            closeMenuOnSelect={true}
                                            name="customFields"
                                            styles={colourStyles}
                                            options={customFields?.newaccountCustomFields.map(
                                              (status) => {
                                                const { id, name } = status;
                                                return {
                                                  value: id,
                                                  label: name,
                                                  type: item.type,
                                                  conditionId: item.value,
                                                };
                                              }
                                            )}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={(selectedStatus) =>
                                              conditionValuesHandler(
                                                selectedStatus,
                                                "customField"
                                              )
                                            }
                                          />
                                          <label
                                            className="activeLabel"
                                            style={{ top: "-14px" }}
                                          >
                                            <span style={{ fontSize: "12px" }}>
                                              {formatedLabel + " Custom Fields"}
                                            </span>
                                          </label>
                                        </div>
                                      )}
                                      {item?.field?.includes("owner_id") && (
                                        <div className="controlsForms">
                                          <Select
                                            value={condValues[item.type]}
                                            closeMenuOnSelect={true}
                                            name="user"
                                            styles={colourStyles}
                                            options={allUsers.map((status) => {
                                              const { id, name } = status;
                                              return {
                                                value: id,
                                                label: name,
                                                type: item.type,
                                                conditionId: item.value,
                                              };
                                            })}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={(selectedStatus) =>
                                              conditionValuesHandler(
                                                selectedStatus,
                                                "owner_id"
                                              )
                                            }
                                          />
                                          <label
                                            className="activeLabel"
                                            style={{ top: "-14px" }}
                                          >
                                            <span style={{ fontSize: "12px" }}>
                                              {formatedLabel + " User"}
                                            </span>
                                          </label>
                                        </div>
                                      )}
                                    </>
                                  )}
                                {filItem.type.includes("contact") &&
                                  item.type.includes("contact") && (
                                    <>
                                      {item.field === "status" && (
                                        <div className="controlsForms">
                                          <Select
                                            value={condValues[item.type]}
                                            closeMenuOnSelect={true}
                                            name={"status"}
                                            styles={colourStyles}
                                            options={allAccountStatus.map(
                                              (status) => {
                                                const { id, name } = status;
                                                return {
                                                  value: id,
                                                  label: name,
                                                  type: item.type,
                                                  conditionId: item.value,
                                                };
                                              }
                                            )}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={(selectedStatus) =>
                                              conditionValuesHandler(
                                                selectedStatus
                                              )
                                            }
                                          />
                                          <label
                                            className="activeLabel"
                                            style={{ top: "-14px" }}
                                          >
                                            <span style={{ fontSize: "12px" }}>
                                              {formatedLabel + " Status"}
                                            </span>
                                          </label>
                                        </div>
                                      )}
                                      {item.field === null && (
                                        <div className="controlsForms">
                                          <Select
                                            value={condValues[item.type]}
                                            closeMenuOnSelect={true}
                                            name="customFields"
                                            styles={colourStyles}
                                            options={customFields?.newcontactCustomFields.map(
                                              (status) => {
                                                const { id, name } = status;
                                                return {
                                                  value: id,
                                                  label: name,
                                                  type: item.type,
                                                  conditionId: item.value,
                                                };
                                              }
                                            )}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={(selectedStatus) =>
                                              conditionValuesHandler(
                                                selectedStatus,
                                                "customField"
                                              )
                                            }
                                          />
                                          <label
                                            className="activeLabel"
                                            style={{ top: "-14px" }}
                                          >
                                            <span style={{ fontSize: "12px" }}>
                                              {formatedLabel + " Custom Fields"}
                                            </span>
                                          </label>
                                        </div>
                                      )}
                                      {item?.field?.includes("owner_id") && (
                                        <div className="controlsForms">
                                          <Select
                                            value={condValues[item.type]}
                                            closeMenuOnSelect={true}
                                            name="user"
                                            styles={colourStyles}
                                            options={allUsers.map((status) => {
                                              const { id, name } = status;
                                              return {
                                                value: id,
                                                label: name,
                                                type: item.type,
                                                conditionId: item.value,
                                              };
                                            })}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={(selectedStatus) =>
                                              conditionValuesHandler(
                                                selectedStatus,
                                                "owner_id"
                                              )
                                            }
                                          />
                                          <label
                                            className="activeLabel"
                                            style={{ top: "-14px" }}
                                          >
                                            <span style={{ fontSize: "12px" }}>
                                              {formatedLabel + " Users"}
                                            </span>
                                          </label>
                                        </div>
                                      )}
                                    </>
                                  )}
                                {filItem.type.includes("deal") &&
                                  item.type.includes("deal") && (
                                    <>
                                      {item.field === "stage" && (
                                        <div className="controlsForms">
                                          <Select
                                            value={condValues[item.type]}
                                            closeMenuOnSelect={true}
                                            name={"status"}
                                            styles={colourStyles}
                                            options={dealStages.map(
                                              (stages) => {
                                                const { id, name } = stages;
                                                return {
                                                  value: id,
                                                  label: name,
                                                  type: item.type,
                                                  conditionId: item.value,
                                                };
                                              }
                                            )}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={(selected) =>
                                              conditionValuesHandler(
                                                selected,
                                                "dealId"
                                              )
                                            }
                                          />
                                          <label
                                            className="activeLabel"
                                            style={{ top: "-14px" }}
                                          >
                                            <span style={{ fontSize: "12px" }}>
                                              {formatedLabel + " Stages"}
                                            </span>
                                          </label>
                                        </div>
                                      )}

                                      {item.field === null && (
                                        <div className="controlsForms">
                                          <Select
                                            value={condValues[item.type]}
                                            closeMenuOnSelect={true}
                                            name="customFields"
                                            styles={colourStyles}
                                            options={customFields?.newdealCustomFields.map(
                                              (status) => {
                                                const { id, name } = status;
                                                return {
                                                  value: id,
                                                  label: name,
                                                  type: item.type,
                                                  conditionId: item.value,
                                                };
                                              }
                                            )}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={(selectedStatus) =>
                                              conditionValuesHandler(
                                                selectedStatus,
                                                "customField"
                                              )
                                            }
                                          />
                                          <label
                                            className="activeLabel"
                                            style={{ top: "-14px" }}
                                          >
                                            <span style={{ fontSize: "12px" }}>
                                              {formatedLabel + " Custom Fields"}
                                            </span>
                                          </label>
                                        </div>
                                      )}
                                      {item?.field?.includes("owner_id") && (
                                        <div className="controlsForms">
                                          <Select
                                            value={condValues[item.type]}
                                            closeMenuOnSelect={true}
                                            name="user"
                                            styles={colourStyles}
                                            options={allUsers.map((status) => {
                                              const { id, name } = status;
                                              return {
                                                value: id,
                                                label: name,
                                                type: item.type,
                                                conditionId: item.value,
                                              };
                                            })}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={(selectedStatus) =>
                                              conditionValuesHandler(
                                                selectedStatus,
                                                "owner_id"
                                              )
                                            }
                                          />
                                          <label
                                            className="activeLabel"
                                            style={{ top: "-14px" }}
                                          >
                                            <span style={{ fontSize: "12px" }}>
                                              {formatedLabel + " Users"}
                                            </span>
                                          </label>
                                        </div>
                                      )}
                                    </>
                                  )}
                                {filItem.type.includes("carrier") &&
                                  item.type.includes("carrier") && (
                                    <>
                                      {item.field === "status" && (
                                        <div className="controlsForms">
                                          <Select
                                            value={condValues[item.type]}
                                            closeMenuOnSelect={true}
                                            name={"status"}
                                            styles={colourStyles}
                                            options={allAccountStatus.map(
                                              (status) => {
                                                const { id, name } = status;
                                                return {
                                                  value: id,
                                                  label: name,
                                                  type: item.type,
                                                  conditionId: item.value,
                                                };
                                              }
                                            )}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={(selectedStatus) =>
                                              conditionValuesHandler(
                                                selectedStatus
                                              )
                                            }
                                          />
                                          <label
                                            className="activeLabel"
                                            style={{ top: "-14px" }}
                                          >
                                            <span style={{ fontSize: "12px" }}>
                                              {formatedLabel + " Status"}
                                            </span>
                                          </label>
                                        </div>
                                      )}
                                      {item.field === null && (
                                        <div className="controlsForms">
                                          <Select
                                            value={condValues[item.type]}
                                            closeMenuOnSelect={true}
                                            name="customFields"
                                            styles={colourStyles}
                                            options={customFields?.newcarrierCustomFields.map(
                                              (status) => {
                                                const { id, name } = status;
                                                return {
                                                  value: id,
                                                  label: name,
                                                  type: item.type,
                                                  conditionId: item.value,
                                                };
                                              }
                                            )}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={(selectedStatus) =>
                                              conditionValuesHandler(
                                                selectedStatus,
                                                "customField"
                                              )
                                            }
                                          />
                                          <label
                                            className="activeLabel"
                                            style={{ top: "-14px" }}
                                          >
                                            <span style={{ fontSize: "12px" }}>
                                              {formatedLabel + " Custom Fields"}
                                            </span>
                                          </label>
                                        </div>
                                      )}
                                      {item?.field?.includes("owner_id") && (
                                        <div className="controlsForms">
                                          <Select
                                            value={condValues[item.type]}
                                            closeMenuOnSelect={true}
                                            name="user"
                                            styles={colourStyles}
                                            options={allUsers.map((status) => {
                                              const { id, name } = status;
                                              return {
                                                value: id,
                                                label: name,
                                                type: item.type,
                                                conditionId: item.value,
                                              };
                                            })}
                                            placeholder={
                                              formatedLabel + " User"
                                            }
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={(selectedStatus) =>
                                              conditionValuesHandler(
                                                selectedStatus,
                                                "owner_id"
                                              )
                                            }
                                          />
                                          <label
                                            className="activeLabel"
                                            style={{ top: "-14px" }}
                                          >
                                            <span style={{ fontSize: "12px" }}>
                                              {formatedLabel + " Users"}
                                            </span>
                                          </label>
                                        </div>
                                      )}
                                    </>
                                  )}
                              </>
                            );
                          })}
                    </>
                  );
                })}

              <label>Status</label>
              <label className="switch1">
                <input
                  type="checkbox"
                  value={isActive}
                  checked={isActive}
                  onChange={() => setIsActive(!isActive)}
                  style={{ width: "20px", display: "inline-block" }}
                />
                <span
                  className="slider1 round1"
                  style={{ transform: "translateY(4px)" }}
                >
                  <span
                    className="general-heading"
                    style={{
                      display: "flex",
                      marginLeft: "30px",
                      marginTop: "-4px",
                    }}
                  >
                    {" "}
                    &nbsp;Active
                  </span>
                </span>
              </label>
              <div className="modal__buttons">
                <button
                  type="button"
                  className="modal__cancel-button"
                  onClick={handleCloseModal}
                  style={{ zIndex: "auto" }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="modal__confirm-button"
                  disabled={loading || !isVerified || !webhookUrl}
                  onClick={submitHandler}
                  style={
                    loading || !isVerified || !webhookUrl
                      ? { background: "#a7abaa", zIndex: "auto" }
                      : { zIndex: "auto" }
                  }
                >
                  {loading ? "Confirming" : "Confirm"}
                </button>
              </div>
            </Box>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CreateWebhooksModal;
