import React from "react";
import TextField from "@mui/material/TextField";

const InputField = ({
  id,
  error,
  label,
  type,
  name,
  helperText = "",
  placeholder,
  value,
  onChange,
  disabled,
  className = "",
  width = "100%",
  size = "small",
  variant = "outlined",
  pattern,
  multiline,
  required = false,
  autoFocus = false,
  sx,
  onWheel,
}) => {
  return (
    <TextField
      pattern={pattern}
      multiline={multiline}
      error={error}
      helperText={error && helperText}
      sx={sx ? sx : { width }}
      id={id}
      label={label}
      type={type}
      size={size}
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      disabled={disabled}
      autoFocus={autoFocus}
      variant={variant}
      color="success"
      className={className}
      required={required}
      onWheel={onWheel}
    />
  );
};

export default InputField;
