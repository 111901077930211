// ThreeDotsMenu.jsx
import React, { useState, useRef, useEffect } from "react";
import "./ThreeDotsMenu.scss";

const ThreeDotsMenu = ({ items }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="three-dots-menu" ref={menuRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="menu-button"
        aria-label="Menu"
      >
        <svg
          viewBox="0 0 24 24"
          width="18"
          height="18"
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
        >
          <circle cx="12" cy="12" r="1" />
          <circle cx="12" cy="5" r="1" />
          <circle cx="12" cy="19" r="1" />
        </svg>
      </button>

      {isOpen && (
        <div className="dropdown-menu">
          {items.map((item, index) => (
            <button
              key={index}
              onClick={() => {
                item.onClick();
                setIsOpen(false);
              }}
              className="menu-item"
            >
              {item.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default ThreeDotsMenu;
