export const carrierTeminologies = {
  name: ["carrier name", "carrier", "name", "dba name"],
  email: [
    "email",
    "email address",
    "carrier email",
    "carrier email address",
    "dispatch email",
  ],
  formatted_phone: [
    "phone",
    "phone number",
    "telephone",
    "telephone number",
    "carrier phone",
    "carrier phone number",
    "cell",
    "phone #",
    "dispatch phone",
    "main phone",
    "main phone number",
  ],
  phone_ext: ["phone extension", "carrier phone extension"],
  mc_number: ["mc#", "mc #", "carrier mc#", "mc number", "carrier mc #"],
  owners: ["carrier owners", "carrier owner", "owner", "owners"],
  equipment_types: [
    "equipment types",
    "equipment type",
    "equipment",
    "equipments",
  ],
  status: ["status", "carrier status"],
  dot_number: ["dot#", "dot #", "dot number", "carrier dot#", "carrier dot #"],
  fleet_size: ["fleet size", "fleet"],
  description: [
    "description",
    "short description",
    "notes",
    "carrier description",
    "desc.",
  ],
  city: ["city", "carrier city", "home city", "home base city"],
  state: [
    "state",
    "province",
    "carrier state",
    "carrier province",
    "home base state",
    "home base province",
  ],
  zip: [
    "zip",
    "postal code",
    "carrier zip",
    "carrier postal code",
    "home base zip",
    "home base province",
  ],
  country: ["country", "carrier country", "home base country"],
  address: [
    "home base",
    "address",
    "carrier address",
    "carrier home base",
    "full address",
    "physical address",
  ],
  lat: [
    "latitude",
    "lat",
    "carrier latitude",
    "carrier lat",
    "home base lat",
    "lat.",
  ],
  long: [
    "longitude",
    "long",
    "lon",
    "lon.",
    "long.",
    "carrier long",
    "carrier lon",
    "carrier longitude",
    "home base lon",
    "home base long",
  ],
};
