import axios from "axios";
import React, { useEffect, useState } from "react";
import ColumnGraph from "../../Charts/ColumnGraph";
// Redux stuff
import { connect } from "react-redux";

function LanesColumn({
  filter_rules,
  chartType,
  fromDashboard,
  setSegmentByDashboard,
  mode,
  segmentByDashboard,
  viewDashboard,
  fromReport,
  allLaneCustomFields,
}) {
  const [segmentBy, setSegmentBy] = useState("owners");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState("");
  const [customFields, setCustomFields] = useState([]);
  const xAxisValues = [
    { value: "owners", label: "Owners" },
    { value: "account_name", label: "Account" },
    { value: "carrier_name", label: "Carrier" },
    { value: "equipment_types", label: "Equipment Types" },
    { value: "modes", label: "Modes" },
    { value: "special_requirements", label: "Special Requirements" },
    { value: "lane_types", label: "Lane Types" },
    { value: "status", label: "Status" },
    { value: "origin_state", label: "Origin State" },
    { value: "destination_state", label: "Destination State" },
  ]
  const fetchCustomFields = () => {
    const customField = allLaneCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field) => field)
        );
      })
      .filter((m) => m !== false);
    const merged = customField.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArray = merged.filter((m) => m !== false);
    setCustomFields(customFieldArray);
  };
  useEffect(() => {
    fetchCustomFields();
  }, []);
  useEffect(() => {
    const getData = () => {
      let newValue = [];
      newValue = filter_rules.map((rule) => {
        let { attribute, operator, value } = rule;
        if (
          Array.isArray(value) &&
          (operator === "is_in" || operator === "is_not_in")
        )
          value = value.map((val) => val?.value);
        return {
          attribute,
          operator,
          value,
        };
      });
      setLoading(true);
      let url = `/charts/lane-chart`;
      let y_axis = "no_of_lanes";
      axios({
        method: "POST",
        url,
        data: {
          chart: {
            type: "column",
            x_axis: segmentBy,
            y_axis,
          },
          filter_rules: newValue,
        },
      })
        .then((res) => {
          setLoading(false);
          setData(res.data.lanes);
          setRefresh(segmentBy + mode);
        })
        .catch((err) => {
          setLoading(false);
        });
    };
    getData();
    fromDashboard && setSegmentByDashboard(segmentBy);
    fromReport && setRefresh(segmentBy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter_rules, segmentBy]);
  useEffect(() => {
    mode === "edit" &&
      viewDashboard === chartType &&
      setSegmentBy(segmentByDashboard);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <label>X-axis</label>
      <select value={segmentBy} onChange={(e) => setSegmentBy(e.target.value)}>
        {xAxisValues.map((field) => (
          <option value={field.value} key={field.value}>{field.label}</option>
        ))}
        {customFields?.map((field) => (
          <option value={field.id}>{field.label}</option>
        ))}
      </select>
      {loading ? (
        <div class="load-wrapp">
          <div class="load-3">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
        </div>
      ) : (
        <ColumnGraph
          data={data}
          segmentBy={segmentBy}
          type={"Lanes"}
          refresh={refresh}
          chartType={chartType}
          fromDashboard={fromDashboard}
          yAxis={"no_of_lanes"}
        />
      )}
    </div>
  );
}

const MSP = (state) => {
  return {
    allLaneCustomFields: state.allLaneCustomFields.fields,
  };
};
const MDP = () => {
  return {};
};

export default connect(MSP, MDP)(LanesColumn);
