import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { NotificationManager } from "react-notifications";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";

import {
  getCurrencyValue,
  isValidCurrency,
} from "../../utils/Helper/reusableFunctions";

// Redux stuff
import { connect } from "react-redux";
import { companyOnboardingSet, fetchAllAllFormQuoteFields } from "../../redux";

import { MenuItem } from "@mui/material";
import InputField from "../../components/Reusables/InputField/InputField";
import MuiSelect from "../../components/Reusables/Select/MuiSelect";

import moment from "moment";

import { Hidden, Visible } from "react-grid-system";
import { Check } from "lucide-react";
import GoogleAutocomplete from "../Quotes/GoogleAutocomplete";
import { customStylesErr } from "../../constants/selectStyles";

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    outline: "#C0EAD8",
    padding: "1px 0px",
    // margin: "5px auto",
    // height: "35px",
    boxShadow: "none",
    borderColor: "lightgray",
    borderWidth: "1px",
    ":active": {
      ...styles[":active"],
      border: "2px solid #4A9876",
      outline: "#4A9876",
    },
    ":hover": {
      ...styles[":hover"],
      border: "2px solid #4A9876",
    },
    ":focus": {
      ...styles[":hover"],
      border: "2px solid #4A9876",
    },
  }),
  indicatorSeparator: (styles) => {
    return {
      display: "none",
    };
  },
  valueContainer: (styles) => {
    return {
      ...styles,
      // height: "37px",
    };
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? "#3aab7b"
        : isFocused
        ? "#C0EAD8"
        : null,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled && (isSelected ? data.color : "#C0EAD8"),
      },
    };
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "#C0EAD8",
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "black",
  }),
};

class CustomQuote extends Component {
  state = {
    fieldsData: [],
    customFields: {},
    customFieldsForAPI: {},
    errorMessage: false,
    windowWidth: window.innerWidth,
    isLoading: false,
    phoneValidationError: false,
    emailValidationError: false,
    menuOpen: "bottom",
    customFieldRevenueTypeCheck: [],
    defaultFields: {
      ship_date: new Date(),
      delivery_date: null,
      origin: {
        address: null,
        lat: null,
        long: null,
        city: null,
        state: null,
        zip: null,
        country: null,
      },
      destination: {
        address: null,
        lat: null,
        long: null,
        city: null,
        state: null,
        zip: null,
        country: null,
      },
      status: "pending",
      cf_equipment_types: [],
      cf_modes: [],
      cf_lane_type: [],
      cf_special_requirements: [],
      quote: null,
    },
    allFields: [],
    stopFields: [],
    stopLocations: [],
    resetCounter: 0,
  };

  addStopField = () => {
    if (this.state.stopFields.length < 10) {
      this.setState((prevState) => ({
        stopFields: [
          ...prevState.stopFields,
          { id: prevState.stopFields.length + 1 },
        ],
      }));
    }
  };

  handleStopRemoval = (index) => {
    this.setState((prevState) => {
      // Remove field and reindex remaining fields
      const newFields = prevState.stopFields
        .filter((_, i) => i !== index)
        .map((field, i) => ({ id: i + 1 }));

      // Remove stop and compact remaining stops
      const newStops = prevState.stopLocations.filter((_, i) => i !== index);

      return {
        stopFields: newFields.length ? newFields : [],
        stopLocations: newStops.length ? newStops : [],
      };
    });
  };

  handleStopLocationUpdate = (place, index) => {
    this.setState((prevState) => {
      const newStops = [...prevState.stopLocations];
      newStops[index] = place;
      return { stopLocations: newStops };
    });
  };

  fetchAccountData = () => {
    this.setState({
      fieldsData: this.props.allQuoteCustomFields,
    });
    const customField = this.props.allQuoteCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field) => {
            const { name } = field;
            return name;
          })
        );
      })
      .filter((m) => m !== false);
    const merged = customField.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArray = merged.filter((m) => m !== false);
    const obj = customFieldArray.reduce((accumulator, currentValue) => {
      accumulator[currentValue] = null;
      return accumulator;
    }, {});
    const customFieldTypeCheck = this.props.allQuoteCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field) => field)
        );
      })
      .filter((m) => m !== false);
    const mergedTypeCheck = customFieldTypeCheck.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArrayTypeCheck = mergedTypeCheck.filter(
      (m) => m !== false && m.type === "revenue"
    );
    const objTypeCheck = customFieldArrayTypeCheck.reduce(
      (obj, item) => Object.assign(obj, { [item.name]: item.type }),
      {}
    );
    this.setState((prevState) => ({
      customFields: obj,
      customFieldsForAPI: obj,
      customFieldRevenueTypeCheck: objTypeCheck,
    }));
  };

  updateAllFieldsWithStops = () => {
    // Create a new array based on original fields
    const originalFields = this.props.allQuotesFields || [];

    // Find the index of the origin field
    const originIndex = originalFields.findIndex(
      (field) => field.name === "origin"
    );

    if (originIndex === -1) return; // Origin field not found

    // Create stop field objects
    const stopFieldObjects = this.state.stopFields.map((stopField, index) => ({
      id: `stop-${stopField.id}`,
      label: `Stop ${index + 1}`,
      type: "stop_location", // Custom type to identify stop fields
      name: `stop-${stopField.id}`,
      stopIndex: index, // Store the index for reference
    }));

    // Insert stop fields after origin
    const newFields = [
      ...originalFields.slice(0, originIndex + 1),
      ...stopFieldObjects,
      ...originalFields.slice(originIndex + 1),
    ];

    this.setState({ allFields: newFields });
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize, { passive: true });
    window.addEventListener("mousemove", this.mouseCoordinates, {
      passive: true,
    });

    this.props.fetchAllAllFormQuoteFields();
    this.fetchAccountData();
    this.setState((prevState) => ({
      allFields: this.props.allQuotesFields,
    }));
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if allQuoteCustomFields has been loaded or changed
    if (
      prevProps.allQuoteCustomFields !== this.props.allQuoteCustomFields &&
      this.props.allQuoteCustomFields?.length > 0
    ) {
      this.fetchAccountData();
    }

    // Similarly check for allQuotesFields if needed
    if (prevProps.allQuotesFields !== this.props.allQuotesFields) {
      this.setState({ allFields: this.props.allQuotesFields });
    }
    // When stopFields change, update allFields to include them
    if (prevState.stopFields !== this.state.stopFields) {
      this.updateAllFieldsWithStops();
    }
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (
  //     this.props.showOpportunityModal === true &&
  //     prevProps.showOpportunityModal !== this.props.showOpportunityModal
  //   ) {
  //     this.fetchAccountData();
  //     this.setState((prevState) => ({
  //       defaultFields: {
  //         ...prevState.defaultFields,
  //         account_owner: this.props.authUser.id,
  //         country: this.props.companyOnboarding?.country
  //           ? this.props.companyOnboarding?.country
  //           : "United States",
  //       },
  //       allFields: this.props.allQuotesFields,
  //     }));
  //   }
  // }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("mousemove", this.mouseCoordinates);
  }

  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  changeHandler = (name, e) => {
    this.setState((prevState) => ({
      defaultFields: {
        ...prevState.defaultFields,
        [name]: e?.target?.value === "" ? null : e?.target?.value,
      },
    }));
  };

  locationChangeHandler = (name, e) => {
    this.setState((prevState) => ({
      defaultFields: {
        ...prevState.defaultFields,
        [name]: e,
      },
    }));
  };
  accountOwnerMultiDropdownChangeHandler = (selectedOptions) => {
    this.setState((prevState) => ({
      defaultFields: {
        ...prevState.defaultFields,
        sharedAccountOwners: selectedOptions,
      },
    }));
  };
  changeHandlerSelect = (name, value) => {
    this.setState((prevState) => ({
      defaultFields: { ...prevState.defaultFields, [name]: value.value },
    }));
  };
  customFieldChangeHandler = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      customFields: { ...prevState.customFields, [name]: value },
      customFieldsForAPI: { ...prevState.customFieldsForAPI, [name]: value },
    }));
  };
  customFieldRevenueChangeHandler = (e) => {
    const { name, value } = e.target;
    const checkValidations = isValidCurrency(value);
    if (checkValidations) {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: value },
        customFieldsForAPI: { ...prevState.customFieldsForAPI, [name]: value },
      }));
    } else {
      return;
    }
  };
  customFieldSelectChangeHandler = (name, value) => {
    this.setState((prevState) => ({
      customFields: { ...prevState.customFields, [name]: value },
      customFieldsForAPI: { ...prevState.customFieldsForAPI, [name]: value },
    }));
  };
  customFieldCheckBoxChangeHandler = (e) => {
    const { name, value } = e.target;
    if (e.target.checked === true) {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: value },
        customFieldsForAPI: {
          ...prevState.customFieldsForAPI,
          [name]: value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: null },
        customFieldsForAPI: {
          ...prevState.customFieldsForAPI,
          [name]: null,
        },
      }));
    }
  };
  defaultFieldsMultiDropdownChangeHandler = (fieldName, selectedOptions) => {
    let selectedArray =
      selectedOptions
        ?.reduce((acc, val) => {
          return [acc, val.value];
        }, [])
        .flat(Infinity) ?? [];
    this.setState((prevState) => ({
      defaultFields: {
        ...prevState.defaultFields,
        [fieldName]: selectedOptions,
      },
      multiSelectDropdownValue: selectedArray,
    }));
  };
  customFieldMultiDropdownChangeHandler = (fieldName, selectedOptions) => {
    let selectedArray =
      selectedOptions
        ?.reduce((acc, val) => {
          return [acc, val.value];
        }, [])
        .flat(Infinity) ?? [];
    this.setState((prevState) => ({
      customFields: {
        ...prevState.customFields,
        [fieldName]: selectedOptions,
      },
      customFieldsForAPI: {
        ...prevState.customFieldsForAPI,
        [fieldName]:
          ";" + selectedOptions?.map((option) => option.value)?.join(";") + ";",
      },
      multiSelectDropdownValue: selectedArray,
    }));
  };
  customFieldDateChangeHandler = (type, date) => {
    this.setState((prevState) => ({
      customFields: { ...prevState.customFields, [type]: date },
      customFieldsForAPI: { ...prevState.customFieldsForAPI, [type]: date },
    }));
  };
  defaultFieldDateChangeHandler = (type, date) => {
    this.setState((prevState) => ({
      defaultFields: { ...prevState.defaultFields, [type]: date },
    }));
  };
  submitHandler = (e) => {
    e.preventDefault();

    if (
      this.state.defaultFields.origin.address === null ||
      this.state.defaultFields.quote === null
    ) {
      this.setState({ errorMessage: true });
      return;
    }
    const requiredFields = this.state.allFields
      ?.filter((field) => field.required)
      ?.map((field) => field.name);
    const isNotError = requiredFields?.every((field) =>
      Array.isArray(this.state.defaultFields[field])
        ? this.state.defaultFields[field].length !== 0
        : this.state.defaultFields[field] !== null &&
          this.state.defaultFields[field] !== ""
    );
    const isNotErrorCustom = requiredFields?.every((field) =>
      Array.isArray(this.state.customFields[field])
        ? this.state.customFields[field].length !== 0
        : this.state.customFields[field] !== null &&
          this.state.customFields[field] !== ""
    );
    console.log("isNotError", isNotError);
    if (!isNotError || !isNotErrorCustom) {
      this.setState({ errorMessage: true });
      return false;
    }
    let defaultFields = this.state.defaultFields;
    for (let i in defaultFields) {
      if (defaultFields[i] === "") {
        defaultFields[i] = null;
      }
    }
    let customField = this.state.customFieldsForAPI;
    for (let i in customField) {
      if (customField[i] === "") {
        customField[i] = null;
      }
    }
    for (let i in this.state.customFieldRevenueTypeCheck) {
      customField[i] = getCurrencyValue(customField[i]);
    }
    this.setState({ isLoading: true });

    const convertDate = moment(this.state.defaultFields.ship_date).format(
      "YYYY-MM-DD"
    );
    const convertDeliveryDate = this.state.defaultFields.delivery_date
      ? moment(this.state.defaultFields.delivery_date).format("YYYY-MM-DD")
      : null;
    axios({
      method: "POST",
      url: `/activities/create`,
      data: {
        name: "Quote",
        shipment_date: convertDate,
        delivery_date: convertDeliveryDate,
        status: this.state.defaultFields.status.toLowerCase(),
        origin_city: this.state.defaultFields.origin.city,
        origin_state: this.state.defaultFields.origin.state,
        origin_zip: this.state.defaultFields.origin.zip,
        origin_address: this.state.defaultFields.origin.address,
        origin_lat: this.state.defaultFields.origin.lat,
        origin_long: this.state.defaultFields.origin.long,
        destination_city: this.state.defaultFields.destination.city,
        destination_state: this.state.defaultFields.destination.state,
        destination_zip: this.state.defaultFields.destination.zip,
        destination_address: this.state.defaultFields.destination.address,
        destination_lat: this.state.defaultFields.destination.lat,
        destination_long: this.state.defaultFields.destination.long,
        equipment_type_ids: this.state.defaultFields?.cf_equipment_types?.map(
          (item) => item.value
        ),
        mode_ids: this.state.defaultFields?.cf_modes?.map((item) => item.value),
        type_ids: this.state.defaultFields?.cf_lane_type?.map(
          (type) => type.value
        ),
        requirement_type_ids:
          this.state.defaultFields?.cf_special_requirements?.map(
            (type) => type.value
          ),
        price: this.state.defaultFields.quote,
        account_id: this.props.accountId,
        category_id: this.props.categoryId,
        type: "activity_logged",
        stops: this.state.stopLocations,
        custom_fields: customField,
        ...(this.props.opportunity_id !== "" &&
          this.props.opportunity_id !== null && {
            opportunity_id: this.props.opportunity_id,
          }),
        ...(this.props.contact_id !== "" &&
          this.props.contact_id !== null && {
            contact_id: this.props.contact_id,
          }),
      },
    })
      .then((response) => {
        NotificationManager.success("Quote created successfully!");
        this.setState({
          isLoading: false,
          defaultFields: {
            ship_date: new Date(),
            delivery_date: null,
            origin: null,
            destination: null,
            status: "pending",
            cf_equipment_types: [],
            cf_modes: [],
            cf_special_requirements: [],
            cf_lane_type: [],
            quote: null,
          },
          customFields: {},
          resetCounter: this.state.resetCounter + 1,
          stopFields: [],
        });

        this.props.loadActivities(1, "refetch");
        this.props.getAccountLanes(1, "refetch");
        this.props.loadQuoteActivities();
        this.props.getTasks();
        this.props.setFormFields({
          account_id: this.props.accountId,
          opportunity_id: "",
          contact_id: "",
          category_id: 1,
          type: "activity_logged",
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        if (error?.response?.status === 500) {
          NotificationManager.error("Error creating quotes.");
        } else {
          NotificationManager.error(error?.response?.data.message);
        }
      })
      .finally(() => {
        this.fetchAccountData();
      });
  };
  mouseCoordinates = (event) => {
    const position = window.innerHeight - event.clientY;

    this.setState({
      menuOpen: position < 450 ? "top" : "bottom",
    });
  };
  showInput = (
    fieldName,
    fieldLabel,
    fieldType,
    fieldChoices,
    fieldRequired,
    fieldParent,
    stops
  ) => {
    let inputType;
    const err =
      this.state.errorMessage === true &&
      fieldRequired &&
      (this.state.defaultFields[fieldName] === null ||
        this.state.defaultFields[fieldName]?.address === null ||
        this.state.defaultFields[fieldName] === "" ||
        this.state.defaultFields[fieldName]?.length === 0 ||
        this.state.customFields[fieldName] === null ||
        this.state.customFields[fieldName] === "" ||
        this.state.customFields[fieldName]?.length === 0);

    // Check if this is a stop field
    if (fieldType === "stop_location") {
      // Extract the stop index from the field props
      const stopFieldId = fieldName.split("-")[1];
      const stopIndex = this.state.stopFields.findIndex(
        (field) => field.id.toString() === stopFieldId
      );

      if (stopIndex >= 0) {
        return (
          <div className="controlsForms" style={{ width: "100%" }}>
            <div>
              <GoogleAutocomplete
                name={`origin${stopFieldId}`}
                className="formField"
                // valueProps={
                //   this.state.stopLocations[stopIndex]?.address
                //     ? this.state.stopLocations[stopIndex]?.address
                //     : undefined
                // }
                onPlaceSelect={(place) =>
                  this.handleStopLocationUpdate(place, stopIndex)
                }
                placeholder="Enter a stop location"
              />
              <button
                type="button"
                onClick={() => this.handleStopRemoval(stopIndex)}
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  position: "absolute",
                  width: "30px",
                  height: "30px",
                  right: "-25px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 0,
                  color: "#666",
                  zIndex: 2,
                }}
              >
                <span
                  title={`Remove Stop ${stopIndex + 1}`}
                  style={{ fontSize: "20px", lineHeight: 0 }}
                >
                  ×
                </span>
              </button>
            </div>
            <label
              className="activeLabel"
              style={{ top: "-12px", fontSize: "12px" }}
            >
              Stop {stopIndex + 1}
            </label>
          </div>
        );
      }
    } else if (fieldType === "text") {
      inputType =
        fieldName === "origin" ? (
          <div className="controlsForms" style={{ width: "100%" }}>
            <GoogleAutocomplete
              name={fieldName}
              className={err ? "formField-err" : "formField"}
              //valueProps={values?.origin?.address}
              onPlaceSelect={(place) =>
                this.locationChangeHandler(fieldName, place)
              }
              resetTrigger={this.state.resetCounter}
            />
            {this.state.stopFields.length < 10 && (
              <button
                type="button"
                onClick={this.addStopField}
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  position: "absolute",
                  width: "30px",
                  height: "30px",
                  right: "-25px",
                  top: "50%",
                  transform: "translateY(-50%)", // Center vertically
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 0,
                  color: "#666",
                }}
              >
                <span
                  title="Add Stop"
                  style={{ fontSize: "20px", lineHeight: 0 }}
                >
                  +
                </span>
              </button>
            )}

            <label
              className="activeLabel"
              style={{
                color: err ? "#d32f2f" : "",
                top: "-12px",
                fontSize: "12px",
              }}
            >
              {fieldLabel}*
            </label>
          </div>
        ) : fieldName === "destination" ? (
          <div className="controlsForms" style={{ width: "100%" }}>
            <GoogleAutocomplete
              name={fieldName}
              className={err ? "formField-err" : "formField"}
              //valueProps={values?.origin?.address}
              onPlaceSelect={(place) =>
                this.locationChangeHandler(fieldName, place)
              }
              resetTrigger={this.state.resetCounter}
            />

            <label
              className="activeLabel"
              style={{
                color: err ? "#d32f2f" : "",
                top: "-12px",
                fontSize: "12px",
              }}
            >
              {fieldLabel}*
            </label>
          </div>
        ) : (
          <div style={{ marginBottom: "0px" }}>
            <InputField
              type="text"
              name={fieldName}
              sx={{ zIndex: 0 }}
              error={err}
              helperText={`${fieldLabel} is required`}
              label={fieldLabel}
              value={
                fieldParent === "default_fields"
                  ? this.state.defaultFields[fieldName]
                  : this.state.customFields[fieldName]
              }
              onChange={(e) =>
                fieldParent === "default_fields"
                  ? this.changeHandler(fieldName, e)
                  : this.customFieldChangeHandler(e)
              }
              required={fieldRequired}
            />
          </div>
        );
    } else if (fieldType === "hyperlink") {
      inputType = (
        <div style={{ marginBottom: "0px" }}>
          <InputField
            type="text"
            error={err}
            sx={{ zIndex: 0 }}
            helperText={`${fieldLabel} is required`}
            name={fieldName}
            label={fieldLabel}
            value={this.state.customFields[fieldName]}
            onChange={(e) =>
              fieldParent === "default_fields"
                ? this.changeHandler(fieldName, e)
                : this.customFieldChangeHandler(e)
            }
            required={fieldRequired}
          />
        </div>
      );
    } else if (fieldType === "revenue") {
      inputType = (
        <div style={{ marginBottom: "0px" }}>
          <InputField
            type="text"
            error={err}
            sx={{ zIndex: 0 }}
            helperText={`${fieldLabel} is required`}
            name={fieldName}
            label={fieldLabel}
            value={this.state.customFields[fieldName]}
            onChange={(e) => this.customFieldRevenueChangeHandler(e)}
            required={fieldRequired}
          />
        </div>
      );
    } else if (fieldType === "paragraph") {
      inputType = (
        <div style={{ marginBottom: "0px" }}>
          <InputField
            name={fieldName}
            label={fieldLabel}
            sx={{ zIndex: 0 }}
            error={err}
            helperText={`${fieldLabel} is required`}
            value={this.state.customFields[fieldName]}
            onChange={(e) =>
              fieldParent === "default_fields"
                ? this.changeHandler(fieldName, e)
                : this.customFieldChangeHandler(e)
            }
            required={fieldRequired}
            multiline={true}
          />
        </div>
      );
    } else if (fieldType === "decimal") {
      inputType = (
        <div style={{ marginBottom: "0px" }}>
          <InputField
            type="number"
            error={err}
            sx={{ zIndex: 0 }}
            helperText={`${fieldLabel} is required`}
            name={fieldName}
            label={fieldLabel}
            value={
              fieldParent === "default_fields"
                ? this.state.defaultFields[fieldName]
                : this.state.customFields[fieldName]
            }
            onChange={(e) =>
              fieldParent === "default_fields"
                ? this.changeHandler(fieldName, e)
                : this.customFieldChangeHandler(e)
            }
            required={fieldRequired}
            onWheel={(e) => e.target.blur()}
          />
        </div>
      );
    } else if (fieldType === "dropdown") {
      inputType = (
        <MuiSelect
          name={fieldName}
          value={
            fieldParent === "default_fields"
              ? this.state.defaultFields[fieldName]
              : this.state.customFields[fieldName]
          }
          error={err}
          sx={{ margin: 0, zIndex: 0 }}
          helperText={`${fieldLabel} is required`}
          label={fieldLabel}
          onChange={(e) =>
            fieldParent === "default_fields"
              ? this.changeHandler(fieldName, e)
              : this.customFieldChangeHandler(e)
          }
          placeholder={fieldName}
          required={fieldRequired}
          menuItem={fieldChoices.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label || item.value}
            </MenuItem>
          ))}
        />
      );
    } else if (fieldType === "checkbox") {
      inputType = (
        <div style={{ width: "20px", marginBottom: "0px" }}>
          <label>
            {fieldLabel}{" "}
            {fieldRequired && (
              <span
                style={{
                  color: "#4A9876",
                  fontSize: "17px",
                  lineHeight: "16px",
                }}
              >
                *
              </span>
            )}
          </label>
          <input
            type="checkbox"
            style={{ display: "inline-block", textAlign: "left" }}
            name={fieldName}
            value={true}
            onChange={this.customFieldCheckBoxChangeHandler}
            checked={this.state.customFields[fieldName]}
          />
        </div>
      );
    } else if (fieldType === "radio") {
      inputType = (
        <div>
          <label>
            {fieldLabel}{" "}
            {fieldRequired && (
              <span
                style={{
                  color: "#4A9876",
                  fontSize: "17px",
                  lineHeight: "16px",
                }}
              >
                *
              </span>
            )}
          </label>
          {fieldChoices.map((choice) => {
            const { id, value } = choice;
            return (
              <div key={id} style={{ textAlign: "left", marginBottom: "0px" }}>
                <input
                  type="radio"
                  style={{
                    display: "inline-block",
                    textAlign: "left",
                    width: "20px",
                  }}
                  name={fieldName}
                  value={value}
                  onChange={(e) =>
                    fieldParent === "default_fields"
                      ? this.changeHandler(fieldName, e)
                      : this.customFieldChangeHandler(e)
                  }
                  checked={this.state.customFields[fieldName] === value}
                  id={fieldName + value}
                  required={fieldRequired}
                />
                <label
                  style={{ display: "inline-block", textAlign: "left" }}
                  htmlFor={fieldName + value}
                >
                  {value}
                </label>
              </div>
            );
          })}
        </div>
      );
    } else if (fieldType === "date") {
      inputType =
        fieldName === "ship_date" || fieldName === "delivery_date" ? (
          <div
            className="controlsForms"
            style={{
              width: "100%",
              marginBottom: "0px",
              marginTop: "0px",
            }}
          >
            <DatePicker
              name={fieldName}
              className={err ? "datePickerQuotes-err" : "datePickerQuotes"}
              selected={Date.parse(this.state.defaultFields[fieldName])}
              value={Date.parse(this.state.defaultFields[fieldName])}
              onChange={(date) =>
                this.defaultFieldDateChangeHandler(fieldName, date)
              }
              dateFormat={
                this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
                  ? "dd/MM/yyyy"
                  : "MM/dd/yyyy"
              }
              placeholderText={
                this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
                  ? "dd/mm/yyyy"
                  : "mm/dd/yyyy"
              }
              autoComplete="off"
              required={fieldRequired}
              popperPlacement="auto"
              popperProps={{
                modifiers: [
                  {
                    name: "zIndex",
                    options: {
                      zIndex: 10,
                    },
                  },
                ],
              }}
            />
            <label
              className="activeLabel"
              style={{ color: err ? "#d32f2f" : "", fontSize: "12px" }}
            >
              {fieldLabel}
            </label>
            {err && (
              <span
                style={{
                  color: "#d32f2f",
                  fontSize: "12px",
                }}
              >
                {fieldLabel} is required
              </span>
            )}
          </div>
        ) : (
          <div
            className="controlsForms"
            style={{ width: "100%", marginBottom: "0px", marginTop: "0px" }}
          >
            <DatePicker
              name={fieldName}
              className={err ? "datePickerQuotes-err" : "datePickerQuotes"}
              selected={Date.parse(this.state.customFields[fieldName])}
              value={Date.parse(this.state.customFields[fieldName])}
              onChange={(date) =>
                this.customFieldDateChangeHandler(fieldName, date)
              }
              dateFormat={
                this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
                  ? "dd/MM/yyyy"
                  : "MM/dd/yyyy"
              }
              placeholderText={`Select ${fieldLabel}`}
              autoComplete="off"
              required={fieldRequired}
              popperPlacement="auto"
              popperProps={{
                modifiers: [
                  {
                    name: "zIndex",
                    options: {
                      zIndex: 10,
                    },
                  },
                ],
              }}
            />
            <label
              className="activeLabel"
              style={{ color: err ? "#d32f2f" : "", fontSize: "12px" }}
            >
              {fieldLabel}
            </label>
            {err && (
              <span
                style={{
                  color: "#d32f2f",
                  fontSize: "12px",
                }}
              >
                {fieldLabel} is required
              </span>
            )}
          </div>
        );
    } else if (fieldType === "multi_select_dropdown") {
      let multiSelectDropdownValue =
        fieldParent === "default_fields"
          ? this.state.defaultFields[fieldName]
          : this.state.customFields[fieldName];
      let options = [];
      if (
        fieldName === "cf_equipment_type" ||
        fieldName === "cf_equipment_types"
      ) {
        options = this.props.allEquipmentTypes?.map((type) => {
          return { value: type.id, label: type.name };
        });
      } else if (fieldName === "cf_special_requirements") {
        options = this.props.allSpecialRequirements?.map((type) => {
          return { value: type.id, label: type.name };
        });
      } else if (fieldName === "cf_modes") {
        options = this.props.allModes?.map((type) => {
          return { value: type.id, label: type.name };
        });
      } else if (fieldName === "cf_lane_type") {
        options = this.props.allLaneTypes?.map((type) => {
          return { value: type.id, label: type.name };
        });
      } else {
        options = fieldChoices.map((choice) => {
          const { value } = choice;
          return { value, label: value };
        });
      }
      inputType = (
        <div className="controlsForms" style={{ width: "100%" }}>
          <Select
            value={multiSelectDropdownValue}
            closeMenuOnSelect={false}
            isMulti
            name={fieldName}
            styles={err ? customStylesErr : colourStyles}
            options={options}
            placeholder="-Select-"
            className="basic-multi-select"
            classNamePrefix="select"
            menuPlacement={
              fieldName === "cf_equipment_types"
                ? "bottom"
                : this.state.menuOpen
            }
            required={fieldRequired}
            onChange={(selectedOption) =>
              fieldParent === "default_fields"
                ? this.defaultFieldsMultiDropdownChangeHandler(
                    fieldName,
                    selectedOption
                  )
                : this.customFieldMultiDropdownChangeHandler(
                    fieldName,
                    selectedOption
                  )
            }
          />
          <label
            className="activeLabel"
            style={{
              color: err ? "#d32f2f" : "",
              top: "-14px",
              fontSize: "12px",
            }}
          >
            {fieldLabel}
            {fieldRequired && (
              <span
                style={{
                  color: "#4A9876",
                  fontSize: "12px",
                  lineHeight: "16px",
                }}
              >
                *
              </span>
            )}
          </label>
          {err && (
            <span
              style={{
                color: "#d32f2f",
                fontSize: "12px",
              }}
            >
              {fieldLabel} is required
            </span>
          )}
        </div>
      );
    }
    return (
      <>
        {fieldType === "heading" ? (
          <h3 className="modal__heading-sub">{fieldLabel}</h3>
        ) : (
          <div>{inputType}</div>
        )}
      </>
    );
  };

  render() {
    const customFieldsCheck =
      this.props.allQuoteCustomFields &&
      Array.isArray(this.props.allQuoteCustomFields) &&
      this.props.allQuoteCustomFields?.[0]?.fields;

    const filteredAllFields = this.state.allFields?.map((item) => {
      const matchingItem = customFieldsCheck?.find(
        (cfItem) => item.id === cfItem.id
      );

      if (matchingItem) {
        // Only take the choices key from matchingItem, keep everything else from item
        return {
          ...item,
          choices: matchingItem.choices,
        };
      }

      return item;
    });

    return (
      <div className="">
        <form>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "20px",
              marginTop: "5px",
              marginBottom: "20px",
              overflowY: "scroll",
              height: "30vh",
              maxHeight: "40vh",
              padding: "10px",
              WebkitOverflowScrolling: "touch",
            }}
          >
            {filteredAllFields?.map((field) => {
              const { id, label, type, choices, name, required, parent_id } =
                field;

              const isHeading = type === "heading";

              return (
                <div
                  key={id}
                  style={{
                    gridColumn: isHeading ? "1 / -1" : "auto", // Make heading span all columns
                  }}
                >
                  {this.showInput(
                    name,
                    label,
                    type,
                    choices,
                    required,
                    parent_id,
                    this.state.stopFields
                  )}
                </div>
              );
            })}
          </div>
          {/* Save Button */}
          <Hidden md sm xs>
            <div style={{ textAlign: "right" }}>
              <button
                tabIndex="0"
                className="button-md"
                disabled={this.state.isLoading}
                loading={this.state.isLoading}
                onClick={this.submitHandler}
              >
                <span
                  style={{
                    transform: "translateY(-2px)",
                    display: "inline-block",
                  }}
                  disabled={""}
                >
                  {this.state.isLoading ? "Saving" : "Save Quote"}
                </span>
                <Check
                  size={16}
                  color="#62CA9D"
                  style={{
                    transform: "translateY(2px)",
                    marginLeft: "5px",
                  }}
                />
              </button>
            </div>
          </Hidden>
          <Visible md sm xs>
            <div>
              <button
                tabIndex="0"
                className="button-md"
                disabled={this.state.isLoading}
                loading={this.state.isLoading}
                onClick={this.submitHandler}
              >
                <span
                  style={{
                    transform: "translateY(-2px)",
                    display: "inline-block",
                  }}
                  disabled={""}
                >
                  {this.state.isLoading ? "Saving" : "Save Quote"}
                </span>
                <Check
                  size={16}
                  color="#62CA9D"
                  style={{
                    transform: "translateY(2px)",
                    marginLeft: "5px",
                  }}
                />
              </button>
            </div>
          </Visible>
        </form>
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    companyOnboarding: state.login.companyOnboarding,
    allQuotesFields: state.allQuotes.fields,
    allEquipmentTypes: state.allEquipmentTypes.equipmentTypes,
    allSpecialRequirements: state.allSpecialRequirements.specialRequirements,
    allModes: state.allModes.modes,
    allQuoteCustomFields: state.allQuoteCustomFields.fields,
    allLaneTypes: state.allLaneTypes.laneTypes,
  };
};

const MDP = (dispatch) => {
  return {
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
    fetchAllAllFormQuoteFields: (allQuotesFields) =>
      dispatch(fetchAllAllFormQuoteFields(allQuotesFields)),
  };
};

export default connect(MSP, MDP)(withRouter(CustomQuote));
