import React from "react";
import "../../sass/DropdownHeader.scss";
import { Link } from "react-router-dom";
import ReactModal from "react-modal";
import DashboardLogo from "../../images/DashboardGreen.svg";
import AccountNavbar from "../../images/AccountsSettingsIcon.svg";
import BillingIcon from "../../images/BillingSettingsIcon.svg";
import TeamsNavbar from "../../images/TeamsSettingsIcon.svg";
import ContactNavbar from "../../images/ContactsSettingsIcon.svg";
import OpportunityNavbar from "../../images/DealsSettingsIcon.svg";
import CarrierGreenNavIcon from "../../images/CarrierGreenNavIcon.svg";
import aimporticon from "../../images/aimporticon.svg";
import GlossaryIcon from "../../images/GlossaryIcon (1).svg";
import GlossaryIcon1 from "../../images/GlossaryIcon (2).svg";
import LogoutIcon from "../../images/Logout.svg";
import ReactTooltip from "react-tooltip";
import IntegrationSettingsIcon from "../../images/IntegrationSettingsIcon.svg";
import SendEmailIcon from "../../images/AccountEmailIcon.svg";
import CustomFieldIcon from "../../images/CustomFieldIcon.svg";
import Webform from "../../images/WebformMenu.svg";
import NotificationSettingModal from "../NotificationSetting/NotificationSettingModal";
import PasswordConfirmModal from "../2FA/PasswordConfirmModal";
import VerifyOtp from "../2FA/VerifyOtp";
import Disable2faModal from "../2FA/Disable2faModal";
import axios from "axios";
import MobileEntryModal from "../2FA/MobileEntryModal";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";

// Redux
import { connect } from "react-redux";
import { authUserSet, authTokenSet, companyOnboardingSet } from "../../redux";
import LaneIcon from "../../svg/LaneIcon";

class DropdownHeader extends React.Component {
  state = {
    displayMenu: false,
    showGlossaryModal: false,
    showContactModal: false,
    showNotificationModal: false,
    showPasswordModal: false,
    showVerifyModal: false,
    showMobileModal: false,
    showDisableModal: false,
    mfaToken: "",
    userMobile: null,
    userCountryCode: null,
  };
  wrapperRef = React.createRef();
  componentDidMount() {
    this.getUserDetails();
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside = (event) => {
    if (
      this.state.displayMenu &&
      this.wrapperRef &&
      !this.wrapperRef.current?.contains(event.target)
    ) {
      this.setState({ displayMenu: false });
    }
  };
  setMfaToken = (mfaToken) => {
    this.setState({
      mfaToken,
    });
  };
  handleGlossaryOpenModal = () => {
    this.setState({
      showGlossaryModal: true,
    });
  };
  handleMobileOpenModal = () => {
    this.setState({
      showMobileModal: true,
    });
  };
  handleDisableOpenModal = () => {
    this.setState({
      showDisableModal: true,
    });
  };
  handlePasswordOpenModal = () => {
    this.setState({
      showPasswordModal: true,
    });
  };
  handleVerifyOpenModal = () => {
    this.setState({
      showVerifyModal: true,
    });
  };
  handleContactOpenModal = () => {
    this.setState({
      showContactModal: true,
    });
  };
  handleNotificationOpenModal = () => {
    this.setState({
      showNotificationModal: true,
    });
  };
  handleNotificationCloseModal = () => {
    this.setState({
      showNotificationModal: false,
    });
  };
  handleGlossaryCloseModal = () => {
    this.setState({
      showGlossaryModal: false,
    });
  };
  handleMobileCloseModal = () => {
    this.setState({
      showMobileModal: false,
    });
  };
  handleDisableCloseModal = () => {
    this.setState({
      showDisableModal: false,
    });
  };
  handlePasswordCloseModal = () => {
    this.setState({
      showPasswordModal: false,
    });
  };
  handleVerifyCloseModal = () => {
    this.setState({
      showVerifyModal: false,
    });
  };
  handleContactCloseModal = () => {
    this.setState({
      showContactModal: false,
    });
  };

  showDropdownMenu = (event) => {
    event.preventDefault();
    this.setState({ displayMenu: !this.state.displayMenu });
  };

  logoutUser = () => {
    localStorage.clear();
    this.props.authUserSet(null);
    this.props.authTokenSet(null);

    window.location.href = "/";
  };

  getUserDetails = () => {
    axios({
      method: "GET",
      url: `/users`,
    }).then((res) => {
      this.setState({
        userMobile: res.data.user.formatted_mobile,
        userCountryCode: res.data.user.country_code,
      });
    });
  };

  render() {
    return (
      <div
        className="dropdown"
        style={{ background: "transparent", width: "15px" }}
        ref={this.wrapperRef}
      >
        <GlossaryModal
          showOpportunityModal={this.state.showGlossaryModal}
          setShowOpportunityModal={this.handleGlossaryOpenModal}
          handleCloseModal={this.handleGlossaryCloseModal}
        />
        <ContactModal
          showOpportunityModal={this.state.showContactModal}
          setShowOpportunityModal={this.handleContactOpenModal}
          handleCloseModal={this.handleContactCloseModal}
        />
        <NotificationSettingModal
          showOpportunityModal={this.state.showNotificationModal}
          setShowOpportunityModal={this.handleNotificationOpenModal}
          handleCloseModal={this.handleNotificationCloseModal}
        />
        <PasswordConfirmModal
          showOpportunityModal={this.state.showPasswordModal}
          setShowOpportunityModal={this.handlePasswordOpenModal}
          handleCloseModal={this.handlePasswordCloseModal}
          userId={this.props.authUser.id}
          handleVerifyOpenModal={this.handleVerifyOpenModal}
          setMfaToken={this.setMfaToken}
        />
        <VerifyOtp
          showOpportunityModal={this.state.showVerifyModal}
          setShowOpportunityModal={this.handleVerifyOpenModal}
          handleCloseModal={this.handleVerifyCloseModal}
          userId={this.props.authUser.id}
          mfaToken={this.state.mfaToken}
        />
        <MobileEntryModal
          showOpportunityModal={this.state.showMobileModal}
          setShowOpportunityModal={this.handleMobileOpenModal}
          handleCloseModal={this.handleMobileCloseModal}
          mobile={this.state.userMobile}
          countryCode={this.state.userCountryCode}
          handlePasswordOpenModal={this.handlePasswordOpenModal}
        />
        <Disable2faModal
          showOpportunityModal={this.state.showDisableModal}
          setShowOpportunityModal={this.handleDisableOpenModal}
          handleCloseModal={this.handleDisableCloseModal}
        />
        <div className="button" onClick={this.showDropdownMenu}>
          <div
            className="customer__profile"
            style={
              this.props.authUser.profile_img
                ? {
                    backgroundImage: `url(${this.props.authUser.profile_img})`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    width: "30px",
                    color: "transparent",
                    backgroundSize: "cover",
                    backgroundColor: "transparent",
                  }
                : {}
            }
          >
            <div className="customer__profile-text">
              {this.props.userName
                ?.split(" ")
                .map((a) => a.charAt(0))
                .join("")}
            </div>
          </div>
        </div>

        {this.state.displayMenu ? (
          <ul
            style={
              window.innerHeight < 560
                ? {
                    maxHeight: window.innerHeight - 100,
                    overflowY: "auto",
                  }
                : {}
            }
          >
            <h4
              style={{
                margin: "5px auto",
                textAlign: "center",
                background: "#f3f3f6",
                fontSize: "14px",
              }}
            >
              {this.props.userName}
            </h4>
            <Link
              to="/me"
              style={{
                color: "black",
                fontWeight: "normal",
              }}
              onClick={() => this.setState({ displayMenu: false })}
            >
              <li>
                <i
                  className="fa fa-cogs"
                  style={{
                    marginRight: "8px",
                    color: "#A0DFC4",
                  }}
                ></i>
                &nbsp;Settings
              </li>
            </Link>
            {this.props.authUser.permissions.includes("crm:importer") ? (
              <Link
                to="/import"
                style={{ color: "black", fontWeight: "normal" }}
                onClick={() => this.setState({ displayMenu: false })}
              >
                <li>
                  <img
                    src={aimporticon}
                    alt=""
                    width="14px"
                    style={{ marginRight: "4px", transform: "translateY(2px)" }}
                  />
                  &nbsp;Import Data
                </li>
              </Link>
            ) : (
              <li data-tip="You do not have permission to access it">
                <img
                  src={aimporticon}
                  alt=""
                  width="14px"
                  style={{ marginRight: "4px", transform: "translateY(2px)" }}
                />
                &nbsp;Import Data
                <ReactTooltip />
              </li>
            )}
            <Link
              to="/integration"
              style={{ color: "black", fontWeight: "normal" }}
              onClick={() => this.setState({ displayMenu: false })}
            >
              <li>
                <img
                  src={IntegrationSettingsIcon}
                  alt=""
                  width="14px"
                  style={{ marginRight: "4px", transform: "translateY(1px)" }}
                />
                &nbsp;Integrations
              </li>
            </Link>
            <Link
              to="/tasks/sequence"
              style={{ color: "black", fontWeight: "normal" }}
              onClick={() => this.setState({ displayMenu: false })}
            >
              <li>
                <img
                  src={SendEmailIcon}
                  alt=""
                  width="14px"
                  style={{ marginRight: "4px", transform: "translateY(1px)" }}
                />
                &nbsp;Follow-up Sequencing
              </li>
            </Link>
            {this.props.authUser.permissions.includes("crm:all:customizer") ? (
              <Link
                to="/dashboard-custom"
                style={{ color: "black", fontWeight: "normal" }}
                onClick={() => this.setState({ displayMenu: false })}
              >
                <li>
                  <img
                    src={DashboardLogo}
                    alt=""
                    width="14px"
                    style={{
                      marginRight: "4px",
                      transform: "translateY(1px)",
                    }}
                  />
                  &nbsp;Dashboard Customization
                </li>
              </Link>
            ) : (
              <li data-tip="You do not have permission to access it">
                <img
                  src={DashboardLogo}
                  alt=""
                  width="14px"
                  style={{ marginRight: "4px", transform: "translateY(1px)" }}
                />
                <ReactTooltip />
                &nbsp;Dashboard Customization
              </li>
            )}
            {this.props.authUser.permissions.includes("crm:all:customizer") ? (
              <li className="has-dropdown-1" style={{ borderBottom: "none" }}>
                <Link to="#">
                  <img
                    src={CustomFieldIcon}
                    alt=""
                    width="14px"
                    style={{ marginRight: "4px" }}
                  />
                  &nbsp;Custom Fields & Groups
                </Link>
                <ul class="nav-dropdown">
                  <li onClick={() => this.setState({ displayMenu: false })}>
                    <Link to="/account-custom">
                      <img src={AccountNavbar} alt="" width="16px" />
                      &nbsp;&nbsp;Accounts
                    </Link>
                  </li>
                  <li onClick={() => this.setState({ displayMenu: false })}>
                    <Link to="/contact-custom">
                      <img src={ContactNavbar} alt="" width="16px" />
                      &nbsp;&nbsp;Contacts
                    </Link>
                  </li>
                  <li onClick={() => this.setState({ displayMenu: false })}>
                    <Link to="/opportunity-custom">
                      <img src={OpportunityNavbar} alt="" width="16px" />
                      &nbsp;&nbsp;Deals
                    </Link>
                  </li>
                  {this.props.companyOnboarding.industry ===
                    "Freight Brokerage" && (
                    <li onClick={() => this.setState({ displayMenu: false })}>
                      <Link to="/carrier-custom">
                        <img src={CarrierGreenNavIcon} alt="" width="16px" />
                        &nbsp;&nbsp;Carriers
                      </Link>
                    </li>
                  )}
                  {this.props.companyOnboarding.industry ===
                    "Freight Brokerage" && (
                    <li onClick={() => this.setState({ displayMenu: false })}>
                      <Link to="/lane-custom">
                        <LaneIcon fill="#62ca9d" />
                        &nbsp;&nbsp;Lanes
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            ) : (
              <li data-tip="You do not have permission to access it">
                <img
                  src={CustomFieldIcon}
                  alt=""
                  width="14px"
                  style={{ marginRight: "4px" }}
                />
                <ReactTooltip />
                &nbsp;Custom Fields & Groups
              </li>
            )}
            {this.props.authUser.permissions.includes("crm:all:customizer") ? (
              <li className="has-dropdown-1" style={{ borderBottom: "none" }}>
                <Link to="#">
                  <img
                    src={CustomFieldIcon}
                    alt=""
                    width="14px"
                    style={{ marginRight: "4px" }}
                  />
                  &nbsp;Custom Forms
                </Link>
                <ul class="nav-dropdown">
                  <li onClick={() => this.setState({ displayMenu: false })}>
                    <Link to="/custom-modal/account">
                      <img src={AccountNavbar} alt="" width="16px" />
                      &nbsp;&nbsp;Accounts
                    </Link>
                  </li>
                  {this.props.companyOnboarding.industry ===
                    "Freight Brokerage" && (
                    <li onClick={() => this.setState({ displayMenu: false })}>
                      <Link to="/custom-modal/carrier">
                        <img src={CarrierGreenNavIcon} alt="" width="16px" />
                        &nbsp;&nbsp;Carrier
                      </Link>
                    </li>
                  )}
                  <li onClick={() => this.setState({ displayMenu: false })}>
                    <Link to="/custom-modal/contact">
                      <img src={ContactNavbar} alt="" width="16px" />
                      &nbsp;&nbsp;Contacts
                    </Link>
                  </li>
                  <li onClick={() => this.setState({ displayMenu: false })}>
                    <Link to="/custom-modal/opportunity">
                      <img src={OpportunityNavbar} alt="" width="16px" />
                      &nbsp;&nbsp;Deals
                    </Link>
                  </li>
                  <li onClick={() => this.setState({ displayMenu: false })}>
                    <Link to="/custom-modal/quote">
                      <RequestQuoteOutlinedIcon
                        fontSize="small"
                        sx={{
                          color: "#62CA9D",
                          margin: "-2px",
                        }}
                      />
                      &nbsp;&nbsp;Quote
                    </Link>
                  </li>
                </ul>
              </li>
            ) : (
              <li data-tip="You do not have permission to access it">
                <img
                  src={CustomFieldIcon}
                  alt=""
                  width="14px"
                  style={{ marginRight: "4px" }}
                />
                <ReactTooltip />
                &nbsp;Custom Forms
              </li>
            )}
            <Link
              to="/webform/contact"
              style={{
                color: "black",
                fontWeight: "normal",
              }}
              onClick={() => this.setState({ displayMenu: false })}
            >
              <li style={{ borderBottom: "1px solid #e5e5e5" }}>
                <img
                  src={Webform}
                  alt=""
                  style={{
                    transform: "translate(-3px,3px)",
                    width: "18px",
                  }}
                />
                &nbsp;Web Forms
              </li>
            </Link>
            <Link
              to="/walk-through"
              style={{ color: "#4A9876", fontWeight: "normal" }}
              onClick={() => this.setState({ displayMenu: false })}
            >
              <li>
                <i className="fa fa-youtube-play" aria-hidden="true"></i>
                &nbsp; Video Tutorials
              </li>
            </Link>
            {this.props.authUser.role === "SUPERADMIN" ? (
              <Link
                to="/payment"
                style={{ color: "black", fontWeight: "normal" }}
                onClick={() => this.setState({ displayMenu: false })}
              >
                <li>
                  <img
                    src={BillingIcon}
                    alt=""
                    width="14px"
                    style={{
                      marginRight: "4px",
                      transform: "translateY(1.5px)",
                    }}
                  />
                  &nbsp;Billing
                </li>
              </Link>
            ) : (
              <li data-tip="You do not have permission to access it">
                <img
                  src={BillingIcon}
                  alt=""
                  width="14px"
                  style={{ marginRight: "4px", transform: "translateY(1.5px)" }}
                />
                &nbsp;Billing
              </li>
            )}
            <Link
              to="/users"
              style={{ color: "black", fontWeight: "normal" }}
              onClick={() => this.setState({ displayMenu: false })}
            >
              <li>
                <img
                  src={TeamsNavbar}
                  alt=""
                  width="14px"
                  style={{ marginRight: "4px" }}
                />
                &nbsp;Users and Teams
              </li>
            </Link>
            <li
              onClick={() => {
                this.handleGlossaryOpenModal();
                this.setState({ displayMenu: false });
              }}
              style={{
                color: "black",
                cursor: "pointer",
                borderBottom: "none",
              }}
            >
              <img
                src={GlossaryIcon}
                alt=""
                width="14px"
                style={{ marginRight: "4px", transform: "translateY(1.5px)" }}
              />
              &nbsp;Glossary
            </li>
            <a
              href="https://salesdashcrm.com/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "black",
                fontWeight: "normal",
                paddingBottom: "10px",
              }}
            >
              <li>
                <img
                  src={GlossaryIcon1}
                  alt=""
                  width="14px"
                  style={{ marginRight: "4px", transform: "translateY(1.5px)" }}
                />
                &nbsp;Privacy Policy
              </li>
            </a>
            <li
              onClick={() => {
                this.handleContactOpenModal();
                this.setState({ displayMenu: false });
              }}
              style={{
                color: "black",
                cursor: "pointer",
              }}
            >
              <i
                className="fa fa-question-circle"
                aria-hidden="true"
                style={{ color: "#A0DFC4", marginRight: "6px" }}
              ></i>
              &nbsp;Contact Us
            </li>
            <Link
              to="/"
              onClick={() => {
                this.logoutUser();
                this.setState({ displayMenu: false });
              }}
              style={{ color: "black", fontWeight: "normal" }}
            >
              <li>
                <img
                  src={LogoutIcon}
                  alt=""
                  width="14px"
                  style={{ marginRight: "4px", transform: "translateY(2.5px)" }}
                />
                &nbsp;Log Out
              </li>
            </Link>
          </ul>
        ) : null}
      </div>
    );
  }
}

class GlossaryModal extends React.Component {
  state = {
    showModal: true,
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "75vh", top: "8%", width: "55vw", left: "22%" }}
            >
              <div className="modal__header">
                <div className="modal__head">
                  <h2 className="modal__heading">Salesdash Glossary</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <div
                style={{
                  fontSize: "14px",
                  lineHeight: "16px",
                  textAlign: "justify",
                  height: "61vh",
                  overflowY: "auto",
                }}
              >
                <strong>Account</strong> - lead, a business or individual to be
                prospected
                <br />
                <strong>Activity</strong> - communication (call, email, text,
                meeting, notes, visit)
                <br />
                <strong>Analytics</strong> - metrics centered around accounts,
                deals, reports, fields, groups, and activities (coming soon)
                <br />
                <strong>Close Date</strong> - projected date for when the
                customer will purchase
                <br />
                <strong>Contact</strong> - employee of a business or location
                (coming soon)
                <br />
                <strong>Deal</strong> - a converted Account that has potential
                to become a customer
                <br />
                <strong>Disqualified</strong> - customer does not fit your ideal
                client profile and you opt to not reach out to them
                <br />
                <strong>Field</strong> - in area where you can enter data (i.e
                name, email, phone)
                <br />
                <strong>Filter</strong> - fields and groups that you can select
                from to breakdown reports
                <br />
                <strong>Group</strong> - a category of fields to enter data
                <br />
                <strong>Lost Deal</strong> - the customer did not purchase
                <br />
                <strong>Lost Reason</strong> - primary reason you lost the deal
                <br />
                <strong>Qualified</strong> - an account that needs to be
                prospected, not a total no from the customer
                <br />
                <strong>Report</strong> - a table or graph displaying data of
                your fields, activities, deals, and accounts
                <br />
                <strong>Revenue</strong> - how much money the deal is
                <br />
                <strong>Stage</strong> - a step in your sales funnel that acts
                as a checkpoint for your ideal customer journey
                <br />
                <strong>Subdomain</strong> - your url to access Salesdash.
                (example.salesdashcrm.com)
                <br />
                <strong>Tab</strong> - internal pages for accounts, deals, and
                reports within Salesdash for quick user navigation
                <br />
                <strong>Target</strong> - your sales goals set by you in
                Salesdash
                <br />
                <strong>Task</strong> - follow up reminder
                <br />
                <strong>Won Deal</strong> - the customer purchased
                <br />
                <strong>Won Reason</strong> - primary reason you won the deal
                <br />
              </div>
              <div className="modal__buttons">
                <button
                  onClick={this.props.handleCloseModal}
                  type="button"
                  className="modal__confirm-button"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

class ContactModal extends React.Component {
  state = {
    showModal: true,
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "30vh", top: "20%", width: "45vw", left: "25%" }}
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon"
                    style={{
                      background: "#61C99D",
                      transform: "translateY(-10px)",
                    }}
                  >
                    <i
                      className="fa fa-question"
                      aria-hidden="true"
                      style={{
                        color: "#fff",
                        fontSize: "22px",
                        transform: "translateY(8px)",
                      }}
                    ></i>
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Contact Salesdash</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <div style={{ fontSize: "14px", lineHeight: "18px" }}>
                Have a question?
                <br /> <br /> Contact the Salesdash team at{" "}
                <strong>info@salesdashcrm.com</strong> or give us a call or text
                at <strong>615.274.4972</strong>.
                <br /> <br />
                We look forward to hearing from you!
              </div>
              <div className="modal__buttons">
                <button
                  onClick={this.props.handleCloseModal}
                  type="button"
                  className="modal__confirm-button"
                  style={{ right: "10%" }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}
const MSP = (state) => {
  return {
    authToken: state.login.authToken,
    authUser: state.login.authUser,
    companyOnboarding: state.login.companyOnboarding,
  };
};

const MDP = (dispatch) => {
  return {
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    authTokenSet: (authTokenData) => dispatch(authTokenSet(authTokenData)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};

export default connect(MSP, MDP)(DropdownHeader);
