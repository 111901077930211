import React, { Component } from "react";
import LaneFieldsCustomSort from "./LaneFieldsCustomSort";

const SortableItem = ({
  fieldId,
  fields,
  handleOpenModal,
  handleEditOpenModal,
  handleDeleteOpenModal,
  handleGroupDeleteOpenModal,
  deleteGroupHandler,
  onFieldSortEnd,
}) => (
  <li style={{ display: "block" }}>
    <div style={{ display: "flex" }}>
      <LaneFieldsCustomSort
        parent_id={fieldId}
        onSortEnd={onFieldSortEnd}
        fields={fields}
        handleEditOpenModal={handleEditOpenModal}
        handleDeleteOpenModal={handleDeleteOpenModal}
        handleGroupDeleteOpenModal={handleGroupDeleteOpenModal}
        handleOpenModal={handleOpenModal}
        deleteGroupHandler={deleteGroupHandler}
      />
    </div>
  </li>
);

const LaneCustomSort = (props) => {
  return (
    <div>
      <SortableItem
        key={`item-${props.group?.id}`}
        fields={props.group?.fields}
        label={props.group?.label}
        fieldId={props.group?.id}
        accountFields={props.accountFields}
        handleEditOpenModal={props.handleEditOpenModal}
        handleDeleteOpenModal={props.handleDeleteOpenModal}
        handleGroupDeleteOpenModal={props.handleGroupDeleteOpenModal}
        handleOpenModal={props.handleOpenModal}
        deleteGroupHandler={props.deleteGroupHandler}
        onFieldSortEnd={props.onFieldSortEnd}
        handleGroupEditOpenModal={props.handleGroupEditOpenModal}
      />
    </div>
  );
};

export default LaneCustomSort;
