import InputField from "../../Reusables/InputField/InputField";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    outline: "#C0EAD8",
    margin: "5px auto",
    // height: "35px",
    boxShadow: "none",
  }),
  indicatorSeparator: (styles) => {
    return {
      display: "none",
    };
  },
  valueContainer: (styles) => {
    return {
      ...styles,
      // height: "37px",
    };
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? "#3aab7b"
        : isFocused
        ? "#C0EAD8"
        : null,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled && (isSelected ? data.color : "#C0EAD8"),
      },
    };
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "#C0EAD8",
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "black",
  }),
};

export const showInput = (
  fieldName,
  fieldLabel,
  fieldType,
  fieldChoices,
  fieldRequired,
  customFields,
  customFieldChangeHandler,
  customFieldRevenueChangeHandler,
  customFieldCheckBoxChangeHandler,
  customFieldSelectChangeHandler,
  customFieldDateChangeHandler,
  customFieldMultiDropdownChangeHandler,
  companyOnboarding
) => {
  let inputType;
  if (fieldType === "text") {
    inputType = (
      <div
        style={{ marginBottom: "15px" }}
        className="no-border-in-lanes-panel"
      >
        <InputField
          type="text"
          name={fieldName}
          label={fieldLabel}
          value={customFields[fieldName]}
          onChange={customFieldChangeHandler}
          required={fieldRequired}
        />
      </div>
    );
  } else if (fieldType === "hyperlink") {
    inputType = (
      <div
        style={{ marginBottom: "15px" }}
        className="no-border-in-lanes-panel"
      >
        <InputField
          type="text"
          name={fieldName}
          label={fieldLabel}
          value={customFields[fieldName]}
          onChange={customFieldChangeHandler}
          required={fieldRequired}
        />
      </div>
    );
  } else if (fieldType === "revenue") {
    inputType = (
      <div
        style={{ marginBottom: "15px" }}
        className="no-border-in-lanes-panel"
      >
        <InputField
          type="text"
          name={fieldName}
          label={fieldLabel}
          value={customFields[fieldName]}
          onChange={customFieldRevenueChangeHandler}
          required={fieldRequired}
        />
      </div>
    );
  } else if (fieldType === "paragraph") {
    inputType = (
      <div
        style={{ marginBottom: "15px" }}
        className="no-border-in-lanes-panel"
      >
        <InputField
          name={fieldName}
          label={fieldLabel}
          value={customFields[fieldName]}
          onChange={customFieldChangeHandler}
          required={fieldRequired}
          multiline={true}
        />
      </div>
    );
  } else if (fieldType === "decimal") {
    inputType = (
      <div
        style={{ marginBottom: "15px" }}
        className="no-border-in-lanes-panel"
      >
        <InputField
          type="number"
          label={fieldLabel}
          name={fieldName}
          value={customFields[fieldName]}
          onChange={customFieldChangeHandler}
          required={fieldRequired}
        />
      </div>
    );
  } else if (fieldType === "dropdown") {
    inputType = (
      <div
        className="controlsForms"
        style={{ width: "100%", marginBottom: "10px", marginTop: "-5px" }}
      >
        <Select
          className="basic-single"
          classNamePrefix="select"
          styles={colourStyles}
          required={fieldRequired}
          value={{
            value: customFields[fieldName],
            label: customFields[fieldName],
          }}
          placeholder="-Select-"
          onChange={(selected) =>
            customFieldSelectChangeHandler(fieldName, selected.value)
          }
          isClearable={false}
          isSearchable={true}
          name={fieldName}
          options={fieldChoices.map((choice) => {
            const { value } = choice;
            return { value, label: value };
          })}
        />
        <label className="activeLabel">
          <span style={{ fontSize: "12px" }}>{fieldLabel}</span>{" "}
          {fieldRequired && (
            <span
              style={{
                color: "#4A9876",
                fontSize: "17px",
                lineHeight: "16px",
              }}
            >
              *
            </span>
          )}
        </label>
      </div>
    );
  } else if (fieldType === "checkbox") {
    inputType = (
      <div style={{ width: "20px", marginBottom: "15px" }}>
        <label>
          {fieldLabel}{" "}
          {fieldRequired && (
            <span
              style={{
                color: "#4A9876",
                fontSize: "17px",
                lineHeight: "16px",
              }}
            >
              *
            </span>
          )}
        </label>
        <input
          type="checkbox"
          style={{ display: "inline-block", textAlign: "left" }}
          name={fieldName}
          value={true}
          onChange={customFieldCheckBoxChangeHandler}
          checked={customFields[fieldName]}
        />
      </div>
    );
  } else if (fieldType === "radio") {
    inputType = (
      <div>
        <label>
          {fieldLabel}{" "}
          {fieldRequired && (
            <span
              style={{
                color: "#4A9876",
                fontSize: "17px",
                lineHeight: "16px",
              }}
            >
              *
            </span>
          )}
        </label>
        {fieldChoices.map((choice) => {
          const { id, value } = choice;
          return (
            <div key={id} style={{ textAlign: "left", marginBottom: "15px" }}>
              <input
                type="radio"
                style={{
                  display: "inline-block",
                  textAlign: "left",
                  width: "20px",
                }}
                name={fieldName}
                value={value}
                onChange={customFieldChangeHandler}
                checked={customFields[fieldName] === value}
                id={fieldName + value}
                required={fieldRequired}
              />
              <label
                style={{ display: "inline-block", textAlign: "left" }}
                htmlFor={fieldName + value}
              >
                {value}
              </label>
            </div>
          );
        })}
      </div>
    );
  } else if (fieldType === "date") {
    inputType = (
      <div
        className="controlsForms"
        style={{ width: "100%", marginBottom: "15px" }}
      >
        <DatePicker
          name={fieldName}
          className="datePicker"
          selected={Date.parse(customFields[fieldName])}
          value={Date.parse(customFields[fieldName])}
          onChange={(date) => customFieldDateChangeHandler(fieldName, date)}
          dateFormat={
            companyOnboarding?.date_format === "DD/MM/YYYY"
              ? "dd/MM/yyyy"
              : "MM/dd/yyyy"
          }
          placeholderText={
            companyOnboarding?.date_format === "DD/MM/YYYY"
              ? "dd/mm/yyyy"
              : "mm/dd/yyyy"
          }
          autoComplete="off"
          required={fieldRequired}
        />
        <label className="activeLabel">
          <span style={{ fontSize: "12px" }}>{fieldLabel}</span>{" "}
          {fieldRequired && (
            <span
              style={{
                color: "#4A9876",
                fontSize: "17px",
                lineHeight: "16px",
              }}
            >
              *
            </span>
          )}
        </label>
      </div>
    );
  } else if (fieldType === "multi_select_dropdown") {
    let multiSelectDropdownValue = customFields[fieldName];
    let options = [];
    options = fieldChoices.map((choice) => {
      const { value } = choice;
      return { value, label: value };
    });
    inputType = (
      <div
        className="controlsForms"
        style={{ width: "100%", marginBottom: "10px", marginTop: "-5px" }}
      >
        <Select
          value={multiSelectDropdownValue}
          closeMenuOnSelect={false}
          isMulti
          name={fieldName}
          styles={colourStyles}
          options={options}
          placeholder="-Select-"
          className="basic-multi-select"
          classNamePrefix="select"
          required={fieldRequired}
          onChange={(selectedOption) =>
            customFieldMultiDropdownChangeHandler(fieldName, selectedOption)
          }
          components={{
            IndicatorSeparator: () => null,
            ClearIndicator: () => null,
            DropdownIndicator: () => null,
          }}
        />
        <label className="activeLabel">
          <span style={{ fontSize: "12px" }}>{fieldLabel}</span>{" "}
          {fieldRequired && (
            <span
              style={{
                color: "#4A9876",
                fontSize: "17px",
                lineHeight: "16px",
              }}
            >
              *
            </span>
          )}
        </label>
      </div>
    );
  }
  return <div>{inputType}</div>;
};
