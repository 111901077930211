import React, { Component } from "react";
import ReactModal from "react-modal";
import axios from "axios";
import NotificationManager from "react-notifications/lib/NotificationManager";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import CancelSendEmailModal from "./Activity/CancelSendEmailModal";
import ActivityJodit from "./Activity/ActivityJodit";
import QuoteEditModal from "./Quotes/Modal/QuoteEditModal";

class EditActivityModal extends Component {
  state = {
    showModal: true,
    name: this.props.name,
    description: this.props.description,
    result_id: this.props.result_id,
    results: [],
    isLoading: false,
    category_id:
      this.props.category_id === undefined
        ? null
        : this.props.category_id.toString(),
    date: "",
    showCancelModal: false,
    cancelLoader: false,
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  changeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  componentDidMount() {
    if (this.props.showOpportunityModal) {
      axios({
        method: "GET",
        url: `/activities/results`,
      }).then((res) => this.setState({ results: res.data.results }));
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.showOpportunityModal !== this.props.showOpportunityModal) {
      if (this.props.metadata && this.props.metadata?.status === "scheduled") {
        this.setState({
          date: new Date(this.props.metadata?.send_at),
        });
      }
      axios({
        method: "GET",
        url: `/activities/results`,
      }).then((res) => this.setState({ results: res.data.results }));
    }
  }

  submitHandler = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    if (this.props.metadata && this.props.metadata?.status === "scheduled") {
      this.editDateTimeHandler();
    }
    axios({
      method: "PUT",
      url: `/activities/${this.props.id}`,
      data: {
        name: this.state.name,
        description:
          this.state.description === "" ? null : this.state.description,
        // account_id: this.props.account_id,
        // opportunity_id: this.props.opportunity_id,
        category_id: this.state.category_id,
        result_id: this.state.result_id,
        type: this.props.type,
      },
    })
      .then((response) => {
        this.props.loadActivities(1, "refetch");
        this.props.handleCloseModal();
        NotificationManager.success("Activity edited successfully");
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        NotificationManager.error("Error editing activity");
        this.setState({ isLoading: false });
      });
  };
  editDateTimeHandler = () => {
    axios({
      method: "PUT",
      url: `/activities/${this.props.id}/scheduled-email`,
      data: {
        send_at: moment(this.state.date).format("YYYY-MM-DD HH:mm:ss"),
      },
    });
  };
  dateChangeHandler = (date) => {
    this.setState({
      date,
    });
  };
  cancelEmailSend = () => {
    this.setState({
      cancelLoader: true,
    });
    axios({
      method: "PATCH",
      url: `/activities/${this.props.id}/scheduled-email`,
    })
      .then((res) => {
        this.props.loadActivities(1, "refetch");
        this.props.handleCloseModal();
        NotificationManager.success("Scheduled email cancelled");
        this.setState({
          isLoading: false,
          cancelLoader: false,
          showCancelModal: false,
        });
      })
      .catch((err) => {
        NotificationManager.error("Error cancelling scheduled email");
        this.setState({ isLoading: false, cancelLoader: false });
      });
  };
  updateContent = (value) => {
    this.setState({
      description: value,
    });
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <CancelSendEmailModal
            showOpportunityModal={this.state.showCancelModal}
            setShowOpportunityModal={() =>
              this.setState({ showCancelModal: true })
            }
            handleCloseModal={() => this.setState({ showCancelModal: false })}
            submitHandler={this.cancelEmailSend}
            loading={this.state.cancelLoader}
          />
          <div
            className={this.state.category_id === "10" ? "muiModal" : "modal"}
          >
            <div
              className="modal__content"
              style={{ height: "72vh", top: "6%", width: "30vw" }}
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                    }}
                  >
                    <i className="fa fa-info" aria-hidden="true"></i>
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Edit Activity</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              {this.state.category_id !== "10" && (
                <form onSubmit={this.submitHandler}>
                  <label>Activity Name</label>
                  <input
                    name="name"
                    type="text"
                    value={this.state.name}
                    onChange={this.changeHandler}
                  />
                  <label>Activity Description</label>
                  <div
                    style={{ position: "relative", height: "30vh" }}
                    className="c-input-area"
                  >
                    <ActivityJodit
                      content={this.state.description}
                      updateContent={this.updateContent}
                    />
                  </div>
                  <label style={{ display: "inline-block" }}>Type</label>
                  <div className="modal-radioButton">
                    <label
                      htmlFor="call"
                      style={
                        this.state.category_id === "1"
                          ? {
                              border: "2px solid #A0DFC4",
                              outline: "none",
                              color: "#555555",
                              padding: "4px 12px",
                              borderRadius: "9px",
                              marginRight: "13px",
                              background: "#F4F6F5",
                              fontWeight: "normal",
                              fontSize: "14px",
                              lineHeight: "16px",
                            }
                          : {
                              color: "#555555",
                              padding: "4px 12px",
                              border: "none",
                              borderRadius: "9px",
                              background: "#F4F6F5",
                              marginRight: "13px",
                              fontWeight: "normal",
                              fontSize: "14px",
                              lineHeight: "16px",
                            }
                      }
                    >
                      Call
                    </label>
                    <input
                      type="radio"
                      name="category_id"
                      value="1"
                      id="call"
                      className="radio-button"
                      onChange={this.changeHandler}
                    />
                    <label
                      htmlFor="email"
                      style={
                        this.state.category_id === "2"
                          ? {
                              border: "2px solid #A0DFC4",
                              outline: "none",
                              color: "#555555",
                              padding: "4px 12px",
                              borderRadius: "9px",
                              marginRight: "13px",
                              background: "#F4F6F5",
                              fontWeight: "normal",
                              fontSize: "14px",
                              lineHeight: "16px",
                            }
                          : {
                              color: "#555555",
                              padding: "4px 12px",
                              border: "none",
                              borderRadius: "9px",
                              background: "#F4F6F5",
                              marginRight: "13px",
                              fontWeight: "normal",
                              fontSize: "14px",
                              lineHeight: "16px",
                            }
                      }
                    >
                      Email
                    </label>
                    <input
                      type="radio"
                      name="category_id"
                      value="2"
                      id="email"
                      className="radio-button"
                      onChange={this.changeHandler}
                    />
                    <label
                      htmlFor="text"
                      style={
                        this.state.category_id === "3"
                          ? {
                              border: "2px solid #A0DFC4",
                              outline: "none",
                              color: "#555555",
                              padding: "4px 12px",
                              borderRadius: "9px",
                              marginRight: "13px",
                              background: "#F4F6F5",
                              fontWeight: "normal",
                              fontSize: "14px",
                              lineHeight: "16px",
                            }
                          : {
                              color: "#555555",
                              padding: "4px 12px",
                              border: "none",
                              borderRadius: "9px",
                              background: "#F4F6F5",
                              marginRight: "13px",
                              fontWeight: "normal",
                              fontSize: "14px",
                              lineHeight: "16px",
                            }
                      }
                    >
                      Text
                    </label>
                    <input
                      type="radio"
                      name="category_id"
                      value={3}
                      id="text"
                      className="radio-button"
                      onChange={this.changeHandler}
                    />
                    <label
                      htmlFor="visit"
                      style={
                        this.state.category_id === "4"
                          ? {
                              border: "2px solid #A0DFC4",
                              outline: "none",
                              color: "#555555",
                              padding: "4px 12px",
                              borderRadius: "9px",
                              marginRight: "13px",
                              background: "#F4F6F5",
                              fontWeight: "normal",
                              fontSize: "14px",
                              lineHeight: "16px",
                            }
                          : {
                              color: "#555555",
                              padding: "4px 12px",
                              border: "none",
                              borderRadius: "9px",
                              background: "#F4F6F5",
                              marginRight: "13px",
                              fontWeight: "normal",
                              fontSize: "14px",
                              lineHeight: "16px",
                            }
                      }
                    >
                      Visit
                    </label>
                    <input
                      type="radio"
                      name="category_id"
                      value="4"
                      id="visit"
                      className="radio-button"
                      onChange={this.changeHandler}
                    />
                    <label
                      htmlFor="meeting"
                      style={
                        this.state.category_id === "5"
                          ? {
                              border: "2px solid #A0DFC4",
                              outline: "none",
                              color: "#555555",
                              padding: "4px 12px",
                              borderRadius: "9px",
                              marginRight: "13px",
                              background: "#F4F6F5",
                              fontWeight: "normal",
                              fontSize: "14px",
                              lineHeight: "16px",
                            }
                          : {
                              color: "#555555",
                              padding: "4px 12px",
                              border: "none",
                              borderRadius: "9px",
                              background: "#F4F6F5",
                              marginRight: "13px",
                              fontWeight: "normal",
                              fontSize: "14px",
                              lineHeight: "16px",
                            }
                      }
                    >
                      Meeting
                    </label>
                    <input
                      type="radio"
                      name="category_id"
                      value="5"
                      id="meeting"
                      className="radio-button"
                      onChange={this.changeHandler}
                    />
                    <label
                      htmlFor="notes"
                      style={
                        this.state.category_id === "6"
                          ? {
                              border: "2px solid #A0DFC4",
                              outline: "none",
                              color: "#555555",
                              padding: "4px 12px",
                              borderRadius: "9px",
                              marginRight: "13px",
                              background: "#F4F6F5",
                              fontWeight: "normal",
                              fontSize: "14px",
                              lineHeight: "16px",
                            }
                          : {
                              color: "#555555",
                              padding: "4px 12px",
                              border: "none",
                              borderRadius: "9px",
                              background: "#F4F6F5",
                              marginRight: "13px",
                              fontWeight: "normal",
                              fontSize: "14px",
                              lineHeight: "16px",
                            }
                      }
                    >
                      Notes
                    </label>

                    <input
                      type="radio"
                      name="category_id"
                      value="6"
                      id="notes"
                      className="radio-button"
                      onChange={this.changeHandler}
                    />
                  </div>
                  <label>Result</label>
                  <select
                    name="result_id"
                    value={this.state.result_id}
                    onChange={this.changeHandler}
                    style={{ width: "180px" }}
                  >
                    <option value="" selected disabled>
                      -Select-
                    </option>
                    {this.state.results.map((result) => {
                      const { id, name } = result;
                      return (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      );
                    })}
                  </select>
                  {this.props.metadata &&
                    this.props.metadata?.status === "scheduled" && (
                      <div className="date-time-input">
                        <label>Date Time</label>
                        <DatePicker
                          selected={this.state.date}
                          todayButton="Today"
                          value={this.state.date}
                          onChange={(date) => this.dateChangeHandler(date)}
                          placeholderText="Date"
                          autoComplete="off"
                          showTimeSelect
                          dateFormat="MMMM d, yyyy h:mm aa"
                          className="modal-datepicker"
                        />
                        <div>
                          <button
                            className="button-no-border-red"
                            type="button"
                            onClick={() =>
                              this.setState({ showCancelModal: true })
                            }
                            style={{ fontSize: "11px" }}
                          >
                            Cancel Send
                          </button>
                        </div>
                      </div>
                    )}
                  <div className="modal__buttons">
                    <button
                      type="button"
                      className="modal__cancel-button"
                      onClick={this.props.handleCloseModal}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="modal__confirm-button"
                      disabled={this.state.isLoading}
                    >
                      {this.state.isLoading ? "Saving" : "Save"}
                    </button>
                  </div>
                </form>
              )}
              {this.state.category_id === "10" &&
                this.props.showOpportunityModal && (
                  <QuoteEditModal
                    id={this.props.id}
                    quoteData={this.props.quoteData}
                    categoryId={this.props.category_id}
                    loadActivities={this.props.loadActivities}
                    handleCloseModal={this.props.handleCloseModal}
                  />
                )}
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default React.memo(EditActivityModal);
