import React, { Component } from "react";
import axios from "axios";
import { BASE_URL } from "../../config";
import ReportsAccountTable from "./ReportsAccountTable";
import ReportsDealTable from "./ReportsDealTable";
import ReportsActivityTable from "./ReportsActivityTable";
import ReportsContactTable from "./ReportsContactTable";
import ReportsCarrierTable from "./ReportsCarrierTable";
import ReportsLaneTable from "./ReportsLaneTable";

class ReportsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: "",
      operator: "",
      filter_rules: [{ attribute: "", operator: "", value: "" }],
      reportsTable: [],
      showColumn: false,
      displayMenu: false,
      reportsData: {},
      reportType: "",
      intialFilters: [],
    };
  }
  componentDidMount() {
    axios({
      method: "GET",
      url: `${BASE_URL}/reports/${this.props.reportId}`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
    }).then((res) =>
      this.setState({
        reportsData: res.data.report,
        reportType: res.data.report.type,
        filter_rules: res.data.report.filters,
      })
    );
  }
  getReport = () => {
    if (this.state?.reportType === "accounts") {
      return <ReportsAccountTable {...this.props} />;
    } else if (this.state?.reportType === "opportunities") {
      return <ReportsDealTable {...this.props} />;
    } else if (this.state?.reportType === "contacts") {
      return <ReportsContactTable {...this.props} />;
    } else if (this.state?.reportType === "carriers") {
      return <ReportsCarrierTable {...this.props} />;
    } else if (this.state?.reportType === "lanes") {
      return <ReportsLaneTable {...this.props} />;
    } else {
      return <ReportsActivityTable {...this.props} />;
    }
  };
  render() {
    return this.getReport();
  }
}

export default ReportsTable;
