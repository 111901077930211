import React from "react";

const LaneIcon = ({ fill = "#4A9876", width = 18, height =18 }) => {
  return (
    <svg
      fill={fill}
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 494.889 494.889"
    >
      <g>
        <g>
          <g>
            <polygon points="494.889,438.777 395.631,56.111 333.42,56.111 405.402,438.777 			" />
            <polygon points="89.486,438.777 161.467,56.111 99.257,56.111 0,438.777 			" />
          </g>
          <g>
            <polygon points="263.232,168.716 231.659,168.716 228.593,223.57 266.295,223.57 			" />
            <polygon points="257.105,59.009 237.782,59.009 234.719,113.863 260.169,113.863 			" />
            <g>
              <polygon points="120.872,438.777 192.652,56.37 191.27,56.111 172.352,56.111 100.522,438.777 				" />
              <polygon points="394.365,438.777 322.535,56.111 303.617,56.111 302.236,56.37 374.016,438.777 				" />
            </g>
            <polygon points="269.355,278.424 225.532,278.424 222.471,333.274 272.42,333.274 			" />
            <polygon points="278.113,438.777 275.48,388.129 219.407,388.129 216.774,438.777 			" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default LaneIcon;
