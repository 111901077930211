export const contactTerminologies = {
  contact_owner: [
    "contact owner",
    "owner",
    "customer owner",
    "person owner",
    "contacts owner",
    "customers owner",
    "user owner",
    "owners",
    "salesperson",
  ],
  name: [
    "contact name",
    "name",
    "customer name",
    "person name",
    "user name",
    "full name",
    "client name",
    "contact full name",
    "customer full name",
    "person full name",
    "user full name",
    "client full name",
  ],
  account_name: [
    "account name",
    "company name",
    "company",
    "organization name",
    "organization",
    "org",
    "org name",
    "account",
    "customer",
    "contact company",
    "contact organization",
    "contact org",
    "customer company",
    "customer organization",
  ],
  carrier_name: ["carrier name", "carrier"],
  source: ["source", "contact source", "customer source", "person source"],
  state: ["state", "contact state", "customer state", "person state"],
  zip: [
    "zip",
    "zip code",
    "postal code",
    "contact zip",
    "customer zip",
    "person zip",
    "contact zip code",
    "customer zip code",
    "person zip code",
    "contact postal code",
    "customer postal code",
    "person postal code",
  ],
  city: ["city", "contact city", "customer city", "person city"],
  address: [
    "address",
    "contact address",
    "customer address",
    "person address",
    "full address",
  ],
  linkedin: [
    "linkedin",
    "linkedin url",
    "contact linkedin",
    "customer linkedin",
    "contact linkedin url",
    "customer linkedin url",
    "person linkedin url",
    "linkedin contact profile url",
    "linkedin person profile url",
  ],
  country: ["country", "contact country", "customer country", "person country"],
  email: [
    "email",
    "email address",
    "contact email",
    "customer email",
    "contact email address",
    "customer email address",
    "person email address",
  ],
  formatted_phone: [
    "phone",
    "phone number",
    "contact phone",
    "customer phone",
    "telephone number",
    "contact phone number",
    "customer phone number",
    "person phone number",
    "direct phone number",
    "direct phone",
    "contact direct phone",
    "customer direct phone",
    "person direct phone",
    "direct line",
    "contact direct line",
    "customer direct line",
    "person direct line",
    "direct line number",
    "contact direct line number",
    "customer direct line number",
    "person direct line number",
    "direct telephone number",
    "contact direct telephone number",
    "customer direct telephone number",
    "person direct telephone number",
    "direct phone line",
    "contact direct phone line",
    "customer direct phone line",
    "person direct phone line",
    "direct line phone",
    "contact direct line phone",
    "customer direct line phone",
    "person direct line phone",
    "direct line telephone",
    "contact direct line telephone",
    "customer direct line telephone",
    "person direct line telephone",
    "direct phone line number",
    "contact direct phone line number",
    "customer direct phone line number",
    "person direct phone line number",
    "direct line phone number",
    "contact direct line phone number",
    "customer direct line phone number",
    "person direct line phone number",
    "direct line telephone number",
    "contact direct line telephone number",
    "customer direct line telephone number",
    "main phone",
    "main phone number",
    "contact main phone",
    "customer main phone",
    "person main phone",
    "main phone line",
    "contact main phone line",
    "customer main phone line",
    "person main phone line",
    "contact main phone number",
    "customer main phone number",
    "person main phone number",
    "main line number",
    "contact main line number",
    "customer main line number",
    "person main line number",
    "main telephone number",
    "contact main telephone number",
    "customer main telephone number",
    "person main telephone number",
  ],
  phone_ext: [
    "phone extension",
    "person phone extension",
    "customer phone extension",
    "contact phone extension",
    "telephone extension",
  ],
  formatted_mobile: [
    "mobile",
    "mobile number",
    "cell",
    "cell number",
    "customer mobile",
    "customer mobile number",
    "customer cell",
    "customer cell number",
    "contact mobile",
    "contact mobile number",
    "contact cell",
    "contact cell number",
    "mobile phone",
  ],
  description: [
    "description",
    "short description",
    "notes",
    "contact description",
    "customer description",
    "person description",
  ],
  status: [
    "status",
    "contact status",
    "customer status",
    "lead status",
    "person status",
  ],
  preferred_communication: ["preferred communication"],
  opportunity_name: ["deal name", "deal", "opportunity", "opportunity name"],
  role: [
    "job title",
    "job",
    "title",
    "role",
    "contact role",
    "customer role",
    "contact job title",
    "customer job title",
    "person job title",
  ],
  unique_identifier: ["unique identifier", "zoominfo contact id"],
};
