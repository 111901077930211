import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { colourStyles } from "../../constants/selectStyles";
import "../../sass/CustomerModal.scss";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";
import DragHandlerIcon from "../../images/DragHandler.svg";
import DeleteIconRed from "../../images/DeleteIconRed.svg";
import LockIcon from "../../images/LockIcon.svg";

const DragHandle = sortableHandle(() => (
  <span style={{ cursor: "grabbing" }}>
    <img
      src={DragHandlerIcon}
      alt="::"
      style={{ height: "18px", cursor: "grab" }}
    />
  </span>
));

const RESTRICTED_FIELDS = [
  "ship_date",
  "delivery_date",
  "origin",
  "destination",
  "status",
  "cf_equipment_types",
];
const isRestrictedField = (fieldName, isQuote) => {
  return RESTRICTED_FIELDS.includes(fieldName) && isQuote;
};

const SortableItem = sortableElement(
  ({ showInput, field, deleteField, isQuote }) => (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "8% 84% 8%",
      }}
    >
      {isRestrictedField(field.name, isQuote) ? (
        <span style={{ cursor: "not-allowed" }}>
          <img
            src={LockIcon}
            alt="x"
            style={{
              marginRight: "10px",
              marginTop: "15px",
              cursor: "not-allowed",
            }}
          />
        </span>
      ) : (
        <DragHandle />
      )}

      {showInput(
        field.name,
        field.label,
        field.type,
        field.choices,
        field.required,
        isQuote
      )}
      {field.selected_by_default === true ? null : (
        <button
          className="button-transparent"
          type="button"
          onClick={() => deleteField(field)}
        >
          <img src={DeleteIconRed} alt="Delete" width="18px" />
        </button>
      )}
    </div>
  )
);

const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});

const ModalPreview = ({
  displayIcon,
  heading,
  description,
  selectedFields,
  setSelectedFields,
  sectionHeadingChangeHandler,
  deleteField,
  isQuote,
}) => {
  const getRestrictedIndices = () => {
    return selectedFields
      .map((field, index) =>
        isRestrictedField(field.name, isQuote) ? index : null
      )
      .filter((index) => index !== null);
  };

  // Function to handle sort end event
  const onSortEnd = ({ oldIndex, newIndex }) => {
    // Get the moving field
    const movingField = selectedFields[oldIndex];

    // Get all restricted indices
    const restrictedIndices = getRestrictedIndices();

    // Check if this field is restricted from dragging
    if (isRestrictedField(movingField.name, isQuote)) {
      // If the field is restricted, don't allow the move
      return;
    }

    // Check if the destination is a position currently occupied by a restricted field
    // or would displace a restricted field from its position
    if (restrictedIndices.includes(newIndex)) {
      // Don't allow moving a field to a position occupied by a restricted field
      return;
    }

    // If dragging through restricted fields would change their positions, don't allow it
    // This is for when you drag from above restricted fields to below them or vice versa
    const dragDirection = newIndex > oldIndex ? 1 : -1;

    if (dragDirection > 0) {
      // Dragging downward
      for (let i = oldIndex + 1; i <= newIndex; i++) {
        if (restrictedIndices.includes(i)) {
          // The drag would move a restricted field, so don't allow it
          return;
        }
      }
    } else {
      // Dragging upward
      for (let i = oldIndex - 1; i >= newIndex; i--) {
        if (restrictedIndices.includes(i)) {
          // The drag would move a restricted field, so don't allow it
          return;
        }
      }
    }

    // If no restrictions are violated, proceed with the move
    setSelectedFields(arrayMove(selectedFields, oldIndex, newIndex));
  };
  const showInput = (
    fieldName,
    fieldLabel,
    fieldType,
    fieldChoices,
    fieldRequired
  ) => {
    let inputType;
    if (fieldName === "preferred_communication") {
      inputType = (
        <div className="modal-radioButton">
          <label
            htmlFor="call"
            style={{
              color: "#555555",
              padding: "4px 12px",
              border: "none",
              borderRadius: "9px",
              background: "#F4F6F5",
              marginRight: "13px",
              fontWeight: "normal",
              fontSize: "14px",
              lineHeight: "16px",
            }}
          >
            Call
          </label>
          <input
            type="checkbox"
            name="preferred_communication"
            value="call"
            id="call"
            className="radio-button"
          />
          <label
            htmlFor="email"
            style={{
              color: "#555555",
              padding: "4px 12px",
              border: "none",
              borderRadius: "9px",
              background: "#F4F6F5",
              marginRight: "13px",
              fontWeight: "normal",
              fontSize: "14px",
              lineHeight: "16px",
            }}
          >
            Email
          </label>
          <input
            type="checkbox"
            name="preferred_communication"
            value="email"
            id="email"
            className="radio-button"
          />
          <label
            htmlFor="text"
            style={{
              color: "#555555",
              padding: "4px 12px",
              border: "none",
              borderRadius: "9px",
              background: "#F4F6F5",
              marginRight: "13px",
              fontWeight: "normal",
              fontSize: "14px",
              lineHeight: "16px",
            }}
          >
            Text
          </label>
          <input
            type="checkbox"
            name="preferred_communication"
            value="text"
            id="text"
            className="radio-button"
          />
        </div>
      );
    } else if (fieldType === "heading") {
      inputType = (
        <div className="controlsDashboard">
          {isQuote ? (
            <>
              <input
                type="text"
                name={fieldName}
                required={true}
                value={fieldLabel}
                onChange={(e) => sectionHeadingChangeHandler(fieldName, e)}
                className=""
                style={{
                  fontSize: "14px",
                  borderRadius: "4px",
                  border: "1px solid #c5c5d3",
                  width: "95%",
                }}
              />
              <label className="activeLabel" style={{ top: "-5px" }}>
                Section Heading
              </label>
            </>
          ) : (
            <>
              <input
                type="text"
                name={fieldName}
                required={true}
                value={fieldLabel}
                onChange={(e) => sectionHeadingChangeHandler(fieldName, e)}
                className="modal__heading-sub"
                style={{
                  fontSize: "15px",
                  borderRadius: "4px",
                  padding: "8px",
                  border: "1px solid #c5c5d3",
                  paddingTop: "13px",
                  paddingBottom: "5px",
                  minWidth: "220px",
                  marginRight: "10px",
                }}
              />
              <label className="activeLabel" style={{ top: "-5px" }}>
                Section Heading
              </label>
            </>
          )}
        </div>
      );
    } else if (fieldType === "text") {
      inputType = (
        <input type="text" name={fieldName} required={fieldRequired} />
      );
    } else if (fieldType === "hyperlink") {
      inputType = (
        <input type="text" name={fieldName} required={fieldRequired} />
      );
    } else if (fieldType === "revenue") {
      inputType = (
        <input type="text" name={fieldName} required={fieldRequired} />
      );
    } else if (fieldType === "paragraph") {
      inputType = (
        <textarea name={fieldName} required={fieldRequired}></textarea>
      );
    } else if (fieldType === "decimal") {
      inputType = (
        <input type="number" name={fieldName} required={fieldRequired} />
      );
    } else if (fieldType === "dropdown") {
      inputType = (
        <Select
          className="basic-single"
          classNamePrefix="select"
          styles={colourStyles}
          required={fieldRequired}
          placeholder="-Select-"
          isClearable={false}
          isSearchable={true}
          name={fieldName}
          options={fieldChoices.map((choice) => {
            const { value } = choice;
            return { value, label: value };
          })}
        />
      );
    } else if (fieldType === "checkbox") {
      inputType = (
        <div style={{ width: "20px" }}>
          <input
            type="checkbox"
            style={{ display: "inline-block", textAlign: "left" }}
            name={fieldName}
            value={true}
          />
        </div>
      );
    } else if (fieldType === "radio") {
      inputType = (
        <div>
          {fieldChoices.map((choice) => {
            const { id, value } = choice;
            return (
              <div key={id} style={{ textAlign: "left" }}>
                <input
                  type="radio"
                  style={{
                    display: "inline-block",
                    textAlign: "left",
                    width: "20px",
                  }}
                  name={fieldName}
                  id={fieldName + value}
                  required={fieldRequired}
                />
                <label
                  style={{ display: "inline-block", textAlign: "left" }}
                  htmlFor={fieldName + value}
                >
                  {value}
                </label>
              </div>
            );
          })}
        </div>
      );
    } else if (fieldType === "date") {
      inputType = (
        <DatePicker
          name={fieldName}
          selected={Date.parse()}
          value={Date.parse()}
          autoComplete="off"
          required={fieldRequired}
        />
      );
    } else if (fieldType === "multi_select_dropdown") {
      inputType = (
        <Select
          closeMenuOnSelect={false}
          isMulti
          name={fieldName}
          styles={colourStyles}
          options={fieldChoices.map((choice) => {
            const { value } = choice;
            return { value: value, label: value };
          })}
          placeholder="-Select-"
          className="basic-multi-select"
          classNamePrefix="select"
          required={fieldRequired}
        />
      );
    }
    return (
      <div>
        {fieldType !== "heading" && (
          <label>
            {fieldLabel}{" "}
            {fieldRequired && (
              <span
                style={{
                  color: "#4A9876",
                  fontSize: "17px",
                  lineHeight: "16px",
                }}
              >
                *
              </span>
            )}
          </label>
        )}
        {inputType}
      </div>
    );
  };

  return (
    <div
      className="modal__content customize-modal"
      style={{ height: "77vh", top: "0%", zIndex: "1", left: "30px" }}
    >
      <div className="modal__header">
        <span>
          <div className="customerInfo-icon">
            <img
              src={displayIcon}
              alt=""
              width="17px"
              style={{ transform: "translateY(9px)" }}
            />
          </div>
        </span>
        <div className="modal__head">
          <h2 className="modal__heading">{heading}</h2>
          <p className="modal__heading-desc">{description}</p>
        </div>
        <button
          className="modal__close"
          style={{
            border: "none",
            background: "transparent",
            height: "12px",
            cursor: "pointer",
            outline: "none",
          }}
        >
          {" "}
          <i className="fa fa-times" aria-hidden="true"></i>
        </button>
      </div>
      <form
        style={{
          maxHeight: "58vh",
          overflowY: "auto",
          overflowX: "hidden",
          height: "auto",
          minHeight: "50vh",
        }}
      >
        <SortableContainer
          onSortEnd={onSortEnd}
          useDragHandle
          // Prevent dragging entirely when in quote mode for restricted items
          shouldCancelStart={(e) => {
            // Find the closest SortableItem parent element
            let element = e.target;
            while (element && !element.getAttribute("data-field-name")) {
              element = element.parentElement;
            }

            // If we found an element with a field name
            if (element) {
              const fieldName = element.getAttribute("data-field-name");
              return isRestrictedField(fieldName, isQuote);
            }

            return false;
          }}
          helperClass="dragging-helper"
        >
          <div
            style={
              isQuote
                ? {
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: "20px",
                    padding: "10px",
                  }
                : {}
            }
          >
            {selectedFields?.map((field, index) => (
              <SortableItem
                stepIndex={index}
                key={`field-modal-${index}`}
                index={index}
                showInput={showInput}
                field={field}
                deleteField={deleteField}
                isQuote={isQuote}
              />
            ))}
          </div>
        </SortableContainer>

        <div className="modal__buttons">
          {/* <button type="reset" className="modal__cancel-button">
            Cancel
          </button> */}
          {/* <button type="button" className="modal__confirm-button">
            Save
          </button> */}
        </div>
      </form>
    </div>
  );
};

export default ModalPreview;
