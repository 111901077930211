import {
  FETCH_QUOTE_CUSTOM_FIELDS_REQUEST,
  FETCH_QUOTE_CUSTOM_FIELDS_SUCCESS,
  FETCH_QUOTE_CUSTOM_FIELDS_FAILURE,
} from "./types";

import axios from "axios";
import { BASE_URL } from "../../config";

// Action Creators
const fetchAllQuoteCustomFieldsRequest = () => {
  return {
    type: FETCH_QUOTE_CUSTOM_FIELDS_REQUEST,
  };
};
const fetchAllQuoteCustomFieldsSuccess = (data) => {
  return {
    type: FETCH_QUOTE_CUSTOM_FIELDS_SUCCESS,
    payload: data,
  };
};
const fetchAllQuoteCustomFieldsFailure = (err) => {
  return {
    type: FETCH_QUOTE_CUSTOM_FIELDS_FAILURE,
    payload: err,
  };
};

// Function to make the API request with retry logic
const fetchFieldsWithRetry = async (retryCount = 3) => {
  const token = JSON.parse(localStorage.getItem("authToken"))?.access_token;

  // Define the request configuration
  const config = {
    method: "GET",
    url: `${BASE_URL}/fields/lane_quote`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // Retry logic
  for (let i = 0; i < retryCount; i++) {
    try {
      const response = await axios(config);
      return response.data.fields; // Return the data on success
    } catch (error) {
      // If this was the last attempt, throw the error
      if (i === retryCount - 1) {
        throw error;
      }
      console.warn(`Retrying request (${i + 1}/${retryCount})...`);
    }
  }
};

// Redux Thunk action with retry logic
export const fetchAllQuoteCustomFields = (retryCount = 3) => {
  return async (dispatch) => {
    dispatch(fetchAllQuoteCustomFieldsRequest());

    try {
      // Call the retryable API function
      const fields = await fetchFieldsWithRetry(retryCount);
      dispatch(fetchAllQuoteCustomFieldsSuccess(fields));
    } catch (error) {
      const errorMsg =
        error.response?.data?.message ||
        "Error fetching accounts custom fields";
      dispatch(fetchAllQuoteCustomFieldsFailure(errorMsg));
    }
  };
};
