import React, { useEffect, useState } from "react";
import { Autocomplete } from "@react-google-maps/api";

function GoogleAutocomplete({
  onPlaceSelect,
  placeholder,
  className,
  valueProps,
  name,
  resetTrigger,
}) {
  const [value, setValue] = useState("");

  useEffect(() => {
    if (valueProps) {
      setValue(valueProps);
    } else if (!valueProps) {
      onPlaceSelect({
        address: null,
        lat: null,
        long: null,
        city: null,
        state: null,
        zip: null,
        country: null,
      });
    }
  }, [valueProps]);

  useEffect(() => {
    // Only reset if resetTrigger has a value and changed
    if (resetTrigger) {
      setValue("");
      onPlaceSelect({
        address: null,
        lat: null,
        long: null,
        city: null,
        state: null,
        zip: null,
        country: null,
      });
    }
  }, [resetTrigger]);

  const onLoad = (autocomplete) => {
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (!place.geometry) {
        console.error("No details available for input: ", place);
        const failedPlaceObject = {
          address: null,
          lat: null,
          long: null,
          city: null,
          state: null,
          zip: null,
          country: null,
        };
        onPlaceSelect(failedPlaceObject);
        return;
      }
      const { lat, lng } = place.geometry.location;
      // Extract address components
      const addressComponents = place.address_components;

      let city = null;
      let state = null;
      let zip = null;
      let country = null;

      for (let i = 0; i < addressComponents.length; i++) {
        const component = addressComponents[i];
        const types = component.types;

        if (types.includes("locality")) {
          city = component.long_name;
        }
        if (types.includes("administrative_area_level_1")) {
          state = component.short_name;
        }
        if (types.includes("postal_code")) {
          zip = component.long_name;
        }
        if (types.includes("country")) {
          country = component.long_name;
        }
      }

      console.log("City:", city);
      console.log("State:", state);
      console.log("Zip Code:", zip);
      console.log("Country:", country);
      console.log("Latitude:", lat());
      console.log("Longitude:", lng());
      console.log("google address check", place);
      const placeObject = {
        address: place.formatted_address,
        lat: lat(),
        long: lng(),
        city,
        state,
        zip,
        country,
      };
      onPlaceSelect(placeObject);
      setValue(place.formatted_address);
    });
  };

  return (
    <Autocomplete
      onLoad={onLoad}
      onPlaceChanged={() => {}}
      className="custom-autocomplete"
    >
      <input
        type="text"
        name={name}
        placeholder={placeholder}
        className={className}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </Autocomplete>
  );
}

export default GoogleAutocomplete;
