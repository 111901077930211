import React from "react";
import Collapsible from "react-collapsible";
import { Link } from "react-router-dom";
import CustomIcon from "../../../images/Customize.svg";
import ArrowIcon from "../../../images/Arrow.svg";

const CustomFieldHelperText = ({ windowWidth }) => {
  return (
    <div className="col-1-of-3">
      <div
        style={
          windowWidth > 650
            ? {
                boxShadow: "0px 2px 20px rgba(51, 51, 51, 0.15)",
                height: "auto",
                borderRadius: "11px",
                background: "#FFFFFF",
                padding: "5px 20px 20px 20px",
                marginLeft: "10px",
              }
            : {
                boxShadow: "0px 2px 20px rgba(51, 51, 51, 0.15)",
                height: "auto",
                borderRadius: "11px",
                background: "#FFFFFF",
                padding: "5px 20px 20px 20px",
                width: "86vw",
              }
        }
      >
        <div style={{ display: "flex", marginTop: "30px" }}>
          <div style={{ display: "inline-block" }}>
            <img
              src={CustomIcon}
              alt=""
              width="34px"
              style={{
                transform: "translateY(15px)",
                marginLeft: "30px",
                marginRight: "24px",
              }}
            />
          </div>
          <div
            style={{
              display: "inline",
            }}
          >
            <h2
              style={{
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "24px",
              }}
            >
              Custom Fields in Salesdash
            </h2>
            <p
              style={{
                fontSize: "14px",
                lineHeight: "16px",
              }}
            >
              The purpose of adding Fields is for you to easily
              collect the information you feel most necessary about every
              customer you add to your CRM.
            </p>
            <p
              style={{
                fontSize: "14px",
                lineHeight: "16px",
              }}
            >
              Fields can be added at any time. They can also be
              deleted, however be cautious because any data that has been
              submitted within a deleted group or field is deleted permanently.
            </p>
            <Collapsible
              className="collapseHeader"
              classParentString="collapseHeader"
              trigger={
                <div>
                  Fields
                  <button
                    type="button"
                    style={{
                      background: "transparent",
                      padding: "3px 10px",
                      border: "none",
                      borderRadius: "5px",
                      color: "white",
                      outline: "none",
                      cursor: "pointer",
                      position: "absolute",
                      top: "30%",
                      right: "2%",
                    }}
                  >
                    <img src={ArrowIcon} alt="" height="10px" />
                  </button>
                </div>
              }
              triggerWhenOpen="Fields"
              triggerStyle={{
                fontSize: "15px",
                lineHeight: "18px",
                cursor: "pointer",
              }}
            >
              <p
                style={{
                  fontSize: "14px",
                  lineHeight: "16px",
                }}
              >
                Types of Fields
              </p>
              <ol
                style={{
                  fontSize: "14px",
                  lineHeight: "16px",
                }}
              >
                <li>
                  <strong>Text Field</strong> - One line of text
                </li>
                <li>
                  <strong>Text Area</strong> - Multiple lines of text, allows
                  for paragraph
                </li>
                <li>
                  <strong>Number</strong> - Field to only enter numbers
                </li>
                <li>
                  <strong>Dropdown</strong> - Displays predefined options to
                  select one option
                </li>
                <li>
                  <strong>Checkbox</strong> - Click to check off an added field.
                  Useful when trying to confirm if an action has been complete,
                  information has been confirmed/collected about a customer
                </li>
                <li>
                  <strong>Radio Button</strong> - Click to choose one option
                  from predefined options
                </li>
                <li>
                  <strong>Date</strong> - Enter a date from the calendar
                </li>
                <li>
                  <strong>Multi-select</strong> - Add a preset of options and be
                  able to select multiple options
                </li>
              </ol>
            </Collapsible>
            <p
              style={{
                fontSize: "14px",
                lineHeight: "16px",
              }}
            >
              If there is anything we can help with, please reach out to us -{" "}
              <strong>info@salesdashcrm.com</strong>.
            </p>
            <Link
              to="/"
              style={{
                textDecoration: "none",
                marginBottom: "30px",
                padding: "8px 16px",
              }}
              className="button-sm-secondary"
            >
              Exit
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomFieldHelperText;
