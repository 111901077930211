// Libraries
import React, { useCallback, useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { LoadScript } from "@react-google-maps/api";

// env variables
import {
  // RING_CENTRAL_CLIENT_SECRET,
  RING_CENTRAL_APP_SERVER,
  RING_CENTRAL_CLIENT_ID,
  GOOGLE_API_KEY,
} from "../config";

// SD files
import Dashboard from "../components/Dashboard";
import Nav from "../components/Nav";
import TabBar from "../components/Tabs";
import SubscriptionNav from "../components/SubscriptionNav";
import SampleDataNav from "../components/SampleDataNav";
import SalesdashRouter from "./SalesdashRouter";

// Redux stuff
import { connect } from "react-redux";
import {
  companyOnboardingSet,
  authUserSet,
  authTokenSet,
  fetchAllDashboards,
  fetchAllAllFormAccountFields,
  fetchAllAllFormCarrierFields,
  fetchAllAllFormContactFields,
  fetchAllAllFormDealFields,
  fetchAllAllFormQuoteFields,
  fetchAllUsers,
  fetchAllEquipmentTypes,
  fetchAllLaneTypes,
  fetchAllSpecialRequirements,
  fetchAllModes,
  fetchAllAccountSources,
  fetchAllAccountStatuses,
  setCurrentActivity,
  fetchAllCarrierStatuses,
  fetchAllContactStatuses,
  fetchAllAccountCustomFields,
  fetchAllLaneCustomFields,
  fetchAllCarrierCustomFields,
  fetchAllContactCustomFields,
  fetchAllDealCustomFields,
  fetchAllLaneStatuses,
  fetchAllQuoteCustomFields,
} from "../redux";
import DashboardLoader from "../components/Loaders/DashboardLoader";

const libraries = ["places"];
function AppRouter(props) {
  const [isSampleData, setIsSampleData] = useState(false);
  const [showRingCentralWidget, setShowRingCentralWidget] = useState(false);
  const [activityState, setActivityState] = useState({});
  const [recordingLink, setRecordingLink] = useState("");
  const [callDescription, setCallDescription] = useState("");
  const activityStateConstant = activityState;
  const subscriptionStatus = JSON.parse(
    localStorage.getItem("subscriptionStatus")
  );
  console.log("IsSampleData", isSampleData);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkSampleData = () => {
    axios({
      method: "GET",
      url: `/admin/sample-data`,
    })
      .then((res) => {
        setIsSampleData(res.data.is_sample_data_available);
      })
      .catch((err) => {
        setIsSampleData(false);
      });
  };
  const deleteSampleData = () => {
    axios({
      method: "DELETE",
      url: `/admin/sample-data`,
    })
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        NotificationManager.error("Error deleting sample data");
      });
  };
  const checkSubcription = useCallback(() => {
    axios({
      method: "GET",
      url: `/company/${props.authUser.company_id}/subscription-status`,
    }).then((res) => {
      localStorage.setItem(
        "subscriptionStatus",
        JSON.stringify(res.data.company.subscription_status)
      );
    });
  }, [props.authUser.company_id]);

  const getUserDetails = () => {
    axios({
      method: "GET",
      url: `/users`,
    }).then((res) => {
      setShowRingCentralWidget(res.data.user.is_ringcentral_enabled);
    });
  };

  useEffect(() => {
    checkSubcription();
  }, [checkSubcription]);
  useEffect(() => {
    checkSampleData();
  }, []);
  useEffect(() => {
    props.fetchAllDashboards();
    props.fetchAllEquipmentTypes();
    props.fetchAllLaneTypes();
    props.fetchAllSpecialRequirements();
    props.fetchAllModes();
    props.fetchAllAccountSources();
    props.fetchAllAccountStatuses();
    props.fetchAllLaneStatuses();
    props.fetchAllCarrierStatuses();
    props.fetchAllContactStatuses();
    props.fetchAllUsers();
    props.fetchAllAllFormAccountFields();
    props.fetchAllAllFormCarrierFields();
    props.fetchAllAllFormContactFields();
    props.fetchAllAllFormDealFields();
    props.fetchAllAccountCustomFields();
    props.fetchAllLaneCustomFields();
    props.fetchAllDealCustomFields();
    props.fetchAllCarrierCustomFields();
    props.fetchAllContactCustomFields();
    props.fetchAllAllFormQuoteFields();
    props.fetchAllQuoteCustomFields();
    getUserDetails();
    document.documentElement.setAttribute("spellcheck", "true");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (
      window.location.pathname === "/reset-password" &&
      props.authUser !== null
    ) {
      const myLocation = window.location.href;
      console.log("ho gia");
      localStorage.clear();
      props.authUserSet(null);
      props.authTokenSet(null);
      window.location.href = myLocation;
    }
  }, [props]);

  // Ring Central App
  useEffect(() => {
    function ringCentralWidget() {
      var rcs = document.createElement("script");
      rcs.src = `https://ringcentral.github.io/ringcentral-embeddable/adapter.js?clientId=${RING_CENTRAL_CLIENT_ID}&appServer=${RING_CENTRAL_APP_SERVER}&authMode=oauth&prompt=login&redirectUri=${encodeURIComponent(
        "https://ringcentral.github.io/ringcentral-embeddable/redirect.html"
      )}`;
      // Add error handling
      rcs.onerror = (error) => {
        console.error("RingCentral widget loading error:", error);
      };
      var rcs0 = document.getElementsByTagName("script")[0];
      rcs0.parentNode.insertBefore(rcs, rcs0);
      var clickToDial = new RingCentralC2D(); // eslint-disable-line no-undef
      clickToDial.on(RingCentralC2D.events.call, (phoneNumber) => {// eslint-disable-line
        // eslint-disable-line no-undef
        // eslint-disable-line
        RCAdapter.clickToCall(phoneNumber, true); // eslint-disable-line
      });
      clickToDial.on(RingCentralC2D.events.text, (phoneNumber) => {// eslint-disable-line
        // eslint-disable-line no-undef
        // eslint-disable-line
        RCAdapter.clickToSMS(phoneNumber); // eslint-disable-line
      });
    }
    if (!RING_CENTRAL_CLIENT_ID || !RING_CENTRAL_APP_SERVER) {
      console.error("RingCentral configuration not fully loaded");
      return;
    }
    showRingCentralWidget && ringCentralWidget();
  }, [showRingCentralWidget, RING_CENTRAL_CLIENT_ID, RING_CENTRAL_APP_SERVER]);
  useEffect(() => {
    document
      .querySelector("#rc-widget-adapter-frame")
      ?.contentWindow.postMessage(
        {
          type: "rc-adapter-register-third-party-service",
          service: {
            name: "TestService",
            callLoggerPath: "/callLogger",
            callLoggerTitle: "Log Notes",
            showLogModal: true,
            // recordingWithToken: 1
          },
        },
        "*"
      );
  }, [document.querySelector("#rc-widget-adapter-frame")]);
  // const editActivityForNotes = (notes) => {
  //   if (activityStateConstant?.id && notes) {
  //     axios({
  //       method: "PUT",
  //       url: `/activities/${activityStateConstant.id}`,
  //       data: {
  //         name: activityStateConstant.name,
  //         description: notes + activityStateConstant.description,
  //         category_id: 1,
  //         result_id: null,
  //         type: "activity_logged",
  //       },
  //     })
  //       .then((res) => {
  //         props.setCurrentActivity({
  //           fetchActivity: true,
  //           data: {},
  //         });
  //       })
  //       .then((res) => {
  //         props.setCurrentActivity({
  //           fetchActivity: false,
  //         });
  //       });
  //   }
  // };
  // const getCallNotes = (telephonySessionId) => {
  //   axios({
  //     method: "GET",
  //     url: `https://api-ucc.ringcentral.com/ai/rio/v1/call-summary/notes?telephonySessionId=${telephonySessionId}&ignoreDenaliError=true`,
  //   }).then((res) => {
  //     const notes = res.data?.data;
  //     editActivityForNotes(notes);
  //   });
  // };
  const fetchPhoneInfo = async (value) => {
    const res = await axios({
      method: "GET",
      url: `/dashboard/search?query=${encodeURIComponent(value)}`,
    });
    return res.data.data || null;
  };
  const getPhoneNumberWithoutCountryCode = (phoneNumber) => {
    if (phoneNumber.startsWith("+")) {
      return phoneNumber.slice(2);
    }
    return phoneNumber;
  };
  const getPreferredRecord = (matchedRecords) => {
    if (matchedRecords.length === 1) {
      return matchedRecords[0];
    }
    const priorityOrder = ["contact", "opportunity", "account", "carrier"];
    for (const type of priorityOrder) {
      const record = matchedRecords.find((record) => record.type === type);
      if (record) {
        return record;
      }
    }
    return matchedRecords[0] || null;
  };
  const logCall = async () => {
    if (callDescription) {
      const toNumber = getPhoneNumberWithoutCountryCode(
        callDescription?.to?.phoneNumber
      );
      const matchedRecords = await fetchPhoneInfo(toNumber);
      if (matchedRecords?.length > 0) {
        const record = getPreferredRecord(matchedRecords);
        axios({
          method: "POST",
          url: `/activities/create`,
          data: {
            name: `${callDescription?.direction} Call`,
            description: `${callDescription?.direction} Call made at ${Date(
              callDescription?.startTime
            )} to <span style="color: #4A9876;">${
              callDescription?.to?.phoneNumber
            }</span>`,
            category_id: 1,
            ...(record.type === "account" && {
              account_id: record.id,
            }),
            ...(record.type === "contact" && {
              contact_id: record.id,
              ...(record.account_id && { account_id: record.account_id }),
              ...(record.carrier_id && { carrier_id: record.carrier_id }),
            }),
            ...(record.type === "opportunity" && {
              opportunity_id: record.id,
              ...(record.account_id && { account_id: record.account_id }),
              ...(record.carrier_id && { carrier_id: record.carrier_id }),
              ...(record.contact_id && { contact_id: record.contact_id }),
            }),
            ...(record.type === "carrier" && {
              carrier_id: record.id,
            }),
            type: "activity_logged",
          },
          validateStatus: function (status) {
            // Only resolve if the status is not 204
            return status !== 204;
          },
        })
          .then((res) => {
            setActivityState(res.data.activity);
            // setTimeout(() => {
            //   getCallNotes(callDescription?.telephonySessionId);
            // }, 20000);
            props.setCurrentActivity({
              fetchActivity: true,
              data: res.data.activity,
            });
          })
          .then((res) => {
            props.setCurrentActivity({
              fetchActivity: false,
            });
          });
      }
    }
  };
  useEffect(() => {
    callDescription && logCall();
  }, [callDescription]);
  const editActivity = () => {
    console.log("activityState", activityStateConstant);
    if (activityStateConstant?.id && recordingLink) {
      axios({
        method: "PUT",
        url: `/activities/${activityStateConstant.id}`,
        data: {
          name: activityStateConstant.name + " (Recording)",
          description:
            activityStateConstant.description +
            `<div style="margin-top: 6px;">Call Recording: <a href=${recordingLink} target="_blank" rel="noopener noreferrer">Recording Link</a></div>`,
          category_id: activityStateConstant.category_id,
          result_id: null,
          type: activityStateConstant.type,
        },
      })
        .then((res) => {
          props.setCurrentActivity({
            fetchActivity: true,
            data: {},
          });
        })
        .then((res) => {
          props.setCurrentActivity({
            fetchActivity: false,
          });
        });
    }
  };
  useEffect(() => {
    editActivity();
  }, [recordingLink]);
  useEffect(() => {
    const handleMessage = (e) => {
      const data = e.data;

      if (data) {
        if (
          data.type === "rc-active-call-notify" &&
          data.call &&
          data.call.terminationType === "final" &&
          data.call.direction === "Outbound"
        ) {
          setCallDescription(data.call);
        } else if (
          data.type === "rc-active-call-notify" &&
          data.call &&
          data.call.telephonyStatus === "CallConnected" &&
          data.call.direction === "Outbound"
        ) {
        } else if (data && data.type === "rc-post-message-request") {
          console.log(data);
          if (data?.body?.call?.recording?.link) {
            setRecordingLink(data?.body?.call?.recording?.link);
          } else if (data && data.type === "rc-call-log-notes") {
            console.log("notes", data, data?.call);
          }
          if (data.path === "/callLogger") {
            console.log(data);
            // response to widget
            document
              .querySelector("#rc-widget-adapter-frame")
              .contentWindow.postMessage(
                {
                  type: "rc-post-message-response",
                  responseId: data.requestId,
                  response: { data: "ok" },
                },
                "*"
              );
          }
        } else if (data.type === "rc-callLogger-auto-log-notify") {
          console.log("rc-callLogger-auto-log-notify:", data);
        }
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [activityState]);
  return (
    <BrowserRouter>
      <LoadScript
        googleMapsApiKey={GOOGLE_API_KEY}
        libraries={libraries}
        loadingElement={DashboardLoader}
      >
        <div>
          {(subscriptionStatus === "inactive" ||
            subscriptionStatus === "past_due") && (
            <SubscriptionNav
              status={subscriptionStatus}
              role={props.authUser.role}
            />
          )}
          {isSampleData && (
            <SampleDataNav deleteSampleData={deleteSampleData} />
          )}
          <Nav isSampleData={isSampleData} status={subscriptionStatus} />
          {/* {props.authUser.tour_completed === false && <OnboardingMenu />} */}
          <div
            style={
              (subscriptionStatus === "inactive" ||
                subscriptionStatus === "past_due") &&
              isSampleData
                ? { marginTop: "90px" }
                : subscriptionStatus === "inactive" ||
                  subscriptionStatus === "past_due" ||
                  isSampleData
                ? { marginTop: "70px" }
                : { marginTop: "50px" }
            }
          >
            <TabBar />
            <div
              style={
                (subscriptionStatus === "inactive" ||
                  subscriptionStatus === "past_due") &&
                isSampleData
                  ? { paddingTop: "81px" }
                  : subscriptionStatus === "inactive" ||
                    subscriptionStatus === "past_due" ||
                    isSampleData
                  ? { paddingTop: "61px" }
                  : { paddingTop: "41px" }
              }
            >
              {!props.companyOnboarding?.onboarded ? (
                <Switch>
                  <Route component={Dashboard} />
                </Switch>
              ) : (
                <SalesdashRouter />
              )}
            </div>
          </div>
        </div>
      </LoadScript>
    </BrowserRouter>
  );
}

const MSP = (state) => {
  return {
    companyOnboarding: state.login.companyOnboarding,
    authUser: state.login.authUser,
    authToken: state.login.authToken,
    dashboardsState: state.dashboardsState,
    allAccountFields: state.allAccountFields,
    allAccountCustomFields: state.allAccountCustomFields.fields,
    allLaneCustomFields: state.allLaneCustomFields.fields,
    allCarrierFields: state.allCarrierFields,
    allCarrierCustomFields: state.allCarrierCustomFields.fields,
    allContactFields: state.allContactFields,
    allContactCustomFields: state.allContactCustomFields.fields,
    allDealFields: state.allDealFields,
    allDealCustomFields: state.allDealCustomFields.fields,
    allUsers: state.allUsers,
    allEquipmentTypes: state.allEquipmentTypes.equipmentTypes,
    allLaneTypes: state.allLaneTypes.laneTypes,
    allModes: state.allModes.modes,
    allAccountSources: state.allAccountSources.accountSources,
    allAccountStatuses: state.allAccountStatuses.accountStatuses,
    allLaneStatuses: state.allLaneStatuses.laneStatuses,
    allCarrierStatuses: state.allCarrierStatuses.carrierStatuses,
    allContactStatuses: state.allContactStatuses.contactStatuses,
    tabs: state.tabsState.tabs,
    activeTabIndex: state.tabsState.activeTabIndex,
    activities: state.activities,
  };
};
const MDP = (dispatch) => {
  return {
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    authTokenSet: (authTokenData) => dispatch(authTokenSet(authTokenData)),
    fetchAllDashboards: (dashboardData) =>
      dispatch(fetchAllDashboards(dashboardData)),
    fetchAllEquipmentTypes: (equipmentData) =>
      dispatch(fetchAllEquipmentTypes(equipmentData)),
    fetchAllLaneTypes: (laneTypes) => dispatch(fetchAllLaneTypes(laneTypes)),
    fetchAllSpecialRequirements: (specialRequirements) =>
      dispatch(fetchAllSpecialRequirements(specialRequirements)),
    fetchAllModes: (modes) => dispatch(fetchAllModes(modes)),
    fetchAllAccountSources: (accountSources) =>
      dispatch(fetchAllAccountSources(accountSources)),
    fetchAllAccountStatuses: (data) => dispatch(fetchAllAccountStatuses(data)),
    fetchAllLaneStatuses: (data) => dispatch(fetchAllLaneStatuses(data)),
    fetchAllCarrierStatuses: (data) => dispatch(fetchAllCarrierStatuses(data)),
    fetchAllContactStatuses: (data) => dispatch(fetchAllContactStatuses(data)),
    fetchAllUsers: (usersData) => dispatch(fetchAllUsers(usersData)),
    fetchAllAllFormAccountFields: (allAccountFields) =>
      dispatch(fetchAllAllFormAccountFields(allAccountFields)),
    fetchAllAllFormQuoteFields: (allQuoteFields) =>
      dispatch(fetchAllAllFormQuoteFields(allQuoteFields)),
    fetchAllAllFormCarrierFields: (allCarrierFields) =>
      dispatch(fetchAllAllFormCarrierFields(allCarrierFields)),
    fetchAllAllFormContactFields: (allContactFields) =>
      dispatch(fetchAllAllFormContactFields(allContactFields)),
    fetchAllAllFormDealFields: (allDealFields) =>
      dispatch(fetchAllAllFormDealFields(allDealFields)),
    setCurrentActivity: (data) => dispatch(setCurrentActivity(data)),
    fetchAllAccountCustomFields: (data) =>
      dispatch(fetchAllAccountCustomFields(data)),
    fetchAllLaneCustomFields: (data) =>
      dispatch(fetchAllLaneCustomFields(data)),
    fetchAllCarrierCustomFields: (data) =>
      dispatch(fetchAllCarrierCustomFields(data)),
    fetchAllContactCustomFields: (data) =>
      dispatch(fetchAllContactCustomFields(data)),
    fetchAllDealCustomFields: (data) =>
      dispatch(fetchAllDealCustomFields(data)),
    fetchAllQuoteCustomFields: (data) =>
      dispatch(fetchAllQuoteCustomFields(data)),
  };
};

export default connect(MSP, MDP)(AppRouter);
