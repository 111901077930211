import React, { useEffect } from "react";
import moment from "moment";
import DashboardLoader from "../Loaders/DashboardLoader";
import "./QuotesView.scss";
import CollapsibleRoute from "./Modal/CollapsibleRoute";

import Pagination from "react-js-pagination";

const QuotesView = ({
  laneQuotesData,
  laneQuotes,
  total,
  setLaneActivePage,
  loadLaneQuotes,
  laneQuoteLoader,
  laneQuoteActivePage,
  loadActivities,
  activityLoader,
  logs,
  activePage,
  setActivePage,
  totalQuotes,
}) => {
  useEffect(() => {
    if (!laneQuotes) {
      loadActivities();
    }
  }, [loadActivities]);
  return (
    <div className="lanes-view">
      <div className="lanes-container">
        {activityLoader ? (
          <DashboardLoader />
        ) : (
          <div
            id="scrollableDiv"
            style={{ maxHeight: "700px", overflow: "auto", height: "auto" }}
          >
            {!laneQuotes ? (
              //Quote Tab
              <>
                {Array.isArray(logs) &&
                  logs.map((item) => {
                    const { lane_quote } = item;
                    const quotePrice = new Intl.NumberFormat("en-US").format(
                      lane_quote?.price
                    );
                    const shipmentDate =
                      lane_quote?.shipment_date &&
                      moment(lane_quote?.shipment_date).format("MM/DD/YY");
                    const deliveryDate = lane_quote?.delivery_date
                      ? moment(lane_quote.delivery_date).format("MM/DD/YY")
                      : null;

                    return (
                      <div key={item?.id}>
                        <div style={{ marginBottom: "10px" }}>
                          <CollapsibleRoute
                            lane_quote={lane_quote}
                            price={quotePrice}
                            createdBy={item?.created_by}
                            shipmentDate={shipmentDate}
                            equipmentTypes={lane_quote?.equipment_types}
                            modes={lane_quote?.modes}
                            deliveryDate={deliveryDate}
                            quoteData={item}
                            loadActivities={loadActivities}
                            custom_fields={lane_quote?.custom_fields}
                          />
                        </div>
                      </div>
                    );
                  })}
              </>
            ) : (
              // Lane Quote
              <>
                {!laneQuoteLoader &&
                  Array.isArray(laneQuotesData) &&
                  laneQuotesData.map((item) => {
                    const lane_quote = item;
                    const quotePrice = new Intl.NumberFormat("en-US").format(
                      lane_quote?.price
                    );
                    const shipmentDate =
                      lane_quote?.shipment_date &&
                      moment(lane_quote?.shipment_date).format("MM/DD/YY");
                    const deliveryDate = lane_quote?.delivery_date
                      ? moment(lane_quote.delivery_date).format("MM/DD/YY")
                      : null;

                    return (
                      <div key={item?.id}>
                        <div style={{ marginBottom: "10px" }}>
                          <CollapsibleRoute
                            lane_quote={lane_quote}
                            price={quotePrice}
                            createdBy={lane_quote.created_by}
                            shipmentDate={shipmentDate}
                            equipmentTypes={lane_quote?.equipment_types}
                            modes={lane_quote?.modes}
                            deliveryDate={deliveryDate}
                            quoteData={item}
                            loadActivities={loadLaneQuotes}
                            custom_fields={lane_quote?.custom_fields}
                          />
                        </div>
                      </div>
                    );
                  })}
              </>
            )}
          </div>
        )}
        {!laneQuotes && !activityLoader && (
          <Pagination
            activePage={activePage}
            itemsCountPerPage={30}
            totalItemsCount={totalQuotes}
            pageRangeDisplayed={5}
            onChange={(page) => {
              setActivePage(page);
              loadActivities(page);
            }}
            itemClass="page-item"
            linkClass="page-link"
            hideDisabled
          />
        )}

        {laneQuotes && !laneQuoteLoader && (
          <Pagination
            activePage={laneQuoteActivePage}
            itemsCountPerPage={30}
            totalItemsCount={total}
            pageRangeDisplayed={5}
            onChange={(page) => {
              setLaneActivePage(page);
              loadLaneQuotes(page);
            }}
            itemClass="page-item"
            linkClass="page-link"
            hideDisabled
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(QuotesView);
