import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import ReactTooltip from "react-tooltip";
import Pagination from "react-js-pagination";
import "./LanesView.scss";

import {
  Box,
  Typography,
  Grid2 as Grid,
  Card,
  CardContent,
  Chip,
} from "@mui/material";
import {
  AccessTime,
  AttachMoney,
  CheckCircleOutline,
  HighlightOff,
  RequestQuoteOutlined,
} from "@mui/icons-material";

import {
  companyOnboardingSet,
  authUserSet,
  setTab,
  setActiveTabIndex,
} from "../../redux";
import { connect } from "react-redux";
import { debounce } from "lodash";
import { getCurrencyValue } from "../../utils/Helper/reusableFunctions";
import { CurrencyList } from "../../constants/currencylist";
import Select from "react-select";
import GoogleAutocompleteComponent from "../GoogleComponents/GoogleAutocompleteComponent";

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    outline: "#4A9876",
    boxShadow: "none",
    cursor: "pointer",
    margin: "5px auto",
    height: "35px",
    borderColor: "lightgray",

    ":active": {
      ...styles[":active"],
      border: "2px solid #4A9876",
      outline: "#4A9876",
    },
    ":hover": {
      ...styles[":hover"],
      border: "2px solid #4A9876",
    },
    ":focus": {
      ...styles[":hover"],
      border: "2px solid #4A9876",
    },
  }),
  indicatorSeparator: (styles) => {
    return {
      display: "none",
    };
  },

  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
  }),

  valueContainer: (styles) => {
    return {
      ...styles,
      height: "37px",
    };
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? "#3aab7b"
        : isFocused
        ? "#C0EAD8"
        : null,
      cursor: isDisabled ? "not-allowed" : "pointer",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled && (isSelected ? data.color : "#C0EAD8"),
      },
    };
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "#C0EAD8",
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "black",
  }),
};

const LanesQuotesList = (props) => {
  const [stats, setStats] = useState([]);
  const [quotesData, setQuotesData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [total, setTotal] = useState(0);
  const [sortDirection, setSortDirection] = useState(true);
  const [loading, setLoading] = useState(false);
  const [userAccountQuery, setUserAccountQuery] = useState("");
  const [isReportsLoading, setIsReportsLoading] = useState(false);
  const [allAccounts, setAccounts] = useState([]);

  const status = [
    {
      value: "pending",
      label: "Pending",
    },
    {
      value: "won",
      label: "Won",
    },
    {
      value: "lost",
      label: "Lost",
    },
  ];

  // Filters states
  const [selectedFilters, setFilters] = useState({
    accountName: "",
    owner: [],
    equipmentTypes: [],
    modes: [],
    status: [],
    origin: {
      address: null,
      lat: null,
      long: null,
      city: null,
      state: null,
      zip: null,
      country: null,
    },
    destination: {
      address: null,
      lat: null,
      long: null,
      city: null,
      state: null,
      zip: null,
      country: null,
    },
    shipDate: null,
    createdDate: { value: "last_30_days", label: "Last 30 days" },
  });

  const dateFilters = [
    { value: "all_time", label: "All time" },
    { value: "today", label: "Today" },
    { value: "this_week", label: "Current Week" },
    { value: "last_week", label: "Previous Week" },
    { value: "this_month", label: "Current Month" },
    { value: "last_month", label: "Previous Month" },
    { value: "this_quarter", label: "Current Quarter" },
    { value: "last_quarter", label: "Previous Quarter" },
    { value: "last_7_days", label: "Last 7 days" },
    { value: "last_14_days", label: "Last 14 days" },
    { value: "last_30_days", label: "Last 30 days" },
    { value: "last_60_days", label: "Last 60 days" },
    { value: "last_90_days", label: "Last 90 days" },
    { value: "last_365_days", label: "Last 365 days" },
  ];

  // constants
  const statsCardsData = [
    {
      id: 1,
      title: "Total Quotes",
      value: stats?.lane_quote_count || 0,
      icon: (
        <RequestQuoteOutlined fontSize="medium" sx={{ color: "lightgray" }} />
      ),
      color: "default",
    },
    {
      id: 2,
      title: "Won",
      value: stats?.won_count || 0,
      icon: <CheckCircleOutline fontSize="medium" sx={{ color: "#8BD5A8" }} />,
      color: "success",
    },
    {
      id: 3,
      title: "Lost",
      value: stats?.lost_count || 0,
      icon: <HighlightOff fontSize="medium" sx={{ color: "#F5A9A9" }} />,
      color: "error",
    },
    {
      id: 4,
      title: "Revenue Pending",
      value: Number(stats?.pending_revenue) || 0,
      icon: <AccessTime fontSize="medium" sx={{ color: "#A2C1F5" }} />,
      color: "info",
    },
    {
      id: 5,
      title: "Revenue Won",
      value: Number(stats?.won_revenue) || 0,
      icon: <AttachMoney fontSize="medium" sx={{ color: "#8BD5A8" }} />,
      color: "success",
    },
    {
      id: 6,
      title: "Revenue Lost",
      value: Number(stats?.lost_revenue) || 0,
      icon: <AttachMoney fontSize="medium" sx={{ color: "#F5A9A9" }} />,
      color: "error",
    },
  ];

  const formatCurrency = (amount) => {
    const currency = props.companyOnboarding?.currency;
    const currencySymbol = CurrencyList.find((cur) => cur.code === currency);

    return amount
      ? currencySymbol.symbol +
          getCurrencyValue(amount)?.toLocaleString("en-US")
      : 0;
  };

  const getStatusStyles = (status) => {
    switch (status.toLowerCase()) {
      case "won":
        return {
          bg: "#e8f5e9",
          text: "#2e7d32",
        };
      case "lost":
        return {
          bg: "#ffebee",
          text: "#c62828",
        };
      case "pending":
        return {
          bg: "#fceecf",
          text: "#cd8003",
        };
      default:
        return {
          bg: "#e0e0e0",
          text: "#616161",
        };
    }
  };

  const getStats = (pageParam = 1, sortKey) => {
    let url = `lanes/quotes/overview?_limit=30&_page=${pageParam}`;

    if (sortKey) {
      const dir = sortDirection === true ? "desc" : "asc";
      url += `&sort_key=${sortKey}&sort_dir=${dir}`;
    }

    if (selectedFilters.owner && selectedFilters?.owner?.length > 0) {
      url += `&user_ids=${selectedFilters.owner
        .map((type) => type.value)
        .join(",")}`;
    }
    if (selectedFilters.accountName && selectedFilters.accountName) {
      url += `&account_name=${selectedFilters.accountName.label}`;
    }
    if (selectedFilters.origin.city) {
      url += `&origin_city=${selectedFilters.origin.city}`;
    } else if (selectedFilters.origin.state) {
      url += `&origin_state=${selectedFilters.origin.state}`;
    } else if (selectedFilters.origin.zip) {
      url += `&origin_zip=${selectedFilters.origin.zip}`;
    }

    if (selectedFilters.destination.city) {
      url += `&destination_city=${selectedFilters.destination.city}`;
    } else if (selectedFilters.destination.state) {
      url += `&destination_state=${selectedFilters.destination.state}`;
    } else if (selectedFilters.destination.zip) {
      url += `&destination_zip=${selectedFilters.destination.zip}`;
    }
    if (
      selectedFilters.equipmentTypes &&
      selectedFilters.equipmentTypes?.length > 0
    ) {
      url += `&equipment_type_ids=${selectedFilters.equipmentTypes
        ?.map((type) => type.value)
        .join(",")}`;
    }

    if (selectedFilters.modes && selectedFilters.modes?.length > 0) {
      url += `&mode_ids=${selectedFilters.modes
        ?.map((type) => type.value)
        .join(",")}`;
    }
    if (selectedFilters.status && selectedFilters?.status?.length > 0) {
      url += `&status=${selectedFilters.status
        ?.map((type) => type.value)
        .join(",")}`;
    }

    if (selectedFilters.shipDate && selectedFilters?.shipDate?.value) {
      url += `&shipment_date=${selectedFilters.shipDate?.value}`;
    }
    if (selectedFilters.createdDate && selectedFilters.createdDate?.value) {
      url += `&created_at=${selectedFilters.createdDate?.value}`;
    }

    setLoading(true);

    axios({
      method: "GET",
      url,
    })
      .then((res) => {
        setStats(res.data.lane_quote_stats);
        setQuotesData(res.data.lane_quotes);
        setTotal(res.data.total);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const fetchAccounts = debounce(() => {
    setIsReportsLoading(true);

    let url = `/accounts?_limit=50&_page=1&sort_key=name&sort_dir=asc`;

    if (userAccountQuery) {
      url += `&name=${encodeURIComponent(userAccountQuery)}`;
    }

    axios({
      method: "GET",
      url: url,
    }).then((res) => {
      if (userAccountQuery) {
        setAccounts((prevReports) => {
          const newReports = res.data.accounts.map((item) => ({
            id: item.id,
            name: item.name,
          }));

          // Combine arrays and remove duplicates based on id
          const combinedReports = [...prevReports, ...newReports];
          const uniqueReports = Array.from(
            new Map(
              combinedReports.map((report) => [report.id, report])
            ).values()
          );

          return uniqueReports;
        });
      } else {
        setAccounts(
          res.data.accounts.map((item) => ({
            id: item.id,
            name: item.name,
          }))
        );
      }
      setIsReportsLoading(false);
    });
  }, 500);

  useEffect(() => {
    getStats(1, null);
  }, [selectedFilters]);

  useEffect(() => {
    fetchAccounts();
  }, []);

  useEffect(() => {
    if (userAccountQuery) {
      fetchAccounts();
    }
  }, [userAccountQuery]);

  const tableHeaders = [
    { key: "account_owner", title: "Owner", sort: true },
    { key: "account_name", title: "Account Name", sort: true },
    { key: "origin_city", title: "Origin", sort: false },
    { key: "destination_city", title: "Destination", sort: false },
    { key: "price", title: "Quote", sort: true },
    { key: "status", title: "Status", sort: false },
    { key: "equipment_types", title: "Equipment Type", sort: false },
    { key: "modes", title: "Modes", sort: false },
    { key: "shipment_date", title: "Ship Date", sort: true },
    { key: "created_at", title: "Created", sort: true },
  ];

  const handleAccountClick = (newTabData, e) => {
    if (e.metaKey || e.ctrlKey) {
      props.setTab({ ...newTabData, blank: true });
    } else {
      props.setActiveTabIndex(props.totalTabs + 1);
      props.setTab(newTabData);
      props.history.push("/active-tabs");
    }
  };

  return (
    <div className="accountList">
      <ReactTooltip multiline={true} id="beta-tooltip" place="right" />
      <div
        style={{
          borderBottom: "1px solid #E3E3E3",
          marginBottom: "10px",
        }}
      >
        <h1 style={{ marginBottom: "0px" }}>Quotes Overview</h1>
      </div>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "1fr", // 1 column on mobile
            sm: "repeat(2, 1fr)", // 2 columns on tablet
            md: "repeat(3, 1fr)", // 3 columns on desktop
          },
          gap: 3,
          mb: 4,
          marginTop: "20px",
        }}
      >
        {" "}
        {statsCardsData.map((card) => (
          <Grid item xs={12} sm={6} md={4} key={card.id}>
            <Card
              variant="outlined"
              sx={{
                height: "100%",
                borderRadius: "15px",
                borderWidth: "2px",
                borderColor:
                  card.color === "default"
                    ? "lightgray"
                    : card.color === "success"
                    ? "#8BD5A8"
                    : card.color === "error"
                    ? "#F5A9A9"
                    : "#A2C1F5",
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "",
                  paddingLeft: "24px",
                }}
              >
                <Box>
                  <Typography color="text.secondary" gutterBottom>
                    {card.title}
                  </Typography>
                  <Typography
                    variant="h4"
                    component="div"
                    sx={{ fontWeight: "bold" }}
                  >
                    {card.title.includes("Revenue")
                      ? formatCurrency(card.value)
                      : card.value.toLocaleString("en-US")}
                  </Typography>
                </Box>
                {card.icon}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Box>
      {/* Filters */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(20, 1fr)",
          gap: "5px",
          width: "70%",
        }}
      >
        <div
          className="lanes-panel-form-1-div"
          style={{ marginBottom: "10px", gridColumn: "span 4" }}
        >
          <div className="controlsForms" style={{ width: "100%" }}>
            <Select
              value={selectedFilters.owner}
              closeMenuOnSelect={false}
              isMulti={true}
              styles={colourStyles}
              options={props.allUser?.map((type) => {
                return { value: type.id, label: type.name };
              })}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: () => null,
              }}
              placeholder="-Select-"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(selectedOptions) =>
                setFilters((state) => ({
                  ...state,
                  owner: selectedOptions,
                }))
              }
            />
            <label
              className="activeLabel"
              style={{
                fontSize: "12px",
              }}
            >
              Owner
            </label>
          </div>
        </div>

        <div
          className="lanes-panel-form-1-div"
          style={{ gridColumn: "span 4" }}
        >
          <div className="controlsForms" style={{ width: "100%" }}>
            <Select
              value={selectedFilters.createdDate}
              closeMenuOnSelect={true}
              isMulti={false}
              styles={colourStyles}
              options={dateFilters.map((type) => {
                return { value: type.value, label: type.label };
              })}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: () => null,
              }}
              placeholder="-Select-"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(selectedOptions) =>
                setFilters((state) => ({
                  ...state,
                  createdDate: selectedOptions,
                }))
              }
              isClearable={true}
            />
            <label
              className="activeLabel"
              style={{
                fontSize: "12px",
              }}
            >
              Created Date
            </label>
          </div>
        </div>

        <div
          className="lanes-panel-form-1-div"
          style={{ gridColumn: "span 4" }}
        >
          <div className="controlsForms" style={{ width: "100%" }}>
            <Select
              value={selectedFilters.shipDate}
              closeMenuOnSelect={true}
              isMulti={false}
              styles={colourStyles}
              options={dateFilters.map((type) => {
                return { value: type.value, label: type.label };
              })}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: () => null,
              }}
              placeholder="-Select-"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(selectedOptions) =>
                setFilters((state) => ({
                  ...state,
                  shipDate: selectedOptions,
                }))
              }
              isClearable={true}
            />
            <label
              className="activeLabel"
              style={{
                fontSize: "12px",
              }}
            >
              Ship Date
            </label>
          </div>
        </div>

        <div
          className="lanes-panel-form-1-div"
          style={{ gridColumn: "span 5" }}
        >
          <div className="controlsForms" style={{ width: "100%" }}>
            <Select
              value={selectedFilters.accountName}
              closeMenuOnSelect={true}
              isMulti={false}
              styles={colourStyles}
              options={allAccounts?.map((type) => {
                return { value: type.id, label: type.name };
              })}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: () => null,
              }}
              placeholder="-Select-"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(selectedOptions) =>
                setFilters((state) => ({
                  ...state,
                  accountName: selectedOptions,
                }))
              }
              noOptionsMessage={({ inputValue }) => {
                if (inputValue) {
                  setUserAccountQuery(inputValue);
                }
                return isReportsLoading ? "Loading..." : "No reports found";
              }}
              isClearable={true}
            />
            <label
              className="activeLabel"
              style={{
                fontSize: "12px",
              }}
            >
              Account Name
            </label>
          </div>
        </div>

        <div
          className="lanes-panel-form-1-div"
          style={{ gridColumn: "span 4" }}
        >
          <div className="controlsForms" style={{ width: "100%" }}>
            <Select
              value={selectedFilters.status}
              closeMenuOnSelect={false}
              isMulti
              styles={colourStyles}
              options={status?.map((type) => {
                return { value: type.value, label: type.label };
              })}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: () => null,
              }}
              placeholder="-Select-"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(selectedOptions) =>
                setFilters((state) => ({
                  ...state,
                  status: selectedOptions,
                }))
              }
            />
            <label
              className="activeLabel"
              style={{
                fontSize: "12px",
              }}
            >
              Status
            </label>
          </div>
        </div>
        <div
          className="lanes-panel-form-1-div"
          style={{ gridColumn: "span 4" }}
        >
          <div className="controlsForms" style={{ width: "100%" }}>
            <GoogleAutocompleteComponent
              className="locationField"
              onPlaceSelect={(selectedOptions) =>
                setFilters((state) => ({
                  ...state,
                  origin: selectedOptions,
                }))
              }
            />

            <label
              className="activeLabel"
              style={{
                fontSize: "12px",
              }}
            >
              Origin
            </label>
          </div>
        </div>
        <div
          className="lanes-panel-form-1-div"
          style={{ gridColumn: "span 4" }}
        >
          <div className="controlsForms" style={{ width: "100%" }}>
            <GoogleAutocompleteComponent
              className="locationField"
              onPlaceSelect={(selectedOptions) =>
                setFilters((state) => ({
                  ...state,
                  destination: selectedOptions,
                }))
              }
            />
            <label
              className="activeLabel"
              style={{
                fontSize: "12px",
              }}
            >
              Destination
            </label>
          </div>
        </div>

        <div
          className="lanes-panel-form-1-div"
          style={{ gridColumn: "span 4" }}
        >
          <div className="controlsForms" style={{ width: "100%" }}>
            <Select
              value={selectedFilters.equipmentTypes}
              closeMenuOnSelect={false}
              isMulti
              styles={colourStyles}
              options={props.allEquipmentTypes?.map((type) => {
                return { value: type.id, label: type.name };
              })}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: () => null,
              }}
              placeholder="-Select-"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(selectedOptions) =>
                setFilters((state) => ({
                  ...state,
                  equipmentTypes: selectedOptions,
                }))
              }
            />
            <label
              className="activeLabel"
              style={{
                fontSize: "12px",
              }}
            >
              Equipment Types
            </label>
          </div>
        </div>
        <div
          className="lanes-panel-form-1-div"
          style={{ gridColumn: "span 4" }}
        >
          <div className="controlsForms" style={{ width: "100%" }}>
            <Select
              value={selectedFilters.modes}
              closeMenuOnSelect={false}
              isMulti
              styles={colourStyles}
              options={props.allModes?.map((type) => {
                return { value: type.id, label: type.name };
              })}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: () => null,
              }}
              placeholder="-Select-"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(selectedOptions) =>
                setFilters((state) => ({
                  ...state,
                  modes: selectedOptions,
                }))
              }
            />
            <label
              className="activeLabel"
              style={{
                fontSize: "12px",
              }}
            >
              Modes
            </label>
          </div>
        </div>
      </div>
      {loading ? (
        <div class="load-wrapp">
          <div class="load-3">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
        </div>
      ) : loading === false && quotesData.length === 0 ? (
        <div
          style={{
            textAlign: "center",
            marginTop: "50px",
            fontWeight: "500",
          }}
        >
          No quotes found
        </div>
      ) : (
        <div style={{ width: "96vw", height: "auto", overflowX: "auto" }}>
          <table
            className="accountList__table"
            style={{ marginTop: "20px", width: "93vw", tableLayout: "fixed" }}
          >
            <thead>
              <tr>
                {tableHeaders.map((header, index) => (
                  <th key={index}>
                    <Row>
                      <Col lg={9} xl={9}>
                        {header.title}
                      </Col>
                      {header.sort && (
                        <Col lg={3} xl={3}>
                          <button
                            type="button"
                            style={{
                              background: "transparent",
                              border: "none",
                              cursor: "pointer",
                              outline: "none",
                              color: "#657885",
                            }}
                            onClick={() => {
                              setSortDirection(!sortDirection);
                              getStats(activePage, header.key);
                            }}
                          >
                            <i className="fa fa-long-arrow-up"></i>
                            <i className="fa fa-long-arrow-down"></i>
                          </button>
                        </Col>
                      )}
                    </Row>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody style={{ fontSize: "16px" }}>
              {quotesData?.map((item) => {
                const {
                  id,
                  account_id,
                  account_name,
                  account_owners,
                  origin_address,
                  destination_address,
                  status,
                  price,
                  equipment_types,
                  modes,
                  shipment_date,
                  created_at,
                } = item;

                let newTabData = {
                  type: "account",
                  id: account_id,
                  name: account_name,
                };
                return (
                  <tr key={id}>
                    <td>
                      {account_owners?.map((item) => item.name).join(", ")}
                    </td>
                    <td>
                      <span
                        style={{ cursor: "pointer", color: "#4A9876" }}
                        onClick={(e) => handleAccountClick(newTabData, e)}
                      >
                        {account_name}
                      </span>
                    </td>
                    <td>{origin_address}</td>
                    <td>{destination_address}</td>
                    <td>${price.toLocaleString()}</td>
                    <td>
                      {" "}
                      <Chip
                        label={status}
                        size="small"
                        sx={{
                          backgroundColor: getStatusStyles(status).bg,
                          color: getStatusStyles(status).text,
                          fontWeight: "bold",
                          textTransform: "capitalize",
                          height: "24px",
                          borderRadius: "16px",
                          fontSize: "13px",
                        }}
                      />
                    </td>
                    <td>
                      {equipment_types?.map((item) => item.name).join(", ") ||
                        "N/A"}
                    </td>
                    <td>
                      {modes?.map((item) => item.name).join(", ") || "N/A"}
                    </td>
                    <td>{moment(shipment_date).format("MM/DD/YY")}</td>
                    <td>{moment(created_at).format("MM/DD/YY")}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      <Pagination
        activePage={activePage}
        itemsCountPerPage={30}
        totalItemsCount={total}
        pageRangeDisplayed={5}
        onChange={(page) => {
          setActivePage(page);
          getStats(page, null);
        }}
        itemClass="page-item"
        linkClass="page-link"
        hideDisabled
      />
    </div>
  );
};

const MSP = (state) => {
  return {
    companyOnboarding: state.login.companyOnboarding,
    authUser: state.login.authUser,
    allUser: state.allUsers.users,
    allEquipmentTypes: state.allEquipmentTypes.equipmentTypes,
    allSpecialRequirements: state.allSpecialRequirements.specialRequirements,
    allModes: state.allModes.modes,
    totalTabs: state.tabsState.tabs.length,
  };
};

const MDP = (dispatch) => {
  return {
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
  };
};

export default connect(MSP, MDP)(LanesQuotesList);
