import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import "../sass/ActivityLog.scss";
import Activity from "./Activity";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import "../sass/Animation.scss";
import { v4 as uuidv4 } from "uuid";
import DashboardLoader from "./Loaders/DashboardLoader";

const ActivityLog = (props) => {
  const [mailProvider, setMailProvider] = useState("");
  const [processLoader, setProcessLoader] = useState(false);
  const [processedLogs, setProcessedLogs] = useState([]);
  const [processedPinnedActivity, setProcessedPinnedActivity] = useState(null);
  const [page, setPage] = useState(1);
  const loadingRef = useRef(false);
  const mountedRef = useRef(true);

  const {
    loadActivities,
    pinnedActivity,
    activityLoader,
    setHasMoreItems,
    hasMoreItems,
    logs,
    totalActivities,
    expand,
    loadQuoteActivities,
  } = props;

  // Set up cleanup when component unmounts
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const fetchMailProvider = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: `/users/mail-provider`,
      });
      if (mountedRef.current) {
        setMailProvider(res.data.user.mail_provider);
      }
    } catch (error) {
      console.error("Error fetching mail provider:", error);
    }
  };

  const getEmailData = async (activityId) => {
    try {
      const res = await axios({
        method: "GET",
        url: `/activities/${activityId}`,
      });
      return res.data.activity?.email_data || null;
    } catch (error) {
      console.error("Error fetching email data:", error);
      return null;
    }
  };

  const processPinnedActivity = async () => {
    if (!pinnedActivity || !mountedRef.current) return;

    const {
      id,
      description: activityDescription,
      category_id,
    } = pinnedActivity;

    try {
      const emailData =
        category_id?.toString() === "2" ? await getEmailData(id) : null;

      if (!mountedRef.current) return;

      const description =
        category_id?.toString() === "2"
          ? emailData?.email_body
          : activityDescription;

      const attachments =
        category_id?.toString() === "2" ? emailData?.attachments : [];

      const descriptionLength = description?.length || 0;
      const numberOfLineBreaks = (description?.match(/\n/g) || []).length;
      const textArray = description?.split(/^/gm) || [];
      const textToShow = textArray?.slice(0, 3).join("");

      const trimmedDescription =
        descriptionLength > 140 || numberOfLineBreaks > 3
          ? textToShow.substring(0, 137) + "..."
          : description;

      const trimmedDescriptionLink = trimmedDescription
        ? trimmedDescription.replaceAll("<a", "<a target='_blank'")
        : "";
      const descriptionLink = description
        ? description.replaceAll("<a", "<a target='_blank'")
        : "";

      if (mountedRef.current) {
        setProcessedPinnedActivity({
          ...pinnedActivity,
          description,
          trimmedDescription,
          trimmedDescriptionLink,
          descriptionLink,
          attachments,
        });
      }
    } catch (error) {
      console.error("Error processing pinned activity:", error);
    }
  };

  const processLogs = async () => {
    if (!logs?.length || !mountedRef.current) return;

    try {
      if (mountedRef.current) {
        setProcessLoader(true);
      }

      const resolvedLogs = await Promise.all(
        logs.map(async (data) => {
          if (!mountedRef.current) return null;

          const { date, activities } = data;

          const resolvedActivities = await Promise.all(
            activities.map(async (activity) => {
              if (!mountedRef.current) return null;

              const {
                id,
                description: activityDescription,
                category_id,
                lane_quote,
              } = activity;

              const emailData =
                category_id?.toString() === "2" ? await getEmailData(id) : null;

              const description =
                category_id?.toString() === "2"
                  ? emailData?.email_body
                  : activityDescription;

              const attachments =
                category_id?.toString() === "2" ? emailData?.attachments : [];

              const descriptionLength = description?.length || 0;
              const numberOfLineBreaks = (description?.match(/\n/g) || [])
                .length;
              const textArray = description?.split(/^/gm) || [];
              const textToShow = textArray?.slice(0, 3).join("");

              const trimmedDescription =
                descriptionLength > 140 || numberOfLineBreaks > 3
                  ? textToShow.substring(0, 137) + "..."
                  : description;

              const trimmedDescriptionLink = trimmedDescription
                ? trimmedDescription.replaceAll("<a", "<a target='_blank'")
                : "";
              const descriptionLink = description
                ? description.replaceAll("<a", "<a target='_blank'")
                : "";

              return {
                ...activity,
                description,
                trimmedDescription,
                trimmedDescriptionLink,
                descriptionLink,
                attachments,
              };
            })
          ).then((activities) => activities.filter(Boolean));

          return {
            date,
            activities: resolvedActivities,
          };
        })
      ).then((logs) => logs.filter(Boolean));

      if (mountedRef.current) {
        setProcessedLogs(resolvedLogs);
        setProcessLoader(false);
      }
    } catch (error) {
      console.error("Error processing logs:", error);
      if (mountedRef.current) {
        setProcessLoader(false);
      }
    }
  };

  useEffect(() => {
    fetchMailProvider();
  }, []);

  useEffect(() => {
    if (!loadingRef.current) {
      loadingRef.current = true;
      loadActivities(1, "initial");
    }
  }, [loadActivities]);

  useEffect(() => {
    processLogs();
    // Reset loading ref after logs are processed
    loadingRef.current = false;
  }, [logs]);

  useEffect(() => {
    processPinnedActivity();
  }, [pinnedActivity]);

  const loadMore = () => {
    if (
      loadingRef.current ||
      !hasMoreItems ||
      activityLoader ||
      !mountedRef.current
    ) {
      return;
    }

    const nextPage = page + 1;
    console.log("Loading page:", nextPage); // Add logging

    loadingRef.current = true;
    setPage(nextPage);
    loadActivities(nextPage, "nextPage");
  };

  if (
    processedLogs.length === 0 &&
    !processedPinnedActivity &&
    activityLoader === false &&
    processLoader === false
  ) {
    return (
      <h2
        style={{
          fontSize: "14px",
          fontWeight: "400",
          margin: "20px 30px",
          textAlign: "center",
        }}
      >
        Log your first activity or set a task to follow-up with this client.
      </h2>
    );
  }

  return (
    <div id="scrollableDiv" style={{ height: "82vh", overflow: "auto" }}>
      <InfiniteScroll
        dataLength={processedLogs.reduce(
          (acc, curr) => acc + (curr.activities?.length || 0),
          0
        )}
        next={loadMore}
        hasMore={hasMoreItems && !loadingRef.current}
        loader={<DashboardLoader />}
        scrollableTarget="scrollableDiv"
        scrollThreshold={0.8} // Changed from "200px" to ratio
        style={{ height: "100%", overflow: "hidden" }}
      >
        <div className="tracks">
          {processedPinnedActivity && pinnedActivity && (
            <div>
              <div className="activityLog__date">
                {moment(processedPinnedActivity.date).format("D MMM YYYY")}
              </div>
              <Activity
                {...processedPinnedActivity}
                icon={
                  pinnedActivity?.category_name === "Notes" &&
                  pinnedActivity?.name === "Web form submitted"
                    ? "Webform"
                    : pinnedActivity?.category_name === "Opened Email"
                    ? "EmailGreen"
                    : pinnedActivity?.category_name + "Green"
                }
                time={moment(pinnedActivity?.created_at).format("LT")}
                name={
                  pinnedActivity.type === "activity_logged"
                    ? `${pinnedActivity.category_name} `
                    : "Task Completed"
                }
                type={pinnedActivity.type}
                title={
                  pinnedActivity.category_id?.toString() === "2"
                    ? pinnedActivity?.email_data?.subject
                    : pinnedActivity.name
                }
                description={
                  expand
                    ? processedPinnedActivity.descriptionLink
                    : processedPinnedActivity.trimmedDescriptionLink
                }
                descriptionFull={processedPinnedActivity.description}
                created_by={pinnedActivity.created_by}
                opportunityName={props.opportunityName}
                whiteSpace={"pre-wrap"}
                id={pinnedActivity.id}
                account_id={pinnedActivity.account_id}
                opportunity_id={pinnedActivity.opportunity_id}
                result_id={pinnedActivity.result_id}
                result_name={pinnedActivity.result_name}
                category_id={pinnedActivity.category_id}
                opportunity_name={pinnedActivity.opportunity_name}
                account_name={pinnedActivity.account_name}
                contact_id={pinnedActivity.contact_id}
                contact_name={pinnedActivity.contact_name}
                loadActivities={loadActivities}
                metadata={processedPinnedActivity.email_data}
                parentId={pinnedActivity.id}
                integration={props.integration}
                is_pinned={pinnedActivity.is_pinned}
                pinnedId={pinnedActivity ? pinnedActivity.id : null}
                metadataType={pinnedActivity.metadata_type}
                toEmail={props.toEmail}
                iconExtension={
                  pinnedActivity.metadata_type === "calendly" ? "png" : "svg"
                }
                mailProvider={mailProvider}
                attachments={processedPinnedActivity.attachments}
                quoteData={processedPinnedActivity.lane_quote}
                loadQuoteActivities={loadQuoteActivities}
              />
            </div>
          )}
          {processedLogs.map(({ date, activities }) => (
            <div key={date + uuidv4()}>
              {activities.length > 0 && (
                <div className="activityLog__date">
                  {moment(date).format("D MMM YYYY")}
                </div>
              )}
              {activities.map((activity) => (
                <div key={activity.id}>
                  <Activity
                    {...activity}
                    key={activity.id}
                    icon={
                      activity.metadata_type === "calendly"
                        ? "calendlyIcon"
                        : activity.category_name === "Notes" &&
                          activity.name === "Web form submitted"
                        ? "Webform"
                        : activity.category_name === "Opened Email"
                        ? "EmailGreen"
                        : activity.category_name + "Green"
                    }
                    time={moment(activity.created_at).format("LT")}
                    name={
                      activity.type === "activity_logged"
                        ? `${activity.category_name} `
                        : "Task Completed"
                    }
                    type={activity.type}
                    title={
                      activity.category_id?.toString() === "2"
                        ? activity.email_data?.subject
                        : activity.name
                    }
                    description={
                      expand
                        ? activity.descriptionLink
                        : activity.trimmedDescriptionLink
                    }
                    descriptionFull={activity.description}
                    created_by={activity.created_by}
                    opportunityName={props.opportunityName}
                    whiteSpace={"pre-wrap"}
                    id={activity.id}
                    account_id={activity.account_id}
                    opportunity_id={activity.opportunity_id}
                    result_id={activity.result_id}
                    result_name={activity.result_name}
                    category_id={activity.category_id}
                    opportunity_name={activity.opportunity_name}
                    account_name={activity.account_name}
                    contact_id={activity.contact_id}
                    contact_name={activity.contact_name}
                    carrier_id={activity.carrier_id}
                    carrier_name={activity.carrier_name}
                    loadActivities={loadActivities}
                    metadata={activity.email_data}
                    parentId={activity.email_data?.id}
                    integration={props.integration}
                    is_pinned={activity.is_pinned}
                    pinnedId={pinnedActivity ? pinnedActivity.id : null}
                    metadataType={activity.metadata_type}
                    customerName={props.customerName}
                    toEmail={props.toEmail}
                    iconExtension={
                      activity.metadata_type === "calendly" ? "png" : "svg"
                    }
                    mailProvider={mailProvider}
                    attachments={activity.attachments}
                    quoteData={activity.lane_quote}
                    loadQuoteActivities={loadQuoteActivities}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default React.memo(ActivityLog);
