import React, { useState } from "react";
import { Box, Typography, Paper, Chip } from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import EditActivityModal from "../../EditActivityModal";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ThreeDotsMenu from "../ThreeDotsMenu";
import { DeleteActivityModal } from "../../Activity";

// Styled components

const QuoteCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  marginBottom: theme.spacing(2),
  borderRadius: 8,
  border: "1px solid #E0E0E0",
  marginTop: "20px",
  boxShadow: "0px 2px 6px rgba(200, 200, 200, 0.3)",
  width: "95%",
  transition: "transform 0.2s ease, box-shadow 0.2s ease",
  "&:hover": {
    transform: "translateY(-1px)",
    boxShadow: "0px 2px 6px rgba(200, 200, 200, 0.5)",
    backgroundColor: "white !important",
  },
}));

const StopsButton = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: theme.palette.primary.main,
  cursor: "pointer",
  marginLeft: theme.spacing(0),
  "& .MuiSvgIcon-root": {
    fontSize: 16,
    marginLeft: theme.spacing(0.5),
  },
}));

const StopsList = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const StopItem = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(1),

  "& .MuiSvgIcon-root": {
    fontSize: 18,
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
  },
}));

const CollapsibleRoute = ({
  lane_quote,
  price,
  createdBy,
  shipmentDate,
  equipmentTypes,
  modes,
  deliveryDate,
  quoteData,
  loadActivities,
  custom_fields,
}) => {
  const [showStops, setShowStops] = useState(false);

  const [showEditModal, setShowEditModal] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const hasStops = lane_quote?.stops && lane_quote?.stops.length > 0;
  const stopsCount = lane_quote?.stops ? lane_quote?.stops.length : 0;

  // Process address to remove US, USA, United States
  const formatAddress = (address) => {
    return address?.replace(/\s*,?\s*(US|USA|United States)\s*$/i, "").trim();
  };

  // Status badge styling
  const getStatusStyles = (status) => {
    switch (status.toLowerCase()) {
      case "won":
        return {
          bg: "#e8f5e9",
          text: "#2e7d32",
        };
      case "lost":
        return {
          bg: "#ffebee",
          text: "#c62828",
        };
      case "pending":
        return {
          bg: "#fceecf",
          text: "#cd8003",
        };
      default:
        return {
          bg: "#e0e0e0",
          text: "#616161",
        };
    }
  };

  const statusStyles = getStatusStyles(lane_quote.status);

  const truncateText = (text, maxLength = 20) => {
    if (!text) return "";
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };

  const menuItems = [
    {
      label: "Edit Quote",
      onClick: () => setShowEditModal(true),
    },
    {
      label: "Delete Quote",
      onClick: () => setShowDeleteModal(true),
    },
  ];
  return (
    <QuoteCard elevation={0}>
      {/* Status badge */}
      <Box sx={{ mb: 1, display: "flex", justifyContent: "space-between" }}>
        <div>
          <Chip
            label={lane_quote.status}
            size="small"
            sx={{
              backgroundColor: statusStyles.bg,
              color: statusStyles.text,
              fontWeight: "bold",
              textTransform: "capitalize",
              height: "24px",
              borderRadius: "16px",
              fontSize: "13px",
            }}
          />
          <Typography
            variant="body2"
            component="span"
            sx={{ ml: 1, color: "#555", fontSize: "12px" }}
          >
            Ships: {shipmentDate}
          </Typography>
          {deliveryDate && (
            <Typography
              variant="body2"
              component="span"
              sx={{ ml: 1, color: "#555", fontSize: "12px" }}
            >
              Delivers: {deliveryDate}
            </Typography>
          )}

          <PersonOutlineIcon
            fontSize="small"
            sx={{
              ml: 1,
              verticalAlign: "middle",
              color: "#777",
              fontSize: "14px",
            }}
          />
          <Typography
            variant="body2"
            component="span"
            title={createdBy}
            sx={{ ml: 0.5, color: "#555", fontSize: "12px" }}
          >
            {truncateText(createdBy)}
          </Typography>
        </div>
        {/* Modal edit */}
        <div>
          <ThreeDotsMenu items={menuItems} />
        </div>
      </Box>

      {/* Route and price row */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 1,
        }}
      >
        <Typography
          variant="body2"
          sx={{ fontWeight: "bold", fontSize: "16px" }}
        >
          {formatAddress(lane_quote.origin_address)}{" "}
          <ArrowForwardIcon
            sx={{ fontSize: 15, mx: 0.5, position: "relative", top: "2px" }}
          />{" "}
          {formatAddress(lane_quote.destination_address)}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h6"
            sx={{ fontWeight: 600, mr: 1, fontSize: "18px" }}
          >
            ${price}
          </Typography>
        </Box>
      </Box>

      {/* Stops information */}
      <Box sx={{ mb: 1, display: "flex" }}>
        {hasStops && (
          <Box sx={{ my: 0 }}>
            {stopsCount > 0 && (
              <StopsButton onClick={() => setShowStops(!showStops)}>
                <Typography variant="body2" color="#4A9876">
                  {stopsCount} stops
                </Typography>
                {showStops ? (
                  <KeyboardArrowUpIcon sx={{ color: "#4A9876" }} />
                ) : (
                  <KeyboardArrowDownIcon sx={{ color: "#4A9876" }} />
                )}
              </StopsButton>
            )}

            {showStops && (
              <StopsList
                sx={{ borderLeft: "1px solid #E0E0E0", paddingRight: "" }}
              >
                {lane_quote.stops.map((stop, index) => (
                  <StopItem sx={{ marginLeft: "10px" }} key={index}>
                    <LocationOnOutlinedIcon fontSize="small" />
                    <Typography variant="body2">
                      {formatAddress(stop.address)}
                    </Typography>
                  </StopItem>
                ))}
              </StopsList>
            )}
          </Box>
        )}

        {!hasStops && (
          <Typography variant="body2" color="text.secondary" sx={{ my: 0 }}>
            0 stops
          </Typography>
        )}
      </Box>
      {/* Equipment type chips */}
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
        {equipmentTypes?.map((type, index) => (
          <Chip
            key={index}
            label={type?.name}
            size="small"
            sx={{
              backgroundColor: "#f5f5f5",
              border: "none",
              borderRadius: "16px",
              height: "26px",
              fontSize: "12px",
              fontWeight: 500,
              mr: 0.5,
            }}
          />
        ))}
        {modes?.map((type, index) => (
          <Chip
            key={index}
            label={type?.name}
            variant="outlined"
            size="small"
            sx={{
              backgroundColor: "#f5f5f5",
              border: "none",
              borderRadius: "16px",
              height: "26px",
              fontSize: "12px",
              fontWeight: 500,
              mr: 0.5,
            }}
          />
        ))}
        {custom_fields?.length > 0 &&
          custom_fields
            ?.filter(
              (item) =>
                item.value &&
                ["dropdown", "multi_select_dropdown"].includes(item.type)
            )
            ?.map((cf, index) => {
              return (
                <Chip
                  key={index}
                  label={
                    cf.type === "multi_select_dropdown"
                      ? cf?.value
                          .substring(1)
                          .replace(/;(?!$)/g, ",")
                          .replace(/;$/, "")
                      : cf?.value
                  }
                  variant="outlined"
                  size="small"
                  sx={{
                    backgroundColor: "#f5f5f5",
                    border: "none",
                    borderRadius: "16px",
                    height: "26px",
                    fontSize: "12px",
                    fontWeight: 500,
                    mr: 0.5,
                  }}
                />
              );
            })}
      </Box>
      {showEditModal && (
        <EditActivityModal
          showOpportunityModal={showEditModal}
          setShowOpportunityModal={() => setShowEditModal(true)}
          handleCloseModal={() => setShowEditModal(false)}
          id={lane_quote.activity_id}
          name={null}
          description={null}
          account_id={quoteData.account_id}
          carrier_id={null}
          opportunity_id={null}
          category_id={quoteData.category_id || 10}
          result_id={null}
          type={quoteData.type}
          loadActivities={loadActivities}
          quoteData={lane_quote}
        />
      )}
      {showDeleteModal && (
        <DeleteActivityModal
          showOpportunityModal={showDeleteModal}
          setShowOpportunityModal={() => setShowDeleteModal(true)}
          handleCloseModal={() => setShowDeleteModal(false)}
          id={lane_quote.activity_id}
          loadActivities={loadActivities}
        />
      )}
    </QuoteCard>
  );
};

export default CollapsibleRoute;
