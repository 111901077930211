import {
  FETCH_QUOTE_FIELDS_REQUEST,
  FETCH_QUOTE_FIELDS_SUCCESS,
  FETCH_QUOTE_FIELDS_FAILURE,
} from "./types";

import axios from "axios";
import { BASE_URL } from "../../config";

const fetchAllFormQuoteFieldsRequest = () => {
  return {
    type: FETCH_QUOTE_FIELDS_REQUEST,
  };
};
const fetchAllFormQuoteFieldsSuccess = (data) => {
  return {
    type: FETCH_QUOTE_FIELDS_SUCCESS,
    payload: data,
  };
};
const fetchAllFormQuoteFieldsFailure = (err) => {
  return {
    type: FETCH_QUOTE_FIELDS_FAILURE,
    payload: err,
  };
};

// Fetch All AllFormAccountFields
export const fetchAllAllFormQuoteFields = () => {
  return (dispatch) => {
    dispatch(fetchAllFormQuoteFieldsRequest());
    axios({
      method: "GET",
      url: `${BASE_URL}/admin/default-fields?type=lane_quote`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
    })
      .then((res) => {
        const data = res.data.default_fields;
        dispatch(fetchAllFormQuoteFieldsSuccess(data));
      })
      .catch((err) => {
        const errorMsg = err.response?.data?.message;
        dispatch(fetchAllFormQuoteFieldsFailure(errorMsg));
      });
  };
};
