import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import CreateWebhooksModal from "./CreateWebhooksModal";
import EditWebhooksModal from "./EditWebhooksModal";
import DeleteWebhooksModal from "./DeleteWebhooksModal";
import moment from "moment";
import { useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import { Box } from "@mui/material";

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    outline: "#4A9876",
    margin: "0",
    boxShadow: "none",
    ":active": {
      ...styles[":active"],
      border: "2px solid #4A9876",
      outline: "#4A9876",
    },
    ":hover": {
      ...styles[":hover"],
      border: "1px solid #4A9876",
    },
    ":focus": {
      ...styles[":hover"],
      border: "1px solid #4A9876",
    },
  }),
  indicatorSeparator: (styles) => {
    return {
      display: "none",
    };
  },
  menu: (provided) => ({
    ...provided,
    zIndex: 10,
  }),

  menuList: (provided) => ({
    ...provided,
    maxHeight: "150px",
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 10,
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? "#3aab7b"
        : isFocused
        ? "#C0EAD8"
        : null,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled && (isSelected ? data.color : "#C0EAD8"),
      },
    };
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "#C0EAD8",
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "black",
  }),
};

const WebhooksList = () => {
  const [showWebhooksModal, setShowWebhooksModal] = useState(false);
  const [showEditWebhooksModal, setShowEditWebhooksModal] = useState(false);
  const [showDeleteWebhooksModal, setShowDeleteWebhooksModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [webhooksLoading, setWebhooksLoading] = useState(false);
  const [webhooksEditLoading, setWebhooksEditLoading] = useState(false);
  const [webhooksDeleteLoading, setWebhooksDeleteLoading] = useState(false);
  const [webhooksList, setWebhooksList] = useState([]);
  const [selectedWebhook, setSelectedWebhook] = useState({});
  const [customFields, setCustomFields] = useState({});
  const [webHooksEvents, setWebHooksEvents] = useState();
  const [dealStages, setDelaStages] = useState();

  const [copiedStates, setCopiedStates] = useState({});

  const allAccountCustomFields = useSelector(
    (state) => state.allAccountCustomFields.fields
  );
  const allAccountStatuses = useSelector(
    (state) => state.allAccountStatuses.accountStatuses
  );
  const allContactsCustomFields = useSelector(
    (state) => state.allContactCustomFields.fields
  );
  const allDealCustomFields = useSelector(
    (state) => state.allDealCustomFields.fields
  );
  const allCarrierCustomFields = useSelector(
    (state) => state.allCarrierCustomFields.fields
  );

  const allUsers = useSelector((state) => state.allUsers.users);

  const tableHeaders = [
    "Webhooks URL",
    "Description",
    "Created By",
    "Events",
    "Secret",
    "status",
    "Created Date",
    "Action",
  ];

  function copyText(text, webhookId) {
    var input = document.createElement("textarea");
    input.innerHTML = text;
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand("copy");
    document.body.removeChild(input);

    // Update state only for the specific webhook
    setCopiedStates((prev) => ({
      ...prev,
      [webhookId]: "Copied",
    }));

    // Reset the button text after 2 seconds
    setTimeout(() => {
      setCopiedStates((prev) => ({
        ...prev,
        [webhookId]: "Copy",
      }));
    }, 2000);

    return result;
  }

  const getAllWebhooks = () => {
    setLoading(true);
    axios({
      method: "GET",
      url: `/webhooks/subscriptions`,
    }).then((res) => {
      console.log("res check", res.status === 200);
      setWebhooksList(res.data.webhooks);
      setLoading(false);
    });
  };
  const getAllFunnelStages = () => {
    setLoading(true);
    axios({
      method: "GET",
      url: `/funnels`,
    }).then((res) => {
      setDelaStages(res.data.funnels?.[0].stages);
      setLoading(false);
    });
  };

  const fetchEvents = () => {
    setLoading(true);
    axios({
      method: "GET",
      url: `/webhooks/events`,
    })
      .then((res) => {
        setWebHooksEvents(res.data.events);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        NotificationManager.error("Error");
      });
  };

  const getCustomFields = useCallback(() => {
    const fieldTypes = [
      { key: "accountCustomFields", fields: allAccountCustomFields },
      { key: "contactCustomFields", fields: allContactsCustomFields },
      { key: "dealCustomFields", fields: allDealCustomFields },
      { key: "carrierCustomFields", fields: allCarrierCustomFields },
    ];

    fieldTypes.forEach(({ key, fields }) => {
      setCustomFields((prevState) => ({
        ...prevState,
        [key]: fields,
      }));

      // Process the fields
      const customField = fields
        .map((group) => {
          const { fields: groupFields, meta } = group;
          return (
            (meta === null || meta.visible_in_form === true) &&
            groupFields?.map((field) => {
              const { label, name, id, type, choices } = field;
              return {
                name: label,
                key: name,
                custom: true,
                id,
                type,
                choices,
                editable: true,
              };
            })
          );
        })
        .filter((m) => m !== false);

      const merged = customField.reduce((prev, next) => prev.concat(next), []);

      const customFieldArray = merged.filter((m) => m !== false);

      // Update the processed fields
      setCustomFields((prevState) => ({
        ...prevState,
        [`new${key}`]: customFieldArray,
      }));
    });
  }, [
    allAccountCustomFields,
    allContactsCustomFields,
    allDealCustomFields,
    allCarrierCustomFields,
  ]);

  useEffect(() => {
    getAllWebhooks();
    getCustomFields();
    fetchEvents();
    getAllFunnelStages();
  }, []);

  return (
    <div className="reports">
      <div
        style={{
          borderBottom: "1px solid #E3E3E3",
          marginBottom: "10px",
        }}
      >
        <h2 style={{ marginBottom: "0px" }}>
          Webhooks
          <button
            className="button-md"
            style={{ float: "right" }}
            type="button"
            onClick={() => setShowWebhooksModal(true)}
          >
            Create Webhooks
          </button>
        </h2>
        <p> </p>
      </div>
      {loading ? (
        <div class="load-wrapp">
          <div class="load-3">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
        </div>
      ) : webhooksList && webhooksList.length === 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            fontWeight: "500",
          }}
        >
          No records found.
        </div>
      ) : (
        <table
          className="accountList__table"
          style={{ marginTop: "20px", width: "93vw", tableLayout: "fixed" }}
        >
          <thead>
            <tr>
              {tableHeaders.map((headers, index) => (
                <th key={headers + index}>{headers}</th>
              ))}
            </tr>
          </thead>
          <tbody style={{ fontSize: "16px" }}>
            {webhooksList?.map((webhook) => (
              <tr key={webhook.id}>
                <td>{webhook.webhook_url}</td>
                <td>{webhook.description}</td>
                <td>{webhook.created_by}</td>
                <td>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    {webhook.events?.map((eve, index) => {
                      const formatedLabel = eve.type
                        .replace(".", " ")
                        .replace(/\b\w/g, (c) => c.toUpperCase());

                      return (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            paddingTop: "5px",
                          }}
                        >
                          <strong>{formatedLabel}</strong>
                          <span>
                            {eve?.conditions.map((item) => item?.description)}
                          </span>
                          <span
                            style={{
                              borderBottom: "1px solid #E2E2E2",
                              paddingBottom: "5px",
                            }}
                          >
                            {eve.type.includes(".updated") &&
                              eve?.conditions?.map((item, condIndex) => {
                                const desc = item.description.toLowerCase();

                                if (eve.type.includes("account")) {
                                  if (desc.includes("status")) {
                                    return (
                                      <div key={condIndex}>
                                        <span>
                                          <strong>Value:</strong> {item.value}
                                        </span>
                                      </div>
                                    );
                                  } else if (desc.includes("custom field")) {
                                    const selectedCustomField =
                                      customFields?.newaccountCustomFields.find(
                                        (field) => field.id === item.value
                                      );
                                    return (
                                      <div key={condIndex}>
                                        <span>
                                          <strong>Value:</strong>{" "}
                                          {selectedCustomField?.name}
                                        </span>
                                      </div>
                                    );
                                  } else if (desc.includes("user")) {
                                    const selectedUser = allUsers?.find(
                                      (user) => user.id === item.value
                                    );
                                    return (
                                      <div key={condIndex}>
                                        <span>
                                          <strong>Value:</strong>{" "}
                                          {selectedUser?.name}
                                        </span>
                                      </div>
                                    );
                                  }
                                }

                                if (eve.type.includes("contact")) {
                                  if (desc.includes("status")) {
                                    return (
                                      <div key={condIndex}>
                                        <span>
                                          <strong>Value:</strong> {item.value}
                                        </span>
                                      </div>
                                    );
                                  } else if (desc.includes("custom field")) {
                                    const selectedCustomField =
                                      customFields?.newcontactCustomFields.find(
                                        (field) => field.id === item.value
                                      );
                                    return (
                                      <div key={condIndex}>
                                        <span>
                                          <strong>Value:</strong>{" "}
                                          {selectedCustomField?.name}
                                        </span>
                                      </div>
                                    );
                                  } else if (desc.includes("user")) {
                                    const selectedUser = allUsers?.find(
                                      (user) => user.id === item.value
                                    );
                                    return (
                                      <div key={condIndex}>
                                        <span>
                                          <strong>Value:</strong>{" "}
                                          {selectedUser?.name}
                                        </span>
                                      </div>
                                    );
                                  }
                                }

                                if (eve.type.includes("carrier")) {
                                  if (desc.includes("status")) {
                                    return (
                                      <div key={condIndex}>
                                        <span>
                                          <strong>Value:</strong> {item.value}
                                        </span>
                                      </div>
                                    );
                                  } else if (desc.includes("custom field")) {
                                    const selectedCustomField =
                                      customFields?.newcarrierCustomFields.find(
                                        (field) => field.id === item.value
                                      );
                                    return (
                                      <div key={condIndex}>
                                        <span>
                                          <strong>Value:</strong>{" "}
                                          {selectedCustomField?.name}
                                        </span>
                                      </div>
                                    );
                                  } else if (desc.includes("user")) {
                                    const selectedUser = allUsers?.find(
                                      (user) => user.id === item.value
                                    );
                                    return (
                                      <div key={condIndex}>
                                        <span>
                                          <strong>Value:</strong>{" "}
                                          {selectedUser?.name}
                                        </span>
                                      </div>
                                    );
                                  }
                                }

                                if (eve.type.includes("deal")) {
                                  if (desc.includes("status")) {
                                    return (
                                      <div key={condIndex}>
                                        <span>
                                          <strong>Value:</strong> {item.value}
                                        </span>
                                      </div>
                                    );
                                  } else if (desc.includes("custom field")) {
                                    const selectedCustomField =
                                      customFields?.newdealCustomFields.find(
                                        (field) => field.id === item.value
                                      );
                                    return (
                                      <div key={condIndex}>
                                        <span>
                                          <strong>Value:</strong>{" "}
                                          {selectedCustomField?.name}
                                        </span>
                                      </div>
                                    );
                                  } else if (desc.includes("stage")) {
                                    const getStage =
                                      dealStages?.find(
                                        (findStage) =>
                                          findStage.id === item.value
                                      )?.name || "";

                                    return (
                                      <div key={condIndex}>
                                        <span>
                                          <strong>Value:</strong> {getStage}
                                        </span>
                                      </div>
                                    );
                                  } else if (desc.includes("user")) {
                                    const selectedUser = allUsers?.find(
                                      (user) => user.id === item.value
                                    );
                                    return (
                                      <div key={condIndex}>
                                        <span>
                                          <strong>Value:</strong>{" "}
                                          {selectedUser?.name}
                                        </span>
                                      </div>
                                    );
                                  }
                                }
                                return "";
                              })}
                          </span>
                        </Box>
                      );
                    })}
                  </Box>
                </td>
                <td
                  style={{
                    wordWrap: "break-word",
                    wordBreak: "break-all",
                  }}
                >
                  {webhook.secret}
                  <button
                    onClick={() => copyText(webhook.secret, webhook.id)}
                    style={{
                      background: "#bfe9d7",
                      color: "#199661",
                      border: "none",
                      outline: "none",
                      cursor: "pointer",
                      borderRadius: "0 4px 4px 0",
                    }}
                  >
                    {copiedStates[webhook.id] || "Copy"}{" "}
                    <i className="fa fa-clipboard" aria-hidden="true"></i>
                    &nbsp;
                  </button>
                </td>
                <td style={{ textTransform: "capitalize" }}>
                  {webhook.status}
                </td>
                <td>{moment(webhook.created_at).format("L")}</td>
                <td>
                  <button
                    type="button"
                    className="button-sm"
                    onClick={() => {
                      setSelectedWebhook(webhook);
                      setShowEditWebhooksModal(true);
                    }}
                  >
                    Edit
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedWebhook(webhook);
                      setShowDeleteWebhooksModal(true);
                    }}
                    className="button-sm"
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {showWebhooksModal && (
        <CreateWebhooksModal
          showModal={showWebhooksModal}
          handleCloseModal={() => setShowWebhooksModal(false)}
          modalHeight={`75`}
          modalTop={`15`}
          webhooksLoading={webhooksLoading}
          setWebhooksLoading={setWebhooksLoading}
          getAllWebhooks={getAllWebhooks}
          colourStyles={colourStyles}
          webHooksEvents={webHooksEvents}
          allAccountStatus={allAccountStatuses}
          customFields={customFields}
          dealStages={dealStages}
          allUsers={allUsers}
        />
      )}

      {showEditWebhooksModal && (
        <EditWebhooksModal
          showModal={showEditWebhooksModal}
          handleCloseModal={() => setShowEditWebhooksModal(false)}
          modalHeight={`75`}
          modalTop={`15`}
          webhooksLoading={webhooksEditLoading}
          setWebhooksLoading={setWebhooksEditLoading}
          getAllWebhooks={getAllWebhooks}
          webhookData={selectedWebhook}
          colourStyles={colourStyles}
          webHooksEvents={webHooksEvents}
          allAccountStatus={allAccountStatuses}
          customFields={customFields}
          dealStages={dealStages}
          allUsers={allUsers}
        />
      )}
      {showDeleteWebhooksModal && (
        <DeleteWebhooksModal
          showModal={showDeleteWebhooksModal}
          handleCloseModal={() => setShowDeleteWebhooksModal(false)}
          modalHeight={`30`}
          modalTop={`25`}
          webhooksLoading={webhooksDeleteLoading}
          setWebhooksLoading={setWebhooksDeleteLoading}
          getAllWebhooks={getAllWebhooks}
          webhookData={selectedWebhook}
        />
      )}
    </div>
  );
};

export default WebhooksList;
