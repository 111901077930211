import React, { useEffect, useState } from "react";
import "./CustomizeAccountModalPage.scss";
import AccountIcon from "../../images/AccountIcon.svg";
import ModalPreview from "./ModalPreview";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { defaultAccountFields } from "../../constants/fields/defaultAccountFields";
import { defaultContactFields } from "../../constants/fields/defaultContactFields";
import { defaultDealFields } from "../../constants/fields/defaultDealFields";
import { defaultQuoteFields } from "../../constants/fields/defaultQuoteFields";
import { NotificationManager } from "react-notifications";
// Redux stuff
import { connect } from "react-redux";
import {
  fetchAllAllFormAccountFields,
  fetchAllAllFormCarrierFields,
  fetchAllAllFormContactFields,
  fetchAllAllFormDealFields,
  fetchAllAllFormQuoteFields,
  setActiveTabIndex,
  fetchAllQuoteCustomFields,
} from "../../redux";
import DashboardLoader from "../Loaders/DashboardLoader";
import Checkbox from "../Reusables/Checkbox";
import { defaultCarrierFields } from "../../constants/fields/defaultCarrierFields";
import { Plus } from "lucide-react";
import { DeleteFieldModal, EditFieldModal, FieldModal } from "../AccountModals";
import { v4 as uuidv4 } from "uuid";
import Select from "react-select";
import DatePicker from "react-datepicker";
import DeleteIcon from "../../images/DeleteIcon.svg";
import EditIcon from "../../images/Edit.svg";

const CustomizeAccountModalPage = (props) => {
  const pathArray = window.location.pathname.split("/");
  const [loading, setLoading] = useState(false);
  const [fieldsLoader, setFieldsLoader] = useState(false);
  const [customFields, setCustomFields] = useState([]);

  const [showAddFieds, setShowAddFields] = useState(false);
  const [fieldTypes, setFieldTypes] = useState();
  const [fieldLabel, setFieldLabel] = useState("");
  const [fieldRequired, setFieldRequired] = useState(false);
  const [fieldType, setFieldType] = useState("");

  const [fieldChoices, setFieldChoices] = useState([
    { tempId: 1, value: "", position: 1 },
    { tempId: 2, value: "", position: 2 },
  ]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editError, setEditError] = useState(false);
  const [hasChoices, setHasChoices] = useState();

  const [currentField, setCurrentField] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editFieldLabel, setEditFieldLabel] = useState("");
  const [editFieldType, setEditFieldType] = useState("");
  const [editFieldChoices, setEditFieldChoices] = useState([]);
  const [choices, setChoices] = useState([]);

  const [selectedFields, setSelectedFields] = useState(
    pathArray[2] === "account"
      ? defaultAccountFields.fields?.filter(
          (field) => field.selected_by_default === true
        )
      : pathArray[2] === "carrier"
      ? defaultCarrierFields.fields?.filter(
          (field) => field.selected_by_default === true
        )
      : pathArray[2] === "contact"
      ? defaultContactFields.fields?.filter(
          (field) => field.selected_by_default === true
        )
      : pathArray[2] === "quote"
      ? defaultQuoteFields.fields?.filter(
          (field) => field.selected_by_default === true
        )
      : defaultDealFields.fields?.filter(
          (field) => field.selected_by_default === true
        )
  );

  const fetchAccountData = () => {
    axios({
      method: "GET",
      url: `/fields/${pathArray[2] === "quote" ? "lane_quote" : pathArray[2]}`,
    })
      .then((response) => {
        const allCustomFields = response.data.fields?.filter(
          (field) => field.meta === null || field.meta.visible_in_form === true
        );
        const defaultFields =
          pathArray[2] === "account"
            ? defaultAccountFields
            : pathArray[2] === "carrier"
            ? defaultCarrierFields
            : pathArray[2] === "contact"
            ? defaultContactFields
            : pathArray[2] === "quote"
            ? defaultQuoteFields
            : defaultDealFields;
        const allFields = [defaultFields, ...allCustomFields];
        setCustomFields(allFields);
      })
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    fetchAccountData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathArray[2]]);
  const getStoredFieldsData = () => {
    setFieldsLoader(true);
    const fallBackFields =
      pathArray[2] === "account"
        ? defaultAccountFields.fields?.filter(
            (field) => field.selected_by_default === true
          )
        : pathArray[2] === "contact"
        ? defaultContactFields.fields?.filter(
            (field) => field.selected_by_default === true
          )
        : pathArray[2] === "carrier"
        ? defaultCarrierFields.fields?.filter(
            (field) => field.selected_by_default === true
          )
        : pathArray[2] === "quote"
        ? defaultQuoteFields.fields?.filter(
            (field) => field.selected_by_default === true
          )
        : defaultDealFields.fields?.filter(
            (field) => field.selected_by_default === true
          );
    axios({
      method: "GET",
      url: `/admin/default-fields?type=${
        pathArray[2] === "quote" ? "lane_quote" : pathArray[2]
      }`,
    })
      .then((res) => {
        setFieldsLoader(false);
        const default_fields = res.data?.default_fields?.data;
        default_fields
          ? setSelectedFields(default_fields)
          : selectedFields(fallBackFields);
      })
      .catch((err) => {
        setFieldsLoader(false);
        selectedFields(fallBackFields);
      });
  };
  useEffect(() => {
    getStoredFieldsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathArray[2]]);
  const submitHandler = () => {
    setLoading(true);
    axios({
      method: "PUT",
      url: `/admin/default-fields`,
      data: {
        type: pathArray[2] === "quote" ? "lane_quote" : pathArray[2],
        is_modified: true,
        data: selectedFields,
      },
    })
      .then((res) => {
        setLoading(false);
        NotificationManager.success("Form saved successfully.");
        props.fetchAllAllFormAccountFields();
        props.fetchAllAllFormCarrierFields();
        props.fetchAllAllFormContactFields();
        props.fetchAllAllFormDealFields();
        props.fetchAllAllFormQuoteFields();
        props.setActiveTabIndex(0);
        props.history.push("/active-tabs");
      })
      .catch((err) => {
        setLoading(false);
        NotificationManager.error("Error saving changes.");
      });
  };
  const selectedFieldsChangeHandler = (field, e) => {
    if (e.target.checked) {
      const newArray = [...selectedFields, field];
      setSelectedFields(newArray);
    } else {
      const filteredArray = selectedFields?.filter(
        (f) => f.id !== field.id && f.name !== field.name
      );
      setSelectedFields(filteredArray);
    }
  };
  const changeRequiredHandler = (field, e) => {
    if (e.target.checked) {
      const newArray = selectedFields?.map((f) => {
        if (f.id === field.id && f.name === field.name) {
          f.required = true;
        }
        return f;
      });
      setSelectedFields(newArray);
    } else {
      const newArray = selectedFields?.map((f) => {
        if (f.id === field.id && f.name === field.name) {
          f.required = false;
        }
        return f;
      });
      setSelectedFields(newArray);
    }
  };
  const deleteField = (field) => {
    const filteredArray = selectedFields?.filter(
      (f) =>
        f.id !== field.id && f.name !== field.name && f.label !== field.label
    );
    setSelectedFields(filteredArray);
  };
  const insertSectionHeading = () => {
    const newArray = [
      ...selectedFields,
      {
        choices: [],
        field_class: pathArray[2],
        id: `d-sectionHeading${selectedFields.length}`,
        label: "Section Heading",
        name: `section_heading${selectedFields.length}`,
        parent_id: "default_fields",
        placeholder: null,
        position: 1,
        required: true,
        type: "heading",
        visible: null,
      },
    ];
    setSelectedFields(newArray);
  };
  const sectionHeadingChangeHandler = (name, e) => {
    const { value } = e.target;
    const newArray = selectedFields?.map((field) => {
      if (field.name === name) {
        field.label = value;
      }
      return field;
    });
    setSelectedFields(newArray);
  };
  let selectedFieldsArray = selectedFields?.map((field) => field.name);
  let requiredFieldsArray = selectedFields
    ?.filter((field) => field.required)
    ?.map((field) => field.name);

  const handleOpenModal = () => {
    setShowAddFields(true);
  };
  const handleCloseModal = () => {
    setShowAddFields(false);
  };

  const getFieldsAccount = () => {
    axios({
      method: "GET",
      url: `/fields/types`,
    }).then((res) => {
      setFieldTypes(res.data.types);
    });
  };

  const fieldRequiredCheckBoxChangeHandler = (e) => {
    const { value } = e.target;
    if (e.target.checked === true) {
      setFieldRequired(value);
    } else {
      setFieldRequired(null);
    }
  };

  const addFieldHandler = (e) => {
    e.preventDefault();

    if (!fieldLabel || !fieldType) {
      setErrorMessage(true);
      return false;
    } else if (
      (fieldType === "dropdown" ||
        fieldType === "radio" ||
        fieldType === "multi_select_dropdown") &&
      fieldChoices.length < 2
    ) {
      setErrorMessage(true);
      return false;
    } else if (
      (fieldType === "dropdown" ||
        fieldType === "radio" ||
        fieldType === "multi_select_dropdown") &&
      fieldChoices.map((choice) => choice.value === "").includes(true)
    ) {
      setErrorMessage(true);
      return false;
    }

    setIsLoading(true);

    axios({
      method: "POST",
      url: `/fields`,
      data: {
        label: fieldLabel,
        field_class: "lane_quote",
        type: fieldType,
        required: fieldRequired ? true : false,
        parent_id: customFields?.[1]?.id, //default group id
        choices: fieldChoices.map((choice) => {
          return {
            value: choice.value,
          };
        }),
      },
    })
      .then((response) => {
        props.fetchAllAllFormQuoteFields();
        props.fetchAllQuoteCustomFields();
        handleCloseModal();
        getFieldsAccount();
        NotificationManager.success("Field added successfully.");

        fetchAccountData();
        // Reset all state values to defaults
        setFieldLabel("");
        setFieldType("");
        //setAddFieldModalGroupId("");
        setFieldChoices([
          { tempId: 1, value: "", position: 1 },
          { tempId: 2, value: "", position: 2 },
        ]);
        setFieldRequired(false);
        setErrorMessage(false);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 409) {
          NotificationManager.error("Field name already exists");
        } else {
          NotificationManager.error("Error creating field");
        }
        setIsLoading(false);
      });
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;

    if (name === "fieldChoices") {
      setFieldChoices((prevChoices) => [prevChoices, value]);
    } else if (name === "fieldType") {
      setFieldType(value);

      const hasChoicesValue = fieldTypes.find(
        (type) => type.name === value
      ).has_choices;
      setHasChoices(hasChoicesValue);
    } else {
      if (name === "fieldLabel") {
        setFieldLabel(value);
      } else if (name === "fieldRequired") {
        setFieldRequired(value);
      }
    }
  };

  const addChoice = () => {
    setFieldChoices((prevChoices) => [
      ...prevChoices,
      {
        tempId: uuidv4(),
        value: "",
        position: prevChoices.length + 1,
      },
    ]);
  };

  const changeNewChoiceHandler = (tempId, e) => {
    const { value } = e.target;

    setFieldChoices((prevChoices) =>
      prevChoices.map((choice) => {
        if (choice.tempId === tempId) {
          return { ...choice, value: value };
        }
        return choice;
      })
    );
  };

  const handleEditOpenModal = (fieldId, parentId) => {
    setCurrentField((prevState) => {
      const field = customFields
        .find((field) => field.id === parentId)
        .fields.find((field) => field.id === fieldId);

      setShowEditModal(true);
      setEditFieldLabel(field.label);
      setEditFieldType(field.type);

      return field;
    });
  };

  const handleEditCloseModal = () => {
    setShowEditModal(false);
    setEditFieldChoices([]);
  };

  const handleDeleteOpenModal = (fieldId, parentId) => {
    setCurrentField((prevState) => {
      const field = customFields
        .find((field) => field.id === parentId)
        .fields.find((field) => field.id === fieldId);

      setShowDeleteModal(true);
      setEditFieldLabel(field.label);
      setEditFieldType(field.type);

      return field;
    });
  };

  const handleDeleteCloseModal = () => {
    setShowDeleteModal(false);
  };

  const editFieldHandler = (e) => {
    e.preventDefault();
    if (!currentField.label || !currentField.type) {
      setEditError(true);
      return false;
    } else if (
      (currentField.type === "dropdown" ||
        currentField.type === "radio" ||
        currentField.type === "multi_select_dropdown") &&
      editFieldChoices.length > 0 &&
      editFieldChoices.map((choice) => choice.value === "").includes(true)
    ) {
      setEditError(true);
      return false;
    }

    setIsLoading(true);
    axios({
      method: "PUT",
      url: `/fields/${currentField.id}`,

      data: {
        label: currentField?.label,
        field_class: "lane_quote",
        type: currentField.type,
        required: false,
        parent_id: currentField.parent_id,
        destroy: false,
        position: currentField.position,
        choices: [
          ...currentField?.choices?.map((choice) => {
            return {
              position: choice.position,
              value: choice.value,
              destroy: choice.destroy || false,
              choice_id: choice.id,
            };
          }),
          ...editFieldChoices.map((choice) => {
            return {
              position: choice.position,
              value: choice.value,
              destroy: choice.destroy || false,
            };
          }),
        ],
      },
    })
      .then((response) => {
        setIsLoading(false);
        setEditError(false);
        handleEditCloseModal();
        props.fetchAllAllFormQuoteFields();
        props.fetchAllQuoteCustomFields();
        getFieldsAccount();
        fetchAccountData();
        NotificationManager.success("Field edited successfully.");
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        NotificationManager.error("Error editing field");
      });
  };
  const deleteFieldHandler = (e) => {
    e.preventDefault();
    deleteField(currentField);
    axios({
      method: "PUT",
      url: `/fields/${currentField.id}`,
      data: {
        label: currentField.label,
        field_class: "lane_quote",
        type: currentField.type,
        required: false,
        parent_id: currentField.parent_id,
        destroy: true,
        position: currentField.position,
        choices: currentField?.choices?.map((choice) => {
          return {
            position: choice.position,
            value: choice.value,
            destroy: false,
          };
        }),
      },
    })
      .then((response) => {
        props.fetchAllAllFormQuoteFields();
        props.fetchAllQuoteCustomFields();
        getFieldsAccount();
        fetchAccountData();
        handleDeleteCloseModal();
        NotificationManager.success("Field deleted successfully.");
      })
      .catch((error) => NotificationManager.error("Error deleting field"));
  };

  const editRequiredFieldChangeHandler = (e) => {
    const { value } = e.target;
    if (e.target.checked === true) {
      setCurrentField((prevState) => ({
        ...prevState,
        required: value,
      }));
    } else {
      setCurrentField((prevState) => ({
        ...prevState,
        required: null,
      }));
    }
  };

  const editFieldChangeHandler = (e) => {
    const { name, value } = e.target;
    setCurrentField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const editChoiceChangeHandler = (choiceId, e) => {
    const { value } = e.target;
    setCurrentField((prevState) => ({
      ...prevState,
      choices: prevState.choices.map((choice) => {
        if (choice.id === choiceId) {
          choice.value = value;
        }
        return choice;
      }),
    }));
  };

  const addEditFieldChoice = () => {
    setEditFieldChoices((prevState) => [
      ...prevState,
      {
        tempId: uuidv4(),
        value: "",
        position: prevState.length + 1,
      },
    ]);
  };

  const deleteChoiceChangeHandler = (choiceId, e) => {
    setCurrentField((prevState) => ({
      ...prevState,
      choices: prevState.choices.map((choice) => {
        if (choice.id === choiceId) {
          choice.destroy = true;
        }
        return choice;
      }),
    }));
  };

  const changeEditFieldNewChoiceHandler = (tempId, e) => {
    const { value } = e.target;
    setEditFieldChoices((prevState) =>
      prevState.map((choice) => {
        if (choice.tempId === tempId) {
          choice.value = value;
        }
        return choice;
      })
    );
  };

  const deleteEditChoiceNewChangeHandler = (tempId, e) => {
    setEditFieldChoices((prevState) => [
      ...prevState.filter((choice) => choice.tempId !== tempId),
    ]);
  };

  const showPreview = () => {
    const type = fieldType;
    const choices = fieldChoices;
    const label = fieldLabel;
    const required = fieldRequired;

    switch (type) {
      case "paragraph":
        return (
          <div>
            <label>
              {label}{" "}
              {required && (
                <span
                  style={{
                    color: "#4A9876",
                    fontSize: "17px",
                    lineHeight: "16px",
                  }}
                >
                  *
                </span>
              )}
            </label>
            <textarea></textarea>
          </div>
        );
      case "hyperlink":
        return (
          <div>
            <label>
              {label}{" "}
              {required && (
                <span
                  style={{
                    color: "#4A9876",
                    fontSize: "17px",
                    lineHeight: "16px",
                  }}
                >
                  *
                </span>
              )}
            </label>
            <input type="text" />
          </div>
        );
      case "revenue":
        return (
          <div>
            <label>
              {label}{" "}
              {required && (
                <span
                  style={{
                    color: "#4A9876",
                    fontSize: "17px",
                    lineHeight: "16px",
                  }}
                >
                  *
                </span>
              )}
            </label>
            <input type="text" />
          </div>
        );
      case "text":
        return (
          <div>
            <label>
              {label}{" "}
              {required && (
                <span
                  style={{
                    color: "#4A9876",
                    fontSize: "17px",
                    lineHeight: "16px",
                  }}
                >
                  *
                </span>
              )}
            </label>
            <input type="text" />
          </div>
        );
      case "decimal":
        return (
          <div>
            <label>
              {label}{" "}
              {required && (
                <span
                  style={{
                    color: "#4A9876",
                    fontSize: "17px",
                    lineHeight: "16px",
                  }}
                >
                  *
                </span>
              )}
            </label>
            <input type="number" />
          </div>
        );
      case "dropdown":
        return (
          <div>
            <label>
              {label}{" "}
              {required && (
                <span
                  style={{
                    color: "#4A9876",
                    fontSize: "17px",
                    lineHeight: "16px",
                  }}
                >
                  *
                </span>
              )}
            </label>
            <select>
              <option>- Select -</option>
              {choices.map((choice) => (
                <option>{choice.value}</option>
              ))}
            </select>
          </div>
        );
      case "checkbox":
        return (
          <div style={{ textAlign: "left" }}>
            <input
              type="checkbox"
              style={{
                display: "inline-block",
                textAlign: "left",
                width: "14px",
                marginRight: "10px",
              }}
            />
            <label
              style={{
                display: "inline-block",
                textAlign: "left",
                fontSize: "14px",
              }}
            >
              {label}
            </label>
          </div>
        );
      case "radio":
        return (
          <div style={{ textAlign: "left" }}>
            <label>
              {label}{" "}
              {required && (
                <span
                  style={{
                    color: "#4A9876",
                    fontSize: "17px",
                    lineHeight: "16px",
                  }}
                >
                  *
                </span>
              )}
            </label>
            {choices.map((choice) => (
              <div key={choice.id}>
                <input
                  type="radio"
                  style={{
                    display: "inline-block",
                    textAlign: "left",
                    width: "14px",
                    marginRight: "10px",
                  }}
                  id={choice.id + choice.value}
                  name="radioButtonPreview"
                />
                <label
                  style={{
                    display: "inline-block",
                    textAlign: "left",
                    fontSize: "14px",
                  }}
                  htmlFor={choice.id + choice.value}
                >
                  {choice.value}
                </label>
              </div>
            ))}
          </div>
        );
      case "date":
        return (
          <div>
            <label>
              {label}{" "}
              {required && (
                <span
                  style={{
                    color: "#4A9876",
                    fontSize: "17px",
                    lineHeight: "16px",
                  }}
                >
                  *
                </span>
              )}
            </label>
            <DatePicker
              selected={new Date()}
              onChange={(date) => Date.parse(date)}
              dateFormat={
                props.companyOnboarding?.date_format === "DD/MM/YYYY"
                  ? "dd/MM/yyyy"
                  : "MM/dd/yyyy"
              }
              placeholderText={
                props.companyOnboarding?.date_format === "DD/MM/YYYY"
                  ? "dd/mm/yyyy"
                  : "mm/dd/yyyy"
              }
            />
          </div>
        );
      case "multi_select_dropdown":
        const colourStyles = {
          control: (styles) => ({
            ...styles,
            backgroundColor: "white",
            width: "280px",
            outline: "#C0EAD8",
            minHeight: "30px",
            padding: "5px auto",
          }),
          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
              ...styles,
              backgroundColor: isDisabled
                ? null
                : isSelected
                ? data.color
                : isFocused
                ? "#C0EAD8"
                : null,
              cursor: isDisabled ? "not-allowed" : "default",

              ":active": {
                ...styles[":active"],
                backgroundColor:
                  !isDisabled && (isSelected ? data.color : "#C0EAD8"),
              },
            };
          },
          multiValue: (styles, { data }) => {
            return {
              ...styles,
              backgroundColor: "#C0EAD8",
            };
          },
          multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: "black",
            fontSize: "13px",
          }),
        };
        return (
          <div>
            <label>
              {label}{" "}
              {required && (
                <span
                  style={{
                    color: "#4A9876",
                    fontSize: "17px",
                    lineHeight: "16px",
                  }}
                >
                  *
                </span>
              )}
            </label>
            <Select
              closeMenuOnSelect={false}
              isMulti
              styles={colourStyles}
              options={choices.map((choice) => {
                const { value } = choice;
                return { value: value, label: value };
              })}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
        );
      default: {
      }
    }
  };

  useEffect(() => {
    getFieldsAccount();
  }, []);

  return (
    <div className="customize-modal-container">
      <div>
        <ModalPreview
          heading={`Create ${pathArray[2] === "account" ? "an" : "a"} ${
            pathArray[2] === "opportunity" ? "Deal" : pathArray[2]
          }`}
          description={`Add some information about the ${
            pathArray[2] === "opportunity" ? "deal" : pathArray[2]
          }`}
          displayIcon={AccountIcon}
          selectedFields={selectedFields}
          setSelectedFields={setSelectedFields}
          sectionHeadingChangeHandler={sectionHeadingChangeHandler}
          deleteField={deleteField}
          isQuote={pathArray[2] === "quote"}
        />
      </div>
      <div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <button
            className="button-md button-outline-green"
            type="button"
            onClick={() => insertSectionHeading()}
            style={{
              marginRight: "15px",
              height: "36px",
              padding: "0 16px",
              fontWeight: "500",
              background: "#ffffff",
              color: "#4A9876",
              border: "1px solid #4A9876",
            }}
          >
            Insert Section Heading
            <Plus
              size={14}
              color="#62CA9D"
              strokeWidth={3}
              style={{ transform: "translateY(2px)", marginLeft: "5px" }}
            />
          </button>
          {pathArray[2] === "quote" && (
            <button
              type="button"
              className="button-md"
              style={{ marginRight: "15px" }}
              onClick={() => setShowAddFields(true)}
            >
              Add Custom Fields
              <Plus
                size={14}
                color="#62CA9D"
                strokeWidth={3}
                style={{ transform: "translateY(2px)", marginLeft: "5px" }}
              />
            </button>
          )}

          <button
            type="button"
            onClick={() => submitHandler()}
            disabled={loading}
            className="button-md"
          >
            {loading ? "Saving..." : "Save Changes"}
          </button>
        </div>
        {fieldsLoader ? (
          <>
            <DashboardLoader />
            <DashboardLoader />
            <DashboardLoader />
          </>
        ) : (
          customFields?.map((group) => {
            const { label, fields, meta, id } = group;
            return (
              <div key={id}>
                {meta === null ? (
                  <div>
                    <h3
                      className="modal__heading-sub"
                      style={{ fontSize: "16px" }}
                    >
                      {label}
                    </h3>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "50% 50%",
                      }}
                    >
                      {fields?.map((field) => {
                        const { id, label, type, required, parent_id } = field;
                        return (
                          <span key={id} className="customize-modal-field">
                            <h3 style={{ color: "#555" }}>Selected</h3>
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "space-between",
                              }}
                            >
                              {label}
                              {pathArray[2] === "quote" &&
                                parent_id !== "default_fields" && (
                                  <span style={{ marginRight: "25px" }}>
                                    <button
                                      onClick={() =>
                                        handleEditOpenModal(id, parent_id)
                                      }
                                      style={{ margin: "0 5px" }}
                                    >
                                      <img
                                        src={EditIcon}
                                        alt="Edit Field"
                                        width={15}
                                      />
                                    </button>
                                    <button
                                      onClick={() =>
                                        handleDeleteOpenModal(id, parent_id)
                                      }
                                    >
                                      <img
                                        src={DeleteIcon}
                                        alt="Delete Field"
                                      />
                                    </button>
                                  </span>
                                )}
                            </div>
                            <h3 style={{ color: "#555" }}>Required</h3>
                            <Checkbox
                              label=""
                              id={id}
                              isDisabled={field.selected_by_default === true}
                              isChecked={selectedFieldsArray.includes(
                                field.name
                              )}
                              onChange={(e) =>
                                selectedFieldsChangeHandler(field, e)
                              }
                              value={true}
                            />
                            <span
                              style={{
                                color: "#a7abaa",
                                transform: "translateY(2px)",
                                fontSize: "11px",
                              }}
                            >
                              {type === "multi_select_dropdown"
                                ? "Multi-select"
                                : type === "decimal"
                                ? "Number"
                                : type}
                            </span>
                            <Checkbox
                              label=""
                              id={id}
                              isDisabled={field.selected_by_default === true}
                              isChecked={
                                required ||
                                requiredFieldsArray.includes(field.name)
                              }
                              onChange={(e) => changeRequiredHandler(field, e)}
                              value={true}
                            />
                          </span>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
              </div>
            );
          })
        )}
      </div>
      {showAddFieds && (
        <FieldModal
          showOpportunityModal={showAddFieds}
          setShowOpportunityModal={handleOpenModal}
          handleCloseModal={handleCloseModal}
          fieldTypes={fieldTypes}
          fieldLabel={fieldLabel}
          fieldRequired={fieldRequired}
          fieldRequiredCheckBoxChangeHandler={
            fieldRequiredCheckBoxChangeHandler
          }
          addFieldHandler={addFieldHandler}
          changeHandler={changeHandler}
          fieldType={fieldType}
          accountFields={null}
          accountField={null}
          fieldChoices={fieldChoices}
          //choices={""}
          addChoice={addChoice}
          hasChoices={hasChoices}
          changeNewChoiceHandler={changeNewChoiceHandler}
          addFieldModalGroupId={null}
          deleteChoiceNewChangeHandler={null}
          showPreview={showPreview}
          errorMessage={errorMessage}
          isLoading={isLoading}
          isQuote={pathArray[2] === "quote"}
        />
      )}
      {showEditModal && (
        <EditFieldModal
          showOpportunityModal={showEditModal}
          setShowOpportunityModal={handleEditOpenModal}
          handleCloseModal={handleEditCloseModal}
          editRequiredFieldChangeHandler={editRequiredFieldChangeHandler}
          currentField={currentField}
          fieldTypes={fieldTypes}
          fieldLabel={fieldLabel}
          editFieldLabel={editFieldLabel}
          editFieldType={editFieldType}
          editFieldChoices={editFieldChoices}
          editFieldHandler={editFieldHandler}
          changeHandler={changeHandler}
          fieldType={fieldType}
          accountFields={customFields}
          accountField={null}
          //choices={choices}
          addChoice={addChoice}
          hasChoices={hasChoices}
          editFieldChangeHandler={editFieldChangeHandler}
          editChoiceChangeHandler={editChoiceChangeHandler}
          deleteFieldHandler={deleteFieldHandler}
          addEditFieldChoice={addEditFieldChoice}
          deleteChoiceChangeHandler={deleteChoiceChangeHandler}
          changeEditFieldNewChoiceHandler={changeEditFieldNewChoiceHandler}
          deleteEditChoiceNewChangeHandler={deleteEditChoiceNewChangeHandler}
          isLoading={isLoading}
          isQuote={pathArray[2] === "quote"}
          errorMessage={editError}
        />
      )}

      {showDeleteModal && (
        <DeleteFieldModal
          showOpportunityModal={showDeleteModal}
          setShowOpportunityModal={handleDeleteOpenModal}
          handleCloseModal={handleDeleteCloseModal}
          deleteFieldHandler={deleteFieldHandler}
        />
      )}
    </div>
  );
};
const MSP = (state) => {
  return {
    authToken: state.login.authToken,
    authUser: state.login.authUser,
    totalTabs: state.tabsState.tabs.length,
    dashboardsState: state.dashboardsState,
    isAccountFieldsModified: state.allAccountFields.isModified,
    isCarrierFieldsModified: state.allCarrierFields.isModified,
    isContactFieldsModified: state.allContactFields.isModified,
    isDealFieldsModified: state.allDealFields.isModified,
    companyOnboarding: state.login.companyOnboarding,
  };
};
const MDP = (dispatch) => {
  return {
    fetchAllAllFormAccountFields: (allAccountFields) =>
      dispatch(fetchAllAllFormAccountFields(allAccountFields)),
    fetchAllAllFormCarrierFields: (allCarrierFields) =>
      dispatch(fetchAllAllFormCarrierFields(allCarrierFields)),
    fetchAllAllFormContactFields: (allContactFields) =>
      dispatch(fetchAllAllFormContactFields(allContactFields)),
    fetchAllAllFormDealFields: (allDealFields) =>
      dispatch(fetchAllAllFormDealFields(allDealFields)),
    fetchAllAllFormQuoteFields: (allQuoteFields) =>
      dispatch(fetchAllAllFormQuoteFields(allQuoteFields)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    fetchAllQuoteCustomFields: (data) =>
      dispatch(fetchAllQuoteCustomFields(data)),
  };
};
export default connect(MSP, MDP)(withRouter(CustomizeAccountModalPage));
