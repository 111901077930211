import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MenuItem } from "@mui/material";
import Select from "react-select";

import moment from "moment";

import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import MuiSelect from "../../Reusables/Select/MuiSelect";
import InputField from "../../Reusables/InputField/InputField";
import GoogleAutocomplete from "../GoogleAutocomplete";
import { customStylesErr } from "./../../../constants/selectStyles";

const QuoteEditModal = (props) => {
  const { quoteData } = props;

  const quoteStatus = ["Pending", "Won", "Lost"];
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const [stopFields, setStopFields] = useState(quoteData?.stops);
  const [stopLocations, setStopLocations] = useState(quoteData?.stops); // Add this new state

  // Add this function to handle adding new origin fields
  const addStopField = () => {
    if (stopFields.length < 10) {
      setStopFields([...stopFields, { id: stopFields.length + 1 }]);
    }
  };
  // Validation Schema
  const schema = Yup.object().shape({
    shipDate: Yup.date().required("Ship date is required"),
    deliveryDate: Yup.date().nullable(),
    status: Yup.string().required("Status is required"),
    origin: Yup.object()
      .shape({
        address: Yup.string().nullable(),
        lat: Yup.number().nullable(),
        long: Yup.number().nullable(),
        city: Yup.string().nullable(),
        state: Yup.string().nullable(),
        zip: Yup.string().nullable(),
        country: Yup.string().nullable(),
      })
      .nullable()
      .required("Origin is required"),
    destination: Yup.object()
      .shape({
        address: Yup.string().nullable(),
        lat: Yup.number().nullable(),
        long: Yup.number().nullable(),
        city: Yup.string().nullable(),
        state: Yup.string().nullable(),
        zip: Yup.string().nullable(),
        country: Yup.string().nullable(),
      })
      .nullable()
      .required("Destination is required"),
    equipmentTypes: Yup.array()
      .min(1, "Equipment type is required")
      .required("Equipment types are required")
      .nullable(),
    modes: Yup.array().nullable(),
    quote: Yup.number()
      .positive("Quote must be positive")
      .nullable()
      .required("Quote is required"),
  });

  // Initial Form Values
  const initialValues = {
    shipDate: moment(quoteData?.shipment_date).toDate(),
    deliveryDate: quoteData?.delivery_date
      ? moment(quoteData?.delivery_date).toDate()
      : null,

    status: quoteData?.status,
    origin: {
      address: quoteData?.origin_address,
      lat: quoteData?.origin_lat,
      long: quoteData?.origin_long,
      city: quoteData?.origin_city,
      state: quoteData?.origin_state,
      zip: quoteData?.origin_zip,
      country: quoteData?.origin_country,
    },
    destination: {
      address: quoteData?.destination_address,
      lat: quoteData?.destination_lat,
      long: quoteData?.destination_long,
      city: quoteData?.destination_city,
      state: quoteData?.destination_state,
      zip: quoteData?.destination_zip,
      country: quoteData?.destination_country,
    },
    equipmentTypes: quoteData?.equipment_types?.map((item) => ({
      value: item?.id,
      label: item?.name,
    })),
    modes: quoteData?.modes?.map((item) => ({
      value: item?.id,
      label: item?.name,
    })),
    quote: quoteData?.price,
  };

  const submitQuote = (values) => {
    const {
      shipDate,
      deliveryDate,
      status,
      origin,
      destination,
      equipmentTypes,
      modes,
      quote,
    } = values;

    const convertDate = moment(shipDate).format("YYYY-MM-DD");
    const convertDeliveryDate = deliveryDate
      ? moment(deliveryDate).format("YYYY-MM-DD")
      : null;

    setLoading(true);
    axios({
      method: "PUT",
      url: `/activities/${props.id}`,
      data: {
        name: "Quote",
        lane_quote_id: quoteData?.id,
        shipment_date: convertDate,
        delivery_date: convertDeliveryDate,
        status: status.toLowerCase(),
        origin_city: origin.city,
        origin_state: origin.state,
        origin_zip: origin.zip,
        origin_address: origin.address,
        origin_lat: origin.lat,
        origin_long: origin.long,
        destination_city: destination.city,
        destination_state: destination.state,
        destination_zip: destination.zip,
        destination_address: destination.address,
        destination_lat: destination.lat,
        destination_long: destination.long,
        equipment_type_ids: equipmentTypes.map((item) => item?.value),
        mode_ids: modes.map((item) => item?.value),
        price: quote,
        category_id: props.categoryId,
        stops: stopLocations,
        type: "activity_logged",
      },
    })
      .then((res) => {
        NotificationManager.success("Quote Edit Successfully!");
        setErrorMessage(false);
        setLoading(false);
        props.loadActivities(1, "refetch");
        props.handleCloseModal();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      outline: "#C0EAD8",
      padding: "1px 0px",
      boxShadow: "none",
      borderColor: "lightgray",
    }),
    indicatorSeparator: (styles) => {
      return {
        display: "none",
      };
    },
    valueContainer: (styles) => {
      return {
        ...styles,
        // height: "37px",
      };
    },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? "#3aab7b"
          : isFocused
          ? "#C0EAD8"
          : null,
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled && (isSelected ? data.color : "#C0EAD8"),
        },
      };
    },
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "#C0EAD8",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "black",
    }),
  };

  return (
    <>
      {/* Forms */}
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={submitQuote}
        validateOnBlur={true}
        validateOnChange={true}
      >
        {({ errors, touched, values, handleChange, setFieldValue }) => (
          <Form>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "20px",
                marginTop: "2rem",
                marginBottom: "20px",
                overflowY: "scroll",
                height: "40vh",
                maxHeight: "50vh",
                padding: "15px",
                WebkitOverflowScrolling: "touch",
              }}
            >
              <div
                className="controlsForms"
                style={{
                  width: "101%",
                  marginTop: "2px",
                }}
              >
                <DatePicker
                  selected={values.shipDate}
                  name="shipDate"
                  value={values.shipDate}
                  onChange={(date) => setFieldValue("shipDate", date)}
                  minDate={moment().toDate()}
                  className="datePickerQuotes"
                  placeholderText="Select Date"
                  dateFormat={
                    props.companyOnboarding?.date_format === "DD/MM/YYYY"
                      ? "dd/MM/yyyy"
                      : "MM/dd/yyyy"
                  }
                  autoComplete="off"
                  popperPlacement="auto"
                  popperProps={{
                    modifiers: [
                      {
                        name: "zIndex",
                        options: {
                          zIndex: 10,
                        },
                      },
                    ],
                  }}
                />
                <label className="activeLabel" style={{ top: "-12px" }}>
                  <span style={{ fontSize: "12px" }}> Ship Date </span>
                </label>
                {errors.shipDate && touched.shipDate && (
                  <span
                    style={{
                      color: "#d32f2f",
                      fontSize: "12px",
                    }}
                  >
                    {errors.shipDate}
                  </span>
                )}
              </div>
              <div
                className="controlsForms"
                style={{
                  width: "101%",
                  marginTop: "2px",
                }}
              >
                <DatePicker
                  selected={values.deliveryDate}
                  name="deliveryDate"
                  value={values.deliveryDate}
                  onChange={(date) => setFieldValue("deliveryDate", date)}
                  minDate={moment().toDate()}
                  className="datePickerQuotes"
                  placeholderText="Select Delivery Date"
                  dateFormat={
                    props.companyOnboarding?.date_format === "DD/MM/YYYY"
                      ? "dd/MM/yyyy"
                      : "MM/dd/yyyy"
                  }
                  autoComplete="off"
                  popperPlacement="auto"
                  popperProps={{
                    modifiers: [
                      {
                        name: "zIndex",
                        options: {
                          zIndex: 10,
                        },
                      },
                    ],
                  }}
                />
                <label className="activeLabel" style={{ top: "-12px" }}>
                  <span style={{ fontSize: "12px" }}> Delivery Date</span>
                </label>
              </div>

              {/* Origin */}
              <div className="controlsForms" style={{ width: "100%" }}>
                <div>
                  <GoogleAutocomplete
                    name="origin"
                    className={
                      touched.origin && errors.origin
                        ? "formField-err"
                        : "formField"
                    }
                    valueProps={values?.origin?.address}
                    onPlaceSelect={(place) => setFieldValue("origin", place)}
                  />
                  {Array.isArray(stopFields) && stopFields?.length < 10 && (
                    <button
                      type="button"
                      onClick={addStopField}
                      style={{
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                        position: "absolute",
                        width: "30px",
                        height: "30px",
                        right: "-25px",
                        top: "40%",
                        transform: "translateY(-50%)", // Center vertically
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: 0,
                        color: "#666",
                      }}
                    >
                      <span
                        title="Add Stop"
                        style={{ fontSize: "20px", lineHeight: 0 }}
                      >
                        +
                      </span>
                    </button>
                  )}
                </div>
                <label className="activeLabel" style={{ top: "-12px" }}>
                  <span style={{ fontSize: "12px" }}>Origin</span>
                </label>
                {touched.origin && errors.origin && (
                  <span
                    style={{
                      color: "#d32f2f",
                      fontSize: "12px",
                    }}
                  >
                    {typeof errors.origin === "object" && errors.origin?.address
                      ? errors.origin.address
                      : "Origin is required"}
                  </span>
                )}
              </div>
              {/* MultiStops  */}
              {Array.isArray(stopFields) &&
                stopFields?.map((field, index) => (
                  <div
                    key={field.id}
                    className="controlsForms"
                    style={{ width: "100%" }}
                  >
                    <div key={field.id}>
                      <GoogleAutocomplete
                        name={`origin${field.id}`}
                        className="formField"
                        valueProps={
                          stopLocations[index]?.address
                            ? stopLocations[index]?.address
                            : undefined
                        }
                        onPlaceSelect={(place) =>
                          // Update the stops array, replacing the stop at this index if it exists
                          setStopLocations((prevStops) => {
                            const newStops = [...prevStops];
                            newStops[index] = place;
                            return newStops;
                          })
                        }
                        placeholder="Enter a stop location"
                      />
                      <button
                        type="button"
                        onClick={() => {
                          // Remove field and reindex remaining fields
                          setStopFields((fields) => {
                            const newFields = fields
                              .filter((_, i) => i !== index)
                              .map((field, i) => ({ id: i + 1 }));
                            return newFields.length ? newFields : [];
                          });

                          // Remove stop and compact remaining stops
                          setStopLocations((prevStops) => {
                            const newStops = prevStops.filter(
                              (_, i) => i !== index
                            );
                            return newStops.length ? newStops : [];
                          });
                        }}
                        style={{
                          background: "none",
                          border: "none",
                          cursor: "pointer",
                          position: "absolute",
                          width: "30px",
                          height: "30px",
                          right: "-25px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: 0,
                          color: "#666",
                          zIndex: 2,
                        }}
                      >
                        <span
                          title={`Remove Stop ${index + 1}`}
                          style={{ fontSize: "20px", lineHeight: 0 }}
                        >
                          ×
                        </span>
                      </button>
                    </div>
                    <label className="activeLabel" style={{ top: "-12px" }}>
                      <span style={{ fontSize: "12px" }}>Stop {index + 1}</span>
                    </label>
                  </div>
                ))}
              {/* Destination */}
              <div className="controlsForms" style={{ width: "100%" }}>
                <div>
                  <GoogleAutocomplete
                    name="destination"
                    className={
                      touched.destination && errors.destination
                        ? "formField-err"
                        : "formField"
                    }
                    valueProps={values?.destination?.address}
                    onPlaceSelect={(place) =>
                      setFieldValue("destination", place)
                    }
                  />
                </div>
                <label className="activeLabel" style={{ top: "-12px" }}>
                  <span style={{ fontSize: "12px" }}>Destination</span>
                </label>
                {touched.destination && errors.destination && (
                  <span
                    style={{
                      color: "#d32f2f",
                      fontSize: "12px",
                    }}
                  >
                    {typeof errors.destination === "object" &&
                    errors.destination?.address
                      ? errors.destination.address
                      : "Destination is required"}
                  </span>
                )}
              </div>

              <MuiSelect
                name="status"
                value={
                  values?.status?.charAt(0).toUpperCase() +
                  values?.status?.slice(1)
                }
                error={errors.status}
                helperText={errors.status}
                label="Status"
                sx={{ marginBottom: "0", zIndex: 0 }}
                onChange={handleChange}
                placeholder="State"
                menuItem={quoteStatus.map((status) => (
                  <MenuItem
                    sx={{ textTransform: "capitalize" }}
                    key={status}
                    value={status}
                  >
                    {status}
                  </MenuItem>
                ))}
              />
              <div className="controlsForms" style={{ width: "100%" }}>
                <Select
                  name="equipmentTypes"
                  value={values.equipmentTypes}
                  closeMenuOnSelect={false}
                  isMulti
                  styles={
                    errors.equipmentTypes && touched.equipmentTypes
                      ? customStylesErr
                      : colourStyles
                  }
                  options={props.allEquipmentTypes?.map((type) => {
                    return { value: type.id, label: type.name };
                  })}
                  placeholder="-Select-"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(selectedOptions) =>
                    setFieldValue("equipmentTypes", selectedOptions)
                  }
                />
                <label
                  className="activeLabel"
                  style={{
                    color:
                      errors.equipmentTypes && touched.equipmentTypes
                        ? "#d32f2f"
                        : "",
                    top: "-14px",
                    height: "10px",
                  }}
                >
                  <span style={{ fontSize: "12px" }}>Equipment Types</span>
                </label>
                {errors.equipmentTypes && touched.equipmentTypes && (
                  <span
                    style={{
                      color: "#d32f2f",
                      fontSize: "12px",
                    }}
                  >
                    {errors.equipmentTypes}
                  </span>
                )}
              </div>
              <div className="controlsForms" style={{ width: "100%" }}>
                <Select
                  name="modes"
                  value={values.modes}
                  closeMenuOnSelect={false}
                  isMulti
                  styles={colourStyles}
                  options={props.allModes?.map((type) => {
                    return { value: type.id, label: type.name };
                  })}
                  placeholder="-Select-"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  menuPlacement="top"
                  onChange={(selectedOptions) =>
                    setFieldValue("modes", selectedOptions)
                  }
                />
                <label
                  className="activeLabel"
                  style={{ top: "-14px", height: "10px" }}
                >
                  <span style={{ fontSize: "12px" }}>Modes</span>
                </label>
                {errors.modes && touched.modes && (
                  <span
                    style={{
                      color: "#d32f2f",
                      fontSize: "12px",
                    }}
                  >
                    {errors.modes}
                  </span>
                )}
              </div>
              <InputField
                label="Quote"
                name="quote"
                type="number"
                sx={{ width: "100%", zIndex: 0 }}
                value={values.quote}
                onChange={handleChange}
                error={errors.quote && touched.quote}
                helperText={errors.quote}
                onWheel={(e) => e.target.blur()}
              />
            </div>

            {/* Save Button */}

            <div className="modal__buttons">
              <button
                type="button"
                className="modal__cancel-button"
                onClick={props.handleCloseModal}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="modal__confirm-button"
                disabled={loading}
              >
                {loading ? "Saving" : "Save"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

const MSP = (state) => {
  return {
    authUser: state.login.authUser,
    allEquipmentTypes: state.allEquipmentTypes.equipmentTypes,
    allModes: state.allModes.modes,
    companyOnboarding: state.login.companyOnboarding,
  };
};

export default connect(MSP)(QuoteEditModal);
