import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import GoogleAutocompleteComponent from "../GoogleComponents/GoogleAutocompleteComponent";
import GoogleMapComponent from "../GoogleComponents/GoogleMapComponent";
import Select from "react-select";
import "../../sass/LanesPanel.scss";
import LaneIcon from "../../images/lane.svg";
import DropdownLaneStatusPanel from "../Dropdown/DropdownLaneStatusPanel";

//redux
import { connect } from "react-redux";
import {
  fetchAllEquipmentTypes,
  fetchAllSpecialRequirements,
} from "../../redux";
import ReusableModal from "../Reusables/ReusableModal";
import { NotificationManager } from "react-notifications";
import ThreeDotsMenu from "./ThreeDotsMenu";
import MapboxLanesMap from "../Mapbox/MapboxLanesMap";
import { Check } from "lucide-react";
import {
  getCurrencyValue,
  isValidCurrency,
} from "../../utils/Helper/reusableFunctions";
import { showInput } from "./CustomFields/reusableFunctions";
import QuotesView from "../Quotes/QuotesView";
import DashboardLoader from "./../Loaders/DashboardLoader";

const EditLanePanel = ({
  carrierId,
  accountId,
  setAddLane,
  allEquipmentTypes,
  allSpecialRequirements,
  allModes,
  allLaneTypes,
  getCarrierLanes,
  laneData,
  allLaneStatuses,
  allLaneCustomFields,
  companyOnboarding,
}) => {
  //states
  const [origin, setOrigin] = useState({
    address: null,
    lat: null,
    long: null,
    city: null,
    state: null,
    zip: null,
    country: null,
  });
  const [destination, setDestination] = useState({
    address: null,
    lat: null,
    long: null,
    city: null,
    state: null,
    zip: null,
    country: null,
  });
  const [price, setPrice] = useState(null);
  const [equipmentTypes, setEquipementTypes] = useState([]);
  const [speciaRequirements, setSpeciaRequirements] = useState([]);
  const [modes, setModes] = useState([]);
  const [rpm, setRpm] = useState(null);
  const [volume, setVolume] = useState(null);
  const [mileage, setMileage] = useState(1);
  const [laneTypes, setLaneTypes] = useState([]);
  const [description, setDescription] = useState(null);
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [searchLoader, setSearchLoader] = useState(false);
  const [daysOfWeek, setDaysOfWeek] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [status, setStatus] = useState(allLaneStatuses[0]?.id || null);
  const [statusColor, setStatusColor] = useState("#FF9966");
  const [fieldData, setFieldData] = useState([]);
  const [customField, setCustomField] = useState({});
  const [customFieldsForAPI, setCustomFieldsForAPI] = useState({});
  const [customFieldRevenueTypeCheck, setCustomFieldRevenueTypeCheck] =
    useState([]);
  const [activityLoader, setActivityLoader] = useState(false);
  const [logs, setLogs] = useState();

  const [activePage, setActivePage] = useState(1);

  const days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

  // change handlers
  const handleOriginPlaceSelect = (place) => {
    setOrigin(place);
  };
  const handleDestinationPlaceSelect = (place) => {
    setDestination(place);
  };
  const daysOfWeekChangeHandler = (e) => {
    const { value } = e.target;
    if (e.target.checked === true) {
      const newDays = [...daysOfWeek.filter((day) => day !== ""), value];
      setDaysOfWeek(newDays);
    } else {
      const newDays = daysOfWeek.filter((pc) => pc !== value);
      setDaysOfWeek(newDays);
    }
  };
  const customFieldInitializer = (initialValue) => {
    setFieldData(allLaneCustomFields);
    const customField = allLaneCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field) => {
            const { name } = field;
            return name;
          })
        );
      })
      .filter((m) => m !== false);
    const merged = customField.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArray = merged.filter((m) => m !== false);
    const obj = customFieldArray.reduce((accumulator, currentValue) => {
      accumulator[currentValue] = null;
      return accumulator;
    }, {});
    const customFieldTypeCheck = allLaneCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field) => field)
        );
      })
      .filter((m) => m !== false);
    const mergedTypeCheck = customFieldTypeCheck.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArrayTypeCheck = mergedTypeCheck.filter(
      (m) => m !== false && m.type === "revenue"
    );
    const objTypeCheck = customFieldArrayTypeCheck.reduce(
      (obj, item) => Object.assign(obj, { [item.name]: item.type }),
      {}
    );
    const transformedInitialValue = Object.fromEntries(
      Object.entries(initialValue).map(([key, value]) => {
        if (typeof value === "string" && value.startsWith(";")) {
          return [
            key,
            value
              .split(";")
              .filter((f) => f !== "")
              .map((val) => ({ label: val, value: val })),
          ];
        }
        return [key, value];
      })
    );
    const finalObject = { ...obj, ...transformedInitialValue };
    const finalObjectForAPI = { ...obj, ...initialValue };
    setCustomFieldRevenueTypeCheck(objTypeCheck);
    setCustomField(finalObject);
    setCustomFieldsForAPI(finalObjectForAPI);
  };

  const customFieldChangeHandler = (e) => {
    const { name, value } = e.target;
    setCustomField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setCustomFieldsForAPI((prevState) => ({ ...prevState, [name]: value }));
  };
  const customFieldRevenueChangeHandler = (e) => {
    const { name, value } = e.target;
    const checkValidations = isValidCurrency(value);
    if (checkValidations) {
      setCustomField((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      setCustomFieldsForAPI((prevState) => ({ ...prevState, [name]: value }));
    }
  };
  const customFieldSelectChangeHandler = (name, value) => {
    setCustomField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setCustomFieldsForAPI((prevState) => ({ ...prevState, [name]: value }));
  };
  const customFieldCheckBoxChangeHandler = (e) => {
    const { name, value } = e.target;
    if (e.target.checked === true) {
      setCustomField((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      setCustomFieldsForAPI((prevState) => ({ ...prevState, [name]: value }));
    } else {
      setCustomField((prevState) => ({
        ...prevState,
        [name]: null,
      }));
      setCustomFieldsForAPI((prevState) => ({ ...prevState, [name]: null }));
    }
  };
  const customFieldMultiDropdownChangeHandler = (
    fieldName,
    selectedOptions
  ) => {
    setCustomField((prevState) => ({
      ...prevState,
      [fieldName]: selectedOptions,
    }));
    setCustomFieldsForAPI((prevState) => ({
      ...prevState,
      [fieldName]:
        ";" + selectedOptions?.map((option) => option.value)?.join(";") + ";",
    }));
  };
  const customFieldDateChangeHandler = (type, date) => {
    setCustomField((prevState) => ({
      ...prevState,
      [type]: date,
    }));
    setCustomFieldsForAPI((prevState) => ({
      ...prevState,
      [type]: date,
    }));
  };

  useEffect(() => {
    if (laneData) {
      setOrigin({
        address: laneData?.origin_address,
        lat: parseFloat(laneData?.origin_lat),
        long: parseFloat(laneData?.origin_long),
        city: laneData?.origin_city,
        state: laneData?.origin_state,
        zip: laneData?.origin_zip,
        country: laneData?.origin_country,
      });
      setDestination({
        address: laneData?.destination_address,
        lat: parseFloat(laneData?.destination_lat),
        long: parseFloat(laneData?.destination_long),
        city: laneData?.destination_city,
        state: laneData?.destination_state,
        zip: laneData?.destination_zip,
        country: laneData?.destination_country,
      });
      setPrice(laneData?.price === null ? 0 : laneData?.price);
      setRpm(laneData?.rate_per_mile === null ? 0 : laneData?.rate_per_mile);
      setVolume(laneData?.volume === null ? "" : laneData?.volume);
      setStatusColor(
        laneData?.status_color === null ? "#FF9966" : laneData?.status_color
      );
      setEquipementTypes(
        laneData?.equipment_types?.map((type) => ({
          label: type.name,
          value: type.id,
        }))
      );
      setSpeciaRequirements(
        laneData?.requirement_types?.map((type) => ({
          label: type.name,
          value: type.id,
        }))
      );
      setLaneTypes(
        laneData?.types?.map((type) => ({
          label: type.name,
          value: type.id,
        }))
      );
      setModes(
        laneData?.modes?.map((type) => ({
          label: type.name,
          value: type.id,
        }))
      );
      setDaysOfWeek(
        laneData?.days_of_week === null
          ? []
          : laneData?.days_of_week?.split(",")
      );
      setDescription(
        laneData?.description === null ? "" : laneData?.description
      );
      setStatus(
        laneData?.status_id === null
          ? allLaneStatuses[0]?.id
          : laneData?.status_id
      );
      customFieldInitializer(laneData?.custom_fields);
    }
  }, [laneData]);
  // search by lane
  const searchByLane = () => {
    setSearchLoader(true);
    const searchParam = carrierId ? "accounts" : "carriers";
    let url = `/${searchParam}/lanes`;
    if (origin.city) {
      const paramSeparator = url.includes("?") ? "&" : "?";
      url += `${paramSeparator}origin_city=${origin.city}`;
    } else if (origin.zip) {
      const paramSeparator = url.includes("?") ? "&" : "?";
      url += `${paramSeparator}origin_zip=${origin.zip}`;
    } else if (origin.state) {
      const paramSeparator = url.includes("?") ? "&" : "?";
      url += `${paramSeparator}origin_state=${origin.state}`;
    }
    if (destination.city) {
      const paramSeparator = url.includes("?") ? "&" : "?";
      url += `${paramSeparator}destination_city=${destination.city}`;
    } else if (destination.zip) {
      const paramSeparator = url.includes("?") ? "&" : "?";
      url += `${paramSeparator}destination_zip=${destination.zip}`;
    } else if (destination.state) {
      const paramSeparator = url.includes("?") ? "&" : "?";
      url += `${paramSeparator}destination_state=${destination.state}`;
    }
    if (equipmentTypes.length > 0) {
      const paramSeparator = url.includes("?") ? "&" : "?";
      url += `${paramSeparator}equipment_type_ids=${equipmentTypes
        ?.map((type) => type.value)
        .join(",")}`;
    }
    if (speciaRequirements.length > 0) {
      const paramSeparator = url.includes("?") ? "&" : "?";
      url += `${paramSeparator}requirement_type_ids=${speciaRequirements
        ?.map((type) => type.value)
        .join(",")}`;
    }
    if (modes.length > 0) {
      const paramSeparator = url.includes("?") ? "&" : "?";
      url += `${paramSeparator}modes_ids=${modes
        ?.map((type) => type.value)
        .join(",")}`;
    }
    if (daysOfWeek.length > 0) {
      const paramSeparator = url.includes("?") ? "&" : "?";
      url += `${paramSeparator}days_of_week=${daysOfWeek.join(",")}`;
    }
    if (price) {
      const paramSeparator = url.includes("?") ? "&" : "?";
      url += `${paramSeparator}price=${price}`;
    }
    axios({
      method: "GET",
      url,
    })
      .then((res) => {
        setAccounts(res.data.lanes?.results);
        setSearchLoader(false);
        document
          .getElementById(`search-shipper-table-${carrierId}-${accountId}`)
          .scrollIntoView({ behavior: "smooth" });
      })
      .catch((err) => setSearchLoader(false));
  };
  // submitHandlers
  const editLane = () => {
    if (!laneData.id) {
      setLoading(false);
      return false;
    }
    if (!origin.address || !destination.address) {
      setLoading(false);
      return false;
    }
    let customField = customFieldsForAPI;
    for (let i in customField) {
      if (customField[i] === "") {
        customField[i] = null;
      }
    }
    for (let i in customFieldRevenueTypeCheck) {
      customField[i] = getCurrencyValue(customField[i]);
    }
    setLoading(true);
    axios({
      method: "PUT",
      url: `/lanes/${laneData.id}`,
      data: {
        price: Number(price) || 0,
        description: description === "" ? null : description,
        origin_city: origin.city,
        origin_state: origin.state,
        origin_zip: origin.zip,
        origin_address: origin.address,
        origin_lat: origin.lat,
        origin_long: origin.long,
        destination_city: destination.city,
        destination_state: destination.state,
        destination_zip: destination.zip,
        destination_address: destination.address,
        destination_lat: destination.lat,
        destination_long: destination.long,
        equipment_type_ids: equipmentTypes?.map((type) => type.value),
        requirement_type_ids: speciaRequirements?.map((type) => type.value),
        mode_ids: modes?.map((type) => type.value),
        type_ids: laneTypes?.map((type) => type.value),
        volume: Number(volume),
        rate_per_mile: Number(rpm) || 0,
        days_of_week: daysOfWeek.length > 0 ? daysOfWeek.join(",") : null,
        status_id: status,
        custom_fields: customField,
      },
    })
      .then((res) => {
        setLoading(false);
        setAddLane(false);
        getCarrierLanes(1, "refetch");
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const setPricing = (type, value) => {
    if (type === "rpm") {
      const newPrice = value * mileage;
      const roundedPrice = Math.round(newPrice);
      setRpm(value);
      setPrice(roundedPrice);
    } else if (type === "price") {
      const newRpm = value / mileage;
      setRpm(newRpm.toFixed(2));
      setPrice(value);
    }
  };
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      outline: "#C0EAD8",
      margin: "5px auto",
      // height: "35px",
      boxShadow: "none",
    }),
    indicatorSeparator: (styles) => {
      return {
        display: "none",
      };
    },
    valueContainer: (styles) => {
      return {
        ...styles,
        // height: "37px",
      };
    },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? "#3aab7b"
          : isFocused
          ? "#C0EAD8"
          : null,
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled && (isSelected ? data.color : "#C0EAD8"),
        },
      };
    },
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "#C0EAD8",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "black",
    }),
  };
  const deleteLane = () => {
    setDeleteLoading(true);
    axios({
      method: "DELETE",
      url: `/lanes/${laneData.id}`,
    })
      .then((res) => {
        NotificationManager.success("Lane deleted successfully.");
        setDeleteLoading(false);
        setAddLane(false);
        getCarrierLanes(1, "refetch");
      })
      .catch((err) => {
        setDeleteLoading(false);
        NotificationManager.error("Error deleting lane,");
      });
  };
  const menuItems = [
    {
      label: "Delete Lane",
      onClick: () => setShowDeleteModal(true),
    },
    {
      label: "Cancel",
      onClick: () => setAddLane(false),
    },
  ];

  const fetchLaneQuotes = useCallback(
    (page = 1) => {
      setActivityLoader(true);

      let url = `/lanes/quotes?_page=${page}&_limit=30&account_id=${accountId}`;
      if (origin.city) {
        const paramSeparator = url.includes("?") ? "&" : "?";
        url += `${paramSeparator}origin_city=${origin.city}`;
      } else if (origin.zip) {
        const paramSeparator = url.includes("?") ? "&" : "?";
        url += `${paramSeparator}origin_zip=${origin.zip}`;
      } else if (origin.state) {
        const paramSeparator = url.includes("?") ? "&" : "?";
        url += `${paramSeparator}origin_state=${origin.state}`;
      }
      if (destination.city) {
        const paramSeparator = url.includes("?") ? "&" : "?";
        url += `${paramSeparator}destination_city=${destination.city}`;
      } else if (destination.zip) {
        const paramSeparator = url.includes("?") ? "&" : "?";
        url += `${paramSeparator}destination_zip=${destination.zip}`;
      } else if (destination.state) {
        const paramSeparator = url.includes("?") ? "&" : "?";
        url += `${paramSeparator}destination_state=${destination.state}`;
      }
      axios({
        method: "GET",
        url,
        headers: {
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("authToken"))?.access_token,
        },
      })
        .then((res) => {
          setActivityLoader(false);

          // /seTotalQuotes(res.data.total);
          setLogs(res.data);
        })
        .catch((err) => {
          console.error("Error loading activities:", err);
          setActivityLoader(false);
        });
    },
    [origin.city, destination.city, equipmentTypes]
  );

  useEffect(() => {
    if (origin.city && destination.city) {
      fetchLaneQuotes();
    }
  }, [fetchLaneQuotes, origin.city, destination.city, equipmentTypes]);

  return (
    <div className="lanes-panel" style={{ position: "relative" }}>
      <ReusableModal
        showModal={showDeleteModal}
        handleCloseModal={() => setShowDeleteModal(false)}
        submitHandler={deleteLane}
        modalHeading={`Delete Lane`}
        modalPara={`Are you sure you want to delete this lane?`}
        modalHeight={`30`}
        modalTop={`25`}
        loading={deleteLoading}
      />
      <h3 className="activity-title">
        <span
          style={{
            display: "inline-block",
            marginRight: "5px",
          }}
        >
          <div
            className="customerInfo-icon-modal"
            style={{
              transform: "translateY(0px)",
              background: "rgb(255, 227, 179)",
              width: "30px",
              height: "30px",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <img src={LaneIcon} alt="+ Lane" style={{ width: "17px" }} />
          </div>
        </span>
        Edit Lane
        <DropdownLaneStatusPanel
          allStatuses={allLaneStatuses}
          editStatusChangeHandler={setStatus}
          statusId={laneData?.status_id}
          setStatusColor={setStatusColor}
        />
      </h3>
      <div className="lanes-panel-form" style={{ marginTop: "20px" }}>
        <div
          className="controlsForms"
          style={{ width: "100%", marginBottom: "10px", marginTop: "-5px" }}
        >
          <GoogleAutocompleteComponent
            onPlaceSelect={handleOriginPlaceSelect}
            valueProps={laneData.origin_address}
          />
          <label className="activeLabel" style={{ fontSize: "12px" }}>
            Origin
          </label>
        </div>
        <div
          className="controlsForms"
          style={{ width: "100%", marginBottom: "10px", marginTop: "-5px" }}
        >
          <GoogleAutocompleteComponent
            onPlaceSelect={handleDestinationPlaceSelect}
            valueProps={laneData.destination_address}
          />
          <label className="activeLabel" style={{ fontSize: "12px" }}>
            Destination
          </label>
        </div>
        <div
          className="controlsForms"
          style={{ width: "100%", marginBottom: "10px", marginTop: "-5px" }}
        >
          <div style={{ position: "relative" }}>
            <div
              style={{
                position: "absolute",
                top: "14px",
                left: "8px",
                fontWeight: "500",
              }}
            >
              $
            </div>
            <input
              style={{ paddingLeft: "20px", width: "calc(95% - 20px)" }}
              type="number"
              value={price}
              onChange={(e) => setPricing("price", e.target.value)}
            />
          </div>
          <label className="activeLabel" style={{ fontSize: "12px" }}>
            Linehaul
          </label>
        </div>
        <div
          className="controlsForms"
          style={{ width: "100%", marginBottom: "10px", marginTop: "-5px" }}
        >
          <div style={{ position: "relative" }}>
            <div
              style={{
                position: "absolute",
                top: "14px",
                left: "8px",
                fontWeight: "500",
              }}
            >
              $
            </div>
            <input
              style={{ paddingLeft: "20px", width: "calc(95% - 20px)" }}
              type="number"
              value={rpm}
              onChange={(e) => setPricing("rpm", e.target.value)}
            />
          </div>
          <label className="activeLabel" style={{ fontSize: "12px" }}>
            Rate Per Mile
          </label>
        </div>
        <div
          className="controlsForms"
          style={{ width: "100%", marginBottom: "10px", marginTop: "-5px" }}
        >
          <input
            type="number"
            value={volume}
            onChange={(e) => setVolume(e.target.value)}
          />
          <label className="activeLabel" style={{ fontSize: "12px" }}>
            Volume (Weekly)
          </label>
        </div>
      </div>
      <div className="lanes-panel-form-1" style={{ marginTop: "15px" }}>
        <div className="lanes-panel-form-1-div">
          <div
            className="controlsForms"
            style={{ width: "100%", marginBottom: "10px", marginTop: "-5px" }}
          >
            <Select
              value={equipmentTypes}
              closeMenuOnSelect={false}
              isMulti
              styles={colourStyles}
              options={allEquipmentTypes?.map((type) => {
                return { value: type.id, label: type.name };
              })}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
                DropdownIndicator: () => null,
              }}
              placeholder="-Select-"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(selectedOptions) =>
                setEquipementTypes(selectedOptions)
              }
            />
            <label className="activeLabel" style={{ fontSize: "12px" }}>
              Equipment Types
            </label>
          </div>
        </div>
        <div className="lanes-panel-form-1-div">
          <div
            className="controlsForms"
            style={{ width: "100%", marginBottom: "10px", marginTop: "-5px" }}
          >
            <Select
              value={modes}
              closeMenuOnSelect={false}
              isMulti
              styles={colourStyles}
              options={allModes?.map((type) => {
                return { value: type.id, label: type.name };
              })}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
                DropdownIndicator: () => null,
              }}
              placeholder="-Select-"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(selectedOptions) => setModes(selectedOptions)}
            />
            <label className="activeLabel" style={{ fontSize: "12px" }}>
              Modes
            </label>
          </div>
        </div>
        <div className="lanes-panel-form-1-div">
          <div
            className="controlsForms"
            style={{ width: "100%", marginBottom: "10px", marginTop: "-5px" }}
          >
            <Select
              value={speciaRequirements}
              closeMenuOnSelect={false}
              isMulti
              styles={colourStyles}
              options={allSpecialRequirements?.map((type) => {
                return { value: type.id, label: type.name };
              })}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
                DropdownIndicator: () => null,
              }}
              placeholder="-Select-"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(selectedOptions) =>
                setSpeciaRequirements(selectedOptions)
              }
            />
            <label className="activeLabel" style={{ fontSize: "12px" }}>
              Special Requirements
            </label>
          </div>
        </div>
        <div className="lanes-panel-form-1-div">
          <div
            className="controlsForms"
            style={{ width: "100%", marginBottom: "10px", marginTop: "-5px" }}
          >
            <Select
              value={laneTypes}
              closeMenuOnSelect={false}
              isMulti
              styles={colourStyles}
              options={allLaneTypes?.map((type) => {
                return { value: type.id, label: type.name };
              })}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
                DropdownIndicator: () => null,
              }}
              placeholder="-Select-"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(selectedOptions) => setLaneTypes(selectedOptions)}
            />
            <label className="activeLabel" style={{ fontSize: "12px" }}>
              Lane Type
            </label>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "15px" }}>
        {fieldData?.map((group) => {
          const { fields, meta, id } = group;
          return (
            <div key={id}>
              {meta === null ? (
                <div className="lanes-panel-form-1">
                  {fields?.map((field) => {
                    const { id, label, type, choices, name, required } = field;
                    return (
                      <div key={id} className="lanes-panel-form-1-div">
                        {showInput(
                          name,
                          label,
                          type,
                          choices,
                          required,
                          customField,
                          customFieldChangeHandler,
                          customFieldRevenueChangeHandler,
                          customFieldCheckBoxChangeHandler,
                          customFieldSelectChangeHandler,
                          customFieldDateChangeHandler,
                          customFieldMultiDropdownChangeHandler,
                          companyOnboarding
                        )}
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
      <div>
        <label>
          {carrierId ? (
            <>
              Days of Week{" "}
              <span style={{ fontSize: "12px" }}>
                (When their trucks are available for this lane)
              </span>
            </>
          ) : (
            <>
              Days of Week{" "}
              <span style={{ fontSize: "12px" }}>
                (What days the freight ships out for this lane)
              </span>
            </>
          )}
        </label>
        <div
          className="modal-radioButton c-radioButton"
          style={{ marginTop: "5px" }}
        >
          {days.map((day) => (
            <span key={day}>
              <label
                htmlFor={`week_${day}`}
                style={
                  daysOfWeek.includes(day)
                    ? {
                        border: "2px solid #4a9876",
                        outline: "none",
                        color: "#555555",
                        padding: "4px 12px",
                        borderRadius: "9px",
                        marginRight: "8px",
                        background: "#F4F6F5",
                        fontWeight: "normal",
                        fontSize: "14px",
                        lineHeight: "16px",
                        textTransform: "uppercase",
                      }
                    : {
                        color: "#555555",
                        padding: "4px 12px",
                        border: "none",
                        borderRadius: "9px",
                        background: "#F4F6F5",
                        marginRight: "8px",
                        fontWeight: "normal",
                        fontSize: "14px",
                        lineHeight: "16px",
                        textTransform: "uppercase",
                      }
                }
              >
                {day}
                <input
                  type="checkbox"
                  name="category"
                  id={`week_${day}`}
                  className="radio-button"
                  checked={daysOfWeek.includes(day)}
                  value={day}
                  onChange={(e) => daysOfWeekChangeHandler(e)}
                />
              </label>
            </span>
          ))}
        </div>
      </div>
      <div
        className="lanes-panel-form-1-div"
        style={{ width: "100%", maxWidth: "100%", marginRight: "0" }}
      >
        <div
          className="controlsForms"
          style={{ width: "100%", marginBottom: "10px", marginTop: "10px" }}
        >
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            style={{ width: "98.5%" }}
          ></textarea>
          <label className="activeLabel" style={{ fontSize: "12px" }}>
            Notes
          </label>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginBottom: "10px",
        }}
      >
        <button
          style={{
            position: "absolute",
            right: "10px",
            top: "10px",
            background: "transparent",
            border: "none",
            outline: "none",
            cursor: "pointer",
          }}
          // disabled={deleteLoading}
          // onClick={() => setShowDeleteModal(true)}
        >
          {/* <img src={DeleteIconRed} alt="Delete Lane" height="18px" /> */}
          <ThreeDotsMenu items={menuItems} />
        </button>
        <button
          type="button"
          className="button-md-black-outline"
          style={{ marginRight: "10px", border: "1px solid #000" }}
          onClick={searchByLane}
          disabled={searchLoader}
        >
          {searchLoader ? "Searching" : "Search"}{" "}
          {carrierId ? "Shippers" : "Carriers"}
        </button>
        <button
          className="button-md"
          type="button"
          disabled={loading}
          onClick={editLane}
        >
          {loading ? "Saving" : "Save"} Updates
          <Check
            size={16}
            color="#62CA9D"
            style={{ transform: "translateY(2px)", marginLeft: "5px" }}
          />
        </button>
      </div>
      {origin?.address && destination?.address && (
        <div style={{ display: "flex", gap: "1rem" }}>
          {activityLoader ? (
            <div style={{ width: "50%" }}>
              <DashboardLoader />
            </div>
          ) : (
            logs?.lane_quotes?.total > 0 && (
              <div style={{ width: "50%" }}>
                <h3>Quotes ({logs?.lane_quotes?.total})</h3>
                <div style={{ marginTop: "-20px" }}>
                  <QuotesView
                    accountId={accountId}
                    total={logs?.lane_quotes?.total}
                    laneQuotesData={logs?.lane_quotes?.results}
                    laneQuotes={true}
                    loadLaneQuotes={fetchLaneQuotes}
                    setLaneActivePage={setActivePage}
                    laneQuoteLoader={activityLoader}
                    laneQuoteActivePage={activePage}
                  />
                </div>
              </div>
            )
          )}

          <div
            style={{
              width: ` ${logs?.lane_quotes?.total > 0 ? "50%" : "100%"} `,
            }}
          >
            <MapboxLanesMap
              lanes={[
                {
                  id: 1,
                  origin: { lat: origin?.lat, lon: origin?.long },
                  destination: {
                    lat: destination?.lat,
                    lon: destination?.long,
                  },
                  color: statusColor,
                },
              ]}
              setMileage={setMileage}
              fromPanel={true}
              isEditLane={logs?.lane_quotes?.total > 0}
            />
          </div>
        </div>
      )}
      {/* <div>
        {origin?.address && destination?.address && (
          <MapboxLanesMap
            lanes={[
              {
                id: 1,
                origin: { lat: origin?.lat, lon: origin?.long },
                destination: { lat: destination?.lat, lon: destination?.long },
                color: statusColor,
              },
            ]}
            setMileage={setMileage}
            fromPanel={true}
          />
        )}
      </div> */}
      <div
        id={`search-shipper-table-${carrierId}-${accountId}`}
        style={{ overflow: "auto" }}
      >
        {accounts.length > 0 && (
          <table className="accountList__table">
            <thead>
              <th>{carrierId ? "Shipper" : "Carrier"}</th>
              <th>Owner</th>
              <th>Origin</th>
              <th>Destination</th>
              <th>Equipment Types</th>
              <th>Spec. Req.</th>
              <th>Price</th>
              <th>Description</th>
            </thead>
            <tbody>
              {accounts?.map((account) => {
                const owner = account.owners
                  ?.map((owner) => owner.name)
                  ?.join(", ");
                const name = carrierId
                  ? account.account_name
                  : account.carrier_name;
                return (
                  <tr key={account.id}>
                    <td>{name}</td>
                    <td>{owner}</td>
                    <td>{account.origin_address}</td>
                    <td>{account.destination_address}</td>
                    <td>
                      {account.equipment_types
                        ?.map((type) => type.name)
                        ?.join(", ")}
                    </td>
                    <td>
                      {account.requirement_types
                        ?.map((type) => type.name)
                        ?.join(", ")}
                    </td>
                    <td>{account.price}</td>
                    <td>{account.description}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

const MSP = (state) => {
  return {
    allEquipmentTypes: state.allEquipmentTypes.equipmentTypes,
    allSpecialRequirements: state.allSpecialRequirements.specialRequirements,
    allModes: state.allModes.modes,
    allLaneTypes: state.allLaneTypes.laneTypes,
    allLaneStatuses: state.allLaneStatuses.laneStatuses,
    allLaneCustomFields: state.allLaneCustomFields.fields,
    companyOnboarding: state.login.companyOnboarding,
  };
};
const MDP = (dispatch) => {
  return {
    fetchAllEquipmentTypes: (equipmentTypes) =>
      dispatch(fetchAllEquipmentTypes(equipmentTypes)),
    fetchAllSpecialRequirements: (specialRequirementsData) =>
      dispatch(fetchAllSpecialRequirements(specialRequirementsData)),
  };
};

export default connect(MSP, MDP)(EditLanePanel);
