import {
  InputLabel,
  Select,
  FormControl,
  FormHelperText,
  TextField,
} from "@mui/material";

const MuiSelect = ({
  label,
  name,
  value,
  onChange,
  menuItem,
  error,
  helperText,
  required,
  style,
  placeholder,
  sx,
  slotProps,
}) => {
  return (
    <>
      <FormControl
        fullWidth
        sx={sx ? sx : { marginBottom: "15px", fontSize: "14px", zIndex: 0 }}
        error={error}
      >
        {/* <InputLabel  id="demo-simple-select-label">
          {label}
        </InputLabel> */}
        <TextField
          select
          error={error}
          label={label}
          name={name}
          size="small"
          sx={{ fontSize: "14px" }}
          value={value}
          onChange={onChange}
          color="success"
          required={required}
          style={style}
          placeholder={placeholder}
          slotProps={slotProps}
        >
          {menuItem}
        </TextField>
        <FormHelperText>{error && helperText}</FormHelperText>
      </FormControl>
    </>
  );
};

export default MuiSelect;
