import React, { Component } from "react";
import ReactModal from "react-modal";
import "../../sass/CustomerModal.scss";
import { withRouter } from "react-router-dom";
import axios from "axios";
import AccountIcon from "../../images/AccountIcon.svg";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { countriesList } from "../../constants/countriesList";
import { colourStyles } from "../../constants/selectStyles";
import { usStatesList } from "../../constants/usStatesList";
import {
  getCurrencyValue,
  isValidCurrency,
} from "../../utils/Helper/reusableFunctions";
import { AsYouType } from "libphonenumber-js";

// Redux stuff
import { connect } from "react-redux";
import {
  setTab,
  setActiveTabIndex,
  companyOnboardingSet,
  fetchAllAllFormAccountFields,
  authUserSet,
} from "../../redux";

import { MenuItem, Modal } from "@mui/material";
import InputField from "../../components/Reusables/InputField/InputField";
import MuiSelect from "../../components/Reusables/Select/MuiSelect";
import MuiButton from "../../components/Reusables/Button/MuiButton";

import { muiModalStyle } from "../../constants/muiModalStyle";
import { customStylesErr } from "../../constants/selectStyles";

import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";

class CreateAccountModal extends Component {
  state = {
    fieldsData: [],
    customFields: {},
    customFieldsForAPI: {},
    errorMessage: false,
    windowWidth: window.innerWidth,
    isLoading: false,
    phoneValidationError: false,
    emailValidationError: false,
    menuOpen: "bottom",
    customFieldRevenueTypeCheck: [],
    defaultFields: {
      account_owner: null,
      sharedAccountOwners: [],
      name: null,
      phone: null,
      phone_ext: null,
      email: null,
      address: null,
      city: null,
      state: null,
      zip: null,
      country: "United States",
      preferred_communication: [],
      source: null,
      website: null,
      linkedin: null,
      description: null,
    },
    allFields: [],
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.handleCloseModal();
    this.setState({
      fieldsData: [],
      name: null,
      state: null,
      source: null,
      zip: null,
      city: null,
      address: null,
      email: null,
      phone: null,
      phone_ext: null,
      website: null,
      linkedin: null,
      description: null,
      preferred_communication: [],
      frontendPreferredCommunicationArray: [],
      customFields: {},
      customFieldsForAPI: {},
      errorMessage: false,
      isLoading: false,
      phoneValidationError: false,
      emailValidationError: false,
      customFieldRevenueTypeCheck: [],
      defaultFields: {
        name: null,
        phone: null,
        phone_ext: null,
        email: null,
        address: null,
        city: null,
        state: null,
        zip: null,
        country: "United States",
        preferred_communication: [],
        source: null,
        website: null,
        linkedin: null,
        description: null,
        sharedAccountOwners: [],
      },
      isHovered: false,
    });
  };
  fetchAccountData = () => {
    this.setState({
      fieldsData: this.props.allAccountCustomFields,
    });
    const customField = this.props.allAccountCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field) => {
            const { name } = field;
            return name;
          })
        );
      })
      .filter((m) => m !== false);
    const merged = customField.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArray = merged.filter((m) => m !== false);
    const obj = customFieldArray.reduce((accumulator, currentValue) => {
      accumulator[currentValue] = null;
      return accumulator;
    }, {});
    const customFieldTypeCheck = this.props.allAccountCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field) => field)
        );
      })
      .filter((m) => m !== false);
    const mergedTypeCheck = customFieldTypeCheck.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArrayTypeCheck = mergedTypeCheck.filter(
      (m) => m !== false && m.type === "revenue"
    );
    const objTypeCheck = customFieldArrayTypeCheck.reduce(
      (obj, item) => Object.assign(obj, { [item.name]: item.type }),
      {}
    );
    this.setState((prevState) => ({
      customFields: obj,
      customFieldsForAPI: obj,
      customFieldRevenueTypeCheck: objTypeCheck,
    }));
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize, { passive: true });
    window.addEventListener("mousemove", this.mouseCoordinates, {
      passive: true,
    });
    this.props.fetchAllAllFormAccountFields();
    this.fetchAccountData();
    this.setState((prevState) => ({
      defaultFields: {
        ...prevState.defaultFields,
        account_owner: this.props.authUser.id,
        country: this.props.companyOnboarding?.country
          ? this.props.companyOnboarding?.country
          : "United States",
      },
      allFields: this.props.allAccountFields,
    }));
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.showOpportunityModal === true &&
      prevProps.showOpportunityModal !== this.props.showOpportunityModal
    ) {
      this.fetchAccountData();
      this.setState((prevState) => ({
        defaultFields: {
          ...prevState.defaultFields,
          account_owner: this.props.authUser.id,
          country: this.props.companyOnboarding?.country
            ? this.props.companyOnboarding?.country
            : "United States",
        },
        allFields: this.props.allAccountFields,
      }));
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("mousemove", this.mouseCoordinates);
  }
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  changeHandler = (name, e) => {
    const { value } = e.target;
    if (name === "phone") {
      const formatter = new AsYouType("US"); // Initialize AsYouType instance
      const formattedValue = formatter.input(value); // Format the input value
      this.setState((prevState) => ({
        defaultFields: {
          ...prevState.defaultFields,
          [name]: formattedValue ? formattedValue : null,
        },
      }));
    } else if (e.target.name === "account_owner") {
      this.setState((prevState) => ({
        defaultFields: {
          ...prevState.defaultFields,
          [name]: e.target.value === "" ? null : e.target.value,
          sharedAccountOwners: [],
        },
      }));
    } else {
      this.setState((prevState) => ({
        defaultFields: {
          ...prevState.defaultFields,
          [name]: e.target.value === "" ? null : e.target.value,
        },
      }));
    }
  };
  accountOwnerMultiDropdownChangeHandler = (selectedOptions) => {
    this.setState((prevState) => ({
      defaultFields: {
        ...prevState.defaultFields,
        sharedAccountOwners: selectedOptions,
      },
    }));
  };
  changeHandlerSelect = (name, value) => {
    this.setState((prevState) => ({
      defaultFields: { ...prevState.defaultFields, [name]: value.value },
    }));
  };
  customFieldChangeHandler = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      customFields: { ...prevState.customFields, [name]: value },
      customFieldsForAPI: { ...prevState.customFieldsForAPI, [name]: value },
    }));
  };
  customFieldRevenueChangeHandler = (e) => {
    const { name, value } = e.target;
    const checkValidations = isValidCurrency(value);
    if (checkValidations) {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: value },
        customFieldsForAPI: { ...prevState.customFieldsForAPI, [name]: value },
      }));
    } else {
      return;
    }
  };
  customFieldSelectChangeHandler = (name, value) => {
    this.setState((prevState) => ({
      customFields: { ...prevState.customFields, [name]: value },
      customFieldsForAPI: { ...prevState.customFieldsForAPI, [name]: value },
    }));
  };
  customFieldCheckBoxChangeHandler = (e) => {
    const { name, value } = e.target;
    if (e.target.checked === true) {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: value },
        customFieldsForAPI: {
          ...prevState.customFieldsForAPI,
          [name]: value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        customFields: { ...prevState.customFields, [name]: null },
        customFieldsForAPI: {
          ...prevState.customFieldsForAPI,
          [name]: null,
        },
      }));
    }
  };
  defaultFieldsMultiDropdownChangeHandler = (fieldName, selectedOptions) => {
    let selectedArray =
      selectedOptions
        ?.reduce((acc, val) => {
          return [acc, val.value];
        }, [])
        .flat(Infinity) ?? [];
    this.setState((prevState) => ({
      defaultFields: {
        ...prevState.defaultFields,
        [fieldName]: selectedOptions,
      },
      multiSelectDropdownValue: selectedArray,
    }));
  };
  customFieldMultiDropdownChangeHandler = (fieldName, selectedOptions) => {
    let selectedArray =
      selectedOptions
        ?.reduce((acc, val) => {
          return [acc, val.value];
        }, [])
        .flat(Infinity) ?? [];
    this.setState((prevState) => ({
      customFields: {
        ...prevState.customFields,
        [fieldName]: selectedOptions,
      },
      customFieldsForAPI: {
        ...prevState.customFieldsForAPI,
        [fieldName]:
          ";" + selectedOptions?.map((option) => option.value)?.join(";") + ";",
      },
      multiSelectDropdownValue: selectedArray,
    }));
  };
  customFieldDateChangeHandler = (type, date) => {
    this.setState((prevState) => ({
      customFields: { ...prevState.customFields, [type]: date },
      customFieldsForAPI: { ...prevState.customFieldsForAPI, [type]: date },
    }));
  };
  submitHandler = (e) => {
    e.preventDefault();
    const phoneValidator = /^[0-9.+)( ,-]*$/;
    const emailValidator = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,16}$/i;
    if (
      this.state.defaultFields.phone !== null &&
      this.state.defaultFields.phone !== "" &&
      !phoneValidator.test(this.state.defaultFields.phone)
    ) {
      this.setState({
        isLoading: false,
        phoneValidationError: true,
      });
      return false;
    }
    if (
      this.state.defaultFields.phone !== null &&
      this.state.defaultFields.phone !== "" &&
      phoneValidator.test(this.state.defaultFields.phone)
    ) {
      this.setState({
        phoneValidationError: false,
      });
    }
    if (
      this.state.defaultFields.email !== null &&
      this.state.defaultFields.email !== "" &&
      !emailValidator.test(this.state.defaultFields.email)
    ) {
      this.setState({
        isLoading: false,
        emailValidationError: true,
      });
      return false;
    }
    if (
      this.state.defaultFields.email !== null &&
      this.state.defaultFields.email !== "" &&
      emailValidator.test(this.state.defaultFields.email)
    ) {
      this.setState({
        emailValidationError: false,
      });
    }
    if (
      this.state.defaultFields.name === null ||
      this.state.defaultFields.name === ""
    ) {
      this.setState({ errorMessage: true });
      return;
    }
    const requiredFields = this.state.allFields
      ?.filter((field) => field.required)
      ?.map((field) => field.name);
    const isNotError = requiredFields?.every((field) =>
      Array.isArray(this.state.defaultFields[field])
        ? this.state.defaultFields[field].length !== 0
        : this.state.defaultFields[field] !== null &&
          this.state.defaultFields[field] !== ""
    );
    const isNotErrorCustom = requiredFields?.every((field) =>
      Array.isArray(this.state.customFields[field])
        ? this.state.customFields[field].length !== 0
        : this.state.customFields[field] !== null &&
          this.state.customFields[field] !== ""
    );
    console.log("isNotError", isNotError);
    if (!isNotError || !isNotErrorCustom) {
      this.setState({ errorMessage: true });
      return false;
    }
    let defaultFields = this.state.defaultFields;
    for (let i in defaultFields) {
      if (defaultFields[i] === "") {
        defaultFields[i] = null;
      }
    }
    let customField = this.state.customFieldsForAPI;
    for (let i in customField) {
      if (customField[i] === "") {
        customField[i] = null;
      }
    }
    for (let i in this.state.customFieldRevenueTypeCheck) {
      customField[i] = getCurrencyValue(customField[i]);
    }
    this.setState({ isLoading: true });
    axios({
      method: "POST",
      url: `/accounts/create`,
      data: {
        owners:
          !defaultFields.account_owner ||
          defaultFields.account_owner === "Unassigned Leads"
            ? []
            : defaultFields.sharedAccountOwners
                ?.map((user) => ({
                  is_primary: false,
                  owner_id: user.value,
                }))
                .concat([
                  {
                    is_primary: true,
                    owner_id: defaultFields.account_owner,
                  },
                ]),
        name: defaultFields.name,
        source: defaultFields.source,
        state: defaultFields.state,
        zip: defaultFields.zip,
        city: defaultFields.city,
        address: defaultFields.address,
        country:
          defaultFields.country === null
            ? "United States"
            : defaultFields.country,
        email: defaultFields.email,
        formatted_phone: defaultFields.phone,
        phone_ext: defaultFields.phone_ext,
        website:
          defaultFields.website === null
            ? null
            : defaultFields.website.startsWith("https://") ||
              defaultFields.website.startsWith("http://")
            ? defaultFields.website
            : "https://" + defaultFields.website,
        linkedin:
          defaultFields.linkedin === null
            ? null
            : defaultFields.linkedin.startsWith("https://") ||
              defaultFields.linkedin.startsWith("http://")
            ? defaultFields.linkedin
            : "https://" + defaultFields.linkedin,
        description:
          defaultFields.description === "" ? null : defaultFields.description,
        preferred_communication: defaultFields.preferred_communication?.map(
          (pref) => pref.value
        ),
        custom_fields: customField,
      },
    })
      .then((response) => {
        let newTabData = {
          type: "account",
          id: response.data.account.account_id,
          name: response.data.account.name,
        };

        this.props.setActiveTabIndex(this.props.totalTabs + 1);
        this.props.setTab(newTabData);
        this.props.history.push("/active-tabs");
        this.handleCloseModal();
      })
      .then((response) => {
        NotificationManager.success("Account created successfully!");
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        if (error?.response?.status === 500) {
          NotificationManager.error("Error creating account.");
        } else {
          NotificationManager.error(error?.response?.data.message);
        }
      })
      .finally(() => {
        this.fetchAccountData();
      });
  };
  mouseCoordinates = (event) => {
    const position = window.innerHeight - event.clientY;
    this.setState({
      menuOpen: position < 450 ? "top" : "bottom",
    });
  };
  showInput = (
    fieldName,
    fieldLabel,
    fieldType,
    fieldChoices,
    fieldRequired,
    fieldParent
  ) => {
    let inputType;

    const err =
      this.state.errorMessage === true &&
      fieldRequired &&
      (this.state.defaultFields[fieldName] === null ||
        this.state.defaultFields[fieldName] === "" ||
        this.state.defaultFields[fieldName]?.length === 0 ||
        this.state.customFields[fieldName] === null ||
        this.state.customFields[fieldName] === "" ||
        this.state.customFields[fieldName]?.length === 0);

    const emailErr =
      fieldName === "email" &&
      this.state.emailValidationError === true &&
      this.state.defaultFields.email !== "" &&
      this.state.defaultFields.email !== null;

    const phoneErr =
      fieldName === "phone" &&
      this.state.phoneValidationError === true &&
      this.state.defaultFields.phone !== "" &&
      this.state.defaultFields.phone !== null;

    if (fieldType === "text") {
      inputType = (
        <div style={{ marginBottom: "15px" }}>
          <InputField
            type="text"
            name={fieldName}
            error={
              fieldName === "email"
                ? emailErr
                : fieldName === "phone"
                ? phoneErr
                : err
            }
            helperText={
              fieldName === "email"
                ? "Please enter a valid email address"
                : fieldName === "phone"
                ? "Please enter a valid phone number"
                : `${fieldLabel} is required`
            }
            label={fieldLabel}
            value={
              fieldParent === "default_fields"
                ? this.state.defaultFields[fieldName]
                : this.state.customFields[fieldName]
            }
            onChange={(e) =>
              fieldParent === "default_fields"
                ? this.changeHandler(fieldName, e)
                : this.customFieldChangeHandler(e)
            }
            required={fieldRequired}
          />
        </div>
      );
    } else if (fieldType === "hyperlink") {
      inputType = (
        <div style={{ marginBottom: "15px" }}>
          <InputField
            type="text"
            error={err}
            helperText={`${fieldLabel} is required`}
            name={fieldName}
            label={fieldLabel}
            value={this.state.customFields[fieldName]}
            onChange={(e) =>
              fieldParent === "default_fields"
                ? this.changeHandler(fieldName, e)
                : this.customFieldChangeHandler(e)
            }
            required={fieldRequired}
          />
        </div>
      );
    } else if (fieldType === "revenue") {
      inputType = (
        <div style={{ marginBottom: "15px" }}>
          <InputField
            type="text"
            error={err}
            helperText={`${fieldLabel} is required`}
            name={fieldName}
            label={fieldLabel}
            value={this.state.customFields[fieldName]}
            onChange={(e) => this.customFieldRevenueChangeHandler(e)}
            required={fieldRequired}
          />
        </div>
      );
    } else if (fieldType === "paragraph") {
      inputType = (
        <div style={{ marginBottom: "15px" }}>
          <InputField
            name={fieldName}
            label={fieldLabel}
            error={err}
            helperText={`${fieldLabel} is required`}
            value={this.state.customFields[fieldName]}
            onChange={(e) =>
              fieldParent === "default_fields"
                ? this.changeHandler(fieldName, e)
                : this.customFieldChangeHandler(e)
            }
            required={fieldRequired}
            multiline={true}
          />
        </div>
      );
    } else if (fieldType === "decimal") {
      inputType = (
        <div style={{ marginBottom: "15px" }}>
          <InputField
            type="number"
            error={err}
            helperText={`${fieldLabel} is required`}
            name={fieldName}
            label={fieldLabel}
            value={this.state.customFields[fieldName]}
            onChange={(e) =>
              fieldParent === "default_fields"
                ? this.changeHandler(fieldName, e)
                : this.customFieldChangeHandler(e)
            }
            required={fieldRequired}
          />
        </div>
      );
    } else if (fieldType === "dropdown") {
      inputType =
        fieldName === "state" ? (
          this.state.defaultFields.country !== "United States" ? (
            <div style={{ marginBottom: "15px" }}>
              <InputField
                type="text"
                error={err}
                helperText={`${fieldLabel} is required`}
                name="state"
                label={fieldLabel}
                value={this.state.defaultFields.state}
                onChange={(e) => this.changeHandler("state", e)}
                required={fieldRequired}
              />
            </div>
          ) : (
            <div
              className="controlsForms"
              style={{ width: "100%", marginTop: "5px", marginBottom: "10px" }}
            >
              <Select
                className="basic-single"
                classNamePrefix="select"
                styles={colourStyles}
                value={{
                  value: this.state.defaultFields.state,
                  label: this.state.defaultFields.state,
                }}
                placeholder="-Select-"
                onChange={(selected) =>
                  this.changeHandlerSelect("state", selected)
                }
                isClearable={false}
                isSearchable={true}
                menuPlacement={this.state.menuOpen}
                options={usStatesList.map((state) => ({
                  value: state,
                  label: state,
                }))}
              />
              <label className="activeLabel">
                <span style={{ fontSize: "12px" }}>{fieldLabel}</span>{" "}
                {fieldRequired && (
                  <span
                    style={{
                      color: "#4A9876",
                      fontSize: "17px",
                      lineHeight: "16px",
                    }}
                  >
                    *
                  </span>
                )}
              </label>
            </div>
          )
        ) : fieldName === "country" ? (
          <div
            className="controlsForms"
            style={{ width: "100%", marginTop: "5px", marginBottom: "10px" }}
          >
            <Select
              className="basic-single"
              classNamePrefix="select"
              styles={colourStyles}
              value={{
                value: this.state.defaultFields.country,
                label: this.state.defaultFields.country,
              }}
              placeholder="-Select-"
              onChange={(selected) =>
                this.changeHandlerSelect("country", selected)
              }
              isClearable={false}
              isSearchable={true}
              menuPlacement={this.state.menuOpen}
              options={countriesList.map((country) => ({
                value: country,
                label: country,
              }))}
            />
            <label className="activeLabel">
              <span style={{ fontSize: "12px" }}>{fieldLabel}</span>{" "}
              {fieldRequired && (
                <span
                  style={{
                    color: "#4A9876",
                    fontSize: "17px",
                    lineHeight: "16px",
                  }}
                >
                  *
                </span>
              )}
            </label>
          </div>
        ) : fieldName === "account_owner_id" ? (
          <>
            <div style={{ marginBottom: "0px", marginTop: "15px" }}>
              <MuiSelect
                label="Account Owner *"
                error={err}
                helperText={`${fieldLabel} is required`}
                value={this.state.defaultFields.account_owner}
                onChange={(e) => this.changeHandler("account_owner", e)}
                name="account_owner"
                menuItem={[
                  ...(this.props.authUser.permissions.includes(
                    "crm:unassigned:leads:viewer"
                  ) || this.props.authUser.role === "SUPERADMIN"
                    ? [
                        <MenuItem value="Unassigned Leads">
                          Unassigned Leads
                        </MenuItem>,
                      ]
                    : []),
                  ...(this.props.allUsers?.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {user.name}
                    </MenuItem>
                  )) || []),
                ]}
              />
            </div>
            {/* <select
              value={this.state.defaultFields.account_owner}
              onChange={(e) => this.changeHandler("account_owner", e)}
              name="account_owner"
              style={{ width: "99%" }}
            >
              {(this.props.authUser.permissions.includes(
                "crm:unassigned:leads:viewer"
              ) ||
                this.props.authUser.role === "SUPERADMIN") && (
                <option value="">Unassigned Leads</option>
              )}
              {this.props.allUsers?.map((user) => {
                const { id, name } = user;
                return (
                  <option key={id} value={id}>
                    {name}
                  </option>
                );
              })}
            </select> */}
            {this.state.defaultFields.account_owner &&
              this.props.companyOnboarding
                ?.is_multiple_account_owner_enabled && (
                <>
                  <div
                    className="controlsForms"
                    style={{
                      width: "100%",
                      marginBottom: "10px",
                      marginTop: "5px",
                    }}
                  >
                    <Select
                      value={this.state.defaultFields.sharedAccountOwners}
                      closeMenuOnSelect={false}
                      menuPlacement={this.state.menuOpen}
                      isMulti
                      name={"accountOwner"}
                      styles={colourStyles}
                      options={this.props.allUsers
                        ?.filter(
                          (user) =>
                            user.id?.toString() !==
                            this.state.defaultFields.account_owner?.toString()
                        )
                        ?.map((user) => ({
                          value: user.id,
                          label: user.name,
                        }))}
                      placeholder="-Select-"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      required={true}
                      onChange={(selectedOption) =>
                        this.accountOwnerMultiDropdownChangeHandler(
                          selectedOption
                        )
                      }
                    />
                    <label className="activeLabel">Shared Account Owners</label>
                  </div>
                </>
              )}
          </>
        ) : fieldName === "source" ? (
          <div
            className="controlsForms"
            style={{
              width: "100%",
              marginBottom: "10px",
              marginTop: "5px",
            }}
          >
            <Select
              className="basic-single"
              classNamePrefix="select"
              styles={colourStyles}
              value={{
                value: this.state.defaultFields.source,
                label: this.state.defaultFields.source,
              }}
              placeholder="-Select-"
              onChange={(selected) =>
                this.changeHandlerSelect("source", selected)
              }
              isClearable={false}
              isSearchable={true}
              menuPlacement={this.state.menuOpen}
              options={this.props.allAccountSources?.map((source) => ({
                value: source.name,
                label: source.name,
              }))}
            />
            <label className="activeLabel">{fieldLabel}</label>
          </div>
        ) : (
          <div
            className="controlsForms"
            style={{
              width: "100%",
              marginBottom: "10px",
              marginTop: "5px",
            }}
          >
            <Select
              className="basic-single"
              classNamePrefix="select"
              styles={err ? customStylesErr : colourStyles}
              required={fieldRequired}
              value={{
                value: this.state.customFields[fieldName],
                label: this.state.customFields[fieldName],
              }}
              placeholder="-Select-"
              onChange={(selected) =>
                fieldParent === "default_fields"
                  ? this.changeHandlerSelect(fieldName, selected.value)
                  : this.customFieldSelectChangeHandler(
                      fieldName,
                      selected.value
                    )
              }
              isClearable={false}
              menuPlacement={this.state.menuOpen}
              isSearchable={true}
              name={fieldName}
              options={fieldChoices.map((choice) => {
                const { value } = choice;
                return { value, label: value };
              })}
            />
            <label
              className="activeLabel"
              style={{ color: err ? "#d32f2f" : "" }}
            >
              {fieldLabel}
              {fieldRequired && (
                <span
                  style={{
                    color: "4A9876",
                    fontSize: "17px",
                    lineHeight: "16px",
                  }}
                >
                  *
                </span>
              )}
            </label>
            {err && (
              <span
                style={{
                  color: "#d32f2f",
                  fontSize: "12px",
                }}
              >
                {fieldLabel} is required
              </span>
            )}
          </div>
        );
    } else if (fieldType === "checkbox") {
      inputType = (
        <div style={{ width: "20px", marginBottom: "15px" }}>
          <label>
            {fieldLabel}{" "}
            {fieldRequired && (
              <span
                style={{
                  color: "#4A9876",
                  fontSize: "17px",
                  lineHeight: "16px",
                }}
              >
                *
              </span>
            )}
          </label>
          <input
            type="checkbox"
            style={{ display: "inline-block", textAlign: "left" }}
            name={fieldName}
            value={true}
            onChange={this.customFieldCheckBoxChangeHandler}
            checked={this.state.customFields[fieldName]}
          />
        </div>
      );
    } else if (fieldType === "radio") {
      inputType = (
        <div>
          <label>
            {fieldLabel}{" "}
            {fieldRequired && (
              <span
                style={{
                  color: "#4A9876",
                  fontSize: "17px",
                  lineHeight: "16px",
                }}
              >
                *
              </span>
            )}
          </label>
          {fieldChoices.map((choice) => {
            const { id, value } = choice;
            return (
              <div key={id} style={{ textAlign: "left", marginBottom: "15px" }}>
                <input
                  type="radio"
                  style={{
                    display: "inline-block",
                    textAlign: "left",
                    width: "20px",
                  }}
                  name={fieldName}
                  value={value}
                  onChange={(e) =>
                    fieldParent === "default_fields"
                      ? this.changeHandler(fieldName, e)
                      : this.customFieldChangeHandler(e)
                  }
                  checked={this.state.customFields[fieldName] === value}
                  id={fieldName + value}
                  required={fieldRequired}
                />
                <label
                  style={{ display: "inline-block", textAlign: "left" }}
                  htmlFor={fieldName + value}
                >
                  {value}
                </label>
              </div>
            );
          })}
        </div>
      );
    } else if (fieldType === "date") {
      inputType = (
        <div
          className="controlsForms"
          style={{ width: "100%", marginBottom: "10px", marginTop: "5px" }}
        >
          <DatePicker
            name={fieldName}
            className="datePicker"
            selected={Date.parse(this.state.customFields[fieldName])}
            value={Date.parse(this.state.customFields[fieldName])}
            onChange={(date) =>
              this.customFieldDateChangeHandler(fieldName, date)
            }
            dateFormat={
              this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
                ? "dd/MM/yyyy"
                : "MM/dd/yyyy"
            }
            placeholderText={
              this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
                ? "dd/mm/yyyy"
                : "mm/dd/yyyy"
            }
            autoComplete="off"
            required={fieldRequired}
            popperPlacement="top"
          />
          <label className="activeLabel">{fieldLabel}</label>
        </div>
      );
    } else if (fieldType === "multi_select_dropdown") {
      let multiSelectDropdownValue = this.state.customFields[fieldName];
      let options = [];
      if (
        fieldName === "cf_equipment_type" ||
        fieldName === "cf_equipment_types"
      ) {
        options = this.props.allEquipmentTypes?.map((type) => {
          return { value: type.name, label: type.name };
        });
      } else if (fieldName === "cf_special_requirements") {
        options = this.props.allSpecialRequirements?.map((type) => {
          return { value: type.name, label: type.name };
        });
      } else if (fieldName === "cf_modes") {
        options = this.props.allModes?.map((type) => {
          return { value: type.name, label: type.name };
        });
      } else {
        options = fieldChoices.map((choice) => {
          const { value } = choice;
          return { value, label: value };
        });
      }
      inputType = (
        <div
          className="controlsForms"
          style={{ width: "100%", marginBottom: "10px", marginTop: "5px" }}
        >
          <Select
            value={multiSelectDropdownValue}
            closeMenuOnSelect={false}
            isMulti
            name={fieldName}
            styles={err ? customStylesErr : colourStyles}
            options={options}
            placeholder="-Select-"
            className="basic-multi-select"
            classNamePrefix="select"
            menuPlacement={this.state.menuOpen}
            required={fieldRequired}
            onChange={(selectedOption) =>
              this.customFieldMultiDropdownChangeHandler(
                fieldName,
                selectedOption
              )
            }
          />
          <label className="activeLabel">
            <span style={{ fontSize: "12px", color: err ? "#d32f2f" : "" }}>
              {fieldLabel}
            </span>{" "}
            {fieldRequired && (
              <span
                style={{
                  color: err ? "#d32f2f" : "#4A9876",
                  fontSize: "17px",
                  lineHeight: "16px",
                }}
              >
                *
              </span>
            )}
          </label>
          {err && (
            <span
              style={{
                color: "#d32f2f",
                fontSize: "12px",
              }}
            >
              {fieldLabel} is required
            </span>
          )}
        </div>
      );
    }
    return (
      <>
        {fieldType === "heading" ? (
          <h3 className="modal__heading-sub">{fieldLabel}</h3>
        ) : (
          <div>
            {/* <label>
              {fieldLabel}{" "}
              {fieldRequired && (
                <span
                  style={{
                    color: "#4A9876",
                    fontSize: "17px",
                    lineHeight: "16px",
                  }}
                >
                  *
                </span>
              )}
            </label> */}
            {inputType}
            {/* <div
              style={{
                color: "#F36363",
                fontSize: "12px",
                marginBottom: "5px",
              }}
            >
              {this.state.errorMessage === true &&
              fieldRequired &&
              (this.state.defaultFields[fieldName] === null ||
                this.state.defaultFields[fieldName] === "" ||
                this.state.defaultFields[fieldName]?.length === 0 ||
                this.state.customFields[fieldName] === null ||
                this.state.customFields[fieldName] === "" ||
                this.state.customFields[fieldName]?.length === 0)
                ? `${fieldLabel} is required`
                : null}
            </div> */}
            {/* <div
              style={{
                color: "#F36363",
                fontSize: "12px",
                marginBottom: "5px",
              }}
            >
              {fieldName === "email" &&
              this.state.emailValidationError === true &&
              this.state.defaultFields.email !== "" &&
              this.state.defaultFields.email !== null
                ? `Please enter a valid email address`
                : null}
            </div> */}
            {/* <div
              style={{
                color: "#F36363",
                fontSize: "12px",
                marginBottom: "5px",
              }}
            >
              {fieldName === "phone" &&
              this.state.phoneValidationError === true &&
              this.state.defaultFields.phone !== "" &&
              this.state.defaultFields.phone !== null
                ? `Please enter a valid phone number`
                : null}
            </div> */}
          </div>
        )}
      </>
    );
  };

  render() {
    const flattenedFields =
      this.props.allAccountCustomFields &&
      Array.isArray(this.props.allAccountCustomFields) &&
      this.props.allAccountCustomFields?.reduce((accumulator, section) => {
        if (section.fields && Array.isArray(section.fields)) {
          return [...accumulator, ...section.fields];
        }
        return accumulator;
      }, []);

    const filteredAllFields = this.state.allFields?.map((item) => {
      const matchingItem = flattenedFields?.find(
        (cfItem) => item.id === cfItem.id
      );

      if (matchingItem) {
        // Only take the choices key from matchingItem, keep everything else from item
        return {
          ...item,
          choices: matchingItem.choices,
        };
      }

      return item;
    });

    return (
      <Modal
        open={this.props.showOpportunityModal}
        contentLabel="onRequestClose Example"
        onClose={this.handleCloseModal}
        ariaHideApp={false}
        style={muiModalStyle}
      >
        <div className="muiModal">
          <div className="modal__content">
            <div className="modal__header">
              <span>
                <NotificationContainer />
              </span>
              <div className="modal__head">
                <h2 className="modal__heading">Create Account</h2>
              </div>
              <button
                onClick={this.handleCloseModal}
                className="modal__close"
                style={{
                  border: "none",
                  background: "transparent",
                  height: "12px",
                  cursor: "pointer",
                  outline: "none",
                }}
              >
                {" "}
                <ClearIcon fontSize="small" />
              </button>
            </div>
            <form
              className="modal__form"
              style={{
                overflowY: "scroll",
                maxHeight: "60vh",
                WebkitOverflowScrolling: "touch",
              }}
            >
              {filteredAllFields?.map((field) => {
                const { id, label, type, choices, name, required, parent_id } =
                  field;
                return (
                  <div key={id}>
                    {this.showInput(
                      name,
                      label,
                      type,
                      choices,
                      required,
                      parent_id
                    )}
                  </div>
                );
              })}
            </form>
            <div className="modal__buttons">
              <button
                onClick={this.handleCloseModal}
                type="reset"
                style={{ bottom: "10px", left: "5px" }}
                className="modal__cancel-button"
              >
                Cancel
              </button>
              <MuiButton
                type="button"
                className="saveBtn"
                style={{
                  position: "absolute",
                  bottom: "10px",
                  width: "auto",
                  height: "35px",
                  color: "white",
                  backgroundColor: "#252a2d",
                }}
                disabled={this.state.isLoading}
                onClick={this.submitHandler}
                loading={this.state.isLoading}
                endIcon={
                  <AddIcon
                    style={{
                      color: this.state.isHovered ? "black" : "#62CA9D",
                    }}
                  />
                }
                onMouseEnter={() => {
                  this.setState({ isHovered: true });
                }}
                onMouseLeave={() => {
                  this.setState({ isHovered: false });
                }}
              >
                {this.state.isLoading ? "Creating..." : "Create"}{" "}
              </MuiButton>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    companyOnboarding: state.login.companyOnboarding,
    allAccountFields: state.allAccountFields.fields,
    authUser: state.login.authUser,
    allEquipmentTypes: state.allEquipmentTypes.equipmentTypes,
    allSpecialRequirements: state.allSpecialRequirements.specialRequirements,
    allModes: state.allModes.modes,
    allUsers: state.allUsers.users,
    allAccountSources: state.allAccountSources.accountSources,
    allAccountCustomFields: state.allAccountCustomFields.fields,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
    fetchAllAllFormAccountFields: (allAccountFields) =>
      dispatch(fetchAllAllFormAccountFields(allAccountFields)),
  };
};

export default connect(MSP, MDP)(withRouter(CreateAccountModal));
