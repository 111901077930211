export const defaultQuoteFields = {
  field_class: "quote",
  fields: [
    {
      choices: [],
      field_class: "quote",
      id: "d-ship_date",
      label: "Ship Date",
      name: "ship_date",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: true,
      type: "date",
      visible: null,
      selected_by_default: true,
    },
    {
      choices: [],
      field_class: "quote",
      id: "d-delivery_date",
      label: "Delivery Date",
      name: "delivery_date",
      parent_id: "default_fields",
      placeholder: "Select Delivery Date",
      position: 1,
      required: false,
      type: "date",
      visible: null,
      selected_by_default: true,
    },

    {
      choices: [],
      field_class: "quote",
      id: "d-origin",
      label: "Origin",
      name: "origin",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: true,
      type: "text",
      visible: null,
      selected_by_default: true,
    },
    {
      choices: [],
      field_class: "quote",
      id: "d-destination",
      label: "Destination",
      name: "destination",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: true,
      type: "text",
      visible: null,
      selected_by_default: true,
    },

    {
      choices: [
        { label: "Pending", value: "pending" },
        { label: "Won", value: "won" },
        { label: "Lost", value: "lost" },
      ],
      field_class: "quote",
      id: "d-status",
      label: "Status",
      name: "status",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: true,
      type: "dropdown",
      visible: null,
      selected_by_default: true,
    },
    {
      choices: [],
      field_class: "quote",
      id: "d-equipment_types",
      label: "Equipment Types",
      name: "cf_equipment_types",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: true,
      type: "multi_select_dropdown",
      visible: null,
      selected_by_default: true,
    },
    {
      choices: [],
      field_class: "quote",
      id: "d-modes",
      label: "Modes",
      name: "cf_modes",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: false,
      type: "multi_select_dropdown",
      visible: null,
    },
    {
      choices: [],
      field_class: "quote",
      id: "d-special_requirements",
      label: "Special Requirements",
      name: "cf_special_requirements",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: false,
      type: "multi_select_dropdown",
      visible: null,
    },
    {
      choices: [],
      field_class: "quote",
      id: "d-lane_type",
      label: "Lane Type",
      name: "cf_lane_type",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: false,
      type: "multi_select_dropdown",
      visible: null,
    },

    {
      choices: [],
      field_class: "quote",
      id: "d-quote",
      label: "Quote",
      name: "quote",
      parent_id: "default_fields",
      placeholder: null,
      position: 1,
      required: true,
      type: "decimal",
      visible: null,
      selected_by_default: true,
    },
  ],
  id: "default_fields",
  label: "Default Fields",
  meta: null,
  name: "default_fields",
  placeholder: null,
  position: 0,
  type: "section",
};
